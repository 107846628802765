import { AccordionItem } from "./AccordionItem"
import { PFlex } from "@porsche-design-system/components-react"
import { FC, ReactNode } from "react"

/**
 * An Accordion is a vertical box containing AccordionItems.
 */
export interface AccordionProps {
    /** flag if the items should all be shown as expanded. */
    expandAll: boolean
    /** context prefix to use for saving state in url */
    urlParamsPrefix: string
    /** index that should be initially expanded, defaults to 0, use a negative number to expand nothing */
    initiallyExpandedIndex?: number | "all"
    /** props defining the AccordionItems. */
    items: {
        label: string
        children: ReactNode
    }[]
}

export const Accordion: FC<AccordionProps> = (props) => (
    <PFlex direction={"column"}>
        {props.items.map((item, index) => (
            <AccordionItem
                key={`accordion_item_${index}`}
                label={item.label}
                isInitiallyExpanded={props.initiallyExpandedIndex === "all" || index === (props.initiallyExpandedIndex ?? 0)}
                urlParamsPrefix={`${props.urlParamsPrefix}.${index}`}
                showExpanded={props.expandAll}
            >
                {item.children}
            </AccordionItem>
        ))}
    </PFlex>
)
