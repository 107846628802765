/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    LinkedRoofColorCustomizationDTO,
    LinkedRoofColorCustomizationDTOFromJSON,
    LinkedRoofColorCustomizationDTOToJSON,
    RoofColorOptionDTO,
    RoofColorOptionDTOFromJSON,
    RoofColorOptionDTOToJSON,
    RoofColorOptionUpdateDTO,
    RoofColorOptionUpdateDTOFromJSON,
    RoofColorOptionUpdateDTOToJSON,
    SimilarRoofColorCustomizationsDTO,
    SimilarRoofColorCustomizationsDTOFromJSON,
    SimilarRoofColorCustomizationsDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface GetLinkedCustomizationOfRoofColorRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

export interface GetOptionOfRoofColorRequest {
    orderTypeKey: string;
    optionCode: string;
}

export interface GetSimilarCustomizationsOfRoofColorRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

export interface UpdateOptionOfRoofColorRequest {
    orderTypeKey: string;
    optionCode: string;
    roofColorOptionUpdateDTO: RoofColorOptionUpdateDTO;
}

/**
 * 
 */
export class OrderTypeRoofColorDataDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get linkedCustomization of RoofColor with given key
     */
    async getLinkedCustomizationOfRoofColorRaw(requestParameters: GetLinkedCustomizationOfRoofColorRequest): Promise<runtime.ApiResponse<LinkedRoofColorCustomizationDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getLinkedCustomizationOfRoofColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getLinkedCustomizationOfRoofColor.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinkedCustomizationOfRoofColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/roof-color/data/linked-customization`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkedRoofColorCustomizationDTOFromJSON(jsonValue));
    }

    /**
     * Get linkedCustomization of RoofColor with given key
     */
    async getLinkedCustomizationOfRoofColor(requestParameters: GetLinkedCustomizationOfRoofColorRequest): Promise<LinkedRoofColorCustomizationDTO> {
        const response = await this.getLinkedCustomizationOfRoofColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get option of RoofColor with given key
     */
    async getOptionOfRoofColorRaw(requestParameters: GetOptionOfRoofColorRequest): Promise<runtime.ApiResponse<RoofColorOptionDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getOptionOfRoofColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getOptionOfRoofColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/roof-color/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoofColorOptionDTOFromJSON(jsonValue));
    }

    /**
     * Get option of RoofColor with given key
     */
    async getOptionOfRoofColor(requestParameters: GetOptionOfRoofColorRequest): Promise<RoofColorOptionDTO> {
        const response = await this.getOptionOfRoofColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get similarCustomizations of RoofColor with given key
     */
    async getSimilarCustomizationsOfRoofColorRaw(requestParameters: GetSimilarCustomizationsOfRoofColorRequest): Promise<runtime.ApiResponse<SimilarRoofColorCustomizationsDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getSimilarCustomizationsOfRoofColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getSimilarCustomizationsOfRoofColor.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getSimilarCustomizationsOfRoofColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/roof-color/data/similar-customizations`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SimilarRoofColorCustomizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get similarCustomizations of RoofColor with given key
     */
    async getSimilarCustomizationsOfRoofColor(requestParameters: GetSimilarCustomizationsOfRoofColorRequest): Promise<SimilarRoofColorCustomizationsDTO> {
        const response = await this.getSimilarCustomizationsOfRoofColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates option of an existing RoofColor
     */
    async updateOptionOfRoofColorRaw(requestParameters: UpdateOptionOfRoofColorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling updateOptionOfRoofColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling updateOptionOfRoofColor.');
        }

        if (requestParameters.roofColorOptionUpdateDTO === null || requestParameters.roofColorOptionUpdateDTO === undefined) {
            throw new runtime.RequiredError('roofColorOptionUpdateDTO','Required parameter requestParameters.roofColorOptionUpdateDTO was null or undefined when calling updateOptionOfRoofColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/roof-color/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoofColorOptionUpdateDTOToJSON(requestParameters.roofColorOptionUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates option of an existing RoofColor
     */
    async updateOptionOfRoofColor(requestParameters: UpdateOptionOfRoofColorRequest): Promise<void> {
        await this.updateOptionOfRoofColorRaw(requestParameters);
    }

}
