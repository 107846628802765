/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VehicleEquipmentCategory,
    VehicleEquipmentCategoryFromJSON,
    VehicleEquipmentCategoryFromJSONTyped,
    VehicleEquipmentCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionDTOVehicleEquipmentCategory
 */
export interface OptionDTOVehicleEquipmentCategory {
    /**
     * 
     * @type {VehicleEquipmentCategory}
     * @memberof OptionDTOVehicleEquipmentCategory
     */
    key: VehicleEquipmentCategory;
    /**
     * 
     * @type {string}
     * @memberof OptionDTOVehicleEquipmentCategory
     */
    label: string;
}

export function OptionDTOVehicleEquipmentCategoryFromJSON(json: any): OptionDTOVehicleEquipmentCategory {
    return OptionDTOVehicleEquipmentCategoryFromJSONTyped(json, false);
}

export function OptionDTOVehicleEquipmentCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionDTOVehicleEquipmentCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': VehicleEquipmentCategoryFromJSON(json['key']),
        'label': json['label'],
    };
}

export function OptionDTOVehicleEquipmentCategoryToJSON(value?: OptionDTOVehicleEquipmentCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': VehicleEquipmentCategoryToJSON(value.key),
        'label': value.label,
    };
}


