/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTODriveTrain,
    OptionDTODriveTrainFromJSON,
    OptionDTODriveTrainFromJSONTyped,
    OptionDTODriveTrainToJSON,
    OptionDTOEngineType,
    OptionDTOEngineTypeFromJSON,
    OptionDTOEngineTypeFromJSONTyped,
    OptionDTOEngineTypeToJSON,
    OptionDTONumberOfSeats,
    OptionDTONumberOfSeatsFromJSON,
    OptionDTONumberOfSeatsFromJSONTyped,
    OptionDTONumberOfSeatsToJSON,
    RelatedOptionDTOStringString,
    RelatedOptionDTOStringStringFromJSON,
    RelatedOptionDTOStringStringFromJSONTyped,
    RelatedOptionDTOStringStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeCreateOptionsDTO
 */
export interface OrderTypeCreateOptionsDTO {
    /**
     * 
     * @type {Array<RelatedOptionDTOStringString>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    modelSeriesRelatedModelGenerations: Array<RelatedOptionDTOStringString>;
    /**
     * 
     * @type {Array<RelatedOptionDTOStringString>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    modelSeriesRelatedDerivatives: Array<RelatedOptionDTOStringString>;
    /**
     * 
     * @type {Array<OptionDTODriveTrain>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    driveTrains: Array<OptionDTODriveTrain>;
    /**
     * 
     * @type {Array<OptionDTOEngineType>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    engineTypes: Array<OptionDTOEngineType>;
    /**
     * 
     * @type {Array<OptionDTONumberOfSeats>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    numberOfSeats: Array<OptionDTONumberOfSeats>;
}

export function OrderTypeCreateOptionsDTOFromJSON(json: any): OrderTypeCreateOptionsDTO {
    return OrderTypeCreateOptionsDTOFromJSONTyped(json, false);
}

export function OrderTypeCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeCreateOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelSeriesRelatedModelGenerations': ((json['modelSeriesRelatedModelGenerations'] as Array<any>).map(RelatedOptionDTOStringStringFromJSON)),
        'modelSeriesRelatedDerivatives': ((json['modelSeriesRelatedDerivatives'] as Array<any>).map(RelatedOptionDTOStringStringFromJSON)),
        'driveTrains': ((json['driveTrains'] as Array<any>).map(OptionDTODriveTrainFromJSON)),
        'engineTypes': ((json['engineTypes'] as Array<any>).map(OptionDTOEngineTypeFromJSON)),
        'numberOfSeats': ((json['numberOfSeats'] as Array<any>).map(OptionDTONumberOfSeatsFromJSON)),
    };
}

export function OrderTypeCreateOptionsDTOToJSON(value?: OrderTypeCreateOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelSeriesRelatedModelGenerations': ((value.modelSeriesRelatedModelGenerations as Array<any>).map(RelatedOptionDTOStringStringToJSON)),
        'modelSeriesRelatedDerivatives': ((value.modelSeriesRelatedDerivatives as Array<any>).map(RelatedOptionDTOStringStringToJSON)),
        'driveTrains': ((value.driveTrains as Array<any>).map(OptionDTODriveTrainToJSON)),
        'engineTypes': ((value.engineTypes as Array<any>).map(OptionDTOEngineTypeToJSON)),
        'numberOfSeats': ((value.numberOfSeats as Array<any>).map(OptionDTONumberOfSeatsToJSON)),
    };
}


