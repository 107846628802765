/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TechnicalDataAttributeEquipmentRelationObject,
    TechnicalDataAttributeEquipmentRelationObjectFromJSON,
    TechnicalDataAttributeEquipmentRelationObjectFromJSONTyped,
    TechnicalDataAttributeEquipmentRelationObjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface TechnicalDataItemDTO
 */
export interface TechnicalDataItemDTO {
    /**
     * 
     * @type {string}
     * @memberof TechnicalDataItemDTO
     */
    orderTypeKey: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TechnicalDataItemDTO
     */
    orderTypeCodes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof TechnicalDataItemDTO
     */
    modelYear: number;
    /**
     * 
     * @type {string}
     * @memberof TechnicalDataItemDTO
     */
    orderTypeName: string;
    /**
     * 
     * @type {Array<TechnicalDataAttributeEquipmentRelationObject>}
     * @memberof TechnicalDataItemDTO
     */
    equipmentRelations: Array<TechnicalDataAttributeEquipmentRelationObject>;
}

export function TechnicalDataItemDTOFromJSON(json: any): TechnicalDataItemDTO {
    return TechnicalDataItemDTOFromJSONTyped(json, false);
}

export function TechnicalDataItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderTypeKey': json['orderTypeKey'],
        'orderTypeCodes': json['orderTypeCodes'],
        'modelYear': json['modelYear'],
        'orderTypeName': json['orderTypeName'],
        'equipmentRelations': ((json['equipmentRelations'] as Array<any>).map(TechnicalDataAttributeEquipmentRelationObjectFromJSON)),
    };
}

export function TechnicalDataItemDTOToJSON(value?: TechnicalDataItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderTypeKey': value.orderTypeKey,
        'orderTypeCodes': value.orderTypeCodes,
        'modelYear': value.modelYear,
        'orderTypeName': value.orderTypeName,
        'equipmentRelations': ((value.equipmentRelations as Array<any>).map(TechnicalDataAttributeEquipmentRelationObjectToJSON)),
    };
}


