/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    CreateResponseDTO,
    CreateResponseDTOFromJSON,
    CreateResponseDTOToJSON,
    ModelSeriesCreateRequestDTO,
    ModelSeriesCreateRequestDTOFromJSON,
    ModelSeriesCreateRequestDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface CreateModelSeriesRequest {
    modelSeriesCreateRequestDTO: ModelSeriesCreateRequestDTO;
}

/**
 * 
 */
export class ModelSeriesCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new ModelSeries
     */
    async createModelSeriesRaw(requestParameters: CreateModelSeriesRequest): Promise<runtime.ApiResponse<CreateResponseDTO>> {
        if (requestParameters.modelSeriesCreateRequestDTO === null || requestParameters.modelSeriesCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('modelSeriesCreateRequestDTO','Required parameter requestParameters.modelSeriesCreateRequestDTO was null or undefined when calling createModelSeries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/model-series/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelSeriesCreateRequestDTOToJSON(requestParameters.modelSeriesCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResponseDTOFromJSON(jsonValue));
    }

    /**
     * Create a new ModelSeries
     */
    async createModelSeries(requestParameters: CreateModelSeriesRequest): Promise<CreateResponseDTO> {
        const response = await this.createModelSeriesRaw(requestParameters);
        return await response.value();
    }

}
