interface Option<K> {
    key: K
}

export const findOption = <T extends Option<K>, K>(options: T[] | undefined, key: K) => options?.find((option) => option.key === key)

interface GroupedOption<T> {
    options: T[]
}

export const findGroupedOption = <T extends Option<K>, K>(groupedOptions: GroupedOption<T>[] | undefined, key: K) =>
    findOption(
        groupedOptions?.flatMap((group) => group.options),
        key
    )
