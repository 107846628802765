import { ColumnBuilder } from "../../../../components/layouts/table/Table"
import { UnmaintainedCustomColorDTO } from "../../../../generated/pdsapi"

export const unmaintainedCustomColorColumns: ColumnBuilder<UnmaintainedCustomColorDTO, undefined>[] = [
    {
        width: 100,
        headerCellProps: {
            content: "Code",
        },
        buildCellContent: (unmaintainedCustomColor) => unmaintainedCustomColor.key,
    },
    {
        grow: 1,
        headerCellProps: {
            content: "Known names",
        },
        buildCellContent: (unmaintainedCustomColor) => unmaintainedCustomColor.knownNames.join(", "),
    },
]
