/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    OptionLocalizationItemDTO,
    OptionLocalizationItemDTOFromJSON,
    OptionLocalizationItemDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface GetLocalizationsOfDescriptionOfExteriorColorRequest {
    orderTypeKey: string;
    optionCode: string;
}

export interface UpdateDescriptionOfExteriorColorRequest {
    orderTypeKey: string;
    optionCode: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class OrderTypeExteriorColorDescriptionDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get localizationsOfDescription of ExteriorColor with given key
     */
    async getLocalizationsOfDescriptionOfExteriorColorRaw(requestParameters: GetLocalizationsOfDescriptionOfExteriorColorRequest): Promise<runtime.ApiResponse<{ [key: string]: OptionLocalizationItemDTO; }>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getLocalizationsOfDescriptionOfExteriorColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getLocalizationsOfDescriptionOfExteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/exterior-color/description/localizations`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, OptionLocalizationItemDTOFromJSON));
    }

    /**
     * Get localizationsOfDescription of ExteriorColor with given key
     */
    async getLocalizationsOfDescriptionOfExteriorColor(requestParameters: GetLocalizationsOfDescriptionOfExteriorColorRequest): Promise<{ [key: string]: OptionLocalizationItemDTO; }> {
        const response = await this.getLocalizationsOfDescriptionOfExteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates description of an existing ExteriorColor
     */
    async updateDescriptionOfExteriorColorRaw(requestParameters: UpdateDescriptionOfExteriorColorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling updateDescriptionOfExteriorColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling updateDescriptionOfExteriorColor.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateDescriptionOfExteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/exterior-color/description`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates description of an existing ExteriorColor
     */
    async updateDescriptionOfExteriorColor(requestParameters: UpdateDescriptionOfExteriorColorRequest): Promise<void> {
        await this.updateDescriptionOfExteriorColorRaw(requestParameters);
    }

}
