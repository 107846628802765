/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    LinkedExteriorColorCustomizationDTOCustomizationValueColorTag,
    LinkedExteriorColorCustomizationDTOCustomizationValueColorTagFromJSON,
    LinkedExteriorColorCustomizationDTOCustomizationValueColorTagFromJSONTyped,
    LinkedExteriorColorCustomizationDTOCustomizationValueColorTagToJSON,
} from './';

/**
 * 
 * @export
 * @interface LinkedExteriorColorCustomizationDTOCustomizationValue
 */
export interface LinkedExteriorColorCustomizationDTOCustomizationValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof LinkedExteriorColorCustomizationDTOCustomizationValue
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof LinkedExteriorColorCustomizationDTOCustomizationValue
     */
    colorTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkedExteriorColorCustomizationDTOCustomizationValue
     */
    hexCode: string;
    /**
     * 
     * @type {LinkedExteriorColorCustomizationDTOCustomizationValueColorTag}
     * @memberof LinkedExteriorColorCustomizationDTOCustomizationValue
     */
    colorTag?: LinkedExteriorColorCustomizationDTOCustomizationValueColorTag;
}

export function LinkedExteriorColorCustomizationDTOCustomizationValueFromJSON(json: any): LinkedExteriorColorCustomizationDTOCustomizationValue {
    return LinkedExteriorColorCustomizationDTOCustomizationValueFromJSONTyped(json, false);
}

export function LinkedExteriorColorCustomizationDTOCustomizationValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedExteriorColorCustomizationDTOCustomizationValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'colorTypeName': !exists(json, 'colorTypeName') ? undefined : json['colorTypeName'],
        'hexCode': json['hexCode'],
        'colorTag': !exists(json, 'colorTag') ? undefined : LinkedExteriorColorCustomizationDTOCustomizationValueColorTagFromJSON(json['colorTag']),
    };
}

export function LinkedExteriorColorCustomizationDTOCustomizationValueToJSON(value?: LinkedExteriorColorCustomizationDTOCustomizationValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
        'colorTypeName': value.colorTypeName,
        'hexCode': value.hexCode,
        'colorTag': LinkedExteriorColorCustomizationDTOCustomizationValueColorTagToJSON(value.colorTag),
    };
}


