/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationValue
 */
export interface LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationValue
     */
    name: BaseNameDTO;
}

export function LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationValueFromJSON(json: any): LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationValue {
    return LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationValueFromJSONTyped(json, false);
}

export function LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
    };
}

export function LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationValueToJSON(value?: LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
    };
}


