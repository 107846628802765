import { couldBeOfType } from "../common/global"
import { AuthorizationApiError, HttpStatus, SimpleOrValidationApiError, ValidationError } from "../generated/pdsapi"
import { ApiRequestFn } from "./boundApi"
import { Path } from "react-hook-form"

export const toNumber1 = <PARAMS, OUTPUT>(request: ApiRequestFn<PARAMS, OUTPUT>) => {
    return (params: PARAMS) => request(params).then((output) => +output)
}

export function isValidationApiError(error: unknown): error is SimpleOrValidationApiError {
    return couldBeOfType<SimpleOrValidationApiError>(error) && typeof error.validationErrors === "object"
}

export function isAuthorizationApiError(error: unknown): error is AuthorizationApiError {
    return couldBeOfType<AuthorizationApiError>(error) && error.status !== undefined && error.status === HttpStatus.UNAUTHORIZED
}

export const convertValidationErrorsToMap = <T>(validationErrors: Array<ValidationError>): Map<Path<T>, string> => {
    return new Map<Path<T>, string>(validationErrors.map((error) => [error.field as Path<T>, error.message]))
}
