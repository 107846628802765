import { TableFilterProps } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toOption } from "../../../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { InteriorMaterial, OrderTypeInteriorFilterOptionsDTO } from "../../../../../../../generated/pdsapi"
import { OrderTypeInteriorsFilter } from "../../../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const OrderTypeInteriorsCardFilter: FunctionComponent<TableFilterProps<OrderTypeInteriorsFilter, OrderTypeInteriorFilterOptionsDTO>> = ({
    filter,
    filterOptions,
    setFilter,
}) => {
    const materialFilter = (
        <MultiSelect
            label={"Materials"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.materials.map(toOption)}
            value={filter.materials ?? []}
            onChange={(materials) => {
                setFilter((filter) => ({
                    ...filter,
                    materials: materials.length === 0 ? undefined : (materials as InteriorMaterial[]),
                }))
            }}
        />
    )

    const tagFilter = (
        <MultiSelect
            label={"Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.colorTags.map(toOption)}
            value={filter.colorTagKeys ?? []}
            onChange={(colorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    colorTagKeys: colorTagKeys.length === 0 ? undefined : colorTagKeys,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            {materialFilter}
            {tagFilter}
        </ColumnGroupsWrapper>
    )
}
