/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EquipmentMarketplaceSpecificsIndexDTOValues,
    EquipmentMarketplaceSpecificsIndexDTOValuesFromJSON,
    EquipmentMarketplaceSpecificsIndexDTOValuesFromJSONTyped,
    EquipmentMarketplaceSpecificsIndexDTOValuesToJSON,
} from './';

/**
 * 
 * @export
 * @interface EquipmentMarketplaceSpecificsIndexDTO
 */
export interface EquipmentMarketplaceSpecificsIndexDTO {
    /**
     * 
     * @type {EquipmentMarketplaceSpecificsIndexDTOValues}
     * @memberof EquipmentMarketplaceSpecificsIndexDTO
     */
    globalValues?: EquipmentMarketplaceSpecificsIndexDTOValues;
    /**
     * 
     * @type {{ [key: string]: EquipmentMarketplaceSpecificsIndexDTOValues; }}
     * @memberof EquipmentMarketplaceSpecificsIndexDTO
     */
    marketplaceValues: { [key: string]: EquipmentMarketplaceSpecificsIndexDTOValues; };
}

export function EquipmentMarketplaceSpecificsIndexDTOFromJSON(json: any): EquipmentMarketplaceSpecificsIndexDTO {
    return EquipmentMarketplaceSpecificsIndexDTOFromJSONTyped(json, false);
}

export function EquipmentMarketplaceSpecificsIndexDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentMarketplaceSpecificsIndexDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'globalValues': !exists(json, 'globalValues') ? undefined : EquipmentMarketplaceSpecificsIndexDTOValuesFromJSON(json['globalValues']),
        'marketplaceValues': (mapValues(json['marketplaceValues'], EquipmentMarketplaceSpecificsIndexDTOValuesFromJSON)),
    };
}

export function EquipmentMarketplaceSpecificsIndexDTOToJSON(value?: EquipmentMarketplaceSpecificsIndexDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'globalValues': EquipmentMarketplaceSpecificsIndexDTOValuesToJSON(value.globalValues),
        'marketplaceValues': (mapValues(value.marketplaceValues, EquipmentMarketplaceSpecificsIndexDTOValuesToJSON)),
    };
}


