import { DefaultFormElementLoading, EditLabeledContentWrapper, EditProps, FormElement, ViewLabeledContentWrapper, ViewProps } from "../FormElementWrapper"
import { PText, PTextFieldWrapper } from "@porsche-design-system/components-react"
import { FieldValues } from "react-hook-form"

export const getNumberFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    number,
    NumberFormElementViewProps,
    NumberFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: NumberFormElementView,
    Edit: NumberFormElementEdit,
})

interface NumberFormElementViewProps {
    value: number | undefined

    showAsHint?: boolean
}

export const NumberFormElementView = ({ label, value, showAsHint }: ViewProps<NumberFormElementViewProps>) => (
    <ViewLabeledContentWrapper label={label}>
        <PText color={showAsHint ? "neutral-contrast-low" : "default"}>{showAsHint ? `${value ?? "-"} *` : (value ?? "-")}</PText>
    </ViewLabeledContentWrapper>
)

interface NumberFormElementEditProps {
    value: number | undefined

    showAsHint?: boolean
    readOnly?: boolean
    optional?: boolean
    min?: number
    max?: number
    step?: number | string
}

export const NumberFormElementEdit = <DATA, UPDATE extends FieldValues>({
    label,
    field,
    optional,
    showAsHint,
    min,
    max,
    step,
    readOnly,
    value,
    validationErrors,
    register,
}: EditProps<DATA, UPDATE, number, NumberFormElementEditProps>) => {
    const errorMessage = validationErrors.get(field._path)

    return (
        <EditLabeledContentWrapper label={label} optional={optional}>
            <PTextFieldWrapper state={errorMessage ? "error" : "none"} message={errorMessage}>
                <input
                    {...register(field._path, {
                        required: !optional,
                        min: min,
                        max: max,
                    })}
                    type={"number"}
                    step={step ?? "any"}
                    defaultValue={showAsHint ? undefined : value}
                    readOnly={readOnly}
                />
            </PTextFieldWrapper>
        </EditLabeledContentWrapper>
    )
}
