import { equipmentTagApi } from "../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLocalizationCard } from "../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { EquipmentTagDetailsAttributesCardContent } from "./data/attributes/EquipmentTagDetailsAttributesCardContent"

export const EquipmentTagProductDetailsContainerConfig: ProductDetailsContainerConfig = {
    getBaseInformation: equipmentTagApi.details.getBaseInformation,
    deleteByKey: equipmentTagApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={equipmentTagApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            equipmentTagApi.details.updateAttributes({
                                key,
                                equipmentTagAttributesUpdateDTO: update,
                            })
                        }
                        Content={EquipmentTagDetailsAttributesCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={equipmentTagApi.details.getLocalizations}
                        updateData={(update) => equipmentTagApi.details.updateLocalizations({ key: update.key, requestBody: update.update })}
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

export const EquipmentTagDetailsContainer = getProductDetailsContainer(EquipmentTagProductDetailsContainerConfig)
