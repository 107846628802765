import { orderTypeRoutes } from "../../../../common/routes"
import { OrderTypeOptionRoutes } from "../OrderTypeOptionRoutes"
import { OrderTypeInteriorCreateContainer } from "./create/OrderTypeInteriorCreateContainer"
import { InteriorDetailsContainer } from "./details/InteriorDetailsContainer"
import { FC } from "react"
import { useParams } from "react-router-dom"

export const OrderTypeInteriorsRoutes: FC = () => {
    const { orderTypeKey } = useParams()

    return OrderTypeOptionRoutes({
        Create: OrderTypeInteriorCreateContainer,
        Details: InteriorDetailsContainer,
        optionListCrumb: {
            name: "Interior",
            path: orderTypeRoutes.details(
                orderTypeKey,
                new URLSearchParams({
                    selectedTab: "2",
                    "tab2.interiors.accordion.isExpanded": "true",
                })
            ),
        },
    })
}
