import { PButtonPure, PDivider, PHeadline, PText } from "@porsche-design-system/components-react"
import ObjectID from "bson-objectid"
import React, { ReactElement } from "react"
import { IntRange, MultiSelectDTOString, OptionDTOString } from "../../generated/pdsapi"
import { propertiesOf, Property } from "../../common/property"
import { FormDisplayData, getEditData } from "../formelements/FormDisplayData"
import { ProductDetailsCardContentProps } from "../../viewtemplates/details/cards/ProductDetailsCard"
import { useSyncState } from "../../hooks/useSyncState"
import { rowGroupsSpacing, RowGroupWrapper } from "../layouts/rowgroupwrapper/RowGroupWrapper"
import { FormElementWrapper } from "../formelements/FormElementWrapper"
import { getTextFormElement } from "../formelements/textformelement/TextFormElement"
import { getBoolFormElement } from "../formelements/boolformelement/BoolFormElement"
import { SpacedItems } from "../layouts/spaceditems/SpacedItems"
import { getTagsFormElement } from "../formelements/tagsformelement/TagsFormElement"
import { toOption } from "../formelements/selecttextformelement/Option"
import { getNumberFormElement } from "../formelements/numberformelement/NumberFormElement"
import { findOption } from "../../viewtemplates/list/filter/ListFilterTagsConfig"
import { conditionalValueLabelOf } from "../../common/conditionalValueLabel"
import { toTagOption } from "../formelements/tagformelement/TagOption"
import { derivativeRoutes, modelGenerationRoutes } from "../../common/routes"

interface OptionCustomizationAttributesDTO<VALUE> {
    optionCode: string
    modelGenerations: MultiSelectDTOString
    derivatives: OptionDTOString[]
    isDefaultValueActive: boolean
    defaultValue: VALUE
    conditionalValues: OptionCustomizationConditionalValueDTO<VALUE>[]
}

interface OptionCustomizationConditionalValueDTO<VALUE> {
    key: string
    modelYearRange?: IntRange
    derivativeKeys?: string[]
    value: VALUE
}

interface OptionCustomizationAttributesUpdateDTO<VALUE_UPDATE> {
    optionCode: string
    modelGenerationKeys: string[]
    isDefaultValueActive: boolean
    defaultValue: VALUE_UPDATE
    conditionalValues: OptionCustomizationConditionalValueDTO<VALUE_UPDATE>[]
}

interface OptionCustomizationValueContentProps<VALUE, VALUE_UPDATE> {
    key: string
    getValue: (attributes: OptionCustomizationAttributesDTO<VALUE>) => VALUE
    property: Property<OptionCustomizationAttributesUpdateDTO<VALUE_UPDATE>, VALUE_UPDATE>
    formDisplayData: FormDisplayData<OptionCustomizationAttributesDTO<VALUE>, OptionCustomizationAttributesUpdateDTO<VALUE_UPDATE>>
}

export type OptionCustomizationValueContent<VALUE, VALUE_UPDATE> = (props: OptionCustomizationValueContentProps<VALUE, VALUE_UPDATE>) => ReactElement

export interface OptionCustomizationDetailsAttributesCardContentProps<VALUE, VALUE_UPDATE>
    extends ProductDetailsCardContentProps<OptionCustomizationAttributesDTO<VALUE>, OptionCustomizationAttributesUpdateDTO<VALUE_UPDATE>> {
    convertValueToValueUpdate: (value: VALUE) => VALUE_UPDATE
    CustomizationValueContent: (props: OptionCustomizationValueContentProps<VALUE, VALUE_UPDATE>) => ReactElement
}

export const OptionCustomizationDetailsAttributesCardContent = <VALUE, VALUE_UPDATE>({
    formDisplayData,
    convertValueToValueUpdate,
    CustomizationValueContent,
}: OptionCustomizationDetailsAttributesCardContentProps<VALUE, VALUE_UPDATE>): ReactElement => {
    const optionCustomizationProperties = propertiesOf<OptionCustomizationAttributesUpdateDTO<VALUE_UPDATE>>()
    const [conditionalValues, setConditionalValues] = useSyncState(formDisplayData.data?.conditionalValues ?? [], {
        onChange: (conditionalValues) =>
            getEditData(formDisplayData)?.setValue(
                "conditionalValues",
                conditionalValues.map((conditionalValue) => ({
                    key: conditionalValue.key,
                    modelYearRange: conditionalValue.modelYearRange,
                    value: convertValueToValueUpdate(conditionalValue.value),
                }))
            ),
    })

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    field={optionCustomizationProperties.optionCode}
                    FormElement={getTextFormElement<OptionCustomizationAttributesDTO<VALUE>, OptionCustomizationAttributesUpdateDTO<VALUE_UPDATE>>()}
                    getViewProps={(data) => ({
                        value: data.optionCode,
                        showAsHint: false,
                    })}
                    getEditProps={(data) => ({
                        value: data.optionCode,
                    })}
                />
                <FormElementWrapper
                    label={"Model Generations"}
                    formDisplayData={formDisplayData}
                    field={optionCustomizationProperties.modelGenerationKeys}
                    FormElement={getTagsFormElement<OptionCustomizationAttributesDTO<VALUE>, OptionCustomizationAttributesUpdateDTO<VALUE_UPDATE>>()}
                    getViewProps={(data) => ({
                        emptyLabel: "-",
                        tags: data.modelGenerations.value.map((modelGeneration) => toTagOption(modelGeneration, modelGenerationRoutes.details)),
                    })}
                    getEditProps={(data) => ({
                        emptyLabel: "-",
                        value: data.modelGenerations.value.map((modelGeneration) => modelGeneration.key),
                        options: data.modelGenerations.options.map(toOption),
                        clearable: true,
                    })}
                />
                <FormElementWrapper
                    label={"Default Value Status"}
                    formDisplayData={formDisplayData}
                    field={optionCustomizationProperties.isDefaultValueActive}
                    FormElement={getBoolFormElement<OptionCustomizationAttributesDTO<VALUE>, OptionCustomizationAttributesUpdateDTO<VALUE_UPDATE>>()}
                    getViewProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.isDefaultValueActive,
                    })}
                    getEditProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.isDefaultValueActive,
                    })}
                />
            </RowGroupWrapper>

            <PDivider />
            <>
                <PHeadline variant={"headline-4"}>Default Value</PHeadline>
                {conditionalValues.length ? (
                    <PText color={"neutral-contrast-medium"}>Not applied in: {conditionalValues.map(conditionalValueLabelOf).join(", ")}</PText>
                ) : null}
            </>

            <CustomizationValueContent
                key={"defaultValue"}
                formDisplayData={formDisplayData}
                getValue={(attributes) => attributes.defaultValue}
                property={optionCustomizationProperties.defaultValue}
            />

            {conditionalValues.length || formDisplayData.kind === "EDIT" ? <PDivider /> : null}
            {conditionalValues.length || formDisplayData.kind === "EDIT" ? <PHeadline variant={"headline-4"}>Conditional Values</PHeadline> : null}

            {conditionalValues.flatMap((conditionalValue, index) => {
                const property = optionCustomizationProperties.conditionalValues._index(index)

                return [
                    <RowGroupWrapper key={`configuration_${index}`} label={"Configuration"}>
                        <FormElementWrapper
                            label={"Key"}
                            formDisplayData={formDisplayData}
                            field={property.key}
                            FormElement={getTextFormElement<OptionCustomizationAttributesDTO<VALUE>, OptionCustomizationAttributesUpdateDTO<VALUE_UPDATE>>()}
                            getViewProps={() => ({
                                value: conditionalValue.key,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.key,
                                readOnly: true,
                            })}
                        />
                    </RowGroupWrapper>,
                    <RowGroupWrapper key={`model_year_condition_${index}`} label={"Model Year Condition"}>
                        <FormElementWrapper
                            label={"From"}
                            formDisplayData={formDisplayData}
                            field={property.modelYearRange.start}
                            FormElement={getNumberFormElement<OptionCustomizationAttributesDTO<VALUE>, OptionCustomizationAttributesUpdateDTO<VALUE_UPDATE>>()}
                            getViewProps={() => ({
                                value: conditionalValue.modelYearRange?.start,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.modelYearRange?.start,
                                optional: true,
                            })}
                        />
                        <FormElementWrapper
                            label={"To"}
                            formDisplayData={formDisplayData}
                            field={property.modelYearRange.end}
                            FormElement={getNumberFormElement<OptionCustomizationAttributesDTO<VALUE>, OptionCustomizationAttributesUpdateDTO<VALUE_UPDATE>>()}
                            getViewProps={() => ({
                                value: conditionalValue.modelYearRange?.end,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.modelYearRange?.end,
                                optional: true,
                            })}
                        />
                    </RowGroupWrapper>,
                    <RowGroupWrapper key={`derivative_keys_${index}`} label={"Derivative Condition"}>
                        <FormElementWrapper
                            label={"Derivatives"}
                            formDisplayData={formDisplayData}
                            field={property.derivativeKeys}
                            FormElement={getTagsFormElement<OptionCustomizationAttributesDTO<VALUE>, OptionCustomizationAttributesUpdateDTO<VALUE_UPDATE>>()}
                            getViewProps={(attributes) => ({
                                emptyLabel: "-",
                                tags:
                                    conditionalValue.derivativeKeys?.map((derivativeKey) => {
                                        const option = findOption(attributes.derivatives, derivativeKey) ?? { key: derivativeKey, label: derivativeKey }
                                        return toTagOption(option, () => derivativeRoutes.details(derivativeKey))
                                    }) ?? [],
                            })}
                            getEditProps={(data) => ({
                                emptyLabel: "-",
                                value: conditionalValue.derivativeKeys ?? [],
                                options: data.derivatives.map(toOption),
                                clearable: true,
                                optional: true,
                            })}
                        />
                    </RowGroupWrapper>,
                    <CustomizationValueContent
                        key={`customization_value_content_${index}`}
                        formDisplayData={formDisplayData}
                        getValue={() => conditionalValue.value}
                        property={property.value}
                    />,
                    formDisplayData.kind === "EDIT" ? (
                        <PButtonPure
                            key={`delete_conditional_value_${index}`}
                            type={"button"}
                            icon={"delete"}
                            onClick={() => {
                                setConditionalValues(conditionalValues.filter((_, i) => i !== index))
                            }}
                        >
                            Delete Conditional Value
                        </PButtonPure>
                    ) : null,
                    formDisplayData.kind === "EDIT" || index !== conditionalValues.length - 1 ? <PDivider key={`divider_${index}`} /> : null,
                ]
            })}
            {formDisplayData.kind === "EDIT" ? (
                <PButtonPure
                    type={"button"}
                    icon={"add"}
                    onClick={() =>
                        setConditionalValues((conditionalValues) => [
                            ...conditionalValues,
                            {
                                key: ObjectID().toHexString(),
                                modelYearRange: { start: 2020, end: 2020 },
                                value: formDisplayData.data.defaultValue,
                            },
                        ])
                    }
                >
                    Add Conditional Value
                </PButtonPure>
            ) : null}
        </SpacedItems>
    )
}
