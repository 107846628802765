import { useJsonMemorizedValue } from "./useJsonMemorizedValue"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"

export const useSyncState = <S>(value: S, props?: { onChange?: (value: S) => void }): [S, Dispatch<SetStateAction<S>>] => {
    const [state, setState] = useState<S>(value)
    const onChangeRef = useRef(props?.onChange)
    const firstRenderRef = useRef(true)

    const memorizedValue = useJsonMemorizedValue(value)
    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false
            return
        }

        setState(memorizedValue)
    }, [memorizedValue])

    useEffect(() => {
        if (onChangeRef.current) onChangeRef.current(state)
    }, [state])

    return [state, setState]
}
