import { orderTypeRoutes } from "../../../common/routes"
import { IncompleteOrderTypeDTO } from "../../../generated/pdsapi"
import { dashboardApi } from "../../../pdsapi"
import { DashboardPagedTableProps } from "../dashboardpagedtable/DashboardPagedTable"

export const IncompleteOrderTypesTableConfig: DashboardPagedTableProps<IncompleteOrderTypeDTO> = {
    titlePrefix: "Incomplete",
    itemsName: "Order Types",
    getRoute: (incompleteOrderType) => orderTypeRoutes.details(incompleteOrderType.key),
    urlParamsPrefix: "order-types.incomplete",
    columns: [
        {
            grow: 2,
            headerCellProps: {
                content: "Codes",
            },
            buildCellContent: (incompleteOrderType) => incompleteOrderType.codes.join(", "),
        },
        {
            grow: 1,
            headerCellProps: {
                content: "Model Year",
            },
            buildCellContent: (incompleteOrderType) => incompleteOrderType.modelYear.toString(),
        },
    ],
    fetchPage: dashboardApi.getIncompleteOrderTypes,
}
