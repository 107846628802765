/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    CustomizationLinksDTO,
    CustomizationLinksDTOFromJSON,
    CustomizationLinksDTOToJSON,
    CustomizationVideoUpdateDTO,
    CustomizationVideoUpdateDTOFromJSON,
    CustomizationVideoUpdateDTOToJSON,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColorCustomizationAttributesValueDTO,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColorCustomizationAttributesValueDTOFromJSON,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColorCustomizationAttributesValueDTOToJSON,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTO,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTOFromJSON,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTOToJSON,
    OptionCustomizationLocalizationsDTO,
    OptionCustomizationLocalizationsDTOFromJSON,
    OptionCustomizationLocalizationsDTOToJSON,
    OptionCustomizationVideoLocalizationsDTO,
    OptionCustomizationVideoLocalizationsDTOFromJSON,
    OptionCustomizationVideoLocalizationsDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    VehicleStateSearchResult,
    VehicleStateSearchResultFromJSON,
    VehicleStateSearchResultToJSON,
} from '../models';

export interface DeletePaintToSampleExteriorColorCustomizationRequest {
    key: string;
}

export interface GetAttributesOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetLinksOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfDescriptionOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
}

export interface GetLocalizationsOfNameOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
}

export interface GetLocalizationsOfVideoOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
}

export interface GetVehicleImagesOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
    limit?: number;
}

export interface UpdateAttributesOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
    optionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTO: OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTO;
}

export interface UpdateDescriptionOfDefaultValueOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
    conditionalValueKey: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfDefaultValueOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
    conditionalValueKey: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateVideoOfDefaultValueOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
    customizationVideoUpdateDTO: CustomizationVideoUpdateDTO;
}

export interface UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRequest {
    key: string;
    conditionalValueKey: string;
    customizationVideoUpdateDTO: CustomizationVideoUpdateDTO;
}

/**
 * 
 */
export class PaintToSampleExteriorColorCustomizationDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a PaintToSampleExteriorColorCustomization by a given key
     */
    async deletePaintToSampleExteriorColorCustomizationRaw(requestParameters: DeletePaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deletePaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a PaintToSampleExteriorColorCustomization by a given key
     */
    async deletePaintToSampleExteriorColorCustomization(requestParameters: DeletePaintToSampleExteriorColorCustomizationRequest): Promise<void> {
        await this.deletePaintToSampleExteriorColorCustomizationRaw(requestParameters);
    }

    /**
     * Get attributes of PaintToSampleExteriorColorCustomization with given key
     */
    async getAttributesOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: GetAttributesOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationAttributesDTOPaintToSampleExteriorColorCustomizationAttributesValueDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationAttributesDTOPaintToSampleExteriorColorCustomizationAttributesValueDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of PaintToSampleExteriorColorCustomization with given key
     */
    async getAttributesOfPaintToSampleExteriorColorCustomization(requestParameters: GetAttributesOfPaintToSampleExteriorColorCustomizationRequest): Promise<OptionCustomizationAttributesDTOPaintToSampleExteriorColorCustomizationAttributesValueDTO> {
        const response = await this.getAttributesOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of PaintToSampleExteriorColorCustomization with given key
     */
    async getBaseInformationOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: GetBaseInformationOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of PaintToSampleExteriorColorCustomization with given key
     */
    async getBaseInformationOfPaintToSampleExteriorColorCustomization(requestParameters: GetBaseInformationOfPaintToSampleExteriorColorCustomizationRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get links of PaintToSampleExteriorColorCustomization with given key
     */
    async getLinksOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: GetLinksOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<CustomizationLinksDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLinksOfPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinksOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/links`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomizationLinksDTOFromJSON(jsonValue));
    }

    /**
     * Get links of PaintToSampleExteriorColorCustomization with given key
     */
    async getLinksOfPaintToSampleExteriorColorCustomization(requestParameters: GetLinksOfPaintToSampleExteriorColorCustomizationRequest): Promise<CustomizationLinksDTO> {
        const response = await this.getLinksOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfDescription of PaintToSampleExteriorColorCustomization with given key
     */
    async getLocalizationsOfDescriptionOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: GetLocalizationsOfDescriptionOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationLocalizationsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfDescriptionOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/description/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationLocalizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfDescription of PaintToSampleExteriorColorCustomization with given key
     */
    async getLocalizationsOfDescriptionOfPaintToSampleExteriorColorCustomization(requestParameters: GetLocalizationsOfDescriptionOfPaintToSampleExteriorColorCustomizationRequest): Promise<OptionCustomizationLocalizationsDTO> {
        const response = await this.getLocalizationsOfDescriptionOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of PaintToSampleExteriorColorCustomization with given key
     */
    async getLocalizationsOfNameOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: GetLocalizationsOfNameOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationLocalizationsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationLocalizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfName of PaintToSampleExteriorColorCustomization with given key
     */
    async getLocalizationsOfNameOfPaintToSampleExteriorColorCustomization(requestParameters: GetLocalizationsOfNameOfPaintToSampleExteriorColorCustomizationRequest): Promise<OptionCustomizationLocalizationsDTO> {
        const response = await this.getLocalizationsOfNameOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfVideo of PaintToSampleExteriorColorCustomization with given key
     */
    async getLocalizationsOfVideoOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: GetLocalizationsOfVideoOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationVideoLocalizationsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfVideoOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/video/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationVideoLocalizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfVideo of PaintToSampleExteriorColorCustomization with given key
     */
    async getLocalizationsOfVideoOfPaintToSampleExteriorColorCustomization(requestParameters: GetLocalizationsOfVideoOfPaintToSampleExteriorColorCustomizationRequest): Promise<OptionCustomizationVideoLocalizationsDTO> {
        const response = await this.getLocalizationsOfVideoOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get vehicleImages of PaintToSampleExteriorColorCustomization with given key
     */
    async getVehicleImagesOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: GetVehicleImagesOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<Array<VehicleStateSearchResult>>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getVehicleImagesOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/vehicle-images`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateSearchResultFromJSON));
    }

    /**
     * Get vehicleImages of PaintToSampleExteriorColorCustomization with given key
     */
    async getVehicleImagesOfPaintToSampleExteriorColorCustomization(requestParameters: GetVehicleImagesOfPaintToSampleExteriorColorCustomizationRequest): Promise<Array<VehicleStateSearchResult>> {
        const response = await this.getVehicleImagesOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing PaintToSampleExteriorColorCustomization
     */
    async updateAttributesOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: UpdateAttributesOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.optionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTO === null || requestParameters.optionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTO === undefined) {
            throw new runtime.RequiredError('optionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTO','Required parameter requestParameters.optionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTO was null or undefined when calling updateAttributesOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTOToJSON(requestParameters.optionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing PaintToSampleExteriorColorCustomization
     */
    async updateAttributesOfPaintToSampleExteriorColorCustomization(requestParameters: UpdateAttributesOfPaintToSampleExteriorColorCustomizationRequest): Promise<void> {
        await this.updateAttributesOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates descriptionOfDefaultValue of an existing PaintToSampleExteriorColorCustomization
     */
    async updateDescriptionOfDefaultValueOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: UpdateDescriptionOfDefaultValueOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateDescriptionOfDefaultValueOfPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateDescriptionOfDefaultValueOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/default-value/description`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates descriptionOfDefaultValue of an existing PaintToSampleExteriorColorCustomization
     */
    async updateDescriptionOfDefaultValueOfPaintToSampleExteriorColorCustomization(requestParameters: UpdateDescriptionOfDefaultValueOfPaintToSampleExteriorColorCustomizationRequest): Promise<void> {
        await this.updateDescriptionOfDefaultValueOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates descriptionOfValueOfConditionalValuesByConditionalValueKey of an existing PaintToSampleExteriorColorCustomization
     */
    async updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/conditional-values/{conditionalValueKey}/value/description`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates descriptionOfValueOfConditionalValuesByConditionalValueKey of an existing PaintToSampleExteriorColorCustomization
     */
    async updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization(requestParameters: UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRequest): Promise<void> {
        await this.updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates nameOfDefaultValue of an existing PaintToSampleExteriorColorCustomization
     */
    async updateNameOfDefaultValueOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: UpdateNameOfDefaultValueOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfDefaultValueOfPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfDefaultValueOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/default-value/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfDefaultValue of an existing PaintToSampleExteriorColorCustomization
     */
    async updateNameOfDefaultValueOfPaintToSampleExteriorColorCustomization(requestParameters: UpdateNameOfDefaultValueOfPaintToSampleExteriorColorCustomizationRequest): Promise<void> {
        await this.updateNameOfDefaultValueOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing PaintToSampleExteriorColorCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/conditional-values/{conditionalValueKey}/value/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing PaintToSampleExteriorColorCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRequest): Promise<void> {
        await this.updateNameOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates videoOfDefaultValue of an existing PaintToSampleExteriorColorCustomization
     */
    async updateVideoOfDefaultValueOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: UpdateVideoOfDefaultValueOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateVideoOfDefaultValueOfPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.customizationVideoUpdateDTO === null || requestParameters.customizationVideoUpdateDTO === undefined) {
            throw new runtime.RequiredError('customizationVideoUpdateDTO','Required parameter requestParameters.customizationVideoUpdateDTO was null or undefined when calling updateVideoOfDefaultValueOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/default-value/video`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomizationVideoUpdateDTOToJSON(requestParameters.customizationVideoUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates videoOfDefaultValue of an existing PaintToSampleExteriorColorCustomization
     */
    async updateVideoOfDefaultValueOfPaintToSampleExteriorColorCustomization(requestParameters: UpdateVideoOfDefaultValueOfPaintToSampleExteriorColorCustomizationRequest): Promise<void> {
        await this.updateVideoOfDefaultValueOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates videoOfValueOfConditionalValuesByConditionalValueKey of an existing PaintToSampleExteriorColorCustomization
     */
    async updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRaw(requestParameters: UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.customizationVideoUpdateDTO === null || requestParameters.customizationVideoUpdateDTO === undefined) {
            throw new runtime.RequiredError('customizationVideoUpdateDTO','Required parameter requestParameters.customizationVideoUpdateDTO was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/details/{key}/conditional-values/{conditionalValueKey}/value/video`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomizationVideoUpdateDTOToJSON(requestParameters.customizationVideoUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates videoOfValueOfConditionalValuesByConditionalValueKey of an existing PaintToSampleExteriorColorCustomization
     */
    async updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization(requestParameters: UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRequest): Promise<void> {
        await this.updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomizationRaw(requestParameters);
    }

}
