import { getCurrentEnvironment } from "./environment"

export const responsiveOptionImageVariants = (imageKey: string) => responsiveImageVariants(imageKey, [160, 320, 640, 1280, 1440])

export const responsiveWheelImageVariants = (imageKey: string) => responsiveImageVariants(imageKey, [95])

const responsiveImageVariants = (imageKey: string, widths: number[]): { src: string; srcSet: { jpg: string; webp: string } } => {
    let imageServiceUrl: string
    switch (getCurrentEnvironment().name) {
        case "PROD":
            imageServiceUrl = "https://images.product.finder.porsche.com"
            break
        case "DEV":
            imageServiceUrl = "https://images.product.finder-k.porsche.com"
            break
        case "LOCAL":
            imageServiceUrl = "https://images.product.finder.porsche.com"
            break
    }

    const buildUrl = (width: number, extension: "jpg" | "webp") => `${imageServiceUrl}/${imageKey}/${width}.${extension}`

    const buildSrcSet = (extension: "jpg" | "webp") => widths.map((width) => `${buildUrl(width, extension)} ${width}w`).join(", ")

    return {
        src: buildUrl(widths[widths.length - 1], "jpg"),
        srcSet: {
            jpg: buildSrcSet("jpg"),
            webp: buildSrcSet("webp"),
        },
    }
}
