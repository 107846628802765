import { propertiesOf } from "../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../components/formelements/EditFormElementWrapper"
import { getBoolFormElement } from "../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { getTextFormElement } from "../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { RoofColorTagCreateRequestDTO } from "../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import { FC } from "react"
import { useLocation } from "react-router-dom"

export const RoofColorTagCreateAttributesCard: FC<ProductCreateContentProps<RoofColorTagCreateRequestDTO, unknown>> = ({ formDisplayData }) => {
    const properties = propertiesOf<RoofColorTagCreateRequestDTO>()
    const searchParams = new URLSearchParams(useLocation().search)

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Key"}
                    formDisplayData={formDisplayData}
                    field={properties.key}
                    FormElement={getTextFormElement<unknown, RoofColorTagCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: searchParams.get("key") ?? "",
                    })}
                />
            </RowGroupWrapper>

            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                })}
            />

            <RowGroupWrapper label={"Status"}>
                <EditFormElementWrapper
                    label={"Active"}
                    formDisplayData={formDisplayData}
                    field={properties.active}
                    FormElement={getBoolFormElement<unknown, RoofColorTagCreateRequestDTO>()}
                    getEditProps={() => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: true,
                    })}
                />
            </RowGroupWrapper>

            <RowGroupWrapper label={"Color"}>
                <EditFormElementWrapper
                    label={"Hex Code"}
                    formDisplayData={formDisplayData}
                    field={properties.hexCode}
                    FormElement={getColorPickerFormElement<unknown, RoofColorTagCreateRequestDTO>()}
                    getEditProps={() => ({
                        hexValue: "#000000",
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
