import { interiorColorTagRoutes } from "../../../../../../common/routes"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getBoolFormElement } from "../../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTagFormElement } from "../../../../../../components/formelements/tagformelement/TagFormElement"
import { toTagOption } from "../../../../../../components/formelements/tagformelement/TagOption"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    InteriorMaterial,
    OptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO,
    OptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO,
} from "../../../../../../generated/pdsapi"
import { useSyncState } from "../../../../../../hooks/useSyncState"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { InteriorColorTile } from "@finder/ui-kit"
import { FC } from "react"
import {
    OptionCustomizationDetailsAttributesCardContent,
    OptionCustomizationValueContent,
} from "../../../../../../components/optioncustomization/OptionCustomizationDetailsAttributesCardContent"

type AttributesDTO = OptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO
type Value = AttributesDTO["defaultValue"]
type AttributesUpdateDTO = OptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO
type ValueUpdate = AttributesUpdateDTO["defaultValue"]

export const InteriorCustomizationDetailsAttributesCardContent: FC<ProductDetailsCardContentProps<AttributesDTO, AttributesUpdateDTO>> = (props) => (
    <OptionCustomizationDetailsAttributesCardContent
        {...props}
        convertValueToValueUpdate={convertValueToValueUpdate}
        CustomizationValueContent={CustomizationValueContent}
    />
)

const convertValueToValueUpdate = (value: Value): ValueUpdate => ({
    name: value.name,
    description: value.description,
    color: {
        seatColorHexCode: value.color.seatColorHexCode,
        seatStitchingColorHexCode: value.color.seatStitchingColorHexCode,
        cockpitColorHexCode: value.color.cockpitColorHexCode,
        cockpitStitchingColorHexCode: value.color.cockpitStitchingColorHexCode,
        isCustomColor: value.color.isCustomColor,
        tagKey: value.color.tag.value?.key,
    },
    material: (value.material.value && value.material.value.key) || InteriorMaterial.OTHERS,
})

const CustomizationValueContent: OptionCustomizationValueContent<Value, ValueUpdate> = ({ getValue, property, formDisplayData }) => {
    const attributes = formDisplayData.data
    const value = attributes && getValue(attributes)
    const [seatColorHexCode, setSeatColorHexCode] = useSyncState(value?.color.seatColorHexCode)
    const [seatStitchingColorHexCode, setSeatStitchingColorHexCode] = useSyncState(value?.color.seatStitchingColorHexCode)
    const [cockpitColorHexCode, setCockpitColorHexCode] = useSyncState(value?.color.cockpitColorHexCode)
    const [cockpitStitchingColorHexCode, setCockpitStitchingColorHexCode] = useSyncState(value?.color.cockpitStitchingColorHexCode)

    const colorLabel = (
        <>
            Color
            <InteriorColorTile
                seatColor={seatColorHexCode ?? "#000000"}
                seatStitchingColor={seatStitchingColorHexCode}
                cockpitColor={cockpitColorHexCode}
                cockpitStitchingColor={cockpitStitchingColorHexCode}
            />
        </>
    )

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={property.name}
                getViewProps={(data) => ({
                    value: getValue(data).name,
                })}
                getEditProps={(data) => ({
                    value: getValue(data).name,
                    optional: true,
                })}
            />
            <DetailsDefaultLocalizationsFormRow
                label={"Description"}
                formDisplayData={formDisplayData}
                field={property.description}
                getViewProps={(data) => ({
                    value: getValue(data).description,
                })}
                getEditProps={(data) => ({
                    value: getValue(data).description,
                    optional: true,
                })}
            />
            <RowGroupWrapper label={colorLabel}>
                <FormElementWrapper
                    label={"Seat Color Hex Code"}
                    formDisplayData={formDisplayData}
                    field={property.color.seatColorHexCode}
                    FormElement={getColorPickerFormElement<AttributesDTO, AttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        hexValue: getValue(data).color.seatColorHexCode,
                        disableGlossy: true,
                    })}
                    getEditProps={(data) => ({
                        hexValue: getValue(data).color.seatColorHexCode,
                        disableGlossy: true,
                        onChange: setSeatColorHexCode,
                    })}
                />
                <FormElementWrapper
                    label={"Seat Stitching Color Hex Code"}
                    formDisplayData={formDisplayData}
                    field={property.color.seatStitchingColorHexCode}
                    FormElement={getColorPickerFormElement<AttributesDTO, AttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        hexValue: getValue(data).color.seatStitchingColorHexCode,
                        disableGlossy: true,
                    })}
                    getEditProps={(data) => ({
                        hexValue: getValue(data).color.seatStitchingColorHexCode,
                        disableGlossy: true,
                        onChange: setSeatStitchingColorHexCode,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <FormElementWrapper
                    label={"Cockpit Color Hex Code"}
                    formDisplayData={formDisplayData}
                    field={property.color.cockpitColorHexCode}
                    FormElement={getColorPickerFormElement<AttributesDTO, AttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        hexValue: getValue(data).color.cockpitColorHexCode,
                        disableGlossy: true,
                    })}
                    getEditProps={(data) => ({
                        hexValue: getValue(data).color.cockpitColorHexCode,
                        disableGlossy: true,
                        onChange: setCockpitColorHexCode,
                    })}
                />
                <FormElementWrapper
                    label={"Cockpit Stitching Color Hex Code"}
                    formDisplayData={formDisplayData}
                    field={property.color.cockpitStitchingColorHexCode}
                    FormElement={getColorPickerFormElement<AttributesDTO, AttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        hexValue: getValue(data).color.cockpitStitchingColorHexCode,
                        disableGlossy: true,
                    })}
                    getEditProps={(data) => ({
                        hexValue: getValue(data).color.cockpitStitchingColorHexCode,
                        disableGlossy: true,
                        onChange: setCockpitStitchingColorHexCode,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <FormElementWrapper
                    label={"Interior Color Tag"}
                    formDisplayData={formDisplayData}
                    field={property.color.tagKey}
                    FormElement={getTagFormElement<AttributesDTO, AttributesUpdateDTO>()}
                    getViewProps={(data) => {
                        const tag = getValue(data).color.tag.value
                        return {
                            tag: tag && toTagOption(tag, interiorColorTagRoutes.details),
                        }
                    }}
                    getEditProps={(data) => ({
                        value: getValue(data).color.tag.value?.key ?? "",
                        options: [
                            {
                                value: "",
                                label: "-",
                            },
                            ...getValue(data).color.tag.options.map((tag) => toOption(tag)),
                        ],
                        optional: true,
                    })}
                />
                <FormElementWrapper
                    label={"Custom Color"}
                    formDisplayData={formDisplayData}
                    field={property.color.isCustomColor}
                    FormElement={getBoolFormElement<AttributesDTO, AttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        trueMessage: "Yes",
                        falseMessage: "No",
                        value: getValue(data).color.isCustomColor,
                    })}
                    getEditProps={(data) => ({
                        trueMessage: "Yes",
                        falseMessage: "No",
                        value: getValue(data).color.isCustomColor,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper label={"Material"}>
                <FormElementWrapper
                    label={"Interior Material"}
                    formDisplayData={formDisplayData}
                    field={property.material}
                    FormElement={getSelectTextFormElement<AttributesDTO, AttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: getValue(data).material.value.label,
                    })}
                    getEditProps={(data) => ({
                        value: getValue(data).material.value.key,
                        options: getValue(data).material.options.map(toOption),
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
