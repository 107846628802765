import { ViewState } from "../../../../../../../common/ViewState"
import { showErrorToast } from "../../../../../../../common/errorToastHelper"
import { browserLanguage } from "../../../../../../../common/global"
import { roofColorCustomizationRoutes } from "../../../../../../../common/routes"
import { EmptyView } from "../../../../../../../components/emptyview/EmptyView"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { WidgetWrapper } from "../../../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { Tag } from "../../../../../../../components/tag/Tag"
import { NamedColorTile } from "../../../../../../../components/tiles/namedcolortile/NamedColorTile"
import { SimilarRoofColorCustomizationsDTO } from "../../../../../../../generated/pdsapi"
import { roofColorApi } from "../../../../../../../pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../../../viewtemplates/details/ProductDetailsContainer"
import { useToast } from "@finder/ui-kit"
import { PFlex } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

export const RoofColorSimilarCustomizationsCard: FC<ProductDetailsTabConfigContentProps<{ orderTypeKey: string; optionCode: string }>> = ({ itemKey }) => {
    const toastRef = useRef(useToast())
    const [similarCustomizations, setSimilarCustomizations] = useState<SimilarRoofColorCustomizationsDTO>()

    useEffect(() => {
        const fetchSimilarCustomizations = async () => {
            try {
                const linkedCustomization = await roofColorApi.details.getSimilarCustomizations({
                    ...itemKey,
                    languageTag: browserLanguage,
                })
                setSimilarCustomizations(linkedCustomization)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchSimilarCustomizations()
    }, [itemKey, toastRef])

    return (
        <WidgetWrapper label={"Similar Customizations"}>
            {similarCustomizations ? (
                <RoofColorSimilarCustomizationsCardContent similarCustomizations={similarCustomizations} />
            ) : (
                <EmptyView viewState={ViewState.LOADING} />
            )}
        </WidgetWrapper>
    )
}

export const RoofColorSimilarCustomizationsCardContent = ({ similarCustomizations }: { similarCustomizations: SimilarRoofColorCustomizationsDTO }) => {
    const navigate = useNavigate()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Customizations for other Model Generations"} childrenSize={12}>
                <SpacedItems direction={"row"} wrap={true} itemSpacing={spacing[16]} lineSpacing={spacing[8]}>
                    {similarCustomizations.customizationsForOtherModelGenerations.map((customization, index) => {
                        return (
                            <Tag key={`Tag_${index}`} onClick={() => navigate(roofColorCustomizationRoutes.details(customization.key))}>
                                <PFlex>
                                    <NamedColorTile label={customization.label} hexCode={customization.hexCode} />
                                </PFlex>
                            </Tag>
                        )
                    })}
                </SpacedItems>
            </RowGroupWrapper>
        </SpacedItems>
    )
}
