import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { CustomInteriorColorCreateContainer } from "./create/CustomInteriorColorCreateContainer"
import { CustomInteriorColorDetailsContainer } from "./details/CustomInteriorColorDetailsContainer"
import { CustomInteriorColorListContainer } from "./list/CustomInteriorColorListContainer"
import { FC } from "react"

export const CustomInteriorColorRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Custom Interior Colors",
        List: CustomInteriorColorListContainer,
        Details: CustomInteriorColorDetailsContainer,
        Create: CustomInteriorColorCreateContainer,
    })
