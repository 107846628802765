/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    CreateResponseDTO,
    CreateResponseDTOFromJSON,
    CreateResponseDTOToJSON,
    ModelGenerationCreateOptionsDTO,
    ModelGenerationCreateOptionsDTOFromJSON,
    ModelGenerationCreateOptionsDTOToJSON,
    ModelGenerationCreateRequestDTO,
    ModelGenerationCreateRequestDTOFromJSON,
    ModelGenerationCreateRequestDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface CreateModelGenerationRequest {
    modelGenerationCreateRequestDTO: ModelGenerationCreateRequestDTO;
}

export interface GetModelGenerationCreateOptionsRequest {
    languageTag: string;
}

/**
 * 
 */
export class ModelGenerationCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new ModelGeneration
     */
    async createModelGenerationRaw(requestParameters: CreateModelGenerationRequest): Promise<runtime.ApiResponse<CreateResponseDTO>> {
        if (requestParameters.modelGenerationCreateRequestDTO === null || requestParameters.modelGenerationCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('modelGenerationCreateRequestDTO','Required parameter requestParameters.modelGenerationCreateRequestDTO was null or undefined when calling createModelGeneration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/model-generations/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelGenerationCreateRequestDTOToJSON(requestParameters.modelGenerationCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResponseDTOFromJSON(jsonValue));
    }

    /**
     * Create a new ModelGeneration
     */
    async createModelGeneration(requestParameters: CreateModelGenerationRequest): Promise<CreateResponseDTO> {
        const response = await this.createModelGenerationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get select options to create ModelGenerations
     */
    async getModelGenerationCreateOptionsRaw(requestParameters: GetModelGenerationCreateOptionsRequest): Promise<runtime.ApiResponse<ModelGenerationCreateOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getModelGenerationCreateOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-generations/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelGenerationCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create ModelGenerations
     */
    async getModelGenerationCreateOptions(requestParameters: GetModelGenerationCreateOptionsRequest): Promise<ModelGenerationCreateOptionsDTO> {
        const response = await this.getModelGenerationCreateOptionsRaw(requestParameters);
        return await response.value();
    }

}
