/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RelatedOptionDTOStringString,
    RelatedOptionDTOStringStringFromJSON,
    RelatedOptionDTOStringStringFromJSONTyped,
    RelatedOptionDTOStringStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface RelatedOptionalSelectDTOStringString
 */
export interface RelatedOptionalSelectDTOStringString {
    /**
     * 
     * @type {RelatedOptionDTOStringString}
     * @memberof RelatedOptionalSelectDTOStringString
     */
    value?: RelatedOptionDTOStringString;
    /**
     * 
     * @type {Array<RelatedOptionDTOStringString>}
     * @memberof RelatedOptionalSelectDTOStringString
     */
    relatedOptions: Array<RelatedOptionDTOStringString>;
}

export function RelatedOptionalSelectDTOStringStringFromJSON(json: any): RelatedOptionalSelectDTOStringString {
    return RelatedOptionalSelectDTOStringStringFromJSONTyped(json, false);
}

export function RelatedOptionalSelectDTOStringStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedOptionalSelectDTOStringString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : RelatedOptionDTOStringStringFromJSON(json['value']),
        'relatedOptions': ((json['relatedOptions'] as Array<any>).map(RelatedOptionDTOStringStringFromJSON)),
    };
}

export function RelatedOptionalSelectDTOStringStringToJSON(value?: RelatedOptionalSelectDTOStringString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': RelatedOptionDTOStringStringToJSON(value.value),
        'relatedOptions': ((value.relatedOptions as Array<any>).map(RelatedOptionDTOStringStringToJSON)),
    };
}


