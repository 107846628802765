import { customInteriorColorApi } from "../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLocalizationCard } from "../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { CustomInteriorColorDetailsAttributesCardContent } from "./data/attributes/CustomInteriorColorDetailsAttributesCardContent"
import { CustomInteriorColorDetailsVehicleImagesCard } from "./data/vehicleimages/CustomInteriorColorDetailsVehicleImagesCard"

export const CustomInteriorColorProductDetailsContainerConfig: ProductDetailsContainerConfig = {
    getBaseInformation: customInteriorColorApi.details.getBaseInformation,
    deleteByKey: customInteriorColorApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={customInteriorColorApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            customInteriorColorApi.details.updateAttributes({
                                key,
                                customInteriorColorAttributesUpdateDTO: update,
                            })
                        }
                        Content={CustomInteriorColorDetailsAttributesCardContent}
                    />
                    <CustomInteriorColorDetailsVehicleImagesCard itemKey={props.itemKey.key} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={customInteriorColorApi.details.getNameLocalizations}
                        updateData={(update) =>
                            customInteriorColorApi.details.updateName({
                                key: update.key,
                                requestBody: update.update,
                            })
                        }
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

export const CustomInteriorColorDetailsContainer = getProductDetailsContainer(CustomInteriorColorProductDetailsContainerConfig)
