/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOInteger,
    OptionDTOIntegerFromJSON,
    OptionDTOIntegerFromJSONTyped,
    OptionDTOIntegerToJSON,
    OptionDTOString,
    OptionDTOStringFromJSON,
    OptionDTOStringFromJSONTyped,
    OptionDTOStringToJSON,
    RelatedGroupedRelatedOptionDTOIntegerStringString,
    RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSON,
    RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSONTyped,
    RelatedGroupedRelatedOptionDTOIntegerStringStringToJSON,
    RelatedOptionGroupDTOStringString,
    RelatedOptionGroupDTOStringStringFromJSON,
    RelatedOptionGroupDTOStringStringFromJSONTyped,
    RelatedOptionGroupDTOStringStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface TechnicalDataFilterOptionsDTO
 */
export interface TechnicalDataFilterOptionsDTO {
    /**
     * 
     * @type {Array<OptionDTOString>}
     * @memberof TechnicalDataFilterOptionsDTO
     */
    modelSeries: Array<OptionDTOString>;
    /**
     * 
     * @type {Array<RelatedOptionGroupDTOStringString>}
     * @memberof TechnicalDataFilterOptionsDTO
     */
    modelSeriesRelatedModelGenerations: Array<RelatedOptionGroupDTOStringString>;
    /**
     * 
     * @type {Array<OptionDTOInteger>}
     * @memberof TechnicalDataFilterOptionsDTO
     */
    modelYears: Array<OptionDTOInteger>;
    /**
     * 
     * @type {Array<RelatedGroupedRelatedOptionDTOIntegerStringString>}
     * @memberof TechnicalDataFilterOptionsDTO
     */
    modelYearAndGenerationRelatedOrderTypes: Array<RelatedGroupedRelatedOptionDTOIntegerStringString>;
}

export function TechnicalDataFilterOptionsDTOFromJSON(json: any): TechnicalDataFilterOptionsDTO {
    return TechnicalDataFilterOptionsDTOFromJSONTyped(json, false);
}

export function TechnicalDataFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataFilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelSeries': ((json['modelSeries'] as Array<any>).map(OptionDTOStringFromJSON)),
        'modelSeriesRelatedModelGenerations': ((json['modelSeriesRelatedModelGenerations'] as Array<any>).map(RelatedOptionGroupDTOStringStringFromJSON)),
        'modelYears': ((json['modelYears'] as Array<any>).map(OptionDTOIntegerFromJSON)),
        'modelYearAndGenerationRelatedOrderTypes': ((json['modelYearAndGenerationRelatedOrderTypes'] as Array<any>).map(RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSON)),
    };
}

export function TechnicalDataFilterOptionsDTOToJSON(value?: TechnicalDataFilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelSeries': ((value.modelSeries as Array<any>).map(OptionDTOStringToJSON)),
        'modelSeriesRelatedModelGenerations': ((value.modelSeriesRelatedModelGenerations as Array<any>).map(RelatedOptionGroupDTOStringStringToJSON)),
        'modelYears': ((value.modelYears as Array<any>).map(OptionDTOIntegerToJSON)),
        'modelYearAndGenerationRelatedOrderTypes': ((value.modelYearAndGenerationRelatedOrderTypes as Array<any>).map(RelatedGroupedRelatedOptionDTOIntegerStringStringToJSON)),
    };
}


