/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization,
    MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSON,
    MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSONTyped,
    MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization
 */
export interface PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization {
    /**
     * 
     * @type {number}
     * @memberof PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization>}
     * @memberof PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization
     */
    content: Array<MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization>;
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSON(json: any): PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization {
    return PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSONTyped(json, false);
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSON)),
    };
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSON(value?: PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItemCount': value.totalItemCount,
        'content': ((value.content as Array<any>).map(MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSON)),
    };
}


