import { browserLanguage } from "../../../common/global"
import { SpacedItems } from "../../../components/layouts/spaceditems/SpacedItems"
import { ColumnBuilder } from "../../../components/layouts/table/Table"
import { Page, PagedTable, PagingFindParams } from "../../../components/pagedtable/PagedTable"
import { PButtonPure, PFlex, PHeadline } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { PropsWithChildren, useCallback, useState } from "react"

export interface DashboardPagedTableProps<ITEM> {
    titlePrefix: string
    itemsName: string
    urlParamsPrefix: string

    columns: ColumnBuilder<ITEM, undefined>[]
    getRoute: (item: ITEM) => string

    fetchPage: (page: { offset: number; size: number; languageTag: string }) => Promise<Page<ITEM>>

    shouldLoadInitially?: boolean
}

export const DashboardPagedTable = <ITEM,>({
    columns,
    fetchPage,
    getRoute,
    itemsName,
    shouldLoadInitially,
    titlePrefix,
    urlParamsPrefix,
}: PropsWithChildren<DashboardPagedTableProps<ITEM>>) => {
    const [page, setPage] = useState<PagingFindParams>({ size: 10, offset: 0 })
    const [shouldLoad, setShouldLoad] = useState(shouldLoadInitially ?? false)

    const onRowClick = useCallback((item: ITEM) => window.open(getRoute(item)), [getRoute])
    const toggleShouldLoad = useCallback(() => setShouldLoad((old) => !old), [])

    return (
        <SpacedItems direction={"column"} lineSpacing={spacing[48]}>
            <PFlex direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <PHeadline variant={"headline-4"}>
                    {titlePrefix} {itemsName}
                </PHeadline>
                <PButtonPure icon={shouldLoad ? "close" : "download"} weight={"regular"} onClick={toggleShouldLoad}>
                    {shouldLoad ? "Close" : "Load"}
                </PButtonPure>
            </PFlex>
            {shouldLoad ? (
                <PagedTable
                    itemsName={itemsName}
                    urlParamsPrefix={urlParamsPrefix}
                    onRowClick={onRowClick}
                    columns={columns}
                    fetchPage={async (page: PagingFindParams) =>
                        fetchPage({
                            ...page,
                            languageTag: browserLanguage,
                        })
                    }
                    findParams={page}
                    setPagingFindParams={setPage}
                />
            ) : null}
        </SpacedItems>
    )
}
