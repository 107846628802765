/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    LocalizationsItemDTO,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    RoofColorTagAttributesDTO,
    RoofColorTagAttributesDTOFromJSON,
    RoofColorTagAttributesDTOToJSON,
    RoofColorTagAttributesUpdateDTO,
    RoofColorTagAttributesUpdateDTOFromJSON,
    RoofColorTagAttributesUpdateDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface DeleteRoofColorTagRequest {
    key: string;
}

export interface GetAttributesOfRoofColorTagRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfRoofColorTagRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfRoofColorTagRequest {
    key: string;
}

export interface UpdateAttributesOfRoofColorTagRequest {
    key: string;
    roofColorTagAttributesUpdateDTO: RoofColorTagAttributesUpdateDTO;
}

export interface UpdateNameOfRoofColorTagRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class RoofColorTagDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a RoofColorTag by a given key
     */
    async deleteRoofColorTagRaw(requestParameters: DeleteRoofColorTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteRoofColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-tags/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a RoofColorTag by a given key
     */
    async deleteRoofColorTag(requestParameters: DeleteRoofColorTagRequest): Promise<void> {
        await this.deleteRoofColorTagRaw(requestParameters);
    }

    /**
     * Get attributes of RoofColorTag with given key
     */
    async getAttributesOfRoofColorTagRaw(requestParameters: GetAttributesOfRoofColorTagRequest): Promise<runtime.ApiResponse<RoofColorTagAttributesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfRoofColorTag.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfRoofColorTag.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-tags/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoofColorTagAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of RoofColorTag with given key
     */
    async getAttributesOfRoofColorTag(requestParameters: GetAttributesOfRoofColorTagRequest): Promise<RoofColorTagAttributesDTO> {
        const response = await this.getAttributesOfRoofColorTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of RoofColorTag with given key
     */
    async getBaseInformationOfRoofColorTagRaw(requestParameters: GetBaseInformationOfRoofColorTagRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfRoofColorTag.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfRoofColorTag.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-tags/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of RoofColorTag with given key
     */
    async getBaseInformationOfRoofColorTag(requestParameters: GetBaseInformationOfRoofColorTagRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfRoofColorTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of RoofColorTag with given key
     */
    async getLocalizationsOfNameOfRoofColorTagRaw(requestParameters: GetLocalizationsOfNameOfRoofColorTagRequest): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfRoofColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-tags/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of RoofColorTag with given key
     */
    async getLocalizationsOfNameOfRoofColorTag(requestParameters: GetLocalizationsOfNameOfRoofColorTagRequest): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfRoofColorTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing RoofColorTag
     */
    async updateAttributesOfRoofColorTagRaw(requestParameters: UpdateAttributesOfRoofColorTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfRoofColorTag.');
        }

        if (requestParameters.roofColorTagAttributesUpdateDTO === null || requestParameters.roofColorTagAttributesUpdateDTO === undefined) {
            throw new runtime.RequiredError('roofColorTagAttributesUpdateDTO','Required parameter requestParameters.roofColorTagAttributesUpdateDTO was null or undefined when calling updateAttributesOfRoofColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/roof-color-tags/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoofColorTagAttributesUpdateDTOToJSON(requestParameters.roofColorTagAttributesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing RoofColorTag
     */
    async updateAttributesOfRoofColorTag(requestParameters: UpdateAttributesOfRoofColorTagRequest): Promise<void> {
        await this.updateAttributesOfRoofColorTagRaw(requestParameters);
    }

    /**
     * Updates name of an existing RoofColorTag
     */
    async updateNameOfRoofColorTagRaw(requestParameters: UpdateNameOfRoofColorTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfRoofColorTag.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfRoofColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/roof-color-tags/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing RoofColorTag
     */
    async updateNameOfRoofColorTag(requestParameters: UpdateNameOfRoofColorTagRequest): Promise<void> {
        await this.updateNameOfRoofColorTagRaw(requestParameters);
    }

}
