import { CustomizationVideoUpdateDTO } from "../../../../generated/pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../ProductDetailsContainer"
import { ProductDetailsCard } from "../ProductDetailsCard"
import { OptionVideosCardContent } from "./content/OptionVideosCardContent"
import { VideoLocalizationsDTO } from "../../../../pdsapi"

interface OptionVideosCardProps<KEY = { key: string }> {
    label: string
    getData: (props: KEY) => Promise<VideoLocalizationsDTO>
    updateData?: (props: KEY & { update: CustomizationVideoUpdateDTO }) => Promise<void>
}

export const OptionVideosCard = <KEY,>(props: ProductDetailsTabConfigContentProps<KEY> & OptionVideosCardProps<KEY>) => (
    <ProductDetailsCard {...props} Content={OptionVideosCardContent} />
)
