import {
    BootControllerApi,
    CustomExteriorColorCreateWebControllerApi,
    CustomExteriorColorDetailsWebControllerApi,
    CustomExteriorColorListWebControllerApi,
    CustomInteriorColorCreateWebControllerApi,
    CustomInteriorColorDetailsWebControllerApi,
    CustomInteriorColorListWebControllerApi,
    CustomizationSortFieldCombinedEnum,
    DashboardWebControllerApi,
    DerivativeCategoryCreateWebControllerApi,
    DerivativeCategoryDetailsWebControllerApi,
    DerivativeCategoryListWebControllerApi,
    DerivativeCategorySortWebControllerApi,
    DerivativeCreateWebControllerApi,
    DerivativeDetailsWebControllerApi,
    DerivativeListWebControllerApi,
    DerivativeSortWebControllerApi,
    EquipmentCatalogueWebControllerApi,
    EquipmentCreateWebControllerApi,
    EquipmentCustomizationCreateWebControllerApi,
    EquipmentCustomizationDetailsWebControllerApi,
    EquipmentCustomizationListWebControllerApi,
    EquipmentTagCreateWebControllerApi,
    EquipmentTagDetailsWebControllerApi,
    EquipmentTagListWebControllerApi,
    EquipmentTagSortWebControllerApi,
    ExteriorColorCatalogueWebControllerApi,
    ExteriorColorCreateWebControllerApi,
    ExteriorColorCustomizationCreateWebControllerApi,
    ExteriorColorCustomizationDetailsWebControllerApi,
    ExteriorColorCustomizationListWebControllerApi,
    ExteriorColorTagCreateWebControllerApi,
    ExteriorColorTagDetailsWebControllerApi,
    ExteriorColorTagListWebControllerApi,
    ExteriorColorTagSortWebControllerApi,
    GetCustomExteriorColorsPageRequest,
    GetCustomInteriorColorsPageRequest,
    GetDerivativeCategoriesPageRequest,
    GetDerivativesPageRequest,
    GetEquipmentCustomizationsPageRequest,
    GetEquipmentOfOrderTypeRequest,
    GetEquipmentsPageRequest,
    GetEquipmentTagsPageRequest,
    GetExteriorColorCustomizationsPageRequest,
    GetExteriorColorsOfOrderTypeRequest,
    GetExteriorColorsPageRequest,
    GetExteriorColorTagsPageRequest,
    GetInteriorColorTagsPageRequest,
    GetInteriorCustomizationsPageRequest,
    GetInteriorsOfOrderTypeRequest,
    GetInteriorsPageRequest,
    GetModelGenerationsPageRequest,
    GetModelSeriesPageRequest,
    GetOrderTypesPageRequest,
    GetRoofColorCustomizationsPageRequest,
    GetRoofColorsOfOrderTypeRequest,
    GetRoofColorsPageRequest,
    GetRoofColorTagsPageRequest,
    GetTechnicalDataPageRequest,
    InteriorCatalogueWebControllerApi,
    InteriorColorTagCreateWebControllerApi,
    InteriorColorTagDetailsWebControllerApi,
    InteriorColorTagListWebControllerApi,
    InteriorColorTagSortWebControllerApi,
    InteriorCreateWebControllerApi,
    InteriorCustomizationCreateWebControllerApi,
    InteriorCustomizationDetailsWebControllerApi,
    InteriorCustomizationListWebControllerApi,
    LocalizationsItemDTO,
    ModelGenerationCreateWebControllerApi,
    ModelGenerationDetailsWebControllerApi,
    ModelGenerationListWebControllerApi,
    ModelGenerationSortWebControllerApi,
    ModelSeriesCreateWebControllerApi,
    ModelSeriesDetailsWebControllerApi,
    ModelSeriesListWebControllerApi,
    ModelSeriesSortWebControllerApi,
    OrderTypeCreateWebControllerApi,
    OrderTypeDataDetailsWebControllerApi,
    OrderTypeDetailsWebControllerApi,
    OrderTypeEquipmentDataDetailsWebControllerApi,
    OrderTypeEquipmentDescriptionDetailsWebControllerApi,
    OrderTypeEquipmentDetailsWebControllerApi,
    OrderTypeEquipmentMarketplaceSpecificsDetailsWebControllerApi,
    OrderTypeEquipmentNameDetailsWebControllerApi,
    OrderTypeExteriorColorDataDetailsWebControllerApi,
    OrderTypeExteriorColorDescriptionDetailsWebControllerApi,
    OrderTypeExteriorColorDetailsWebControllerApi,
    OrderTypeExteriorColorMarketplaceSpecificsDetailsWebControllerApi,
    OrderTypeExteriorColorNameDetailsWebControllerApi,
    OrderTypeInteriorDataDetailsWebControllerApi,
    OrderTypeInteriorDescriptionDetailsWebControllerApi,
    OrderTypeInteriorDetailsWebControllerApi,
    OrderTypeInteriorMarketplaceSpecificsDetailsWebControllerApi,
    OrderTypeInteriorNameDetailsWebControllerApi,
    OrderTypeListWebControllerApi,
    OrderTypeLocalizationDetailsWebControllerApi,
    OrderTypeMediaDetailsWebControllerApi,
    OrderTypeOptionDetailsWebControllerApi,
    OrderTypePackageDetailsWebControllerApi,
    OrderTypePaintToSampleExteriorColorDataDetailsWebControllerApi,
    OrderTypePaintToSampleExteriorColorDescriptionDetailsWebControllerApi,
    OrderTypePaintToSampleExteriorColorDetailsWebControllerApi,
    OrderTypePaintToSampleExteriorColorMarketplaceSpecificsDetailsWebControllerApi,
    OrderTypePaintToSampleExteriorColorNameDetailsWebControllerApi,
    OrderTypePressEmbargoDetailsWebControllerApi,
    OrderTypeRoofColorDataDetailsWebControllerApi,
    OrderTypeRoofColorDescriptionDetailsWebControllerApi,
    OrderTypeRoofColorDetailsWebControllerApi,
    OrderTypeRoofColorMarketplaceSpecificsDetailsWebControllerApi,
    OrderTypeRoofColorNameDetailsWebControllerApi,
    OrderTypeSortField,
    OrderTypeStandardEquipmentDetailsWebControllerApi,
    OrderTypeTechnicalDataDetailsWebControllerApi,
    PaintToSampleExteriorColorCreateWebControllerApi,
    PaintToSampleExteriorColorCustomizationCreateWebControllerApi,
    PaintToSampleExteriorColorCustomizationDetailsWebControllerApi,
    RoofColorCatalogueWebControllerApi,
    RoofColorCreateWebControllerApi,
    RoofColorCustomizationCreateWebControllerApi,
    RoofColorCustomizationDetailsWebControllerApi,
    RoofColorCustomizationListWebControllerApi,
    RoofColorTagCreateWebControllerApi,
    RoofColorTagDetailsWebControllerApi,
    RoofColorTagListWebControllerApi,
    RoofColorTagSortWebControllerApi,
    SortFieldCombinedEnum,
    TechnicalDataListWebControllerApi,
    TechnicalDataSortField,
    VideoLocalizationsItemDTO,
} from "../generated/pdsapi"
import { ProductListFindParamsWithoutFilter } from "../viewtemplates/list/ProductListContainer"
import { boundApi } from "./boundApi"

export type LocalizationsDTO = Record<string, LocalizationsItemDTO>
export type LocalizationsUpdateDTO = { [key: string]: string }
export type VideoLocalizationsDTO = Record<string, VideoLocalizationsItemDTO>

const dashboardControllerApi = boundApi(DashboardWebControllerApi)
export const dashboardApi = {
    getIncompleteExteriorCustomizations: dashboardControllerApi.getIncompleteExteriorColorCustomizations,
    getMissingExteriorColorCustomizations: dashboardControllerApi.getMissingExteriorColorCustomizations,
    getMissingRoofColorCustomizations: dashboardControllerApi.getMissingRoofColorCustomizations,
    getIncompleteInteriorCustomizations: dashboardControllerApi.getIncompleteInteriorCustomizations,
    getMissingInteriorCustomizations: dashboardControllerApi.getMissingInteriorCustomizations,
    getUnmaintainedCustomExteriorColors: dashboardControllerApi.getUnmaintainedCustomExteriorColors,
    getUnmaintainedCustomInteriorColors: dashboardControllerApi.getUnmaintainedCustomInteriorColors,
    getIncompleteOrderTypes: dashboardControllerApi.getIncompleteOrderTypes,
}

const customExteriorColorCreateApi = boundApi(CustomExteriorColorCreateWebControllerApi)
const customExteriorColorDetailsApi = boundApi(CustomExteriorColorDetailsWebControllerApi)
const customExteriorColorListApi = boundApi(CustomExteriorColorListWebControllerApi)
export type CustomExteriorColorFilter = Omit<GetCustomExteriorColorsPageRequest, keyof ProductListFindParamsWithoutFilter<unknown>>
export const customExteriorColorApi = {
    create: {
        create: customExteriorColorCreateApi.createCustomExteriorColor,
        getOptions: customExteriorColorCreateApi.getCustomExteriorColorCreateOptions,
    },
    details: {
        loadVehicleImages: customExteriorColorDetailsApi.findCustomExteriorColorVehicleImages,
        getBaseInformation: customExteriorColorDetailsApi.getBaseInformationOfCustomExteriorColor,
        deleteByKey: customExteriorColorDetailsApi.deleteCustomExteriorColor,
        getAttributes: customExteriorColorDetailsApi.getAttributesOfCustomExteriorColor,
        updateAttributes: customExteriorColorDetailsApi.updateAttributesOfCustomExteriorColor,
        getNameLocalizations: customExteriorColorDetailsApi.getLocalizationsOfNameOfCustomExteriorColor,
        updateName: customExteriorColorDetailsApi.updateNameOfCustomExteriorColor,
    },
    list: {
        getPage: customExteriorColorListApi.getCustomExteriorColorsPage,
        getFilterOptions: customExteriorColorListApi.getCustomExteriorColorFilterOptions,
    },
}

const customInteriorColorCreateApi = boundApi(CustomInteriorColorCreateWebControllerApi)
const customInteriorColorDetailsApi = boundApi(CustomInteriorColorDetailsWebControllerApi)
const customInteriorColorListApi = boundApi(CustomInteriorColorListWebControllerApi)
export type CustomInteriorColorFilter = Omit<GetCustomInteriorColorsPageRequest, keyof ProductListFindParamsWithoutFilter<unknown>>
export const customInteriorColorApi = {
    create: {
        create: customInteriorColorCreateApi.createCustomInteriorColor,
        getOptions: customInteriorColorCreateApi.getCustomInteriorColorCreateOptions,
    },
    details: {
        loadVehicleImages: customInteriorColorDetailsApi.findCustomInteriorColorVehicleImages,
        getBaseInformation: customInteriorColorDetailsApi.getBaseInformationOfCustomInteriorColor,
        deleteByKey: customInteriorColorDetailsApi.deleteCustomInteriorColor,
        getAttributes: customInteriorColorDetailsApi.getAttributesOfCustomInteriorColor,
        updateAttributes: customInteriorColorDetailsApi.updateAttributesOfCustomInteriorColor,
        getNameLocalizations: customInteriorColorDetailsApi.getLocalizationsOfNameOfCustomInteriorColor,
        updateName: customInteriorColorDetailsApi.updateNameOfCustomInteriorColor,
    },
    list: {
        getPage: customInteriorColorListApi.getCustomInteriorColorsPage,
        getFilterOptions: customInteriorColorListApi.getCustomInteriorColorFilterOptions,
    },
}

const derivativeCategoryCreateControllerApi = boundApi(DerivativeCategoryCreateWebControllerApi)
const derivativeCategoryListControllerApi = boundApi(DerivativeCategoryListWebControllerApi)
const derivativeCategoryDetailsControllerApi = boundApi(DerivativeCategoryDetailsWebControllerApi)
const derivativeCategorySortControllerApi = boundApi(DerivativeCategorySortWebControllerApi)
export type DerivativeCategorySortFieldEnum = SortFieldCombinedEnum
export const DerivativeCategorySortField = SortFieldCombinedEnum
export type DerivativeCategoryFilter = Omit<GetDerivativeCategoriesPageRequest, keyof ProductListFindParamsWithoutFilter<DerivativeCategorySortFieldEnum>>

export const derivativeCategoryApi = {
    create: {
        create: derivativeCategoryCreateControllerApi.createDerivativeCategory,
        getCreateOptions: derivativeCategoryCreateControllerApi.getDerivativeCategoryCreateOptions,
    },
    list: {
        getPage: derivativeCategoryListControllerApi.getDerivativeCategoriesPage,
        getFilterOptions: derivativeCategoryListControllerApi.getDerivativeCategoryFilterOptions,
    },
    details: {
        deleteByKey: derivativeCategoryDetailsControllerApi.deleteDerivativeCategory,
        getBaseInformation: derivativeCategoryDetailsControllerApi.getBaseInformationOfDerivativeCategory,
        getAttributes: derivativeCategoryDetailsControllerApi.getAttributesOfDerivativeCategory,
        updateAttributes: derivativeCategoryDetailsControllerApi.updateAttributesOfDerivativeCategory,
        getLinks: derivativeCategoryDetailsControllerApi.getLinksOfDerivativeCategory,
        getLocalizations: derivativeCategoryDetailsControllerApi.getLocalizationsOfNameOfDerivativeCategory,
        updateLocalizations: derivativeCategoryDetailsControllerApi.updateNameOfDerivativeCategory,
    },
    sort: {
        getSortableItems: derivativeCategorySortControllerApi.getDerivativeCategorySortableItems,
        updateSortIndices: derivativeCategorySortControllerApi.updateDerivativeCategorySortIndex,
    },
}

const derivativeCreateControllerApi = boundApi(DerivativeCreateWebControllerApi)
const derivativeListControllerApi = boundApi(DerivativeListWebControllerApi)
const derivativeDetailsControllerApi = boundApi(DerivativeDetailsWebControllerApi)
const derivativeSortControllerApi = boundApi(DerivativeSortWebControllerApi)
export type DerivativeSortFieldEnum = SortFieldCombinedEnum
export const DerivativeSortField = SortFieldCombinedEnum
export type DerivativeFilter = Omit<GetDerivativesPageRequest, keyof ProductListFindParamsWithoutFilter<DerivativeSortFieldEnum>>

export const derivativeApi = {
    create: {
        create: derivativeCreateControllerApi.createDerivative,
        getCreateOptions: derivativeCreateControllerApi.getDerivativeCreateOptions,
    },
    list: {
        getPage: derivativeListControllerApi.getDerivativesPage,
        getFilterOptions: derivativeListControllerApi.getDerivativeFilterOptions,
    },
    details: {
        deleteByKey: derivativeDetailsControllerApi.deleteDerivative,
        getBaseInformation: derivativeDetailsControllerApi.getBaseInformationOfDerivative,
        getAttributes: derivativeDetailsControllerApi.getAttributesOfDerivative,
        updateAttributes: derivativeDetailsControllerApi.updateAttributesOfDerivative,
        getLinks: derivativeDetailsControllerApi.getLinksOfDerivative,
        getLocalizations: derivativeDetailsControllerApi.getLocalizationsOfNameOfDerivative,
        updateLocalizations: derivativeDetailsControllerApi.updateNameOfDerivative,
    },
    sort: {
        getSortableItems: derivativeSortControllerApi.getDerivativeSortableItems,
        updateSortIndices: derivativeSortControllerApi.updateDerivativeSortIndex,
    },
}

const equipmentCustomizationCreateControllerApi = boundApi(EquipmentCustomizationCreateWebControllerApi)
const equipmentCustomizationListControllerApi = boundApi(EquipmentCustomizationListWebControllerApi)
const equipmentCustomizationDetailsControllerApi = boundApi(EquipmentCustomizationDetailsWebControllerApi)
export type EquipmentCustomizationSortFieldEnum = CustomizationSortFieldCombinedEnum
export const EquipmentCustomizationSortField = CustomizationSortFieldCombinedEnum
export type EquipmentCustomizationFilter = Omit<
    GetEquipmentCustomizationsPageRequest,
    keyof ProductListFindParamsWithoutFilter<EquipmentCustomizationSortFieldEnum>
>

export const equipmentCustomizationApi = {
    create: {
        create: equipmentCustomizationCreateControllerApi.createEquipmentCustomization,
        getCreateOptions: equipmentCustomizationCreateControllerApi.getEquipmentCustomizationCreateOptions,
    },
    list: {
        getPage: equipmentCustomizationListControllerApi.getEquipmentCustomizationsPage,
        getFilterOptions: equipmentCustomizationListControllerApi.getEquipmentCustomizationFilterOptions,
    },
    details: {
        deleteByKey: equipmentCustomizationDetailsControllerApi.deleteEquipmentCustomization,
        getBaseInformation: equipmentCustomizationDetailsControllerApi.getBaseInformationOfEquipmentCustomization,
        getAttributes: equipmentCustomizationDetailsControllerApi.getAttributesOfEquipmentCustomization,
        updateAttributes: equipmentCustomizationDetailsControllerApi.updateAttributesOfEquipmentCustomization,
        getLinks: equipmentCustomizationDetailsControllerApi.getLinksOfEquipmentCustomization,
        getName: equipmentCustomizationDetailsControllerApi.getLocalizationsOfNameOfEquipmentCustomization,
        updateDefaultName: equipmentCustomizationDetailsControllerApi.updateNameOfDefaultValueOfEquipmentCustomization,
        updateConditionalValueName:
            equipmentCustomizationDetailsControllerApi.updateNameOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization,
        getDescription: equipmentCustomizationDetailsControllerApi.getLocalizationsOfDescriptionOfEquipmentCustomization,
        updateDefaultDescription: equipmentCustomizationDetailsControllerApi.updateDescriptionOfDefaultValueOfEquipmentCustomization,
        updateConditionalValueDescription:
            equipmentCustomizationDetailsControllerApi.updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization,
        getVideos: equipmentCustomizationDetailsControllerApi.getLocalizationsOfVideoOfEquipmentCustomization,
        updateDefaultVideo: equipmentCustomizationDetailsControllerApi.updateVideoOfDefaultValueOfEquipmentCustomization,
        updateConditionalValueVideo:
            equipmentCustomizationDetailsControllerApi.updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization,
    },
}

const equipmentCreateControllerApi = boundApi(EquipmentCreateWebControllerApi)
const equipmentCatalogueControllerApi = boundApi(EquipmentCatalogueWebControllerApi)
const orderTypeEquipmentDetailsControllerApi = boundApi(OrderTypeEquipmentDetailsWebControllerApi)
const orderTypeEquipmentDataDetailsControllerApi = boundApi(OrderTypeEquipmentDataDetailsWebControllerApi)
const orderTypeEquipmentNameDetailsControllerApi = boundApi(OrderTypeEquipmentNameDetailsWebControllerApi)
const orderTypeEquipmentDescriptionDetailsControllerApi = boundApi(OrderTypeEquipmentDescriptionDetailsWebControllerApi)
const orderTypeEquipmentMarketplaceSpecificsDetailsWebControllerApi = boundApi(OrderTypeEquipmentMarketplaceSpecificsDetailsWebControllerApi)
export type EquipmentSortFieldEnum = SortFieldCombinedEnum
export const EquipmentSortField = SortFieldCombinedEnum
export type EquipmentFilter = Omit<GetEquipmentsPageRequest, keyof ProductListFindParamsWithoutFilter<EquipmentSortFieldEnum>>
export const equipmentApi = {
    create: {
        create: equipmentCreateControllerApi.createEquipment,
        getCreateOptions: equipmentCreateControllerApi.getEquipmentCreateOptions,
    },
    catalogue: {
        getPage: equipmentCatalogueControllerApi.getEquipmentsPage,
        getFilterOptions: equipmentCatalogueControllerApi.getEquipmentFilterOptions,
    },
    details: {
        deleteByKey: orderTypeEquipmentDetailsControllerApi.deleteEquipment,
        getBaseInformation: orderTypeEquipmentDetailsControllerApi.getBaseInformationOfEquipment,
        getOption: orderTypeEquipmentDataDetailsControllerApi.getOptionOfEquipment,
        updateOption: orderTypeEquipmentDataDetailsControllerApi.updateOptionOfEquipment,
        getLinkedCustomization: orderTypeEquipmentDataDetailsControllerApi.getLinkedCustomizationOfEquipment,
        getSimilarCustomizations: orderTypeEquipmentDataDetailsControllerApi.getSimilarCustomizationsOfEquipment,
        getOptionsInFamily: orderTypeEquipmentDataDetailsControllerApi.getOptionsInFamilyOfEquipment,
        getName: orderTypeEquipmentNameDetailsControllerApi.getLocalizationsOfNameOfEquipment,
        updateName: orderTypeEquipmentNameDetailsControllerApi.updateNameOfEquipment,
        getDescription: orderTypeEquipmentDescriptionDetailsControllerApi.getLocalizationsOfDescriptionOfEquipment,
        updateDescription: orderTypeEquipmentDescriptionDetailsControllerApi.updateDescriptionOfEquipment,
        getMarketplaceSpecifics: orderTypeEquipmentMarketplaceSpecificsDetailsWebControllerApi.getMarketplaceSpecificsOfEquipment,
    },
}

const equipmentTagCreateControllerApi = boundApi(EquipmentTagCreateWebControllerApi)
const equipmentTagListControllerApi = boundApi(EquipmentTagListWebControllerApi)
const equipmentTagSortControllerApi = boundApi(EquipmentTagSortWebControllerApi)
const equipmentTagDetailsControllerApi = boundApi(EquipmentTagDetailsWebControllerApi)

export type EquipmentTagSortFieldEnum = SortFieldCombinedEnum
export const EquipmentTagSortField = SortFieldCombinedEnum
export type EquipmentTagFilter = Omit<GetEquipmentTagsPageRequest, keyof ProductListFindParamsWithoutFilter<EquipmentTagSortFieldEnum>>

export const equipmentTagApi = {
    create: {
        create: equipmentTagCreateControllerApi.createEquipmentTag,
        getCreateOptions: equipmentTagCreateControllerApi.getEquipmentTagCreateOptions,
    },
    list: {
        getPage: equipmentTagListControllerApi.getEquipmentTagsPage,
        getFilterOptions: equipmentTagListControllerApi.getEquipmentTagFilterOptions,
    },
    sort: {
        getSortableItems: equipmentTagSortControllerApi.getEquipmentTagSortableItems,
        updateSortIndices: equipmentTagSortControllerApi.updateEquipmentTagSortIndex,
    },
    details: {
        deleteByKey: equipmentTagDetailsControllerApi.deleteEquipmentTag,
        getBaseInformation: equipmentTagDetailsControllerApi.getBaseInformationOfEquipmentTag,
        getAttributes: equipmentTagDetailsControllerApi.getAttributesOfEquipmentTag,
        updateAttributes: equipmentTagDetailsControllerApi.updateAttributesOfEquipmentTag,
        getLocalizations: equipmentTagDetailsControllerApi.getLocalizationsOfNameOfEquipmentTag,
        updateLocalizations: equipmentTagDetailsControllerApi.updateNameOfEquipmentTag,
    },
}

const exteriorColorCustomizationCreateControllerApi = boundApi(ExteriorColorCustomizationCreateWebControllerApi)
const exteriorColorCustomizationListControllerApi = boundApi(ExteriorColorCustomizationListWebControllerApi)
const exteriorColorCustomizationDetailsControllerApi = boundApi(ExteriorColorCustomizationDetailsWebControllerApi)
export type ExteriorColorCustomizationSortFieldEnum = CustomizationSortFieldCombinedEnum
export const ExteriorColorCustomizationSortField = CustomizationSortFieldCombinedEnum
export type ExteriorColorCustomizationFilter = Omit<
    GetExteriorColorCustomizationsPageRequest,
    keyof ProductListFindParamsWithoutFilter<ExteriorColorCustomizationSortFieldEnum>
>

export const exteriorColorCustomizationApi = {
    create: {
        create: exteriorColorCustomizationCreateControllerApi.createExteriorColorCustomization,
        getCreateOptions: exteriorColorCustomizationCreateControllerApi.getExteriorColorCustomizationCreateOptions,
        getVehicleImages: exteriorColorCustomizationCreateControllerApi.getVehicleImagesForExteriorColorCustomization,
    },
    list: {
        getPage: exteriorColorCustomizationListControllerApi.getExteriorColorCustomizationsPage,
        getFilterOptions: exteriorColorCustomizationListControllerApi.getExteriorColorCustomizationFilterOptions,
    },
    details: {
        deleteByKey: exteriorColorCustomizationDetailsControllerApi.deleteExteriorColorCustomization,
        getBaseInformation: exteriorColorCustomizationDetailsControllerApi.getBaseInformationOfExteriorColorCustomization,
        getAttributes: exteriorColorCustomizationDetailsControllerApi.getAttributesOfExteriorColorCustomization,
        updateAttributes: exteriorColorCustomizationDetailsControllerApi.updateAttributesOfExteriorColorCustomization,
        getLinks: exteriorColorCustomizationDetailsControllerApi.getLinksOfExteriorColorCustomization,
        getVehicleImages: exteriorColorCustomizationDetailsControllerApi.getVehicleImagesOfExteriorColorCustomization,
        getName: exteriorColorCustomizationDetailsControllerApi.getLocalizationsOfNameOfExteriorColorCustomization,
        updateDefaultName: exteriorColorCustomizationDetailsControllerApi.updateNameOfDefaultValueOfExteriorColorCustomization,
        updateConditionalValueName:
            exteriorColorCustomizationDetailsControllerApi.updateNameOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomization,
        getDescription: exteriorColorCustomizationDetailsControllerApi.getLocalizationsOfDescriptionOfExteriorColorCustomization,
        updateDefaultDescription: exteriorColorCustomizationDetailsControllerApi.updateDescriptionOfDefaultValueOfExteriorColorCustomization,
        updateConditionalValueDescription:
            exteriorColorCustomizationDetailsControllerApi.updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomization,
        getVideos: exteriorColorCustomizationDetailsControllerApi.getLocalizationsOfVideoOfExteriorColorCustomization,
        updateDefaultVideo: exteriorColorCustomizationDetailsControllerApi.updateVideoOfDefaultValueOfExteriorColorCustomization,
        updateConditionalVideo:
            exteriorColorCustomizationDetailsControllerApi.updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomization,
    },
}

const exteriorColorCreateControllerApi = boundApi(ExteriorColorCreateWebControllerApi)
const exteriorColorCatalogueControllerApi = boundApi(ExteriorColorCatalogueWebControllerApi)
const orderTypeExteriorColorDetailsControllerApi = boundApi(OrderTypeExteriorColorDetailsWebControllerApi)
const orderTypeExteriorColorDataDetailsControllerApi = boundApi(OrderTypeExteriorColorDataDetailsWebControllerApi)
const orderTypeExteriorColorNameDetailsControllerApi = boundApi(OrderTypeExteriorColorNameDetailsWebControllerApi)
const orderTypeExteriorColorDescriptionDetailsControllerApi = boundApi(OrderTypeExteriorColorDescriptionDetailsWebControllerApi)
const orderTypeExteriorColorMarketplaceSpecificsDetailsWebControllerApi = boundApi(OrderTypeExteriorColorMarketplaceSpecificsDetailsWebControllerApi)
export type ExteriorColorSortFieldEnum = SortFieldCombinedEnum
export const ExteriorColorSortField = SortFieldCombinedEnum
export type ExteriorColorFilter = Omit<GetExteriorColorsPageRequest, keyof ProductListFindParamsWithoutFilter<ExteriorColorSortFieldEnum>>
export const exteriorColorApi = {
    create: {
        create: exteriorColorCreateControllerApi.createExteriorColor,
        getCreateOptions: exteriorColorCreateControllerApi.getExteriorColorCreateOptions,
    },
    catalogue: {
        getPage: exteriorColorCatalogueControllerApi.getExteriorColorsPage,
        getFilterOptions: exteriorColorCatalogueControllerApi.getExteriorColorFilterOptions,
    },
    details: {
        deleteByKey: orderTypeExteriorColorDetailsControllerApi.deleteExteriorColor,
        getBaseInformation: orderTypeExteriorColorDetailsControllerApi.getBaseInformationOfExteriorColor,
        getOption: orderTypeExteriorColorDataDetailsControllerApi.getOptionOfExteriorColor,
        updateOption: orderTypeExteriorColorDataDetailsControllerApi.updateOptionOfExteriorColor,
        getLinkedCustomization: orderTypeExteriorColorDataDetailsControllerApi.getLinkedCustomizationOfExteriorColor,
        getSimilarCustomizations: orderTypeExteriorColorDataDetailsControllerApi.getSimilarCustomizationsOfExteriorColor,
        getName: orderTypeExteriorColorNameDetailsControllerApi.getLocalizationsOfNameOfExteriorColor,
        updateName: orderTypeExteriorColorNameDetailsControllerApi.updateNameOfExteriorColor,
        getDescription: orderTypeExteriorColorDescriptionDetailsControllerApi.getLocalizationsOfDescriptionOfExteriorColor,
        updateDescription: orderTypeExteriorColorDescriptionDetailsControllerApi.updateDescriptionOfExteriorColor,
        getMarketplaceSpecifics: orderTypeExteriorColorMarketplaceSpecificsDetailsWebControllerApi.getMarketplaceSpecificsOfExteriorColor,
    },
}

export type ExteriorColorTagSortFieldEnum = SortFieldCombinedEnum
export const ExteriorColorTagSortField = SortFieldCombinedEnum

const exteriorColorTagCreateControllerApi = boundApi(ExteriorColorTagCreateWebControllerApi)
const exteriorColorTagListControllerApi = boundApi(ExteriorColorTagListWebControllerApi)
const exteriorColorTagSortControllerApi = boundApi(ExteriorColorTagSortWebControllerApi)
const exteriorColorTagDetailsControllerApi = boundApi(ExteriorColorTagDetailsWebControllerApi)
export type ExteriorColorTagFilter = Omit<GetExteriorColorTagsPageRequest, keyof ProductListFindParamsWithoutFilter<ExteriorColorTagSortFieldEnum>>

export const exteriorColorTagApi = {
    create: {
        create: exteriorColorTagCreateControllerApi.createExteriorColorTag,
    },
    list: {
        getPage: exteriorColorTagListControllerApi.getExteriorColorTagsPage,
    },
    sort: {
        getSortableItems: exteriorColorTagSortControllerApi.getExteriorColorTagSortableItems,
        updateSortIndices: exteriorColorTagSortControllerApi.updateExteriorColorTagSortIndex,
    },
    details: {
        deleteByKey: exteriorColorTagDetailsControllerApi.deleteExteriorColorTag,
        getBaseInformation: exteriorColorTagDetailsControllerApi.getBaseInformationOfExteriorColorTag,
        getAttributes: exteriorColorTagDetailsControllerApi.getAttributesOfExteriorColorTag,
        updateAttributes: exteriorColorTagDetailsControllerApi.updateAttributesOfExteriorColorTag,
        getNameLocalizations: exteriorColorTagDetailsControllerApi.getLocalizationsOfNameOfExteriorColorTag,
        updateName: exteriorColorTagDetailsControllerApi.updateNameOfExteriorColorTag,
    },
}

const paintToSampleExteriorColorCustomizationCreateControllerApi = boundApi(PaintToSampleExteriorColorCustomizationCreateWebControllerApi)
const paintToSampleExteriorColorCustomizationDetailsControllerApi = boundApi(PaintToSampleExteriorColorCustomizationDetailsWebControllerApi)

export const paintToSampleExteriorColorCustomizationApi = {
    create: {
        create: paintToSampleExteriorColorCustomizationCreateControllerApi.createPaintToSampleExteriorColorCustomization,
        getCreateOptions: paintToSampleExteriorColorCustomizationCreateControllerApi.getPaintToSampleExteriorColorCustomizationCreateOptions,
        getVehicleImages: paintToSampleExteriorColorCustomizationCreateControllerApi.getVehicleImagesForPaintToSampleExteriorColorCustomization,
    },
    details: {
        deleteByKey: paintToSampleExteriorColorCustomizationDetailsControllerApi.deletePaintToSampleExteriorColorCustomization,
        getBaseInformation: paintToSampleExteriorColorCustomizationDetailsControllerApi.getBaseInformationOfPaintToSampleExteriorColorCustomization,
        getAttributes: paintToSampleExteriorColorCustomizationDetailsControllerApi.getAttributesOfPaintToSampleExteriorColorCustomization,
        updateAttributes: paintToSampleExteriorColorCustomizationDetailsControllerApi.updateAttributesOfPaintToSampleExteriorColorCustomization,
        getLinks: paintToSampleExteriorColorCustomizationDetailsControllerApi.getLinksOfPaintToSampleExteriorColorCustomization,
        getVehicleImages: paintToSampleExteriorColorCustomizationDetailsControllerApi.getVehicleImagesOfPaintToSampleExteriorColorCustomization,
        getName: paintToSampleExteriorColorCustomizationDetailsControllerApi.getLocalizationsOfNameOfPaintToSampleExteriorColorCustomization,
        updateDefaultName: paintToSampleExteriorColorCustomizationDetailsControllerApi.updateNameOfDefaultValueOfPaintToSampleExteriorColorCustomization,
        updateConditionalValueName:
            paintToSampleExteriorColorCustomizationDetailsControllerApi.updateNameOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization,
        getDescription: paintToSampleExteriorColorCustomizationDetailsControllerApi.getLocalizationsOfDescriptionOfPaintToSampleExteriorColorCustomization,
        updateDefaultDescription:
            paintToSampleExteriorColorCustomizationDetailsControllerApi.updateDescriptionOfDefaultValueOfPaintToSampleExteriorColorCustomization,
        updateConditionalValueDescription:
            paintToSampleExteriorColorCustomizationDetailsControllerApi.updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization,
        getVideos: paintToSampleExteriorColorCustomizationDetailsControllerApi.getLocalizationsOfVideoOfPaintToSampleExteriorColorCustomization,
        updateDefaultVideo: paintToSampleExteriorColorCustomizationDetailsControllerApi.updateVideoOfDefaultValueOfPaintToSampleExteriorColorCustomization,
        updateConditionalVideo:
            paintToSampleExteriorColorCustomizationDetailsControllerApi.updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfPaintToSampleExteriorColorCustomization,
    },
}

const orderTypePaintToSampleExteriorColorCreateControllerApi = boundApi(PaintToSampleExteriorColorCreateWebControllerApi)
const orderTypePaintToSampleExteriorColorDataDetailsWebControllerApi = boundApi(OrderTypePaintToSampleExteriorColorDataDetailsWebControllerApi)
const orderTypePaintToSampleExteriorColorDetailsWebControllerApi = boundApi(OrderTypePaintToSampleExteriorColorDetailsWebControllerApi)
const orderTypePaintToSampleExteriorColorNameDetailsControllerApi = boundApi(OrderTypePaintToSampleExteriorColorNameDetailsWebControllerApi)
const orderTypePaintToSampleExteriorColorDescriptionDetailsControllerApi = boundApi(OrderTypePaintToSampleExteriorColorDescriptionDetailsWebControllerApi)
const orderTypePaintToSampleExteriorColorMarketplaceSpecificsDetailsWebControllerApi = boundApi(
    OrderTypePaintToSampleExteriorColorMarketplaceSpecificsDetailsWebControllerApi
)

export type PaintToSampleExteriorColorSortFieldEnum = SortFieldCombinedEnum
export const PaintToSampleExteriorColorSortField = SortFieldCombinedEnum

export const paintToSampleExteriorColorApi = {
    create: {
        create: orderTypePaintToSampleExteriorColorCreateControllerApi.createPaintToSampleExteriorColor,
    },
    details: {
        deleteByKey: orderTypePaintToSampleExteriorColorDetailsWebControllerApi.deletePaintToSampleExteriorColor,
        getBaseInformation: orderTypePaintToSampleExteriorColorDetailsWebControllerApi.getBaseInformationOfPaintToSampleExteriorColor,
        getOption: orderTypePaintToSampleExteriorColorDataDetailsWebControllerApi.getOptionOfPaintToSampleExteriorColor,
        getOptions: orderTypePaintToSampleExteriorColorDataDetailsWebControllerApi.getOptionsOfPaintToSampleExteriorColor,
        updateOption: orderTypePaintToSampleExteriorColorDataDetailsWebControllerApi.updateOptionOfPaintToSampleExteriorColor,
        getLinkedCustomization: orderTypePaintToSampleExteriorColorDataDetailsWebControllerApi.getLinkedCustomizationOfPaintToSampleExteriorColor,
        getName: orderTypePaintToSampleExteriorColorNameDetailsControllerApi.getLocalizationsOfNameOfPaintToSampleExteriorColor,
        updateName: orderTypePaintToSampleExteriorColorNameDetailsControllerApi.updateNameOfPaintToSampleExteriorColor,
        getDescription: orderTypePaintToSampleExteriorColorDescriptionDetailsControllerApi.getLocalizationsOfDescriptionOfPaintToSampleExteriorColor,
        updateDescription: orderTypePaintToSampleExteriorColorDescriptionDetailsControllerApi.updateDescriptionOfPaintToSampleExteriorColor,
        getMarketplaceSpecifics:
            orderTypePaintToSampleExteriorColorMarketplaceSpecificsDetailsWebControllerApi.getMarketplaceSpecificsOfPaintToSampleExteriorColor,
    },
}

const interiorCustomizationCreateControllerApi = boundApi(InteriorCustomizationCreateWebControllerApi)
const interiorCustomizationListControllerApi = boundApi(InteriorCustomizationListWebControllerApi)
const interiorCustomizationDetailsControllerApi = boundApi(InteriorCustomizationDetailsWebControllerApi)
export type InteriorCustomizationSortFieldEnum = CustomizationSortFieldCombinedEnum
export const InteriorCustomizationSortField = CustomizationSortFieldCombinedEnum
export type InteriorCustomizationFilter = Omit<
    GetInteriorCustomizationsPageRequest,
    keyof ProductListFindParamsWithoutFilter<InteriorCustomizationSortFieldEnum>
>

export const interiorCustomizationApi = {
    create: {
        create: interiorCustomizationCreateControllerApi.createInteriorCustomization,
        getCreateOptions: interiorCustomizationCreateControllerApi.getInteriorCustomizationCreateOptions,
        getVehicleImages: interiorCustomizationCreateControllerApi.getVehicleImagesForInteriorCustomization,
    },
    list: {
        getPage: interiorCustomizationListControllerApi.getInteriorCustomizationsPage,
        getFilterOptions: interiorCustomizationListControllerApi.getInteriorCustomizationFilterOptions,
    },
    details: {
        deleteByKey: interiorCustomizationDetailsControllerApi.deleteInteriorCustomization,
        getBaseInformation: interiorCustomizationDetailsControllerApi.getBaseInformationOfInteriorCustomization,
        getAttributes: interiorCustomizationDetailsControllerApi.getAttributesOfInteriorCustomization,
        updateAttributes: interiorCustomizationDetailsControllerApi.updateAttributesOfInteriorCustomization,
        getLinks: interiorCustomizationDetailsControllerApi.getLinksOfInteriorCustomization,
        getVehicleImages: interiorCustomizationDetailsControllerApi.getVehicleImagesOfInteriorCustomization,
        getName: interiorCustomizationDetailsControllerApi.getLocalizationsOfNameOfInteriorCustomization,
        updateDefaultName: interiorCustomizationDetailsControllerApi.updateNameOfDefaultValueOfInteriorCustomization,
        updateConditionalValueName: interiorCustomizationDetailsControllerApi.updateNameOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomization,
        getDescription: interiorCustomizationDetailsControllerApi.getLocalizationsOfDescriptionOfInteriorCustomization,
        updateDefaultDescription: interiorCustomizationDetailsControllerApi.updateDescriptionOfDefaultValueOfInteriorCustomization,
        updateConditionalValueDescription:
            interiorCustomizationDetailsControllerApi.updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomization,
    },
}

const interiorCreateControllerApi = boundApi(InteriorCreateWebControllerApi)
const interiorCatalogueControllerApi = boundApi(InteriorCatalogueWebControllerApi)
const orderTypeInteriorDetailsControllerApi = boundApi(OrderTypeInteriorDetailsWebControllerApi)
const orderTypeInteriorDataDetailsControllerApi = boundApi(OrderTypeInteriorDataDetailsWebControllerApi)
const orderTypeInteriorNameDetailsControllerApi = boundApi(OrderTypeInteriorNameDetailsWebControllerApi)
const orderTypeInteriorDescriptionDetailsControllerApi = boundApi(OrderTypeInteriorDescriptionDetailsWebControllerApi)
const orderTypeInteriorMarketplaceSpecificsDetailsWebControllerApi = boundApi(OrderTypeInteriorMarketplaceSpecificsDetailsWebControllerApi)
export type InteriorSortFieldEnum = SortFieldCombinedEnum
export const InteriorSortField = SortFieldCombinedEnum
export type InteriorFilter = Omit<GetInteriorsPageRequest, keyof ProductListFindParamsWithoutFilter<InteriorSortFieldEnum>>
export const interiorApi = {
    create: {
        create: interiorCreateControllerApi.createInterior,
    },
    catalogue: {
        getPage: interiorCatalogueControllerApi.getInteriorsPage,
        getFilterOptions: interiorCatalogueControllerApi.getInteriorFilterOptions,
    },
    details: {
        deleteByKey: orderTypeInteriorDetailsControllerApi.deleteInterior,
        getBaseInformation: orderTypeInteriorDetailsControllerApi.getBaseInformationOfInterior,
        getOption: orderTypeInteriorDataDetailsControllerApi.getOptionOfInterior,
        updateOption: orderTypeInteriorDataDetailsControllerApi.updateOptionOfInterior,
        getLinkedCustomization: orderTypeInteriorDataDetailsControllerApi.getLinkedCustomizationOfInterior,
        getSimilarCustomizations: orderTypeInteriorDataDetailsControllerApi.getSimilarCustomizationsOfInterior,
        getName: orderTypeInteriorNameDetailsControllerApi.getLocalizationsOfNameOfInterior,
        updateName: orderTypeInteriorNameDetailsControllerApi.updateNameOfInterior,
        getDescription: orderTypeInteriorDescriptionDetailsControllerApi.getLocalizationsOfDescriptionOfInterior,
        updateDescription: orderTypeInteriorDescriptionDetailsControllerApi.updateDescriptionOfInterior,
        getMarketplaceSpecifics: orderTypeInteriorMarketplaceSpecificsDetailsWebControllerApi.getMarketplaceSpecificsOfInterior,
    },
}

export type InteriorColorTagSortFieldEnum = SortFieldCombinedEnum
export const InteriorColorTagSortField = SortFieldCombinedEnum

const interiorColorTagCreateControllerApi = boundApi(InteriorColorTagCreateWebControllerApi)
const interiorColorTagListControllerApi = boundApi(InteriorColorTagListWebControllerApi)
const interiorColorTagSortControllerApi = boundApi(InteriorColorTagSortWebControllerApi)
const interiorColorTagDetailsControllerApi = boundApi(InteriorColorTagDetailsWebControllerApi)
export type InteriorColorTagFilter = Omit<GetInteriorColorTagsPageRequest, keyof ProductListFindParamsWithoutFilter<InteriorColorTagSortFieldEnum>>

export const interiorColorTagApi = {
    create: {
        create: interiorColorTagCreateControllerApi.createInteriorColorTag,
    },
    list: {
        getPage: interiorColorTagListControllerApi.getInteriorColorTagsPage,
    },
    sort: {
        getSortableItems: interiorColorTagSortControllerApi.getInteriorColorTagSortableItems,
        updateSortIndices: interiorColorTagSortControllerApi.updateInteriorColorTagSortIndex,
    },
    details: {
        deleteByKey: interiorColorTagDetailsControllerApi.deleteInteriorColorTag,
        getBaseInformation: interiorColorTagDetailsControllerApi.getBaseInformationOfInteriorColorTag,
        getAttributes: interiorColorTagDetailsControllerApi.getAttributesOfInteriorColorTag,
        updateAttributes: interiorColorTagDetailsControllerApi.updateAttributesOfInteriorColorTag,
        getNameLocalizations: interiorColorTagDetailsControllerApi.getLocalizationsOfNameOfInteriorColorTag,
        updateName: interiorColorTagDetailsControllerApi.updateNameOfInteriorColorTag,
    },
}

const modelGenerationCreateControllerApi = boundApi(ModelGenerationCreateWebControllerApi)
const modelGenerationDetailsControllerApi = boundApi(ModelGenerationDetailsWebControllerApi)
const modelGenerationListControllerApi = boundApi(ModelGenerationListWebControllerApi)
const modelGenerationSortControllerApi = boundApi(ModelGenerationSortWebControllerApi)
export type ModelGenerationSortFieldEnum = SortFieldCombinedEnum
export type ModelGenerationFilter = Omit<GetModelGenerationsPageRequest, keyof ProductListFindParamsWithoutFilter<ModelGenerationSortFieldEnum>>

export const ModelGenerationSortField = SortFieldCombinedEnum
export const modelGenerationApi = {
    create: {
        create: modelGenerationCreateControllerApi.createModelGeneration,
        getCreateOptions: modelGenerationCreateControllerApi.getModelGenerationCreateOptions,
    },
    details: {
        getBaseInformation: modelGenerationDetailsControllerApi.getBaseInformationOfModelGeneration,
        deleteByKey: modelGenerationDetailsControllerApi.deleteModelGeneration,
        getAttributes: modelGenerationDetailsControllerApi.getAttributesOfModelGeneration,
        updateAttributes: modelGenerationDetailsControllerApi.updateAttributesOfModelGeneration,
        getName: modelGenerationDetailsControllerApi.getLocalizationsOfNameOfModelGeneration,
        updateName: modelGenerationDetailsControllerApi.updateNameOfModelGeneration,
    },
    list: {
        getPage: modelGenerationListControllerApi.getModelGenerationsPage,
        getFilterOptions: modelGenerationListControllerApi.getModelGenerationFilterOptions,
    },
    sort: {
        getSortableItems: modelGenerationSortControllerApi.getModelGenerationSortableItems,
        updateSortIndices: modelGenerationSortControllerApi.updateModelGenerationSortIndex,
    },
}

const modelSeriesCreateControllerApi = boundApi(ModelSeriesCreateWebControllerApi)
const modelSeriesDetailsControllerApi = boundApi(ModelSeriesDetailsWebControllerApi)
const modelSeriesListControllerApi = boundApi(ModelSeriesListWebControllerApi)
const modelSeriesSortControllerApi = boundApi(ModelSeriesSortWebControllerApi)
export type ModelSeriesSortFieldEnum = SortFieldCombinedEnum
export type ModelSeriesFilter = Omit<GetModelSeriesPageRequest, keyof ProductListFindParamsWithoutFilter<ModelSeriesSortFieldEnum>>

export const ModelSeriesSortField = SortFieldCombinedEnum
export const modelSeriesApi = {
    create: {
        create: modelSeriesCreateControllerApi.createModelSeries,
    },
    details: {
        getBaseInformation: modelSeriesDetailsControllerApi.getBaseInformationOfModelSeries,
        deleteByKey: modelSeriesDetailsControllerApi.deleteModelSeries,
        getAttributes: modelSeriesDetailsControllerApi.getAttributesOfModelSeries,
        updateAttributes: modelSeriesDetailsControllerApi.updateAttributesOfModelSeries,
        getLinks: modelSeriesDetailsControllerApi.getLinksOfModelSeries,
        getName: modelSeriesDetailsControllerApi.getLocalizationsOfNameOfModelSeries,
        updateName: modelSeriesDetailsControllerApi.updateNameOfModelSeries,
    },
    list: {
        getPage: modelSeriesListControllerApi.getModelSeriesPage,
    },
    sort: {
        getSortableItems: modelSeriesSortControllerApi.getModelSeriesSortableItems,
        updateSortIndices: modelSeriesSortControllerApi.updateModelSeriesSortIndex,
    },
}

const orderTypeCreateControllerApi = boundApi(OrderTypeCreateWebControllerApi)
const orderTypeListControllerApi = boundApi(OrderTypeListWebControllerApi)
const orderTypeDetailsControllerApi = boundApi(OrderTypeDetailsWebControllerApi)
const orderTypeDataDetailsControllerApi = boundApi(OrderTypeDataDetailsWebControllerApi)
const orderTypeLocalizationDetailsControllerApi = boundApi(OrderTypeLocalizationDetailsWebControllerApi)
const orderTypeOptionDetailsControllerApi = boundApi(OrderTypeOptionDetailsWebControllerApi)
const orderTypePackageDetailsControllerApi = boundApi(OrderTypePackageDetailsWebControllerApi)
const orderTypeStandardEquipmentDetailsControllerApi = boundApi(OrderTypeStandardEquipmentDetailsWebControllerApi)
const orderTypeTechnicalDataDetailsControllerApi = boundApi(OrderTypeTechnicalDataDetailsWebControllerApi)
const orderTypeMediaControllerApi = boundApi(OrderTypeMediaDetailsWebControllerApi)
const orderTypePressEmbargoDetailsControllerApi = boundApi(OrderTypePressEmbargoDetailsWebControllerApi)
export type OrderTypeFilter = Omit<GetOrderTypesPageRequest, keyof ProductListFindParamsWithoutFilter<OrderTypeSortField>>
export type OrderTypeEquipmentFilter = Omit<GetEquipmentOfOrderTypeRequest, keyof ProductListFindParamsWithoutFilter<SortFieldCombinedEnum> | "key">
export type OrderTypeExteriorColorsFilter = Omit<GetExteriorColorsOfOrderTypeRequest, keyof ProductListFindParamsWithoutFilter<SortFieldCombinedEnum> | "key">
export type OrderTypeInteriorsFilter = Omit<GetInteriorsOfOrderTypeRequest, keyof ProductListFindParamsWithoutFilter<SortFieldCombinedEnum> | "key">
export type OrderTypeRoofColorsFilter = Omit<GetRoofColorsOfOrderTypeRequest, keyof ProductListFindParamsWithoutFilter<SortFieldCombinedEnum> | "key">
export const orderTypeApi = {
    create: {
        create: orderTypeCreateControllerApi.createOrderType,
        getCreateOptions: orderTypeCreateControllerApi.getOrderTypeCreateOptions,
    },
    list: {
        getPage: orderTypeListControllerApi.getOrderTypesPage,
        getFilterOptions: orderTypeListControllerApi.getOrderTypeFilterOptions,
    },
    details: {
        deleteByKey: orderTypeDetailsControllerApi.deleteOrderType,
        getBaseInformation: orderTypeDetailsControllerApi.getBaseInformationOfOrderType,
        getContext: orderTypeDetailsControllerApi.getContextOfOrderType,
        data: {
            getAttributes: orderTypeDataDetailsControllerApi.getAttributesOfOrderType,
            updateAttributes: orderTypeDataDetailsControllerApi.updateAttributesOfOrderType,
        },
        localization: {
            getName: orderTypeLocalizationDetailsControllerApi.getNameOfOrderType,
            updateManualName: orderTypeLocalizationDetailsControllerApi.updateManualOfNameOfOrderType,
        },
        options: {
            getEquipment: orderTypeOptionDetailsControllerApi.getEquipmentOfOrderType,
            getEquipmentFilterOptions: orderTypeOptionDetailsControllerApi.getFilterOptionsOfEquipmentOfOrderType,
            getExteriorColors: orderTypeOptionDetailsControllerApi.getExteriorColorsOfOrderType,
            getPaintToSampleExteriorColors: orderTypeOptionDetailsControllerApi.getPaintToSampleExteriorColorsOfOrderType,
            getExteriorColorFilterOptions: orderTypeOptionDetailsControllerApi.getFilterOptionsOfExteriorColorsOfOrderType,
            getInteriors: orderTypeOptionDetailsControllerApi.getInteriorsOfOrderType,
            getInteriorFilterOptions: orderTypeOptionDetailsControllerApi.getFilterOptionsOfInteriorsOfOrderType,
            getRoofColors: orderTypeOptionDetailsControllerApi.getRoofColorsOfOrderType,
            getRoofColorFilterOptions: orderTypeOptionDetailsControllerApi.getFilterOptionsOfRoofColorsOfOrderType,
        },
        packages: {
            getEquipmentPackages: orderTypePackageDetailsControllerApi.getEquipmentPackagesOfOrderType,
        },
        standardEquipments: {
            getStandardEquipment: orderTypeStandardEquipmentDetailsControllerApi.getlanguageTagOfStandardEquipmentByMarketplaceOfOrderType,
        },
        technicalData: {
            getRelatedData: orderTypeTechnicalDataDetailsControllerApi.getRelatedDataOfOrderType,
            getGlobalAttribute: orderTypeTechnicalDataDetailsControllerApi.getGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType,
            getMarketplaceSpecificAttribute:
                orderTypeTechnicalDataDetailsControllerApi.getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType,
            updateGlobalAttribute: orderTypeTechnicalDataDetailsControllerApi.updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType,
            updateMarketplaceSpecificAttribute:
                orderTypeTechnicalDataDetailsControllerApi.updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType,
        },
        pressEmbargoes: {
            getPressEmbargoes: orderTypePressEmbargoDetailsControllerApi.getOfOrderType,
            updatePressEmbargoes: orderTypePressEmbargoDetailsControllerApi.updateManualOfOrderType,
        },
        media: {
            getEngineSounds: orderTypeMediaControllerApi.getEngineSoundsOfSoundOfMedia,
            updateEngineSounds: orderTypeMediaControllerApi.updateOfEngineSoundsOfSoundOfMedia,
            getRelatedData: orderTypeMediaControllerApi.getRelatedDataOfMedia,
        },
    },
}

const roofColorCustomizationCreateControllerApi = boundApi(RoofColorCustomizationCreateWebControllerApi)
const roofColorCustomizationListControllerApi = boundApi(RoofColorCustomizationListWebControllerApi)
const roofColorCustomizationDetailsControllerApi = boundApi(RoofColorCustomizationDetailsWebControllerApi)
export type RoofColorCustomizationSortFieldEnum = CustomizationSortFieldCombinedEnum
export const RoofColorCustomizationSortField = CustomizationSortFieldCombinedEnum
export type RoofColorCustomizationFilter = Omit<
    GetRoofColorCustomizationsPageRequest,
    keyof ProductListFindParamsWithoutFilter<RoofColorCustomizationSortFieldEnum>
>

export const roofColorCustomizationApi = {
    create: {
        create: roofColorCustomizationCreateControllerApi.createRoofColorCustomization,
        getCreateOptions: roofColorCustomizationCreateControllerApi.getRoofColorCustomizationCreateOptions,
        getVehicleImages: roofColorCustomizationCreateControllerApi.getVehicleImagesForRoofColorCustomization,
    },
    list: {
        getPage: roofColorCustomizationListControllerApi.getRoofColorCustomizationsPage,
        getFilterOptions: roofColorCustomizationListControllerApi.getRoofColorCustomizationFilterOptions,
    },
    details: {
        deleteByKey: roofColorCustomizationDetailsControllerApi.deleteRoofColorCustomization,
        getBaseInformation: roofColorCustomizationDetailsControllerApi.getBaseInformationOfRoofColorCustomization,
        getAttributes: roofColorCustomizationDetailsControllerApi.getAttributesOfRoofColorCustomization,
        updateAttributes: roofColorCustomizationDetailsControllerApi.updateAttributesOfRoofColorCustomization,
        getLinks: roofColorCustomizationDetailsControllerApi.getLinksOfRoofColorCustomization,
        getVehicleImages: roofColorCustomizationDetailsControllerApi.getVehicleImagesOfRoofColorCustomization,
        getName: roofColorCustomizationDetailsControllerApi.getLocalizationsOfNameOfRoofColorCustomization,
        updateDefaultName: roofColorCustomizationDetailsControllerApi.updateNameOfDefaultValueOfRoofColorCustomization,
        updateConditionalValueName:
            roofColorCustomizationDetailsControllerApi.updateNameOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomization,
        getDescription: roofColorCustomizationDetailsControllerApi.getLocalizationsOfDescriptionOfRoofColorCustomization,
        updateDefaultDescription: roofColorCustomizationDetailsControllerApi.updateDescriptionOfDefaultValueOfRoofColorCustomization,
        updateConditionalValueDescription:
            roofColorCustomizationDetailsControllerApi.updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomization,
    },
}

const roofColorCreateControllerApi = boundApi(RoofColorCreateWebControllerApi)
const roofColorCatalogueControllerApi = boundApi(RoofColorCatalogueWebControllerApi)
const orderTypeRoofColorDetailsControllerApi = boundApi(OrderTypeRoofColorDetailsWebControllerApi)
const orderTypeRoofColorDataDetailsControllerApi = boundApi(OrderTypeRoofColorDataDetailsWebControllerApi)
const orderTypeRoofColorNameDetailsControllerApi = boundApi(OrderTypeRoofColorNameDetailsWebControllerApi)
const orderTypeRoofColorDescriptionDetailsControllerApi = boundApi(OrderTypeRoofColorDescriptionDetailsWebControllerApi)
const orderTypeRoofColorMarketplaceSpecificsDetailsWebControllerApi = boundApi(OrderTypeRoofColorMarketplaceSpecificsDetailsWebControllerApi)
export type RoofColorSortFieldEnum = SortFieldCombinedEnum
export const RoofColorSortField = SortFieldCombinedEnum
export type RoofColorFilter = Omit<GetRoofColorsPageRequest, keyof ProductListFindParamsWithoutFilter<RoofColorSortFieldEnum>>
export const roofColorApi = {
    create: {
        create: roofColorCreateControllerApi.createRoofColor,
    },
    catalogue: {
        getPage: roofColorCatalogueControllerApi.getRoofColorsPage,
        getFilterOptions: roofColorCatalogueControllerApi.getRoofColorFilterOptions,
    },
    details: {
        deleteByKey: orderTypeRoofColorDetailsControllerApi.deleteRoofColor,
        getBaseInformation: orderTypeRoofColorDetailsControllerApi.getBaseInformationOfRoofColor,
        getOption: orderTypeRoofColorDataDetailsControllerApi.getOptionOfRoofColor,
        updateOption: orderTypeRoofColorDataDetailsControllerApi.updateOptionOfRoofColor,
        getLinkedCustomization: orderTypeRoofColorDataDetailsControllerApi.getLinkedCustomizationOfRoofColor,
        getSimilarCustomizations: orderTypeRoofColorDataDetailsControllerApi.getSimilarCustomizationsOfRoofColor,
        getName: orderTypeRoofColorNameDetailsControllerApi.getLocalizationsOfNameOfRoofColor,
        updateName: orderTypeRoofColorNameDetailsControllerApi.updateNameOfRoofColor,
        getDescription: orderTypeRoofColorDescriptionDetailsControllerApi.getLocalizationsOfDescriptionOfRoofColor,
        updateDescription: orderTypeRoofColorDescriptionDetailsControllerApi.updateDescriptionOfRoofColor,
        getMarketplaceSpecifics: orderTypeRoofColorMarketplaceSpecificsDetailsWebControllerApi.getMarketplaceSpecificsOfRoofColor,
    },
}

export type RoofColorTagSortFieldEnum = SortFieldCombinedEnum
export const RoofColorTagSortField = SortFieldCombinedEnum

const roofColorTagCreateControllerApi = boundApi(RoofColorTagCreateWebControllerApi)
const roofColorTagListControllerApi = boundApi(RoofColorTagListWebControllerApi)
const roofColorTagSortControllerApi = boundApi(RoofColorTagSortWebControllerApi)
const roofColorTagDetailsControllerApi = boundApi(RoofColorTagDetailsWebControllerApi)
export type RoofColorTagFilter = Omit<GetRoofColorTagsPageRequest, keyof ProductListFindParamsWithoutFilter<RoofColorTagSortFieldEnum>>

export const roofColorTagApi = {
    create: {
        create: roofColorTagCreateControllerApi.createRoofColorTag,
    },
    list: {
        getPage: roofColorTagListControllerApi.getRoofColorTagsPage,
    },
    sort: {
        getSortableItems: roofColorTagSortControllerApi.getRoofColorTagSortableItems,
        updateSortIndices: roofColorTagSortControllerApi.updateRoofColorTagSortIndex,
    },
    details: {
        deleteByKey: roofColorTagDetailsControllerApi.deleteRoofColorTag,
        getBaseInformation: roofColorTagDetailsControllerApi.getBaseInformationOfRoofColorTag,
        getAttributes: roofColorTagDetailsControllerApi.getAttributesOfRoofColorTag,
        updateAttributes: roofColorTagDetailsControllerApi.updateAttributesOfRoofColorTag,
        getNameLocalizations: roofColorTagDetailsControllerApi.getLocalizationsOfNameOfRoofColorTag,
        updateName: roofColorTagDetailsControllerApi.updateNameOfRoofColorTag,
    },
}

const technicalDataListControllerApi = boundApi(TechnicalDataListWebControllerApi)
export type TechnicalDataFilter = Omit<
    GetTechnicalDataPageRequest,
    keyof ProductListFindParamsWithoutFilter<TechnicalDataSortField> | "technicalDataAttribute" | "marketplace"
>

export const technicalDataApi = {
    list: {
        getPage: technicalDataListControllerApi.getTechnicalDataPage,
        getFilterOptions: technicalDataListControllerApi.getTechnicalDataFilterOptions,
        getAttributeOptions: technicalDataListControllerApi.getTechnicalDataAttributeOptions,
    },
}

const bootControllerApi = boundApi(BootControllerApi)
export const bootApi = {
    boot: bootControllerApi.boot,
}
