/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Transmission,
    TransmissionFromJSON,
    TransmissionFromJSONTyped,
    TransmissionToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionDTOTransmission
 */
export interface OptionDTOTransmission {
    /**
     * 
     * @type {Transmission}
     * @memberof OptionDTOTransmission
     */
    key: Transmission;
    /**
     * 
     * @type {string}
     * @memberof OptionDTOTransmission
     */
    label: string;
}

export function OptionDTOTransmissionFromJSON(json: any): OptionDTOTransmission {
    return OptionDTOTransmissionFromJSONTyped(json, false);
}

export function OptionDTOTransmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionDTOTransmission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': TransmissionFromJSON(json['key']),
        'label': json['label'],
    };
}

export function OptionDTOTransmissionToJSON(value?: OptionDTOTransmission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': TransmissionToJSON(value.key),
        'label': value.label,
    };
}


