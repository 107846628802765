import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { NamedColorTile } from "../../../../../components/tiles/namedcolortile/NamedColorTile"
import { InteriorColorTagItemDTO } from "../../../../../generated/pdsapi"
import { InteriorColorTagSortField, InteriorColorTagSortFieldEnum } from "../../../../../pdsapi"
import { activeColumn } from "../../../../../viewtemplates/list/columns/activeColumn"

type InteriorColorTagColumnBuilder = ColumnBuilder<InteriorColorTagItemDTO, InteriorColorTagSortFieldEnum>

export const InteriorColorTagListColumnsConfig: TableColumnsConfig<InteriorColorTagItemDTO, InteriorColorTagSortFieldEnum> = ({ sorting, setSorting }) => {
    const sortIndexColumn: InteriorColorTagColumnBuilder = {
        width: 40,
        headerCellProps: {
            content: "#",
            sorting: {
                field: InteriorColorTagSortField.SORT_INDEX,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (interiorColorTag) => interiorColorTag.sortIndex.toString(),
    }
    const nameColumn: InteriorColorTagColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (interiorColorTag) => interiorColorTag.name,
    }
    const hexCodeColumn: InteriorColorTagColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Hex Code",
        },
        buildCellContent: (interiorColorTag) => <NamedColorTile hexCode={interiorColorTag.hexCode} label={interiorColorTag.hexCode} />,
    }

    return [sortIndexColumn, nameColumn, hexCodeColumn, activeColumn]
}
