/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InteriorColorTileDTO
 */
export interface InteriorColorTileDTO {
    /**
     * 
     * @type {string}
     * @memberof InteriorColorTileDTO
     */
    seatColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorColorTileDTO
     */
    seatStitchingColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorColorTileDTO
     */
    cockpitColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorColorTileDTO
     */
    cockpitStitchingColorHexCode: string;
}

export function InteriorColorTileDTOFromJSON(json: any): InteriorColorTileDTO {
    return InteriorColorTileDTOFromJSONTyped(json, false);
}

export function InteriorColorTileDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorColorTileDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seatColorHexCode': json['seatColorHexCode'],
        'seatStitchingColorHexCode': json['seatStitchingColorHexCode'],
        'cockpitColorHexCode': json['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': json['cockpitStitchingColorHexCode'],
    };
}

export function InteriorColorTileDTOToJSON(value?: InteriorColorTileDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seatColorHexCode': value.seatColorHexCode,
        'seatStitchingColorHexCode': value.seatStitchingColorHexCode,
        'cockpitColorHexCode': value.cockpitColorHexCode,
        'cockpitStitchingColorHexCode': value.cockpitStitchingColorHexCode,
    };
}


