/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TechnicalDataAttributeDataValueObject,
    TechnicalDataAttributeDataValueObjectFromJSON,
    TechnicalDataAttributeDataValueObjectFromJSONTyped,
    TechnicalDataAttributeDataValueObjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject
 */
export interface TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject {
    /**
     * 
     * @type {Array<string>}
     * @memberof TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject
     */
    equipmentCodes: Array<string>;
    /**
     * 
     * @type {{ [key: string]: TechnicalDataAttributeDataValueObject; }}
     * @memberof TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject
     */
    values: { [key: string]: TechnicalDataAttributeDataValueObject; };
}

export function TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectFromJSON(json: any): TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject {
    return TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectFromJSONTyped(json, false);
}

export function TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equipmentCodes': json['equipmentCodes'],
        'values': (mapValues(json['values'], TechnicalDataAttributeDataValueObjectFromJSON)),
    };
}

export function TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectToJSON(value?: TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equipmentCodes': value.equipmentCodes,
        'values': (mapValues(value.values, TechnicalDataAttributeDataValueObjectToJSON)),
    };
}


