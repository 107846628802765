import { DefaultFormElementLoading, EditLabeledContentWrapper, EditProps, FormElement, ViewLabeledContentWrapper, ViewProps } from "../FormElementWrapper"
import { PText, PTextFieldWrapper } from "@porsche-design-system/components-react"
import { Controller, FieldValues } from "react-hook-form"

export const getMultiTextFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string[],
    MultiTextFormElementViewProps,
    MultiTextFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: MultiTextFormElementView,
    Edit: MultiTextFormElementEdit,
})

export interface MultiTextFormElementViewProps {
    value: string[]
}

export const MultiTextFormElementView = ({ label, value }: ViewProps<MultiTextFormElementViewProps>) => (
    <ViewLabeledContentWrapper label={label}>
        <PText>{value.join(", ")}</PText>
    </ViewLabeledContentWrapper>
)

export interface MultiTextFormElementEditProps {
    value: string[]

    readOnly?: boolean
    optional?: boolean
}

export const MultiTextFormElementEdit = <DATA, UPDATE extends FieldValues>({
    label,
    field,
    optional,
    readOnly,
    value,
    validationErrors,
    control,
}: EditProps<DATA, UPDATE, string[], MultiTextFormElementEditProps>) => {
    const errorMessage = validationErrors.get(field._path)

    return (
        <EditLabeledContentWrapper label={label ? `${label} (comma separated)` : undefined} optional={optional}>
            <Controller
                control={control}
                name={field._path}
                defaultValue={value as any}
                rules={{ required: !optional }}
                render={({ field }) => (
                    <PTextFieldWrapper state={errorMessage ? "error" : "none"} message={errorMessage}>
                        <input
                            name={field.name}
                            type={"text"}
                            defaultValue={(field.value as string[]).join(", ")}
                            readOnly={readOnly}
                            onChange={(event) => {
                                if (event.target.value !== "") {
                                    field.onChange(event.target.value.split(",").map((val) => val.trim()))
                                } else {
                                    field.onChange([])
                                }
                            }}
                        />
                    </PTextFieldWrapper>
                )}
            />
        </EditLabeledContentWrapper>
    )
}
