import { propertiesOf } from "../../../../../../common/property"
import { modelSeriesRoutes } from "../../../../../../common/routes"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getTagFormElement } from "../../../../../../components/formelements/tagformelement/TagFormElement"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { DerivativeCategoryAttributesDTO, DerivativeCategoryAttributesUpdateDTO } from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { FC } from "react"

export const DerivativeCategoryDetailsAttributesCardContent: FC<
    ProductDetailsCardContentProps<DerivativeCategoryAttributesDTO, DerivativeCategoryAttributesUpdateDTO>
> = ({ formDisplayData }) => {
    const properties = propertiesOf<DerivativeCategoryAttributesUpdateDTO>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Code"}
                    formDisplayData={formDisplayData}
                    field={properties.key}
                    FormElement={getTextFormElement<DerivativeCategoryAttributesDTO, DerivativeCategoryAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.key,
                        showAsHint: false,
                    })}
                    getEditProps={(data) => ({
                        value: data.key,
                        readOnly: true,
                    })}
                />

                <FormElementWrapper
                    label={"Model Series"}
                    formDisplayData={formDisplayData}
                    field={properties.modelSeriesKey}
                    FormElement={getTagFormElement<DerivativeCategoryAttributesDTO, DerivativeCategoryAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: data.modelSeries.value.label,
                        tag: {
                            label: data.modelSeries.value.label,
                            route: modelSeriesRoutes.details(data.modelSeries.value.key),
                        },
                    })}
                    getEditProps={(data) => ({
                        value: data.modelSeries.value.key,
                        options: data.modelSeries.options.map(toOption),
                    })}
                />
            </RowGroupWrapper>

            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getViewProps={(data) => ({
                    value: data.name,
                })}
                getEditProps={(data) => ({
                    value: data.name,
                })}
            />
        </SpacedItems>
    )
}
