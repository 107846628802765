/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DerivativeItemDTO,
    DerivativeItemDTOFromJSON,
    DerivativeItemDTOFromJSONTyped,
    DerivativeItemDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageDTODerivativeItemDTO
 */
export interface PageDTODerivativeItemDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTODerivativeItemDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<DerivativeItemDTO>}
     * @memberof PageDTODerivativeItemDTO
     */
    content: Array<DerivativeItemDTO>;
}

export function PageDTODerivativeItemDTOFromJSON(json: any): PageDTODerivativeItemDTO {
    return PageDTODerivativeItemDTOFromJSONTyped(json, false);
}

export function PageDTODerivativeItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTODerivativeItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(DerivativeItemDTOFromJSON)),
    };
}

export function PageDTODerivativeItemDTOToJSON(value?: PageDTODerivativeItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItemCount': value.totalItemCount,
        'content': ((value.content as Array<any>).map(DerivativeItemDTOToJSON)),
    };
}


