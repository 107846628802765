import { getCurrentEnvironment } from "../common/environment"
import { couldBeOfType } from "../common/global"
import { AuthorizationApiError, Configuration, HttpStatus, SimpleApiError, SimpleOrValidationApiError } from "../generated/pdsapi"
import { isAuthorizationApiError } from "./apiHelper"

export const apiConfiguration = new Configuration({
    basePath: getCurrentEnvironment().backendUrl,
    credentials: "include",
    middleware: [],
    headers: {
        Accept: "application/json",
    },
})

export const boundApi = <API extends Record<string | symbol, any>>(apiConstructor: new (configuration?: Configuration) => API): API =>
    new Proxy(new apiConstructor(apiConfiguration), {
        get: (api, childProp) => {
            const fn: () => Promise<any> | ((args: any) => Promise<any>) = api[childProp].bind(api)

            switch (fn.length) {
                case 0:
                    return unwrapError(fn as () => Promise<any>)
                case 1:
                    return unwrapError1(fn as (args: any) => Promise<any>)
                default:
                    throw Error(`not supported function call ${childProp.toString()}`)
            }
        },
        set: () => {
            throw Error("set not supported")
        },
    }) as API

export type ApiRequestFn<PARAMS, OUTPUT> = (params: PARAMS) => Promise<OUTPUT>

type ApiRequestError = SimpleApiError & { statusCode: number }

const unwrapError = <OUTPUT>(request: () => Promise<OUTPUT>) => {
    return () => request().catch(unwrapErrorResponse)
}

const unwrapError1 = <PARAMS, OUTPUT>(request: ApiRequestFn<PARAMS, OUTPUT>) => {
    return (params: PARAMS) => request(params).catch(unwrapErrorResponse)
}

const unwrapErrorResponse = async (error: Response) => {
    const authorizeWithRedirectToCurrentPage = (error: AuthorizationApiError) => {
        const loginUrlWithRedirect = getCurrentEnvironment().backendUrl + error.loginUrl.replace("{0}", window.location.href)
        window.location.assign(loginUrlWithRedirect)
        error.message = "Redirect is initiated. Please wait.."
    }

    let apiError: SimpleOrValidationApiError

    try {
        const parsedApiError = await error.json()

        if (isAuthorizationApiError(parsedApiError)) {
            authorizeWithRedirectToCurrentPage(parsedApiError)
        }

        parsedApiError.timeStamp = new Date(parsedApiError.timeStamp)
        apiError = parsedApiError
    } catch (error) {
        apiError = {
            status: HttpStatus.INTERNAL_SERVER_ERROR,
            message: "Ooops, something went wrong.",
            debugMessage: couldBeOfType<object>(error) ? error.toString() : "Something went wrong",
            timeStamp: new Date(),
        }
    }

    // noinspection UnnecessaryLocalVariableJS
    const apiRequestError: ApiRequestError = { ...apiError, statusCode: error.status ?? 500 }
    throw apiRequestError
}
