import { Property } from "../../../../common/property"
import { EditFormElementWrapper } from "../../../../components/formelements/EditFormElementWrapper"
import { EditFormDisplayData, LoadingFormDisplayData } from "../../../../components/formelements/FormDisplayData"
import { getTextFormElement } from "../../../../components/formelements/textformelement/TextFormElement"
import { RowGroupWrapper } from "../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../components/layouts/spaceditems/SpacedItems"
import { BaseNameDTO } from "../../../../generated/pdsapi"
import { PText } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FieldValues } from "react-hook-form"

interface DefaultLocalizationsEditFormRowEditProps {
    value: BaseNameDTO
    optional?: boolean
}

export interface CreateDefaultLocalizationsRowProps<DATA extends FieldValues, CREATE_OPTIONS> {
    label: string
    formDisplayData: LoadingFormDisplayData | EditFormDisplayData<CREATE_OPTIONS, DATA>
    field: Property<DATA, BaseNameDTO>
    getEditProps: (createOptions: CREATE_OPTIONS) => DefaultLocalizationsEditFormRowEditProps
}

export const CreateDefaultLocalizationsRow = <DATA extends FieldValues, CREATE_OPTIONS>({
    label,
    formDisplayData,
    field,
    getEditProps,
}: CreateDefaultLocalizationsRowProps<DATA, CREATE_OPTIONS>) => (
    <SpacedItems direction={"column"} lineSpacing={spacing[8]}>
        <RowGroupWrapper label={label}>
            <EditFormElementWrapper
                label={`${label} - de`}
                formDisplayData={formDisplayData}
                field={field.de}
                FormElement={getTextFormElement<CREATE_OPTIONS, DATA>()}
                getEditProps={(createOptions) => {
                    const defaultNamesProps = getEditProps(createOptions)

                    return {
                        value: defaultNamesProps.value.de ?? "",
                        optional: defaultNamesProps.optional,
                    }
                }}
            />
            <EditFormElementWrapper
                label={`${label} - en`}
                formDisplayData={formDisplayData}
                field={field.en}
                FormElement={getTextFormElement<CREATE_OPTIONS, DATA>()}
                getEditProps={(createOptions) => {
                    const defaultNamesProps = getEditProps(createOptions)

                    return {
                        value: defaultNamesProps.value.en ?? "",
                        optional: defaultNamesProps.optional,
                    }
                }}
            />
        </RowGroupWrapper>
        <RowGroupWrapper label={""} childrenSize={12}>
            <PText color={"neutral-contrast-medium"}>Further localizations can be edited after creation.</PText>
        </RowGroupWrapper>
    </SpacedItems>
)
