import { FormDisplayData, getSimpleFormWatch } from "../../../../components/formelements/FormDisplayData"
import { WidgetWrapper } from "../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { LoadableVehicleImagesCardContent } from "../../../../components/loadablevehicleimagescardcontent/LoadableVehicleImagesCardContent"
import { VehicleStateSearchResult } from "../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../ProductCreateContainer"
import { useMemo } from "react"
import { FieldValues } from "react-hook-form"

export interface VehicleImagesCreateCardProps<UPDATE extends FieldValues> extends ProductCreateContentProps<UPDATE, any> {
    getVehicleImages: (props: { optionCode: string; modelGenerationKeys: string[] }) => Promise<VehicleStateSearchResult[]>
}

export const VehicleImagesCreateCard = <UPDATE extends { optionCode: string; modelGenerationKeys: string[] }>({
    formDisplayData,
    getVehicleImages,
}: VehicleImagesCreateCardProps<UPDATE>) => {
    const watch = getSimpleFormWatch(formDisplayData as FormDisplayData<any, { optionCode: string; modelGenerationKeys: string[] }>)
    const optionCode = watch("optionCode")
    const modelGenerationKeys = watch("modelGenerationKeys")

    const load = useMemo(
        () =>
            optionCode && modelGenerationKeys?.length
                ? () =>
                      getVehicleImages({
                          optionCode,
                          modelGenerationKeys,
                      })
                : undefined,
        [optionCode, modelGenerationKeys, getVehicleImages]
    )

    return (
        <WidgetWrapper label={"Vehicle Images"}>
            <LoadableVehicleImagesCardContent load={load} />
        </WidgetWrapper>
    )
}
