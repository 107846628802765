/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    CustomExteriorColorAttributesDTO,
    CustomExteriorColorAttributesDTOFromJSON,
    CustomExteriorColorAttributesDTOToJSON,
    CustomExteriorColorAttributesUpdateDTO,
    CustomExteriorColorAttributesUpdateDTOFromJSON,
    CustomExteriorColorAttributesUpdateDTOToJSON,
    LocalizationsItemDTO,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    VehicleStateSearchResult,
    VehicleStateSearchResultFromJSON,
    VehicleStateSearchResultToJSON,
} from '../models';

export interface DeleteCustomExteriorColorRequest {
    key: string;
}

export interface FindCustomExteriorColorVehicleImagesRequest {
    key: string;
    limit?: number;
}

export interface GetAttributesOfCustomExteriorColorRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfCustomExteriorColorRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfCustomExteriorColorRequest {
    key: string;
}

export interface UpdateAttributesOfCustomExteriorColorRequest {
    key: string;
    customExteriorColorAttributesUpdateDTO: CustomExteriorColorAttributesUpdateDTO;
}

export interface UpdateNameOfCustomExteriorColorRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class CustomExteriorColorDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a CustomExteriorColor by a given key
     */
    async deleteCustomExteriorColorRaw(requestParameters: DeleteCustomExteriorColorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteCustomExteriorColor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-exterior-colors/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a CustomExteriorColor by a given key
     */
    async deleteCustomExteriorColor(requestParameters: DeleteCustomExteriorColorRequest): Promise<void> {
        await this.deleteCustomExteriorColorRaw(requestParameters);
    }

    /**
     * Find vehicle images that have this CustomExteriorColor
     */
    async findCustomExteriorColorVehicleImagesRaw(requestParameters: FindCustomExteriorColorVehicleImagesRequest): Promise<runtime.ApiResponse<Array<VehicleStateSearchResult>>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling findCustomExteriorColorVehicleImages.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-exterior-colors/details/{key}/vehicle-images`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateSearchResultFromJSON));
    }

    /**
     * Find vehicle images that have this CustomExteriorColor
     */
    async findCustomExteriorColorVehicleImages(requestParameters: FindCustomExteriorColorVehicleImagesRequest): Promise<Array<VehicleStateSearchResult>> {
        const response = await this.findCustomExteriorColorVehicleImagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get attributes of CustomExteriorColor with given key
     */
    async getAttributesOfCustomExteriorColorRaw(requestParameters: GetAttributesOfCustomExteriorColorRequest): Promise<runtime.ApiResponse<CustomExteriorColorAttributesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfCustomExteriorColor.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfCustomExteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-exterior-colors/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomExteriorColorAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of CustomExteriorColor with given key
     */
    async getAttributesOfCustomExteriorColor(requestParameters: GetAttributesOfCustomExteriorColorRequest): Promise<CustomExteriorColorAttributesDTO> {
        const response = await this.getAttributesOfCustomExteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of CustomExteriorColor with given key
     */
    async getBaseInformationOfCustomExteriorColorRaw(requestParameters: GetBaseInformationOfCustomExteriorColorRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfCustomExteriorColor.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfCustomExteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-exterior-colors/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of CustomExteriorColor with given key
     */
    async getBaseInformationOfCustomExteriorColor(requestParameters: GetBaseInformationOfCustomExteriorColorRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfCustomExteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of CustomExteriorColor with given key
     */
    async getLocalizationsOfNameOfCustomExteriorColorRaw(requestParameters: GetLocalizationsOfNameOfCustomExteriorColorRequest): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfCustomExteriorColor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-exterior-colors/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of CustomExteriorColor with given key
     */
    async getLocalizationsOfNameOfCustomExteriorColor(requestParameters: GetLocalizationsOfNameOfCustomExteriorColorRequest): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfCustomExteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing CustomExteriorColor
     */
    async updateAttributesOfCustomExteriorColorRaw(requestParameters: UpdateAttributesOfCustomExteriorColorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfCustomExteriorColor.');
        }

        if (requestParameters.customExteriorColorAttributesUpdateDTO === null || requestParameters.customExteriorColorAttributesUpdateDTO === undefined) {
            throw new runtime.RequiredError('customExteriorColorAttributesUpdateDTO','Required parameter requestParameters.customExteriorColorAttributesUpdateDTO was null or undefined when calling updateAttributesOfCustomExteriorColor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/custom-exterior-colors/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomExteriorColorAttributesUpdateDTOToJSON(requestParameters.customExteriorColorAttributesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing CustomExteriorColor
     */
    async updateAttributesOfCustomExteriorColor(requestParameters: UpdateAttributesOfCustomExteriorColorRequest): Promise<void> {
        await this.updateAttributesOfCustomExteriorColorRaw(requestParameters);
    }

    /**
     * Updates name of an existing CustomExteriorColor
     */
    async updateNameOfCustomExteriorColorRaw(requestParameters: UpdateNameOfCustomExteriorColorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfCustomExteriorColor.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfCustomExteriorColor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/custom-exterior-colors/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing CustomExteriorColor
     */
    async updateNameOfCustomExteriorColor(requestParameters: UpdateNameOfCustomExteriorColorRequest): Promise<void> {
        await this.updateNameOfCustomExteriorColorRaw(requestParameters);
    }

}
