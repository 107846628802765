/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EquipmentOptionDTOBaseLocalization,
    EquipmentOptionDTOBaseLocalizationFromJSON,
    EquipmentOptionDTOBaseLocalizationFromJSONTyped,
    EquipmentOptionDTOBaseLocalizationToJSON,
    OptionalSelectDTOVehicleEquipmentCategory,
    OptionalSelectDTOVehicleEquipmentCategoryFromJSON,
    OptionalSelectDTOVehicleEquipmentCategoryFromJSONTyped,
    OptionalSelectDTOVehicleEquipmentCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface EquipmentOptionDTO
 */
export interface EquipmentOptionDTO {
    /**
     * 
     * @type {string}
     * @memberof EquipmentOptionDTO
     */
    optionCode: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentOptionDTO
     */
    optionType?: string;
    /**
     * 
     * @type {OptionalSelectDTOVehicleEquipmentCategory}
     * @memberof EquipmentOptionDTO
     */
    category: OptionalSelectDTOVehicleEquipmentCategory;
    /**
     * 
     * @type {number}
     * @memberof EquipmentOptionDTO
     */
    sortIndex: number;
    /**
     * 
     * @type {EquipmentOptionDTOBaseLocalization}
     * @memberof EquipmentOptionDTO
     */
    name: EquipmentOptionDTOBaseLocalization;
    /**
     * 
     * @type {EquipmentOptionDTOBaseLocalization}
     * @memberof EquipmentOptionDTO
     */
    description: EquipmentOptionDTOBaseLocalization;
    /**
     * 
     * @type {Array<string>}
     * @memberof EquipmentOptionDTO
     */
    imageKeys: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EquipmentOptionDTO
     */
    family?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentOptionDTO
     */
    serialOptionType?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof EquipmentOptionDTO
     */
    groupIdList: Array<number>;
}

export function EquipmentOptionDTOFromJSON(json: any): EquipmentOptionDTO {
    return EquipmentOptionDTOFromJSONTyped(json, false);
}

export function EquipmentOptionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentOptionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'optionType': !exists(json, 'optionType') ? undefined : json['optionType'],
        'category': OptionalSelectDTOVehicleEquipmentCategoryFromJSON(json['category']),
        'sortIndex': json['sortIndex'],
        'name': EquipmentOptionDTOBaseLocalizationFromJSON(json['name']),
        'description': EquipmentOptionDTOBaseLocalizationFromJSON(json['description']),
        'imageKeys': json['imageKeys'],
        'family': !exists(json, 'family') ? undefined : json['family'],
        'serialOptionType': !exists(json, 'serialOptionType') ? undefined : json['serialOptionType'],
        'groupIdList': json['groupIdList'],
    };
}

export function EquipmentOptionDTOToJSON(value?: EquipmentOptionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'optionType': value.optionType,
        'category': OptionalSelectDTOVehicleEquipmentCategoryToJSON(value.category),
        'sortIndex': value.sortIndex,
        'name': EquipmentOptionDTOBaseLocalizationToJSON(value.name),
        'description': EquipmentOptionDTOBaseLocalizationToJSON(value.description),
        'imageKeys': value.imageKeys,
        'family': value.family,
        'serialOptionType': value.serialOptionType,
        'groupIdList': value.groupIdList,
    };
}


