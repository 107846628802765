import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import {
    TechnicalDataAttributeDataColumnDefinition,
    TechnicalDataAttributeValueType,
    TechnicalDataItemDTO,
    TechnicalDataSortField,
} from "../../../../../generated/pdsapi"
import { PFlex, PText } from "@porsche-design-system/components-react"

type TechnicalDataColumnBuilder = ColumnBuilder<TechnicalDataItemDTO, TechnicalDataSortField>

export const getTechnicalDataListColumnsConfig: (
    columnDefinitions: TechnicalDataAttributeDataColumnDefinition[]
) => TableColumnsConfig<TechnicalDataItemDTO, TechnicalDataSortField> =
    (columnDefinitions) =>
    ({ sorting, setSorting }) => {
        const codesColumn: TechnicalDataColumnBuilder = {
            width: 70,
            headerCellProps: {
                content: "Codes",
            },
            buildCellContent: (technicalData) => technicalData.orderTypeCodes.join(", "),
        }
        const modelYearColumn: TechnicalDataColumnBuilder = {
            width: 60,
            headerCellProps: {
                content: "Model Year",
                sorting: {
                    field: TechnicalDataSortField.MODEL_YEAR,
                    value: sorting,
                    setValue: setSorting,
                },
            },
            buildCellContent: (technicalData) => technicalData.modelYear.toString(),
        }
        const nameColumn: TechnicalDataColumnBuilder = {
            grow: 2,
            headerCellProps: {
                content: "Name",
            },
            buildCellContent: (technicalData) => technicalData.orderTypeName ?? "-",
        }
        const optionCodesColumn: TechnicalDataColumnBuilder = {
            grow: 2,
            headerCellProps: {
                content: "Option Codes",
            },
            buildCellContent: (technicalData) => (
                <PFlex direction={"column"}>
                    {technicalData.equipmentRelations.map((equipmentRelation, index) => {
                        const formattedEquipmentCodes = equipmentRelation.equipmentCodes.length > 0 ? ` ${equipmentRelation.equipmentCodes.join(", ")}` : "-"
                        return <PText key={`equipmentRelation_${index}`}>{formattedEquipmentCodes}</PText>
                    })}
                </PFlex>
            ),
        }

        const dynamicColumnBuilders: TechnicalDataColumnBuilder[] = columnDefinitions.map((columnDefinition) => ({
            grow: 2,
            headerCellProps: {
                content: columnDefinition.label,
            },
            buildCellContent: (technicalData) => (
                <PFlex direction={"column"}>
                    {technicalData.equipmentRelations.map((equipmentRelation, index) => {
                        let value
                        let formElement: JSX.Element
                        switch (columnDefinition.type) {
                            case TechnicalDataAttributeValueType.DOUBLE:
                            case TechnicalDataAttributeValueType.INT:
                                value = equipmentRelation.values[columnDefinition.field] ?? "-"
                                formElement = <PText color={"default"}>{value}</PText>
                                break
                            case TechnicalDataAttributeValueType.INT_RANGE: {
                                value = equipmentRelation.values[columnDefinition.field]
                                const formattedRange = value ? `${value.from} to ${value.to}` : "-"
                                formElement = <PText color={"default"}>{formattedRange}</PText>
                                break
                            }
                            case TechnicalDataAttributeValueType.ENGINE_DESIGN:
                                value = equipmentRelation.values.value
                                formElement = <PText color={"default"}>{value}</PText>
                                break
                            case TechnicalDataAttributeValueType.ENGINE_LAYOUT:
                                value = equipmentRelation.values.value
                                formElement = <PText color={"default"}>{value}</PText>
                                break
                            case TechnicalDataAttributeValueType.TRANSMISSION:
                                value = equipmentRelation.values.value
                                formElement = <PText color={"default"}>{value}</PText>
                                break
                            case TechnicalDataAttributeValueType.FUEL_TYPE:
                                value = equipmentRelation.values.value
                                formElement = <PText color={"default"}>{value}</PText>
                                break
                            default:
                                throw Error(`TechnicalDataAttributeValueType ${columnDefinition.type} set not supported`)
                        }

                        return <div key={`value_${index}`}>{formElement}</div>
                    })}
                </PFlex>
            ),
        }))

        return [codesColumn, modelYearColumn, nameColumn, optionCodesColumn, ...dynamicColumnBuilders]
    }
