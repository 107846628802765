import { showErrorToast } from "../../../../../../common/errorToastHelper"
import { browserLanguage } from "../../../../../../common/global"
import { stringConverterGroup, useURLSearchParamState } from "../../../../../../common/useURLSearchParamState"
import { ColumnGroupsWrapper } from "../../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { LabeledContentWrapper } from "../../../../../../components/layouts/labeledcontentwrapper/LabeledContentWrapper"
import { RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { WidgetWrapper } from "../../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { GroupedLanguageTagSelector } from "../../../../../../components/selectors/groupedlanguagetagselector/GroupedLanguageTagSelector"
import { OrderTypePackagesDTO, OrderTypePackagesDTOPackage } from "../../../../../../generated/pdsapi"
import { orderTypeApi } from "../../../../../../pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../../viewtemplates/details/ProductDetailsContainer"
import { useToast } from "@finder/ui-kit"
import { PDivider, PFlex, PHeadline, PSpinner, PText } from "@porsche-design-system/components-react"
import { color, spacing } from "@porsche-design-system/utilities"
import { FC, useEffect, useRef, useState } from "react"
import styled from "styled-components"

export const OrderTypeEquipmentPackagesCard: FC<ProductDetailsTabConfigContentProps> = ({ itemKey, urlPathParamsPrefix }) => {
    const toastRef = useRef(useToast())
    const [languageTag, setLanguageTag] = useURLSearchParamState(`${urlPathParamsPrefix}.languageTag`, browserLanguage, stringConverterGroup.required)
    const [packages, setPackages] = useState<OrderTypePackagesDTO>()

    useEffect(() => {
        const fetchPackages = async () => {
            setPackages(undefined)

            try {
                const packages = await orderTypeApi.details.packages.getEquipmentPackages({
                    ...itemKey,
                    languageTag,
                })
                setPackages(packages)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchPackages()
    }, [itemKey, languageTag])

    return <OrderTypeEquipmentPackagesCardContent languageTag={languageTag} setLanguageTag={setLanguageTag} packages={packages} />
}

export const OrderTypeEquipmentPackagesCardContent = ({
    languageTag,
    setLanguageTag,
    packages,
}: {
    languageTag: string
    setLanguageTag: (languageTag: string) => void
    packages: OrderTypePackagesDTO | undefined
}) => (
    <WidgetWrapper label={"Equipment Packages"}>
        <SpacedItems direction={"column"} lineSpacing={spacing[32]}>
            <ColumnGroupsWrapper>
                <GroupedLanguageTagSelector value={languageTag} onChange={setLanguageTag} />
            </ColumnGroupsWrapper>

            <PDivider />
            <PHeadline variant={"headline-4"}>Sales Equipment</PHeadline>
            <EquipmentPackages equipmentPackages={packages?.salesEquipment} />
            <PDivider />
            <PHeadline variant={"headline-4"}>Technical Equipment</PHeadline>
            <EquipmentPackages equipmentPackages={packages?.technicalEquipment} />
        </SpacedItems>
    </WidgetWrapper>
)

const EquipmentPackages = ({ equipmentPackages }: { equipmentPackages: OrderTypePackagesDTOPackage[] | undefined }) =>
    equipmentPackages ? (
        <SpacedItems direction={"column"} lineSpacing={spacing[48]}>
            {equipmentPackages.length > 0 ? (
                equipmentPackages.map((equipmentPackage) => (
                    <StyledRow key={equipmentPackage.label}>
                        <RowGroupWrapper label={equipmentPackage.label} childrenSize={3}>
                            {equipmentPackage.content.map((packageContent) => (
                                <LabeledContentWrapper key={packageContent.optionCode} labelColor={"neutral-contrast-medium"} label={packageContent.optionCode}>
                                    <PText>{packageContent.name ?? "-"}</PText>
                                </LabeledContentWrapper>
                            ))}
                        </RowGroupWrapper>
                    </StyledRow>
                ))
            ) : (
                <PText>No packages available</PText>
            )}
        </SpacedItems>
    ) : (
        <PFlex justifyContent={"center"}>
            <PSpinner key={"loadingSpinner"} size={"small"} />
        </PFlex>
    )

const StyledRow = styled.div`
    &:hover {
        background: ${color.background.surface};
    }
`
