import { WidgetWrapper } from "../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { LoadableVehicleImagesCardContent } from "../../../../components/loadablevehicleimagescardcontent/LoadableVehicleImagesCardContent"
import { VehicleStateSearchResult } from "../../../../generated/pdsapi"
import { FC, useCallback } from "react"

interface DetailsVehicleImagesCardProps {
    itemKey: { key: string }
    load: (props: { key: string }) => Promise<VehicleStateSearchResult[]>
    shouldLoad?: boolean
    isInitiallyExpanded?: boolean
}

export const DetailsVehicleImagesCard: FC<DetailsVehicleImagesCardProps> = ({ itemKey, load, shouldLoad, isInitiallyExpanded }) => {
    const loadVehicleImages = useCallback(() => load(itemKey), [load, itemKey])
    return (
        <WidgetWrapper label={"Vehicle Images"}>
            <LoadableVehicleImagesCardContent load={loadVehicleImages} shouldLoad={shouldLoad} isInitiallyExpanded={isInitiallyExpanded} />
        </WidgetWrapper>
    )
}
