/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EquipmentFilterOptionsDTO,
    EquipmentFilterOptionsDTOFromJSON,
    EquipmentFilterOptionsDTOToJSON,
    PageDTOEquipmentItemDTO,
    PageDTOEquipmentItemDTOFromJSON,
    PageDTOEquipmentItemDTOToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    SortFieldCombinedEnum,
    SortFieldCombinedEnumFromJSON,
    SortFieldCombinedEnumToJSON,
    VehicleEquipmentCategory,
    VehicleEquipmentCategoryFromJSON,
    VehicleEquipmentCategoryToJSON,
} from '../models';

export interface GetEquipmentFilterOptionsRequest {
    languageTag: string;
}

export interface GetEquipmentsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: SortFieldCombinedEnum;
    sortDirection: SortDirection;
    search?: string;
    modelGenerationKeys?: Array<string>;
    modelYears?: Array<number>;
    orderTypeKeys?: Array<string>;
    categories?: Array<VehicleEquipmentCategory>;
    tagKeys?: Array<string>;
    families?: Array<string>;
}

/**
 * 
 */
export class EquipmentCatalogueWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter Equipments
     */
    async getEquipmentFilterOptionsRaw(requestParameters: GetEquipmentFilterOptionsRequest): Promise<runtime.ApiResponse<EquipmentFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getEquipmentFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter Equipments
     */
    async getEquipmentFilterOptions(requestParameters: GetEquipmentFilterOptionsRequest): Promise<EquipmentFilterOptionsDTO> {
        const response = await this.getEquipmentFilterOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get page of Equipments
     */
    async getEquipmentsPageRaw(requestParameters: GetEquipmentsPageRequest): Promise<runtime.ApiResponse<PageDTOEquipmentItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getEquipmentsPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getEquipmentsPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getEquipmentsPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getEquipmentsPage.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getEquipmentsPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.modelGenerationKeys) {
            queryParameters['modelGenerationKeys'] = requestParameters.modelGenerationKeys;
        }

        if (requestParameters.modelYears) {
            queryParameters['modelYears'] = requestParameters.modelYears;
        }

        if (requestParameters.orderTypeKeys) {
            queryParameters['orderTypeKeys'] = requestParameters.orderTypeKeys;
        }

        if (requestParameters.categories) {
            queryParameters['categories'] = requestParameters.categories;
        }

        if (requestParameters.tagKeys) {
            queryParameters['tagKeys'] = requestParameters.tagKeys;
        }

        if (requestParameters.families) {
            queryParameters['families'] = requestParameters.families;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOEquipmentItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of Equipments
     */
    async getEquipmentsPage(requestParameters: GetEquipmentsPageRequest): Promise<PageDTOEquipmentItemDTO> {
        const response = await this.getEquipmentsPageRaw(requestParameters);
        return await response.value();
    }

}
