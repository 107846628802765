import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { CustomExteriorColorCreateContainer } from "./create/CustomExteriorColorCreateContainer"
import { CustomExteriorColorDetailsContainer } from "./details/CustomExteriorColorDetailsContainer"
import { CustomExteriorColorListContainer } from "./list/CustomExteriorColorListContainer"
import { FC } from "react"

export const CustomExteriorColorRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Custom Exterior Colors",
        List: CustomExteriorColorListContainer,
        Details: CustomExteriorColorDetailsContainer,
        Create: CustomExteriorColorCreateContainer,
    })
