import { OrderTypeMediaRelatedDataDTO } from "../../../../../generated/pdsapi"
import { createContext, useContext } from "react"

export const OrderTypeMediaContext = createContext<OrderTypeMediaRelatedDataDTO>({
    possibleOptionCodes: [],
    mediaBasePath: "",
})

export const useOrderTypeMediaContext = () => {
    const context = useContext(OrderTypeMediaContext)
    if (!context) {
        throw new Error("useOrderTypeMediaContext requires a <OrderTypeMediaContext.Provider> to be in the component hierarchy.")
    }

    return context
}
