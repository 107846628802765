/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntRange
 */
export interface IntRange {
    /**
     * 
     * @type {number}
     * @memberof IntRange
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof IntRange
     */
    end: number;
}

export function IntRangeFromJSON(json: any): IntRange {
    return IntRangeFromJSONTyped(json, false);
}

export function IntRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': json['start'],
        'end': json['end'],
    };
}

export function IntRangeToJSON(value?: IntRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start,
        'end': value.end,
    };
}


