import { propertiesOf } from "../../../../../../../common/property"
import { FormElementWrapper } from "../../../../../../../components/formelements/FormElementWrapper"
import { ReadonlyFormElementWrapper } from "../../../../../../../components/formelements/ReadonlyFormElementWrapper"
import { getDerivedNameFormElement } from "../../../../../../../components/formelements/derivednameformelement/DerivedNameFormElement"
import { getNumberFormElement } from "../../../../../../../components/formelements/numberformelement/NumberFormElement"
import { getTextFormElement } from "../../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { ExteriorColorOptionDTO, ExteriorColorOptionUpdateDTO } from "../../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { FC } from "react"
import { OptionImage } from "../../../../../../../components/optionimage/OptionImage"
import { getSelectTextFormElement } from "../../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { toOption } from "../../../../../../../components/formelements/selecttextformelement/Option"

export const ExteriorColorOptionCardContent: FC<ProductDetailsCardContentProps<ExteriorColorOptionDTO, ExteriorColorOptionUpdateDTO>> = ({
    formDisplayData,
}) => {
    const properties = propertiesOf<ExteriorColorOptionUpdateDTO>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <ReadonlyFormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.optionCode,
                    })}
                />
                <ReadonlyFormElementWrapper
                    label={"Option Type"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.optionType,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <FormElementWrapper
                    label={"Exterior Color Group"}
                    formDisplayData={formDisplayData}
                    field={properties.exteriorColorGroup}
                    FormElement={getSelectTextFormElement<ExteriorColorOptionDTO, ExteriorColorOptionUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: data.exteriorColorGroup.value?.label ?? "-",
                    })}
                    getEditProps={(options) => ({
                        options: [{ value: "", label: "-" }, ...options.exteriorColorGroup.options.map(toOption)],
                        value: options.exteriorColorGroup.value?.key ?? "",
                        optional: true,
                    })}
                />
                <FormElementWrapper
                    label={"Sort Index"}
                    formDisplayData={formDisplayData}
                    field={properties.sortIndex}
                    FormElement={getNumberFormElement<ExteriorColorOptionDTO, ExteriorColorOptionUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.sortIndex,
                    })}
                    getEditProps={(data) => ({
                        value: data.sortIndex,
                        step: 1,
                        min: 0,
                    })}
                />
            </RowGroupWrapper>

            <RowGroupWrapper label={"Name"}>
                {(["de", "en"] as const).map((languageTag) => (
                    <FormElementWrapper
                        key={languageTag}
                        label={`Name - ${languageTag}`}
                        formDisplayData={formDisplayData}
                        field={properties.optionName[languageTag]}
                        FormElement={getDerivedNameFormElement<ExteriorColorOptionDTO, ExteriorColorOptionUpdateDTO>()}
                        getViewProps={(data) => {
                            const name = data.name[languageTag]
                            return {
                                primaryName: {
                                    value: name.merged.withFallback,
                                    isDerived: !name.merged.raw,
                                },
                                secondaryNames: [
                                    {
                                        label: "Option",
                                        value: name.option.withFallback,
                                        isDerived: !name.option.raw,
                                    },
                                    {
                                        label: "Customization",
                                        value: name.customization?.withFallback,
                                        isDerived: !name.customization?.raw,
                                    },
                                ],
                            }
                        }}
                        getEditProps={(data) => ({
                            rawName: data.name[languageTag].option.raw,
                        })}
                    />
                ))}
            </RowGroupWrapper>

            <RowGroupWrapper label={"Description"}>
                {(["de", "en"] as const).map((languageTag) => (
                    <FormElementWrapper
                        key={languageTag}
                        label={`Description - ${languageTag}`}
                        formDisplayData={formDisplayData}
                        field={properties.optionDescription[languageTag]}
                        FormElement={getDerivedNameFormElement<ExteriorColorOptionDTO, ExteriorColorOptionUpdateDTO>()}
                        getViewProps={(data) => {
                            const description = data.description[languageTag]
                            return {
                                primaryName: {
                                    value: description.merged.withFallback,
                                    isDerived: !description.merged.raw,
                                },
                                secondaryNames: [
                                    {
                                        label: "Option",
                                        value: description.option.withFallback,
                                        isDerived: !description.option.raw,
                                    },
                                    {
                                        label: "Customization",
                                        value: description.customization?.withFallback,
                                        isDerived: !description.customization?.raw,
                                    },
                                ],
                            }
                        }}
                        getEditProps={(data) => ({
                            rawName: data.description[languageTag].option.raw,
                        })}
                    />
                ))}
            </RowGroupWrapper>

            <RowGroupWrapper label={"Images"}>
                {formDisplayData.data?.imageKeys.map((imageKey) => <OptionImage key={imageKey} imageKey={imageKey} sizes={"400px"} />)}
            </RowGroupWrapper>
        </SpacedItems>
    )
}
