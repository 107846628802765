/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderTypeMediaDTOEquipmentRelationDTO,
    OrderTypeMediaDTOEquipmentRelationDTOFromJSON,
    OrderTypeMediaDTOEquipmentRelationDTOFromJSONTyped,
    OrderTypeMediaDTOEquipmentRelationDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeMediaDTO
 */
export interface OrderTypeMediaDTO {
    /**
     * 
     * @type {Array<OrderTypeMediaDTOEquipmentRelationDTO>}
     * @memberof OrderTypeMediaDTO
     */
    equipmentRelations: Array<OrderTypeMediaDTOEquipmentRelationDTO>;
}

export function OrderTypeMediaDTOFromJSON(json: any): OrderTypeMediaDTO {
    return OrderTypeMediaDTOFromJSONTyped(json, false);
}

export function OrderTypeMediaDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeMediaDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equipmentRelations': ((json['equipmentRelations'] as Array<any>).map(OrderTypeMediaDTOEquipmentRelationDTOFromJSON)),
    };
}

export function OrderTypeMediaDTOToJSON(value?: OrderTypeMediaDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equipmentRelations': ((value.equipmentRelations as Array<any>).map(OrderTypeMediaDTOEquipmentRelationDTOToJSON)),
    };
}


