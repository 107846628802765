import { HeadlineWithActions } from "../../headlines/headlinewithactions/HeadlineWithActions"
import { Box } from "../box/Box"
import { Skeleton } from "@finder/ui-kit"
import { spacing } from "@porsche-design-system/utilities"
import { createContext, FC, ReactNode, useContext, useState } from "react"
import styled from "styled-components"

interface WidgetWrapperContextType {
    buttons: [JSX.Element[] | undefined, (buttons: JSX.Element[] | undefined) => void]
}

export const WidgetWrapperContext = createContext<WidgetWrapperContextType>({ buttons: [undefined, () => undefined] })

export const useWidgetWrapperContext = () => {
    return useContext(WidgetWrapperContext)
}

interface WidgetWrapperProps {
    label: string | undefined
    buttons?: JSX.Element[]
    children: ReactNode
}

export const WidgetWrapper: FC<WidgetWrapperProps> = (props) => {
    const [buttons, setButtons] = useState<JSX.Element[] | undefined>()
    return (
        <>
            {props.label ? (
                <HeadlineWithActions variant={"headline-4"} label={props.label} rightActions={props.buttons?.concat(buttons ?? []) ?? buttons} />
            ) : (
                <SkeletonHeadline />
            )}
            <Box height={spacing[16]} />

            <WidgetWrapperContext.Provider value={{ buttons: [buttons, setButtons] }}>{props.children}</WidgetWrapperContext.Provider>
        </>
    )
}

const SkeletonHeadline = styled(Skeleton)`
    height: 32px;
    width: 100%;
`
