import { derivativeCategoriesRoutes, modelGenerationRoutes } from "../../../../common/routes"
import { modelSeriesApi } from "../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLocalizationCard } from "../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { ModelSeriesDetailsAttributesCardContent } from "./data/attributes/ModelSeriesDetailsAttributesCardContent"
import { ModelSeriesLinksCard } from "./data/links/ModelSeriesLinksCard"

export const ModelSeriesProductDetailsContainerConfig: ProductDetailsContainerConfig = {
    getBaseInformation: modelSeriesApi.details.getBaseInformation,
    deleteByKey: modelSeriesApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={modelSeriesApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            modelSeriesApi.details.updateAttributes({
                                key,
                                modelSeriesAttributesUpdateDTO: update,
                            })
                        }
                        Content={ModelSeriesDetailsAttributesCardContent}
                    />
                    <ModelSeriesLinksCard
                        {...props}
                        getData={modelSeriesApi.details.getLinks}
                        getModelGenerationRoute={modelGenerationRoutes.details}
                        getDerivativeCategoryRoute={derivativeCategoriesRoutes.details}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Localization",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={modelSeriesApi.details.getName}
                        updateData={({ key, update }) =>
                            modelSeriesApi.details.updateName({
                                key,
                                requestBody: update,
                            })
                        }
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

export const ModelSeriesDetailsContainer = getProductDetailsContainer(ModelSeriesProductDetailsContainerConfig)
