import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { ModelGenerationFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { ModelGenerationFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const ModelGenerationListFilter: FunctionComponent<TableFilterProps<ModelGenerationFilter, ModelGenerationFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const modelSeriesFilter = (
        <MultiSelect
            label={"Model Series"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelSeries?.map(toOption)}
            value={filter.modelSeriesKeys ?? []}
            onChange={(modelSeriesKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelSeriesKeys: modelSeriesKeys.length === 0 ? undefined : modelSeriesKeys,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>{modelSeriesFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
