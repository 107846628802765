import { SpacedItems } from "../../layouts/spaceditems/SpacedItems"
import { ExteriorColorTile } from "@finder/ui-kit"
import { spacing } from "@porsche-design-system/utilities"
import { FunctionComponent } from "react"

export interface NamedExteriorColorTileProps {
    label: string
    hexCode: string
    glossy?: boolean
}

export const NamedExteriorColorTile: FunctionComponent<NamedExteriorColorTileProps> = (props) => (
    <SpacedItems direction={"row"} itemSpacing={spacing[8]} alignItems={"center"}>
        <ExteriorColorTile color={props.hexCode} glossy={props.glossy} />
        {props.label}
    </SpacedItems>
)
