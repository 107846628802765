import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { EquipmentCustomizationCreateOptionsDTO, EquipmentCustomizationCreateRequestDTO } from "../../../../generated/pdsapi"
import { equipmentCustomizationApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { EquipmentCustomizationCreateAttributesCard } from "./attributes/EquipmentCustomizationCreateAttributesCard"

export const EquipmentCustomizationProductCreateContainerConfig: ProductCreateContainerConfig<
    EquipmentCustomizationCreateRequestDTO,
    EquipmentCustomizationCreateOptionsDTO
> = {
    headline: "Create new Equipment Customization",

    create: (item) =>
        equipmentCustomizationApi.create.create({
            equipmentCustomizationCreateRequestDTO: item,
        }),
    getCreateOptions: equipmentCustomizationApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <EquipmentCustomizationCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const EquipmentCustomizationCreateContainer = getProductCreateContainer(EquipmentCustomizationProductCreateContainerConfig)
