import { LocalizationsUpdateDTO } from "../../../../pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../ProductDetailsContainer"
import { ProductDetailsCard } from "../ProductDetailsCard"
import { DetailsDerivedLocalizationCardContent, DetailsDerivedLocalizationCardContentProps } from "./content/DetailsDerivedLocalizationCardContent"

interface DetailsDerivedLocalizationCardProps<DATA, KEY = { key: string }> extends DetailsDerivedLocalizationCardContentProps<DATA> {
    label: string
    getData: (props: KEY & { languageTag: string }) => Promise<DATA>
    updateData: (props: KEY & { update: LocalizationsUpdateDTO }) => Promise<void>
}

export const DetailsDerivedLocalizationCard = <DATA, KEY = { key: string }>(
    props: ProductDetailsTabConfigContentProps<KEY> & DetailsDerivedLocalizationCardProps<DATA, KEY>
) => <ProductDetailsCard {...props} Content={(contentProps) => <DetailsDerivedLocalizationCardContent {...props} {...contentProps} />} />
