/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    OptionalSelectDTOExteriorColorType,
    OptionalSelectDTOExteriorColorTypeFromJSON,
    OptionalSelectDTOExteriorColorTypeFromJSONTyped,
    OptionalSelectDTOExteriorColorTypeToJSON,
    OptionalSelectDTOString,
    OptionalSelectDTOStringFromJSON,
    OptionalSelectDTOStringFromJSONTyped,
    OptionalSelectDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomExteriorColorAttributesDTO
 */
export interface CustomExteriorColorAttributesDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorAttributesDTO
     */
    key: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomExteriorColorAttributesDTO
     */
    knownNames: Array<string>;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof CustomExteriorColorAttributesDTO
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorAttributesDTO
     */
    hexCode?: string;
    /**
     * 
     * @type {OptionalSelectDTOString}
     * @memberof CustomExteriorColorAttributesDTO
     */
    exteriorColorTag: OptionalSelectDTOString;
    /**
     * 
     * @type {OptionalSelectDTOExteriorColorType}
     * @memberof CustomExteriorColorAttributesDTO
     */
    exteriorColorType: OptionalSelectDTOExteriorColorType;
}

export function CustomExteriorColorAttributesDTOFromJSON(json: any): CustomExteriorColorAttributesDTO {
    return CustomExteriorColorAttributesDTOFromJSONTyped(json, false);
}

export function CustomExteriorColorAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomExteriorColorAttributesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'knownNames': json['knownNames'],
        'name': BaseNameDTOFromJSON(json['name']),
        'hexCode': !exists(json, 'hexCode') ? undefined : json['hexCode'],
        'exteriorColorTag': OptionalSelectDTOStringFromJSON(json['exteriorColorTag']),
        'exteriorColorType': OptionalSelectDTOExteriorColorTypeFromJSON(json['exteriorColorType']),
    };
}

export function CustomExteriorColorAttributesDTOToJSON(value?: CustomExteriorColorAttributesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'knownNames': value.knownNames,
        'name': BaseNameDTOToJSON(value.name),
        'hexCode': value.hexCode,
        'exteriorColorTag': OptionalSelectDTOStringToJSON(value.exteriorColorTag),
        'exteriorColorType': OptionalSelectDTOExteriorColorTypeToJSON(value.exteriorColorType),
    };
}


