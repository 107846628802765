/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    CreateResponseDTO,
    CreateResponseDTOFromJSON,
    CreateResponseDTOToJSON,
    OrderTypeCreateOptionsDTO,
    OrderTypeCreateOptionsDTOFromJSON,
    OrderTypeCreateOptionsDTOToJSON,
    OrderTypeCreateRequestDTO,
    OrderTypeCreateRequestDTOFromJSON,
    OrderTypeCreateRequestDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface CreateOrderTypeRequest {
    orderTypeCreateRequestDTO: OrderTypeCreateRequestDTO;
}

export interface GetOrderTypeCreateOptionsRequest {
    languageTag: string;
}

/**
 * 
 */
export class OrderTypeCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new OrderType
     */
    async createOrderTypeRaw(requestParameters: CreateOrderTypeRequest): Promise<runtime.ApiResponse<CreateResponseDTO>> {
        if (requestParameters.orderTypeCreateRequestDTO === null || requestParameters.orderTypeCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('orderTypeCreateRequestDTO','Required parameter requestParameters.orderTypeCreateRequestDTO was null or undefined when calling createOrderType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderTypeCreateRequestDTOToJSON(requestParameters.orderTypeCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResponseDTOFromJSON(jsonValue));
    }

    /**
     * Create a new OrderType
     */
    async createOrderType(requestParameters: CreateOrderTypeRequest): Promise<CreateResponseDTO> {
        const response = await this.createOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get select options to create OrderTypes
     */
    async getOrderTypeCreateOptionsRaw(requestParameters: GetOrderTypeCreateOptionsRequest): Promise<runtime.ApiResponse<OrderTypeCreateOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOrderTypeCreateOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create OrderTypes
     */
    async getOrderTypeCreateOptions(requestParameters: GetOrderTypeCreateOptionsRequest): Promise<OrderTypeCreateOptionsDTO> {
        const response = await this.getOrderTypeCreateOptionsRaw(requestParameters);
        return await response.value();
    }

}
