/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaintToSampleExteriorColorCreateRequestDTO
 */
export interface PaintToSampleExteriorColorCreateRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof PaintToSampleExteriorColorCreateRequestDTO
     */
    orderTypeKey: string;
    /**
     * 
     * @type {string}
     * @memberof PaintToSampleExteriorColorCreateRequestDTO
     */
    optionCode: string;
    /**
     * 
     * @type {number}
     * @memberof PaintToSampleExteriorColorCreateRequestDTO
     */
    sortIndex?: number;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof PaintToSampleExteriorColorCreateRequestDTO
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof PaintToSampleExteriorColorCreateRequestDTO
     */
    description: BaseNameDTO;
}

export function PaintToSampleExteriorColorCreateRequestDTOFromJSON(json: any): PaintToSampleExteriorColorCreateRequestDTO {
    return PaintToSampleExteriorColorCreateRequestDTOFromJSONTyped(json, false);
}

export function PaintToSampleExteriorColorCreateRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaintToSampleExteriorColorCreateRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderTypeKey': json['orderTypeKey'],
        'optionCode': json['optionCode'],
        'sortIndex': !exists(json, 'sortIndex') ? undefined : json['sortIndex'],
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
    };
}

export function PaintToSampleExteriorColorCreateRequestDTOToJSON(value?: PaintToSampleExteriorColorCreateRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderTypeKey': value.orderTypeKey,
        'optionCode': value.optionCode,
        'sortIndex': value.sortIndex,
        'name': BaseNameDTOToJSON(value.name),
        'description': BaseNameDTOToJSON(value.description),
    };
}


