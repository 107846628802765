import { TableFilterProps } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toOption } from "../../../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { OrderTypeRoofColorFilterOptionsDTO } from "../../../../../../../generated/pdsapi"
import { OrderTypeRoofColorsFilter } from "../../../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const OrderTypeRoofColorsCardFilter: FunctionComponent<TableFilterProps<OrderTypeRoofColorsFilter, OrderTypeRoofColorFilterOptionsDTO>> = (props) => {
    const tagFilter = (
        <MultiSelect
            label={"Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={props.filterOptions?.tags.map(toOption)}
            value={props.filter.tagKeys ?? []}
            onChange={(tagKeys) =>
                props.setFilter((filter) => ({
                    ...filter,
                    tagKeys: tagKeys.length === 0 ? undefined : tagKeys,
                }))
            }
        />
    )

    return <ColumnGroupsWrapper>{tagFilter}</ColumnGroupsWrapper>
}
