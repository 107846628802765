import { propertiesOf } from "../../../../../../../common/property"
import { FormElementWrapper } from "../../../../../../../components/formelements/FormElementWrapper"
import { ReadonlyFormElementWrapper } from "../../../../../../../components/formelements/ReadonlyFormElementWrapper"
import { getDerivedNameFormElement } from "../../../../../../../components/formelements/derivednameformelement/DerivedNameFormElement"
import { getNumberFormElement } from "../../../../../../../components/formelements/numberformelement/NumberFormElement"
import { toOption } from "../../../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTextFormElement } from "../../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { OptionImage } from "../../../../../../../components/optionimage/OptionImage"
import { EquipmentOptionDTO, EquipmentOptionUpdateDTO } from "../../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { FC } from "react"

export const EquipmentOptionCardContent: FC<ProductDetailsCardContentProps<EquipmentOptionDTO, EquipmentOptionUpdateDTO>> = ({ formDisplayData }) => {
    const properties = propertiesOf<EquipmentOptionUpdateDTO>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <ReadonlyFormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.optionCode,
                    })}
                />
                <ReadonlyFormElementWrapper
                    label={"Option Type"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.optionType,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <FormElementWrapper
                    label={"Category"}
                    formDisplayData={formDisplayData}
                    field={properties.category}
                    FormElement={getSelectTextFormElement<EquipmentOptionDTO, EquipmentOptionUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: data.category.value?.label ?? "-",
                    })}
                    getEditProps={(data) => ({
                        value: data.category.value?.key ?? "",
                        options: [{ label: "-", value: "" }, ...data.category.options.map(toOption)],
                        optional: true,
                    })}
                />
                <FormElementWrapper
                    label={"Sort Index"}
                    formDisplayData={formDisplayData}
                    field={properties.sortIndex}
                    FormElement={getNumberFormElement<EquipmentOptionDTO, EquipmentOptionUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.sortIndex,
                    })}
                    getEditProps={(data) => ({
                        value: data.sortIndex,
                        step: 1,
                        min: 0,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <ReadonlyFormElementWrapper
                    label={"Family"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.family,
                    })}
                />
                <ReadonlyFormElementWrapper
                    label={"Serial Option Type"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.serialOptionType,
                    })}
                />
                <ReadonlyFormElementWrapper
                    label={"Group Id List"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.groupIdList.join(", "),
                    })}
                />
            </RowGroupWrapper>

            <RowGroupWrapper label={"Name"}>
                {(["de", "en"] as ("en" | "de")[]).map((languageTag) => (
                    <FormElementWrapper
                        key={languageTag}
                        label={`Name - ${languageTag}`}
                        formDisplayData={formDisplayData}
                        field={properties.optionName[languageTag]}
                        FormElement={getDerivedNameFormElement<EquipmentOptionDTO, EquipmentOptionUpdateDTO>()}
                        getViewProps={(data) => {
                            const name = data.name[languageTag]
                            return {
                                primaryName: {
                                    value: name.merged.withFallback,
                                    isDerived: !name.merged.raw,
                                },
                                secondaryNames: [
                                    {
                                        label: "Option",
                                        value: name.option.withFallback,
                                        isDerived: !name.option.raw,
                                    },
                                    {
                                        label: "Customization",
                                        value: name.customization?.withFallback,
                                        isDerived: !name.customization?.raw,
                                    },
                                ],
                            }
                        }}
                        getEditProps={(data) => ({
                            rawName: data.name[languageTag].option.raw,
                        })}
                    />
                ))}
            </RowGroupWrapper>

            <RowGroupWrapper label={"Description"}>
                {(["de", "en"] as ("en" | "de")[]).map((languageTag) => (
                    <FormElementWrapper
                        key={languageTag}
                        label={`Description - ${languageTag}`}
                        formDisplayData={formDisplayData}
                        field={properties.optionDescription[languageTag]}
                        FormElement={getDerivedNameFormElement<EquipmentOptionDTO, EquipmentOptionUpdateDTO>()}
                        getViewProps={(data) => {
                            const description = data.description[languageTag]
                            return {
                                primaryName: {
                                    value: description.merged.withFallback,
                                    isDerived: !description.merged.raw,
                                },
                                secondaryNames: [
                                    {
                                        label: "Option",
                                        value: description.option.withFallback,
                                        isDerived: !description.option.raw,
                                    },
                                    {
                                        label: "Customization",
                                        value: description.customization?.withFallback,
                                        isDerived: !description.customization?.raw,
                                    },
                                ],
                            }
                        }}
                        getEditProps={(data) => ({
                            rawName: data.description[languageTag].option.raw,
                        })}
                    />
                ))}
            </RowGroupWrapper>

            <RowGroupWrapper label={"Images"}>
                {formDisplayData.data?.imageKeys.map((imageKey) => <OptionImage key={imageKey} imageKey={imageKey} sizes={"400px"} />)}
            </RowGroupWrapper>
        </SpacedItems>
    )
}
