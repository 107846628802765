/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOInteriorMaterial,
    OptionDTOInteriorMaterialFromJSON,
    OptionDTOInteriorMaterialFromJSONTyped,
    OptionDTOInteriorMaterialToJSON,
} from './';

/**
 * 
 * @export
 * @interface SelectDTOInteriorMaterial
 */
export interface SelectDTOInteriorMaterial {
    /**
     * 
     * @type {OptionDTOInteriorMaterial}
     * @memberof SelectDTOInteriorMaterial
     */
    value: OptionDTOInteriorMaterial;
    /**
     * 
     * @type {Array<OptionDTOInteriorMaterial>}
     * @memberof SelectDTOInteriorMaterial
     */
    options: Array<OptionDTOInteriorMaterial>;
}

export function SelectDTOInteriorMaterialFromJSON(json: any): SelectDTOInteriorMaterial {
    return SelectDTOInteriorMaterialFromJSONTyped(json, false);
}

export function SelectDTOInteriorMaterialFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectDTOInteriorMaterial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': OptionDTOInteriorMaterialFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(OptionDTOInteriorMaterialFromJSON)),
    };
}

export function SelectDTOInteriorMaterialToJSON(value?: SelectDTOInteriorMaterial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': OptionDTOInteriorMaterialToJSON(value.value),
        'options': ((value.options as Array<any>).map(OptionDTOInteriorMaterialToJSON)),
    };
}


