import { stringConverterGroup, useURLSearchParamState } from "../../common/useURLSearchParamState"
import { Accordion } from "../accordion/Accordion"
import { TechnicalDataMenuItem, TechnicalDataMenuItemGroup } from "./technicalDataMenuItemGroups"
import { PDivider, PFlex, PIcon, PText, PTextFieldWrapper } from "@porsche-design-system/components-react"
import { color } from "@porsche-design-system/utilities"
import { ChangeEvent, FC } from "react"
import styled from "styled-components"

export type TechnicalDataMenuItemIndex = {
    groupIndex: number
    itemIndex: number
}

/**
 * A TechnicalDataMenu contains a search field above an Accordion.
 */
export interface TechnicalDataMenuProps {
    /** the currently selected item index. */
    selectedIndex: TechnicalDataMenuItemIndex
    /** properties of the menu groups displayed in the accordion. */
    groups: TechnicalDataMenuItemGroup[]
    /**
     * handler function call when a TechnicalDataMenuItem is selected.
     * @param index selected index
     */
    onItemSelected: (index: TechnicalDataMenuItemIndex) => void
    /** context prefix to use for saving state in url */
    urlParamsPrefix: string
}

export const TechnicalDataMenu: FC<TechnicalDataMenuProps> = (props) => {
    const [filter, setFilter] = useURLSearchParamState(`${props.urlParamsPrefix}.filter`, "", stringConverterGroup.required)

    return (
        <PFlex direction={"column"}>
            <StyledFilterBox>
                <PTextFieldWrapper>
                    <input type={"search"} name={"search"} value={filter} onChange={(event: ChangeEvent<HTMLInputElement>) => setFilter(event.target.value)} />
                </PTextFieldWrapper>
            </StyledFilterBox>
            <Accordion
                expandAll={filter.length > 1}
                urlParamsPrefix={`${props.urlParamsPrefix}.accordion`}
                items={props.groups.map((menuGroup, groupIndex) => ({
                    label: menuGroup.label,
                    children: menuGroup.items.map((item, itemIndex) => (
                        <MenuElement
                            key={`menu_element_${groupIndex}_${itemIndex}`}
                            filter={filter}
                            item={item}
                            onClick={() => props.onItemSelected({ itemIndex, groupIndex })}
                            isFirst={itemIndex === 0}
                            isSelected={props.selectedIndex.itemIndex === itemIndex && props.selectedIndex.groupIndex === groupIndex}
                        />
                    )),
                }))}
            />
        </PFlex>
    )
}

const MenuElement: FC<{ filter: string; item: TechnicalDataMenuItem; onClick: () => void; isFirst: boolean; isSelected: boolean }> = ({
    filter,
    item,
    onClick,
    isFirst,
    isSelected,
}) =>
    filter && filter.length > 1 && !item.label.toLowerCase().includes(filter.toLowerCase()) ? (
        <></>
    ) : (
        <>
            <StyledLeftMargin hidden={isFirst}>
                <PDivider />
            </StyledLeftMargin>
            <StyledTechnicalDataMenuItem>
                <PFlex justifyContent={"space-between"} onClick={onClick}>
                    <PText weight={isSelected ? "semibold" : "regular"}>{item.label}</PText>
                    <PIcon name={"arrow-head-right"} />
                </PFlex>
            </StyledTechnicalDataMenuItem>
        </>
    )

const StyledFilterBox = styled.div`
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
`

const StyledLeftMargin = styled.div`
    margin-left: 2rem;
`

const StyledTechnicalDataMenuItem = styled.div`
    margin: 1rem 1rem 1rem 3rem;

    &:hover {
        cursor: pointer;
        color: ${color.brand};
    }
`
