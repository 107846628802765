/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderTypeEquipmentItemDTO
 */
export interface OrderTypeEquipmentItemDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderTypeEquipmentItemDTO
     */
    imageKey?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderTypeEquipmentItemDTO
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeEquipmentItemDTO
     */
    optionCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeEquipmentItemDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeEquipmentItemDTO
     */
    categoryName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeEquipmentItemDTO
     */
    tagNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeEquipmentItemDTO
     */
    family?: string;
}

export function OrderTypeEquipmentItemDTOFromJSON(json: any): OrderTypeEquipmentItemDTO {
    return OrderTypeEquipmentItemDTOFromJSONTyped(json, false);
}

export function OrderTypeEquipmentItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeEquipmentItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageKey': !exists(json, 'imageKey') ? undefined : json['imageKey'],
        'sortIndex': json['sortIndex'],
        'optionCode': json['optionCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'categoryName': !exists(json, 'categoryName') ? undefined : json['categoryName'],
        'tagNames': json['tagNames'],
        'family': !exists(json, 'family') ? undefined : json['family'],
    };
}

export function OrderTypeEquipmentItemDTOToJSON(value?: OrderTypeEquipmentItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imageKey': value.imageKey,
        'sortIndex': value.sortIndex,
        'optionCode': value.optionCode,
        'name': value.name,
        'categoryName': value.categoryName,
        'tagNames': value.tagNames,
        'family': value.family,
    };
}


