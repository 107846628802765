/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipment,
    EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentFromJSON,
    EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentFromJSONTyped,
    EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentToJSON,
} from './';

/**
 * 
 * @export
 * @interface EquipmentMarketplaceSpecificsIndexDTOValues
 */
export interface EquipmentMarketplaceSpecificsIndexDTOValues {
    /**
     * 
     * @type {EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipment}
     * @memberof EquipmentMarketplaceSpecificsIndexDTOValues
     */
    isStandardEquipment?: EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipment;
    /**
     * 
     * @type {Date}
     * @memberof EquipmentMarketplaceSpecificsIndexDTOValues
     */
    validFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EquipmentMarketplaceSpecificsIndexDTOValues
     */
    validTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentMarketplaceSpecificsIndexDTOValues
     */
    visibleForSale: boolean;
}

export function EquipmentMarketplaceSpecificsIndexDTOValuesFromJSON(json: any): EquipmentMarketplaceSpecificsIndexDTOValues {
    return EquipmentMarketplaceSpecificsIndexDTOValuesFromJSONTyped(json, false);
}

export function EquipmentMarketplaceSpecificsIndexDTOValuesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentMarketplaceSpecificsIndexDTOValues {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isStandardEquipment': !exists(json, 'isStandardEquipment') ? undefined : EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentFromJSON(json['isStandardEquipment']),
        'validFrom': !exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validTo': !exists(json, 'validTo') ? undefined : (new Date(json['validTo'])),
        'visibleForSale': json['visibleForSale'],
    };
}

export function EquipmentMarketplaceSpecificsIndexDTOValuesToJSON(value?: EquipmentMarketplaceSpecificsIndexDTOValues | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isStandardEquipment': EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentToJSON(value.isStandardEquipment),
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString().substr(0,10)),
        'validTo': value.validTo === undefined ? undefined : (value.validTo.toISOString().substr(0,10)),
        'visibleForSale': value.visibleForSale,
    };
}


