import { derivativeApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"

export const DerivativeSortContainerTemplateProps: ProductSortContainerConfig = {
    listName: "Derivatives",
    fetchSortables: derivativeApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => derivativeApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

export const DerivativeSortContainer = getProductSortContainer(DerivativeSortContainerTemplateProps)
