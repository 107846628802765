import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { RoofColorCustomizationFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { RoofColorCustomizationFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FC } from "react"

export const RoofColorCustomizationListFilter: FC<TableFilterProps<RoofColorCustomizationFilter, RoofColorCustomizationFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const modelGenerationFilter = (
        <MultiSelect
            label={"Model Generations"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelGenerations?.map(toOption)}
            value={filter.modelGenerationKeys ?? []}
            onChange={(modelGenerationKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelGenerationKeys: modelGenerationKeys.length === 0 ? undefined : modelGenerationKeys,
                }))
            }
        />
    )

    const roofColorTagsFilter = (
        <MultiSelect
            label={"Roof Color Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.roofColorTags?.map(toOption)}
            value={filter.roofColorTagKeys ?? []}
            onChange={(roofColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    roofColorTagKeys: roofColorTagKeys.length === 0 ? undefined : roofColorTagKeys,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>{modelGenerationFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Tag"}>{roofColorTagsFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
