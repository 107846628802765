/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOExteriorColorType,
    OptionDTOExteriorColorTypeFromJSON,
    OptionDTOExteriorColorTypeFromJSONTyped,
    OptionDTOExteriorColorTypeToJSON,
    OptionDTOString,
    OptionDTOStringFromJSON,
    OptionDTOStringFromJSONTyped,
    OptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomExteriorColorFilterOptionsDTO
 */
export interface CustomExteriorColorFilterOptionsDTO {
    /**
     * 
     * @type {Array<OptionDTOString>}
     * @memberof CustomExteriorColorFilterOptionsDTO
     */
    exteriorColorTags: Array<OptionDTOString>;
    /**
     * 
     * @type {Array<OptionDTOExteriorColorType>}
     * @memberof CustomExteriorColorFilterOptionsDTO
     */
    exteriorColorTypes: Array<OptionDTOExteriorColorType>;
}

export function CustomExteriorColorFilterOptionsDTOFromJSON(json: any): CustomExteriorColorFilterOptionsDTO {
    return CustomExteriorColorFilterOptionsDTOFromJSONTyped(json, false);
}

export function CustomExteriorColorFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomExteriorColorFilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exteriorColorTags': ((json['exteriorColorTags'] as Array<any>).map(OptionDTOStringFromJSON)),
        'exteriorColorTypes': ((json['exteriorColorTypes'] as Array<any>).map(OptionDTOExteriorColorTypeFromJSON)),
    };
}

export function CustomExteriorColorFilterOptionsDTOToJSON(value?: CustomExteriorColorFilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exteriorColorTags': ((value.exteriorColorTags as Array<any>).map(OptionDTOStringToJSON)),
        'exteriorColorTypes': ((value.exteriorColorTypes as Array<any>).map(OptionDTOExteriorColorTypeToJSON)),
    };
}


