/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderTypeStandardEquipmentDTOOptionsCategoryOption
 */
export interface OrderTypeStandardEquipmentDTOOptionsCategoryOption {
    /**
     * 
     * @type {string}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategoryOption
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategoryOption
     */
    optionCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategoryOption
     */
    isVisible: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategoryOption
     */
    isStandardFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategoryOption
     */
    isStandardTo?: Date;
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryOptionFromJSON(json: any): OrderTypeStandardEquipmentDTOOptionsCategoryOption {
    return OrderTypeStandardEquipmentDTOOptionsCategoryOptionFromJSONTyped(json, false);
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeStandardEquipmentDTOOptionsCategoryOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'optionCode': json['optionCode'],
        'isVisible': json['isVisible'],
        'isStandardFrom': !exists(json, 'isStandardFrom') ? undefined : (new Date(json['isStandardFrom'])),
        'isStandardTo': !exists(json, 'isStandardTo') ? undefined : (new Date(json['isStandardTo'])),
    };
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryOptionToJSON(value?: OrderTypeStandardEquipmentDTOOptionsCategoryOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'optionCode': value.optionCode,
        'isVisible': value.isVisible,
        'isStandardFrom': value.isStandardFrom === undefined ? undefined : (value.isStandardFrom.toISOString().substr(0,10)),
        'isStandardTo': value.isStandardTo === undefined ? undefined : (value.isStandardTo.toISOString().substr(0,10)),
    };
}


