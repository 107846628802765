import { getEnumKeyByEnumValue } from "../../../../../common/global"
import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { InteriorCustomizationFilterOptionsDTO, InteriorMaterial } from "../../../../../generated/pdsapi"
import { InteriorCustomizationFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const InteriorCustomizationListFilter: FunctionComponent<TableFilterProps<InteriorCustomizationFilter, InteriorCustomizationFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const modelGenerationFilter = (
        <MultiSelect
            label={"Model Generations"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelGenerations?.map(toOption)}
            value={filter.modelGenerationKeys ?? []}
            onChange={(modelGenerationKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelGenerationKeys: modelGenerationKeys.length === 0 ? undefined : modelGenerationKeys,
                }))
            }
        />
    )

    const interiorColorTagsFilter = (
        <MultiSelect
            label={"Interior Color Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.interiorColorTags?.map(toOption)}
            value={filter.interiorColorTagKeys ?? []}
            onChange={(interiorColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    interiorColorTagKeys: interiorColorTagKeys.length === 0 ? undefined : interiorColorTagKeys,
                }))
            }
        />
    )

    const interiorMaterialFilter = (
        <MultiSelect
            label={"Interior Materials"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.interiorMaterials?.map(toOption)}
            value={filter.interiorMaterials ?? []}
            onChange={(interiorMaterials) =>
                setFilter((filter) => ({
                    ...filter,
                    interiorMaterials:
                        interiorMaterials.length === 0
                            ? undefined
                            : interiorMaterials.map(
                                  (interiorMaterialString) => InteriorMaterial[getEnumKeyByEnumValue(InteriorMaterial, interiorMaterialString)!]
                              ),
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>{modelGenerationFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Tag"}>{interiorColorTagsFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Material"}>{interiorMaterialFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
