import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toGroupedOption } from "../../../../../components/formelements/groupedselecttextformelement/GroupedSelectOption"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { EquipmentCustomizationFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { EquipmentCustomizationFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const EquipmentCustomizationListFilter: FunctionComponent<TableFilterProps<EquipmentCustomizationFilter, EquipmentCustomizationFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const modelGenerationFilter = (
        <MultiSelect
            label={"Model Generations"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelGenerations?.map(toOption)}
            value={filter.modelGenerationKeys ?? []}
            onChange={(modelGenerationKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelGenerationKeys: modelGenerationKeys.length === 0 ? undefined : modelGenerationKeys,
                }))
            }
        />
    )

    const equipmentTagsFilter = (
        <MultiSelect
            label={"Equipment Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.categorizedEquipmentTags.map(toGroupedOption)}
            value={filter.equipmentTagKeys ?? []}
            onChange={(equipmentTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    equipmentTagKeys: equipmentTagKeys.length === 0 ? undefined : equipmentTagKeys,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>{modelGenerationFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Tag"}>{equipmentTagsFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
