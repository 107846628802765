/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOBodyType,
    OptionDTOBodyTypeFromJSON,
    OptionDTOBodyTypeFromJSONTyped,
    OptionDTOBodyTypeToJSON,
    OptionDTOString,
    OptionDTOStringFromJSON,
    OptionDTOStringFromJSONTyped,
    OptionDTOStringToJSON,
    RelatedOptionGroupDTOStringString,
    RelatedOptionGroupDTOStringStringFromJSON,
    RelatedOptionGroupDTOStringStringFromJSONTyped,
    RelatedOptionGroupDTOStringStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface DerivativeCreateOptionsDTO
 */
export interface DerivativeCreateOptionsDTO {
    /**
     * 
     * @type {Array<OptionDTOString>}
     * @memberof DerivativeCreateOptionsDTO
     */
    modelSeries: Array<OptionDTOString>;
    /**
     * 
     * @type {Array<RelatedOptionGroupDTOStringString>}
     * @memberof DerivativeCreateOptionsDTO
     */
    modelSeriesRelatedDerivativeCategories: Array<RelatedOptionGroupDTOStringString>;
    /**
     * 
     * @type {Array<OptionDTOBodyType>}
     * @memberof DerivativeCreateOptionsDTO
     */
    bodyTypes: Array<OptionDTOBodyType>;
}

export function DerivativeCreateOptionsDTOFromJSON(json: any): DerivativeCreateOptionsDTO {
    return DerivativeCreateOptionsDTOFromJSONTyped(json, false);
}

export function DerivativeCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DerivativeCreateOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelSeries': ((json['modelSeries'] as Array<any>).map(OptionDTOStringFromJSON)),
        'modelSeriesRelatedDerivativeCategories': ((json['modelSeriesRelatedDerivativeCategories'] as Array<any>).map(RelatedOptionGroupDTOStringStringFromJSON)),
        'bodyTypes': ((json['bodyTypes'] as Array<any>).map(OptionDTOBodyTypeFromJSON)),
    };
}

export function DerivativeCreateOptionsDTOToJSON(value?: DerivativeCreateOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelSeries': ((value.modelSeries as Array<any>).map(OptionDTOStringToJSON)),
        'modelSeriesRelatedDerivativeCategories': ((value.modelSeriesRelatedDerivativeCategories as Array<any>).map(RelatedOptionGroupDTOStringStringToJSON)),
        'bodyTypes': ((value.bodyTypes as Array<any>).map(OptionDTOBodyTypeToJSON)),
    };
}


