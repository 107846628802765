import { ViewState } from "../../../common/ViewState"
import { EmptyView } from "../../emptyview/EmptyView"
import { TableCell } from "./cell/TableCell"
import { TableHeaderCellSorting, TableSortingHeaderCell } from "./sortingheadercell/TableSortingHeaderCell"
import { PDivider, PText } from "@porsche-design-system/components-react"
import { color } from "@porsche-design-system/utilities"
import { Fragment, Key, PropsWithChildren, useMemo } from "react"
import styled, { css } from "styled-components"

export interface HeaderCellProps<SORTING_TYPE> {
    sorting?: TableHeaderCellSorting<SORTING_TYPE>
    content: string | JSX.Element
}

export interface ColumnBuilder<ITEM, SORTING_TYPE> {
    width?: number
    grow?: number

    headerCellProps: HeaderCellProps<SORTING_TYPE>

    buildCellContent: (item: ITEM, index: number) => string | JSX.Element | JSX.Element[]
}

export interface TableProps<ITEM, SORTING_TYPE> {
    viewState: ViewState
    items: ITEM[]
    columns: ColumnBuilder<ITEM, SORTING_TYPE>[]
    onRowClick?: (item: ITEM, index: number) => void
    getItemKey?: (item: ITEM) => Key
    errorMessage?: string
}

export const Table = <ITEM, SORTING_TYPE>(props: PropsWithChildren<TableProps<ITEM, SORTING_TYPE>>) => {
    const { viewState, items, columns, onRowClick, errorMessage, getItemKey } = props

    const headerCells = useMemo(
        () =>
            columns.map((column, index) => (
                <Fragment key={`headerCellFragment_${index}`}>
                    <TableSortingHeaderCell width={column.width} grow={column.grow} sorting={column.headerCellProps.sorting}>
                        {column.headerCellProps.content}
                    </TableSortingHeaderCell>
                </Fragment>
            )),
        [columns]
    )

    const rows = useMemo(
        () =>
            items.map((item, rowIndex) => {
                const onClick = onRowClick && (() => onRowClick(item, rowIndex))
                const wrapText = (content: string | JSX.Element | JSX.Element[]) =>
                    typeof content === "string" ? <StyledPText>{content}</StyledPText> : content

                return (
                    <div key={getItemKey ? `row_${rowIndex}_${getItemKey(item)}` : `row_${rowIndex}`}>
                        <StyledTableRow key={`content_${rowIndex}`} onClick={onClick}>
                            {columns.map((column, columnIndex) => (
                                <Fragment key={`cell_${columnIndex}`}>
                                    <TableCell width={column.width} grow={column.grow}>
                                        {wrapText(column.buildCellContent(item, rowIndex))}
                                    </TableCell>
                                </Fragment>
                            ))}
                        </StyledTableRow>
                        <PDivider key={`divider_${rowIndex}`} />
                    </div>
                )
            }),
        [items, onRowClick, columns, getItemKey]
    )

    return (
        <>
            <StyledTableHeader key={"header"}>{headerCells}</StyledTableHeader>
            <PDivider key={"header_divider"} color={"neutral-contrast-high"} />
            {viewState === ViewState.CONTENT && items.length !== 0 ? rows : <EmptyView viewState={viewState} errorMessage={errorMessage} />}
        </>
    )
}
const StyledTableHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 39px;
`

const StyledTableRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 59px;

    cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};

    ${({ onClick }) =>
        onClick &&
        css`
            &:hover {
                background: ${color.background.surface};
            }
        `}
`

const StyledPText = styled(PText)`
    word-break: break-word;
    width: 100%;
`
