import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { DerivativeCategoryCreateOptionsDTO, DerivativeCategoryCreateRequestDTO } from "../../../../generated/pdsapi"
import { derivativeCategoryApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { DerivativeCategoryCreateAttributesCard } from "./attributes/DerivativeCategoryCreateAttributesCard"

export const DerivativeCategoryProductCreateContainerConfig: ProductCreateContainerConfig<
    DerivativeCategoryCreateRequestDTO,
    DerivativeCategoryCreateOptionsDTO
> = {
    headline: "Create new Derivative Category",

    create: (item) =>
        derivativeCategoryApi.create.create({
            derivativeCategoryCreateRequestDTO: item,
        }),
    getCreateOptions: derivativeCategoryApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <DerivativeCategoryCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const DerivativeCategoryCreateContainer = getProductCreateContainer(DerivativeCategoryProductCreateContainerConfig)
