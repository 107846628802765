import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { NamedColorTile } from "../../../../../components/tiles/namedcolortile/NamedColorTile"
import { ExteriorColorCustomizationItemDTO } from "../../../../../generated/pdsapi"
import { ExteriorColorCustomizationSortField, ExteriorColorCustomizationSortFieldEnum } from "../../../../../pdsapi"

type ExteriorColorCustomizationColumnBuilder = ColumnBuilder<ExteriorColorCustomizationItemDTO, ExteriorColorCustomizationSortFieldEnum>

export const ExteriorColorCustomizationListColumnsConfig: TableColumnsConfig<ExteriorColorCustomizationItemDTO, ExteriorColorCustomizationSortFieldEnum> = ({
    sorting,
    setSorting,
}) => {
    const optionCodeColumn: ExteriorColorCustomizationColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Option Code",
            sorting: {
                field: ExteriorColorCustomizationSortField.OPTION_CODE,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (exteriorColorCustomization) => exteriorColorCustomization.optionCode,
    }
    const modelGenerationColumn: ExteriorColorCustomizationColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Model Generations",
        },
        buildCellContent: (exteriorColorCustomization) => exteriorColorCustomization.modelGenerationNames.join(", "),
    }
    const exteriorColorTagColumn: ExteriorColorCustomizationColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Exterior Color Tag",
        },
        buildCellContent: (exteriorColorCustomization) => {
            const exteriorColorTag = exteriorColorCustomization.defaultValue.tag

            return exteriorColorTag ? <NamedColorTile label={exteriorColorTag.name} hexCode={exteriorColorTag.hexCode} /> : "-"
        },
    }
    const exteriorColorTypeColumn: ExteriorColorCustomizationColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Exterior Color Type",
        },
        buildCellContent: (exteriorColorCustomization) => exteriorColorCustomization.defaultValue.exteriorColorTypeName ?? "-",
    }

    return [optionCodeColumn, modelGenerationColumn, exteriorColorTagColumn, exteriorColorTypeColumn]
}
