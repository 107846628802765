import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { ExteriorColorCustomizationCreateContainer } from "./create/ExteriorColorCustomizationCreateContainer"
import { ExteriorColorCustomizationDetailsContainer } from "./details/ExteriorColorCustomizationDetailsContainer"
import { ExteriorColorCustomizationListContainer } from "./list/ExteriorColorCustomizationListContainer"
import { FC } from "react"

export const ExteriorColorCustomizationRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Exterior Color Customizations",
        List: ExteriorColorCustomizationListContainer,
        Details: ExteriorColorCustomizationDetailsContainer,
        Create: ExteriorColorCustomizationCreateContainer,
    })
