/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RelatedOptionDTOStringString,
    RelatedOptionDTOStringStringFromJSON,
    RelatedOptionDTOStringStringFromJSONTyped,
    RelatedOptionDTOStringStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface RelatedGroupedRelatedOptionDTOIntegerStringString
 */
export interface RelatedGroupedRelatedOptionDTOIntegerStringString {
    /**
     * 
     * @type {string}
     * @memberof RelatedGroupedRelatedOptionDTOIntegerStringString
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof RelatedGroupedRelatedOptionDTOIntegerStringString
     */
    relatedKey: number;
    /**
     * 
     * @type {Array<RelatedOptionDTOStringString>}
     * @memberof RelatedGroupedRelatedOptionDTOIntegerStringString
     */
    options: Array<RelatedOptionDTOStringString>;
}

export function RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSON(json: any): RelatedGroupedRelatedOptionDTOIntegerStringString {
    return RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSONTyped(json, false);
}

export function RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedGroupedRelatedOptionDTOIntegerStringString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'relatedKey': json['relatedKey'],
        'options': ((json['options'] as Array<any>).map(RelatedOptionDTOStringStringFromJSON)),
    };
}

export function RelatedGroupedRelatedOptionDTOIntegerStringStringToJSON(value?: RelatedGroupedRelatedOptionDTOIntegerStringString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'relatedKey': value.relatedKey,
        'options': ((value.options as Array<any>).map(RelatedOptionDTOStringStringToJSON)),
    };
}


