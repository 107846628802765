/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomInteriorColorFilterOptionsDTO,
    CustomInteriorColorFilterOptionsDTOFromJSON,
    CustomInteriorColorFilterOptionsDTOToJSON,
    PageDTOCustomInteriorColorItemDTO,
    PageDTOCustomInteriorColorItemDTOFromJSON,
    PageDTOCustomInteriorColorItemDTOToJSON,
} from '../models';

export interface GetCustomInteriorColorFilterOptionsRequest {
    languageTag: string;
}

export interface GetCustomInteriorColorsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    search?: string;
    interiorColorTagKeys?: Array<string>;
}

/**
 * 
 */
export class CustomInteriorColorListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter CustomInteriorColors
     */
    async getCustomInteriorColorFilterOptionsRaw(requestParameters: GetCustomInteriorColorFilterOptionsRequest): Promise<runtime.ApiResponse<CustomInteriorColorFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getCustomInteriorColorFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomInteriorColorFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter CustomInteriorColors
     */
    async getCustomInteriorColorFilterOptions(requestParameters: GetCustomInteriorColorFilterOptionsRequest): Promise<CustomInteriorColorFilterOptionsDTO> {
        const response = await this.getCustomInteriorColorFilterOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get page of CustomInteriorColors
     */
    async getCustomInteriorColorsPageRaw(requestParameters: GetCustomInteriorColorsPageRequest): Promise<runtime.ApiResponse<PageDTOCustomInteriorColorItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getCustomInteriorColorsPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getCustomInteriorColorsPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getCustomInteriorColorsPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.interiorColorTagKeys) {
            queryParameters['interiorColorTagKeys'] = requestParameters.interiorColorTagKeys;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOCustomInteriorColorItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of CustomInteriorColors
     */
    async getCustomInteriorColorsPage(requestParameters: GetCustomInteriorColorsPageRequest): Promise<PageDTOCustomInteriorColorItemDTO> {
        const response = await this.getCustomInteriorColorsPageRaw(requestParameters);
        return await response.value();
    }

}
