import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { InteriorColorTagCreateContainer } from "./create/InteriorColorTagCreateContainer"
import { InteriorColorTagDetailsContainer } from "./details/InteriorColorTagDetailsContainer"
import { InteriorColorTagListContainer } from "./list/InteriorColorTagListContainer"
import { InteriorColorTagSortContainer } from "./sort/InteriorColorTagSortContainer"
import { FC } from "react"

export const InteriorColorTagRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Interior Color Tags",
        List: InteriorColorTagListContainer,
        Details: InteriorColorTagDetailsContainer,
        Create: InteriorColorTagCreateContainer,
        Sort: InteriorColorTagSortContainer,
    })
