/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    EquipmentTagAttributesDTO,
    EquipmentTagAttributesDTOFromJSON,
    EquipmentTagAttributesDTOToJSON,
    EquipmentTagAttributesUpdateDTO,
    EquipmentTagAttributesUpdateDTOFromJSON,
    EquipmentTagAttributesUpdateDTOToJSON,
    LocalizationsItemDTO,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface DeleteEquipmentTagRequest {
    key: string;
}

export interface GetAttributesOfEquipmentTagRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfEquipmentTagRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfEquipmentTagRequest {
    key: string;
}

export interface UpdateAttributesOfEquipmentTagRequest {
    key: string;
    equipmentTagAttributesUpdateDTO: EquipmentTagAttributesUpdateDTO;
}

export interface UpdateNameOfEquipmentTagRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class EquipmentTagDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a EquipmentTag by a given key
     */
    async deleteEquipmentTagRaw(requestParameters: DeleteEquipmentTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteEquipmentTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-tags/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a EquipmentTag by a given key
     */
    async deleteEquipmentTag(requestParameters: DeleteEquipmentTagRequest): Promise<void> {
        await this.deleteEquipmentTagRaw(requestParameters);
    }

    /**
     * Get attributes of EquipmentTag with given key
     */
    async getAttributesOfEquipmentTagRaw(requestParameters: GetAttributesOfEquipmentTagRequest): Promise<runtime.ApiResponse<EquipmentTagAttributesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfEquipmentTag.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfEquipmentTag.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-tags/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentTagAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of EquipmentTag with given key
     */
    async getAttributesOfEquipmentTag(requestParameters: GetAttributesOfEquipmentTagRequest): Promise<EquipmentTagAttributesDTO> {
        const response = await this.getAttributesOfEquipmentTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of EquipmentTag with given key
     */
    async getBaseInformationOfEquipmentTagRaw(requestParameters: GetBaseInformationOfEquipmentTagRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfEquipmentTag.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfEquipmentTag.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-tags/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of EquipmentTag with given key
     */
    async getBaseInformationOfEquipmentTag(requestParameters: GetBaseInformationOfEquipmentTagRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfEquipmentTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of EquipmentTag with given key
     */
    async getLocalizationsOfNameOfEquipmentTagRaw(requestParameters: GetLocalizationsOfNameOfEquipmentTagRequest): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfEquipmentTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-tags/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of EquipmentTag with given key
     */
    async getLocalizationsOfNameOfEquipmentTag(requestParameters: GetLocalizationsOfNameOfEquipmentTagRequest): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfEquipmentTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing EquipmentTag
     */
    async updateAttributesOfEquipmentTagRaw(requestParameters: UpdateAttributesOfEquipmentTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfEquipmentTag.');
        }

        if (requestParameters.equipmentTagAttributesUpdateDTO === null || requestParameters.equipmentTagAttributesUpdateDTO === undefined) {
            throw new runtime.RequiredError('equipmentTagAttributesUpdateDTO','Required parameter requestParameters.equipmentTagAttributesUpdateDTO was null or undefined when calling updateAttributesOfEquipmentTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/equipment-tags/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EquipmentTagAttributesUpdateDTOToJSON(requestParameters.equipmentTagAttributesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing EquipmentTag
     */
    async updateAttributesOfEquipmentTag(requestParameters: UpdateAttributesOfEquipmentTagRequest): Promise<void> {
        await this.updateAttributesOfEquipmentTagRaw(requestParameters);
    }

    /**
     * Updates name of an existing EquipmentTag
     */
    async updateNameOfEquipmentTagRaw(requestParameters: UpdateNameOfEquipmentTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfEquipmentTag.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfEquipmentTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/equipment-tags/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing EquipmentTag
     */
    async updateNameOfEquipmentTag(requestParameters: UpdateNameOfEquipmentTagRequest): Promise<void> {
        await this.updateNameOfEquipmentTagRaw(requestParameters);
    }

}
