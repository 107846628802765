import { interiorRoutes } from "../../../../common/routes"
import { interiorCustomizationApi } from "../../../../pdsapi"
import { getCustomizationDetailsContainer } from "../../../../viewtemplates/details/CustomizationDetailsContainer"
import { ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLinkedOptionsCard } from "../../../../viewtemplates/details/cards/linkedoptionscard/DetailsLinkedOptionsCard"
import { DetailsVehicleImagesCard } from "../../../../viewtemplates/details/cards/vehicleimagescard/DetailsVehicleImagesCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { DetailsOptionCustomizationLocalizationTab } from "../../../../viewtemplates/details/tabs/optioncustomizationlocalizationtab/DetailsOptionCustomizationLocalizationTab"
import { InteriorCustomizationDetailsAttributesCardContent } from "./data/attributes/InteriorCustomizationDetailsAttributesCardContent"

export const InteriorCustomizationProductDetailsContainerConfig: ProductDetailsContainerConfig = {
    getBaseInformation: interiorCustomizationApi.details.getBaseInformation,
    deleteByKey: interiorCustomizationApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={interiorCustomizationApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            interiorCustomizationApi.details.updateAttributes({
                                key,
                                optionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO: update,
                            })
                        }
                        Content={InteriorCustomizationDetailsAttributesCardContent}
                    />
                    <DetailsLinkedOptionsCard {...props} getData={interiorCustomizationApi.details.getLinks} getRoute={interiorRoutes.details} />
                    <DetailsVehicleImagesCard {...props} load={interiorCustomizationApi.details.getVehicleImages} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={interiorCustomizationApi.details.getName}
                    updateDefaultValue={interiorCustomizationApi.details.updateDefaultName}
                    updateConditionalValue={interiorCustomizationApi.details.updateConditionalValueName}
                />
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={interiorCustomizationApi.details.getDescription}
                    updateDefaultValue={interiorCustomizationApi.details.updateDefaultDescription}
                    updateConditionalValue={interiorCustomizationApi.details.updateConditionalValueDescription}
                />
            ),
        },
    ],
}

export const InteriorCustomizationDetailsContainer = getCustomizationDetailsContainer(InteriorCustomizationProductDetailsContainerConfig)
