/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InteriorCustomizationItemDTOValue,
    InteriorCustomizationItemDTOValueFromJSON,
    InteriorCustomizationItemDTOValueFromJSONTyped,
    InteriorCustomizationItemDTOValueToJSON,
    OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValue,
    OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValueFromJSON,
    OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface InteriorCustomizationItemDTO
 */
export interface InteriorCustomizationItemDTO {
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationItemDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationItemDTO
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InteriorCustomizationItemDTO
     */
    modelGenerationNames: Array<string>;
    /**
     * 
     * @type {InteriorCustomizationItemDTOValue}
     * @memberof InteriorCustomizationItemDTO
     */
    defaultValue: InteriorCustomizationItemDTOValue;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValue>}
     * @memberof InteriorCustomizationItemDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValue>;
}

export function InteriorCustomizationItemDTOFromJSON(json: any): InteriorCustomizationItemDTO {
    return InteriorCustomizationItemDTOFromJSONTyped(json, false);
}

export function InteriorCustomizationItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorCustomizationItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'optionCode': json['optionCode'],
        'modelGenerationNames': json['modelGenerationNames'],
        'defaultValue': InteriorCustomizationItemDTOValueFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValueFromJSON)),
    };
}

export function InteriorCustomizationItemDTOToJSON(value?: InteriorCustomizationItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'optionCode': value.optionCode,
        'modelGenerationNames': value.modelGenerationNames,
        'defaultValue': InteriorCustomizationItemDTOValueToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValueToJSON)),
    };
}


