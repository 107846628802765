/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    ExteriorColorOptionDTO,
    ExteriorColorOptionDTOFromJSON,
    ExteriorColorOptionDTOToJSON,
    ExteriorColorOptionUpdateDTO,
    ExteriorColorOptionUpdateDTOFromJSON,
    ExteriorColorOptionUpdateDTOToJSON,
    LinkedExteriorColorCustomizationDTO,
    LinkedExteriorColorCustomizationDTOFromJSON,
    LinkedExteriorColorCustomizationDTOToJSON,
    SimilarExteriorColorCustomizationsDTO,
    SimilarExteriorColorCustomizationsDTOFromJSON,
    SimilarExteriorColorCustomizationsDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface GetLinkedCustomizationOfExteriorColorRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

export interface GetOptionOfExteriorColorRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

export interface GetSimilarCustomizationsOfExteriorColorRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

export interface UpdateOptionOfExteriorColorRequest {
    orderTypeKey: string;
    optionCode: string;
    exteriorColorOptionUpdateDTO: ExteriorColorOptionUpdateDTO;
}

/**
 * 
 */
export class OrderTypeExteriorColorDataDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get linkedCustomization of ExteriorColor with given key
     */
    async getLinkedCustomizationOfExteriorColorRaw(requestParameters: GetLinkedCustomizationOfExteriorColorRequest): Promise<runtime.ApiResponse<LinkedExteriorColorCustomizationDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getLinkedCustomizationOfExteriorColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getLinkedCustomizationOfExteriorColor.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinkedCustomizationOfExteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/exterior-color/data/linked-customization`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkedExteriorColorCustomizationDTOFromJSON(jsonValue));
    }

    /**
     * Get linkedCustomization of ExteriorColor with given key
     */
    async getLinkedCustomizationOfExteriorColor(requestParameters: GetLinkedCustomizationOfExteriorColorRequest): Promise<LinkedExteriorColorCustomizationDTO> {
        const response = await this.getLinkedCustomizationOfExteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get option of ExteriorColor with given key
     */
    async getOptionOfExteriorColorRaw(requestParameters: GetOptionOfExteriorColorRequest): Promise<runtime.ApiResponse<ExteriorColorOptionDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getOptionOfExteriorColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getOptionOfExteriorColor.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOptionOfExteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/exterior-color/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExteriorColorOptionDTOFromJSON(jsonValue));
    }

    /**
     * Get option of ExteriorColor with given key
     */
    async getOptionOfExteriorColor(requestParameters: GetOptionOfExteriorColorRequest): Promise<ExteriorColorOptionDTO> {
        const response = await this.getOptionOfExteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get similarCustomizations of ExteriorColor with given key
     */
    async getSimilarCustomizationsOfExteriorColorRaw(requestParameters: GetSimilarCustomizationsOfExteriorColorRequest): Promise<runtime.ApiResponse<SimilarExteriorColorCustomizationsDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getSimilarCustomizationsOfExteriorColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getSimilarCustomizationsOfExteriorColor.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getSimilarCustomizationsOfExteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/exterior-color/data/similar-customizations`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SimilarExteriorColorCustomizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get similarCustomizations of ExteriorColor with given key
     */
    async getSimilarCustomizationsOfExteriorColor(requestParameters: GetSimilarCustomizationsOfExteriorColorRequest): Promise<SimilarExteriorColorCustomizationsDTO> {
        const response = await this.getSimilarCustomizationsOfExteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates option of an existing ExteriorColor
     */
    async updateOptionOfExteriorColorRaw(requestParameters: UpdateOptionOfExteriorColorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling updateOptionOfExteriorColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling updateOptionOfExteriorColor.');
        }

        if (requestParameters.exteriorColorOptionUpdateDTO === null || requestParameters.exteriorColorOptionUpdateDTO === undefined) {
            throw new runtime.RequiredError('exteriorColorOptionUpdateDTO','Required parameter requestParameters.exteriorColorOptionUpdateDTO was null or undefined when calling updateOptionOfExteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/exterior-color/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExteriorColorOptionUpdateDTOToJSON(requestParameters.exteriorColorOptionUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates option of an existing ExteriorColor
     */
    async updateOptionOfExteriorColor(requestParameters: UpdateOptionOfExteriorColorRequest): Promise<void> {
        await this.updateOptionOfExteriorColorRaw(requestParameters);
    }

}
