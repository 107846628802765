/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InteriorCustomizationAttributesValueUpdateDTOColor
 */
export interface InteriorCustomizationAttributesValueUpdateDTOColor {
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesValueUpdateDTOColor
     */
    seatColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesValueUpdateDTOColor
     */
    seatStitchingColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesValueUpdateDTOColor
     */
    cockpitColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesValueUpdateDTOColor
     */
    cockpitStitchingColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesValueUpdateDTOColor
     */
    tagKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InteriorCustomizationAttributesValueUpdateDTOColor
     */
    isCustomColor: boolean;
}

export function InteriorCustomizationAttributesValueUpdateDTOColorFromJSON(json: any): InteriorCustomizationAttributesValueUpdateDTOColor {
    return InteriorCustomizationAttributesValueUpdateDTOColorFromJSONTyped(json, false);
}

export function InteriorCustomizationAttributesValueUpdateDTOColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorCustomizationAttributesValueUpdateDTOColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seatColorHexCode': json['seatColorHexCode'],
        'seatStitchingColorHexCode': json['seatStitchingColorHexCode'],
        'cockpitColorHexCode': json['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': json['cockpitStitchingColorHexCode'],
        'tagKey': !exists(json, 'tagKey') ? undefined : json['tagKey'],
        'isCustomColor': json['isCustomColor'],
    };
}

export function InteriorCustomizationAttributesValueUpdateDTOColorToJSON(value?: InteriorCustomizationAttributesValueUpdateDTOColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seatColorHexCode': value.seatColorHexCode,
        'seatStitchingColorHexCode': value.seatStitchingColorHexCode,
        'cockpitColorHexCode': value.cockpitColorHexCode,
        'cockpitStitchingColorHexCode': value.cockpitStitchingColorHexCode,
        'tagKey': value.tagKey,
        'isCustomColor': value.isCustomColor,
    };
}


