import { PText } from "@porsche-design-system/components-react"
import { color } from "@porsche-design-system/utilities"
import { FC } from "react"
import styled from "styled-components"

export interface SelectableButtonProps {
    label: string
    width?: string
    height?: string
    sidePadding?: string
    isSelected: boolean
    selectionLineHeight: string
    onClick?: () => void
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    onClickText?: () => void
    onMouseEnterText?: () => void
    onMouseLeaveText?: () => void
}

export const SelectableButton: FC<SelectableButtonProps> = (props) => (
    <ButtonStyleWrapper
        width={props.width}
        height={props.height}
        sidePadding={props.sidePadding}
        selectionLineHeight={props.selectionLineHeight}
        isSelected={props.isSelected}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
    >
        <StyledPText size={"small"} color={"inherit"} onClick={props.onClickText} onMouseEnter={props.onMouseEnterText} onMouseLeave={props.onMouseLeaveText}>
            {props.label}
        </StyledPText>
    </ButtonStyleWrapper>
)

interface ButtonStyleWrapperProps {
    height?: string
    width?: string
    sidePadding?: string
    selectionLineHeight: string
    isSelected: boolean
}

const ButtonStyleWrapper = styled.div<ButtonStyleWrapperProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    padding-left: ${(props) => (props.sidePadding ? props.sidePadding : "")};
    padding-right: ${(props) => (props.sidePadding ? props.sidePadding : "")};

    height: ${(props) => props.height};
    ${(props) => (props.width ? `width: ${props.width ?? "100%"};` : "")}

    ${(props) => (props.isSelected ? `border-bottom: ${color.state.active} solid ${props.selectionLineHeight};` : "")}

    &:hover {
        color: ${color.state.hover};
    }
`

const StyledPText = styled(PText)`
    white-space: nowrap;
`
