import { Bool2Selector } from "../../selectors/bool2selector/Bool2Selector"
import { DefaultFormElementLoading, EditLabeledContentWrapper, EditProps, FormElement, ViewLabeledContentWrapper, ViewProps } from "../FormElementWrapper"
import { PText } from "@porsche-design-system/components-react"
import { Controller, FieldValues } from "react-hook-form"

export const getBoolFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    boolean,
    BoolFormElementViewProps,
    BoolFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: BoolFormElementView,
    Edit: BoolFormElementEdit,
})

export interface BoolFormElementViewProps {
    value: boolean

    trueMessage: string
    falseMessage: string
}

export const BoolFormElementView = ({ label, value, trueMessage, falseMessage }: ViewProps<BoolFormElementViewProps>) => (
    <ViewLabeledContentWrapper label={label}>
        <PText>{value ? trueMessage : falseMessage}</PText>
    </ViewLabeledContentWrapper>
)

export interface BoolFormElementEditProps {
    value: boolean

    trueMessage: string
    falseMessage: string

    readOnly?: boolean
}

export const BoolFormElementEdit = <DATA, UPDATE extends FieldValues>({
    label,
    value,
    trueMessage,
    falseMessage,
    readOnly,
    control,
    field,
}: EditProps<DATA, UPDATE, boolean, BoolFormElementEditProps>) => (
    <EditLabeledContentWrapper label={label}>
        <Controller
            control={control}
            name={field._path}
            defaultValue={value as any}
            render={({ field }) => (
                <Bool2Selector trueMessage={trueMessage} falseMessage={falseMessage} value={field.value as any} onChange={field.onChange} readOnly={readOnly} />
            )}
        />
    </EditLabeledContentWrapper>
)
