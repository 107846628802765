import { modelSeriesRoutes } from "../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter } from "../../../../common/useURLSearchParamState"
import { ModelSeriesItemDTO, SortDirection } from "../../../../generated/pdsapi"
import { modelSeriesApi, ModelSeriesFilter, ModelSeriesSortField, ModelSeriesSortFieldEnum } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { ModelSeriesListColumnsConfig } from "./columns/ModelSeriesListColumns"
import { ModelSeriesListFilter } from "./filter/ModelSeriesListFilter"
import { ModelSeriesListFilterTagsConfig } from "./filter/ModelSeriesListFilterTagsConfig"

export const ModelSeriesProductListContainerConfig: ProductListContainerConfig<ModelSeriesItemDTO, ModelSeriesFilter, unknown, ModelSeriesSortFieldEnum> = {
    listName: "Model Series",
    createItemLabel: "Add new Model Series",

    buildDetailsPath: (modelSeries) => modelSeriesRoutes.details(modelSeries.key),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<ModelSeriesFilter>({}),
    defaultSorting: {
        sortField: ModelSeriesSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(ModelSeriesSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: modelSeriesApi.list.getPage,
    fetchFilterOptions: async () => ({}),

    defaultShowsFilter: false,
    Filter: ModelSeriesListFilter,
    filterTagsConfig: ModelSeriesListFilterTagsConfig,

    columnsConfig: ModelSeriesListColumnsConfig,
}

export const ModelSeriesListContainer = getProductListContainer(ModelSeriesProductListContainerConfig)
