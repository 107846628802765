import { getLanguageGroups } from "../../../../../common/getLanguageGroups"
import { propertiesOf } from "../../../../../common/property"
import { FormElementWrapper } from "../../../../../components/formelements/FormElementWrapper"
import { getTextFormElement } from "../../../../../components/formelements/textformelement/TextFormElement"
import { RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { LocalizationsDTO, LocalizationsUpdateDTO } from "../../../../../pdsapi"
import { ProductDetailsCardContentProps } from "../../ProductDetailsCard"
import { spacing } from "@porsche-design-system/utilities"
import { FC, useMemo } from "react"
import { useIntl } from "react-intl"

export const DetailsLocalizationCardContent: FC<ProductDetailsCardContentProps<LocalizationsDTO, LocalizationsUpdateDTO>> = ({ formDisplayData }) => {
    const intl = useIntl()
    const properties = propertiesOf<LocalizationsUpdateDTO>()

    const languageGroups = useMemo(() => getLanguageGroups(Object.keys(formDisplayData.data ?? {}), intl), [formDisplayData.data, intl])

    return (
        <SpacedItems lineSpacing={spacing[32]} direction={"column"}>
            {languageGroups.map(({ label, languages }) => (
                <RowGroupWrapper key={label} label={label}>
                    {languages.map(({ label, languageTag }) => (
                        <FormElementWrapper
                            key={languageTag}
                            label={label}
                            formDisplayData={formDisplayData}
                            field={properties._get(languageTag)}
                            FormElement={getTextFormElement<LocalizationsDTO, LocalizationsUpdateDTO>()}
                            getViewProps={(data) => ({
                                value: data[languageTag].withFallback,
                                showAsHint: !data[languageTag].raw,
                            })}
                            getEditProps={(data) => ({
                                value: data[languageTag]?.raw ?? "",
                                optional: true,
                            })}
                        />
                    ))}
                </RowGroupWrapper>
            ))}
        </SpacedItems>
    )
}
