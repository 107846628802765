/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOString,
    OptionDTOStringFromJSON,
    OptionDTOStringFromJSONTyped,
    OptionDTOStringToJSON,
    OptionGroupDTOString,
    OptionGroupDTOStringFromJSON,
    OptionGroupDTOStringFromJSONTyped,
    OptionGroupDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface EquipmentCustomizationFilterOptionsDTO
 */
export interface EquipmentCustomizationFilterOptionsDTO {
    /**
     * 
     * @type {Array<OptionDTOString>}
     * @memberof EquipmentCustomizationFilterOptionsDTO
     */
    modelGenerations: Array<OptionDTOString>;
    /**
     * 
     * @type {Array<OptionGroupDTOString>}
     * @memberof EquipmentCustomizationFilterOptionsDTO
     */
    categorizedEquipmentTags: Array<OptionGroupDTOString>;
}

export function EquipmentCustomizationFilterOptionsDTOFromJSON(json: any): EquipmentCustomizationFilterOptionsDTO {
    return EquipmentCustomizationFilterOptionsDTOFromJSONTyped(json, false);
}

export function EquipmentCustomizationFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentCustomizationFilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelGenerations': ((json['modelGenerations'] as Array<any>).map(OptionDTOStringFromJSON)),
        'categorizedEquipmentTags': ((json['categorizedEquipmentTags'] as Array<any>).map(OptionGroupDTOStringFromJSON)),
    };
}

export function EquipmentCustomizationFilterOptionsDTOToJSON(value?: EquipmentCustomizationFilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelGenerations': ((value.modelGenerations as Array<any>).map(OptionDTOStringToJSON)),
        'categorizedEquipmentTags': ((value.categorizedEquipmentTags as Array<any>).map(OptionGroupDTOStringToJSON)),
    };
}


