/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OrderTypeFilterOptionsDTO,
    OrderTypeFilterOptionsDTOFromJSON,
    OrderTypeFilterOptionsDTOToJSON,
    OrderTypeSortField,
    OrderTypeSortFieldFromJSON,
    OrderTypeSortFieldToJSON,
    PageDTOOrderTypeItemDTO,
    PageDTOOrderTypeItemDTOFromJSON,
    PageDTOOrderTypeItemDTOToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetOrderTypeFilterOptionsRequest {
    languageTag: string;
}

export interface GetOrderTypesPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: OrderTypeSortField;
    sortDirection: SortDirection;
    search?: string;
    modelSeriesKeys?: Array<string>;
    modelGenerationKeys?: Array<string>;
    derivativeKeys?: Array<string>;
}

/**
 * 
 */
export class OrderTypeListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter OrderTypes
     */
    async getOrderTypeFilterOptionsRaw(requestParameters: GetOrderTypeFilterOptionsRequest): Promise<runtime.ApiResponse<OrderTypeFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOrderTypeFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter OrderTypes
     */
    async getOrderTypeFilterOptions(requestParameters: GetOrderTypeFilterOptionsRequest): Promise<OrderTypeFilterOptionsDTO> {
        const response = await this.getOrderTypeFilterOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get page of OrderTypes
     */
    async getOrderTypesPageRaw(requestParameters: GetOrderTypesPageRequest): Promise<runtime.ApiResponse<PageDTOOrderTypeItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOrderTypesPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getOrderTypesPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getOrderTypesPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getOrderTypesPage.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getOrderTypesPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.modelSeriesKeys) {
            queryParameters['modelSeriesKeys'] = requestParameters.modelSeriesKeys;
        }

        if (requestParameters.modelGenerationKeys) {
            queryParameters['modelGenerationKeys'] = requestParameters.modelGenerationKeys;
        }

        if (requestParameters.derivativeKeys) {
            queryParameters['derivativeKeys'] = requestParameters.derivativeKeys;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOOrderTypeItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of OrderTypes
     */
    async getOrderTypesPage(requestParameters: GetOrderTypesPageRequest): Promise<PageDTOOrderTypeItemDTO> {
        const response = await this.getOrderTypesPageRaw(requestParameters);
        return await response.value();
    }

}
