import { Box } from "../../layouts/box/Box"
import { SpacedItems } from "../../layouts/spaceditems/SpacedItems"
import { IconName, PButton, PModal, PText } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC } from "react"

interface ConfirmationModalProps {
    headline: string
    content: string
    confirmIcon: IconName
    confirmLabel: string
    onCancel: () => void
    onConfirm: () => void
    isOpen: boolean
}

export const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
    return (
        <PModal open={props.isOpen} onClose={props.onCancel} heading={props.headline}>
            <Box width={"400px"}>
                <SpacedItems direction={"column"} lineSpacing={spacing[32]}>
                    <PText>{props.content}</PText>
                    <SpacedItems direction={"row"} justifyContent={"flex-end"} itemSpacing={spacing[16]}>
                        <PButton onClick={props.onCancel} icon={"close"} variant={"tertiary"}>
                            Cancel
                        </PButton>
                        <PButton onClick={props.onConfirm} icon={props.confirmIcon}>
                            {props.confirmLabel}
                        </PButton>
                    </SpacedItems>
                </SpacedItems>
            </Box>
        </PModal>
    )
}
