/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PageDTOIncompleteCustomizationDTO,
    PageDTOIncompleteCustomizationDTOFromJSON,
    PageDTOIncompleteCustomizationDTOToJSON,
    PageDTOIncompleteOrderTypeDTO,
    PageDTOIncompleteOrderTypeDTOFromJSON,
    PageDTOIncompleteOrderTypeDTOToJSON,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomizationFromJSON,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomizationToJSON,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSON,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSON,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSON,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSON,
    PageDTOUnmaintainedCustomColorDTO,
    PageDTOUnmaintainedCustomColorDTOFromJSON,
    PageDTOUnmaintainedCustomColorDTOToJSON,
} from '../models';

export interface GetIncompleteExteriorColorCustomizationsRequest {
    languageTag: string;
    offset: number;
    size: number;
}

export interface GetIncompleteInteriorCustomizationsRequest {
    languageTag: string;
    offset: number;
    size: number;
}

export interface GetIncompleteOrderTypesRequest {
    offset: number;
    size: number;
}

export interface GetMissingExteriorColorCustomizationsRequest {
    languageTag: string;
    offset: number;
    size: number;
}

export interface GetMissingInteriorCustomizationsRequest {
    languageTag: string;
    offset: number;
    size: number;
}

export interface GetMissingRoofColorCustomizationsRequest {
    languageTag: string;
    offset: number;
    size: number;
}

export interface GetUnmaintainedCustomExteriorColorsRequest {
    offset: number;
    size: number;
}

export interface GetUnmaintainedCustomInteriorColorsRequest {
    offset: number;
    size: number;
}

/**
 * 
 */
export class DashboardWebControllerApi extends runtime.BaseAPI {

    /**
     * Lists all incompleteExteriorColorCustomizations
     */
    async getIncompleteExteriorColorCustomizationsRaw(requestParameters: GetIncompleteExteriorColorCustomizationsRequest): Promise<runtime.ApiResponse<PageDTOIncompleteCustomizationDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getIncompleteExteriorColorCustomizations.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getIncompleteExteriorColorCustomizations.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getIncompleteExteriorColorCustomizations.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/incomplete-exterior-color-customizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOIncompleteCustomizationDTOFromJSON(jsonValue));
    }

    /**
     * Lists all incompleteExteriorColorCustomizations
     */
    async getIncompleteExteriorColorCustomizations(requestParameters: GetIncompleteExteriorColorCustomizationsRequest): Promise<PageDTOIncompleteCustomizationDTO> {
        const response = await this.getIncompleteExteriorColorCustomizationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all incompleteInteriorCustomizations
     */
    async getIncompleteInteriorCustomizationsRaw(requestParameters: GetIncompleteInteriorCustomizationsRequest): Promise<runtime.ApiResponse<PageDTOIncompleteCustomizationDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getIncompleteInteriorCustomizations.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getIncompleteInteriorCustomizations.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getIncompleteInteriorCustomizations.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/incomplete-interior-customizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOIncompleteCustomizationDTOFromJSON(jsonValue));
    }

    /**
     * Lists all incompleteInteriorCustomizations
     */
    async getIncompleteInteriorCustomizations(requestParameters: GetIncompleteInteriorCustomizationsRequest): Promise<PageDTOIncompleteCustomizationDTO> {
        const response = await this.getIncompleteInteriorCustomizationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all incompleteOrderTypes
     */
    async getIncompleteOrderTypesRaw(requestParameters: GetIncompleteOrderTypesRequest): Promise<runtime.ApiResponse<PageDTOIncompleteOrderTypeDTO>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getIncompleteOrderTypes.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getIncompleteOrderTypes.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/incomplete-order-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOIncompleteOrderTypeDTOFromJSON(jsonValue));
    }

    /**
     * Lists all incompleteOrderTypes
     */
    async getIncompleteOrderTypes(requestParameters: GetIncompleteOrderTypesRequest): Promise<PageDTOIncompleteOrderTypeDTO> {
        const response = await this.getIncompleteOrderTypesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all missingExteriorColorCustomizations
     */
    async getMissingExteriorColorCustomizationsRaw(requestParameters: GetMissingExteriorColorCustomizationsRequest): Promise<runtime.ApiResponse<PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getMissingExteriorColorCustomizations.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getMissingExteriorColorCustomizations.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getMissingExteriorColorCustomizations.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/missing-exterior-color-customizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomizationFromJSON(jsonValue));
    }

    /**
     * Lists all missingExteriorColorCustomizations
     */
    async getMissingExteriorColorCustomizations(requestParameters: GetMissingExteriorColorCustomizationsRequest): Promise<PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization> {
        const response = await this.getMissingExteriorColorCustomizationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all missingInteriorCustomizations
     */
    async getMissingInteriorCustomizationsRaw(requestParameters: GetMissingInteriorCustomizationsRequest): Promise<runtime.ApiResponse<PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getMissingInteriorCustomizations.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getMissingInteriorCustomizations.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getMissingInteriorCustomizations.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/missing-interior-customizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSON(jsonValue));
    }

    /**
     * Lists all missingInteriorCustomizations
     */
    async getMissingInteriorCustomizations(requestParameters: GetMissingInteriorCustomizationsRequest): Promise<PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization> {
        const response = await this.getMissingInteriorCustomizationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all missingRoofColorCustomizations
     */
    async getMissingRoofColorCustomizationsRaw(requestParameters: GetMissingRoofColorCustomizationsRequest): Promise<runtime.ApiResponse<PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getMissingRoofColorCustomizations.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getMissingRoofColorCustomizations.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getMissingRoofColorCustomizations.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/missing-roof-color-customizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSON(jsonValue));
    }

    /**
     * Lists all missingRoofColorCustomizations
     */
    async getMissingRoofColorCustomizations(requestParameters: GetMissingRoofColorCustomizationsRequest): Promise<PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization> {
        const response = await this.getMissingRoofColorCustomizationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all unmaintainedCustomExteriorColors
     */
    async getUnmaintainedCustomExteriorColorsRaw(requestParameters: GetUnmaintainedCustomExteriorColorsRequest): Promise<runtime.ApiResponse<PageDTOUnmaintainedCustomColorDTO>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getUnmaintainedCustomExteriorColors.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getUnmaintainedCustomExteriorColors.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/unmaintained-custom-exterior-colors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOUnmaintainedCustomColorDTOFromJSON(jsonValue));
    }

    /**
     * Lists all unmaintainedCustomExteriorColors
     */
    async getUnmaintainedCustomExteriorColors(requestParameters: GetUnmaintainedCustomExteriorColorsRequest): Promise<PageDTOUnmaintainedCustomColorDTO> {
        const response = await this.getUnmaintainedCustomExteriorColorsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all unmaintainedCustomInteriorColors
     */
    async getUnmaintainedCustomInteriorColorsRaw(requestParameters: GetUnmaintainedCustomInteriorColorsRequest): Promise<runtime.ApiResponse<PageDTOUnmaintainedCustomColorDTO>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getUnmaintainedCustomInteriorColors.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getUnmaintainedCustomInteriorColors.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/unmaintained-custom-interior-colors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOUnmaintainedCustomColorDTOFromJSON(jsonValue));
    }

    /**
     * Lists all unmaintainedCustomInteriorColors
     */
    async getUnmaintainedCustomInteriorColors(requestParameters: GetUnmaintainedCustomInteriorColorsRequest): Promise<PageDTOUnmaintainedCustomColorDTO> {
        const response = await this.getUnmaintainedCustomInteriorColorsRaw(requestParameters);
        return await response.value();
    }

}
