/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    OrderTypeMediaDTO,
    OrderTypeMediaDTOFromJSON,
    OrderTypeMediaDTOToJSON,
    OrderTypeMediaRelatedDataDTO,
    OrderTypeMediaRelatedDataDTOFromJSON,
    OrderTypeMediaRelatedDataDTOToJSON,
    OrderTypeMediaUpdateDTO,
    OrderTypeMediaUpdateDTOFromJSON,
    OrderTypeMediaUpdateDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface GetEngineSoundsOfSoundOfMediaRequest {
    key: string;
}

export interface GetRelatedDataOfMediaRequest {
    key: string;
}

export interface UpdateOfEngineSoundsOfSoundOfMediaRequest {
    key: string;
    orderTypeMediaUpdateDTO: OrderTypeMediaUpdateDTO;
}

/**
 * 
 */
export class OrderTypeMediaDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get engineSoundsOfSound of Media with given key
     */
    async getEngineSoundsOfSoundOfMediaRaw(requestParameters: GetEngineSoundsOfSoundOfMediaRequest): Promise<runtime.ApiResponse<OrderTypeMediaDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getEngineSoundsOfSoundOfMedia.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/media/details/{key}/media/sound/engine-sounds`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeMediaDTOFromJSON(jsonValue));
    }

    /**
     * Get engineSoundsOfSound of Media with given key
     */
    async getEngineSoundsOfSoundOfMedia(requestParameters: GetEngineSoundsOfSoundOfMediaRequest): Promise<OrderTypeMediaDTO> {
        const response = await this.getEngineSoundsOfSoundOfMediaRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get relatedData of Media with given key
     */
    async getRelatedDataOfMediaRaw(requestParameters: GetRelatedDataOfMediaRequest): Promise<runtime.ApiResponse<OrderTypeMediaRelatedDataDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getRelatedDataOfMedia.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/media/details/{key}/media/related-data`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeMediaRelatedDataDTOFromJSON(jsonValue));
    }

    /**
     * Get relatedData of Media with given key
     */
    async getRelatedDataOfMedia(requestParameters: GetRelatedDataOfMediaRequest): Promise<OrderTypeMediaRelatedDataDTO> {
        const response = await this.getRelatedDataOfMediaRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates OfEngineSoundsOfSound of an existing Media
     */
    async updateOfEngineSoundsOfSoundOfMediaRaw(requestParameters: UpdateOfEngineSoundsOfSoundOfMediaRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateOfEngineSoundsOfSoundOfMedia.');
        }

        if (requestParameters.orderTypeMediaUpdateDTO === null || requestParameters.orderTypeMediaUpdateDTO === undefined) {
            throw new runtime.RequiredError('orderTypeMediaUpdateDTO','Required parameter requestParameters.orderTypeMediaUpdateDTO was null or undefined when calling updateOfEngineSoundsOfSoundOfMedia.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/media/details/{key}/media/sound/engine-sounds/`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderTypeMediaUpdateDTOToJSON(requestParameters.orderTypeMediaUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates OfEngineSoundsOfSound of an existing Media
     */
    async updateOfEngineSoundsOfSoundOfMedia(requestParameters: UpdateOfEngineSoundsOfSoundOfMediaRequest): Promise<void> {
        await this.updateOfEngineSoundsOfSoundOfMediaRaw(requestParameters);
    }

}
