/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EquipmentItemDTO
 */
export interface EquipmentItemDTO {
    /**
     * 
     * @type {string}
     * @memberof EquipmentItemDTO
     */
    orderTypeKey: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentItemDTO
     */
    imageKey?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EquipmentItemDTO
     */
    orderTypeCodes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EquipmentItemDTO
     */
    modelYear: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentItemDTO
     */
    optionCode: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentItemDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentItemDTO
     */
    categoryName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EquipmentItemDTO
     */
    tagNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EquipmentItemDTO
     */
    modelGenerationName?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentItemDTO
     */
    family?: string;
}

export function EquipmentItemDTOFromJSON(json: any): EquipmentItemDTO {
    return EquipmentItemDTOFromJSONTyped(json, false);
}

export function EquipmentItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderTypeKey': json['orderTypeKey'],
        'imageKey': !exists(json, 'imageKey') ? undefined : json['imageKey'],
        'orderTypeCodes': json['orderTypeCodes'],
        'modelYear': json['modelYear'],
        'optionCode': json['optionCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'categoryName': !exists(json, 'categoryName') ? undefined : json['categoryName'],
        'tagNames': json['tagNames'],
        'modelGenerationName': !exists(json, 'modelGenerationName') ? undefined : json['modelGenerationName'],
        'family': !exists(json, 'family') ? undefined : json['family'],
    };
}

export function EquipmentItemDTOToJSON(value?: EquipmentItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderTypeKey': value.orderTypeKey,
        'imageKey': value.imageKey,
        'orderTypeCodes': value.orderTypeCodes,
        'modelYear': value.modelYear,
        'optionCode': value.optionCode,
        'name': value.name,
        'categoryName': value.categoryName,
        'tagNames': value.tagNames,
        'modelGenerationName': value.modelGenerationName,
        'family': value.family,
    };
}


