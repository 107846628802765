/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOInteger,
    OptionDTOIntegerFromJSON,
    OptionDTOIntegerFromJSONTyped,
    OptionDTOIntegerToJSON,
    OptionDTOInteriorMaterial,
    OptionDTOInteriorMaterialFromJSON,
    OptionDTOInteriorMaterialFromJSONTyped,
    OptionDTOInteriorMaterialToJSON,
    OptionDTOString,
    OptionDTOStringFromJSON,
    OptionDTOStringFromJSONTyped,
    OptionDTOStringToJSON,
    RelatedGroupedRelatedOptionDTOIntegerStringString,
    RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSON,
    RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSONTyped,
    RelatedGroupedRelatedOptionDTOIntegerStringStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface InteriorFilterOptionsDTO
 */
export interface InteriorFilterOptionsDTO {
    /**
     * 
     * @type {Array<OptionDTOString>}
     * @memberof InteriorFilterOptionsDTO
     */
    modelGenerations: Array<OptionDTOString>;
    /**
     * 
     * @type {Array<OptionDTOInteger>}
     * @memberof InteriorFilterOptionsDTO
     */
    modelYears: Array<OptionDTOInteger>;
    /**
     * 
     * @type {Array<RelatedGroupedRelatedOptionDTOIntegerStringString>}
     * @memberof InteriorFilterOptionsDTO
     */
    modelYearAndGenerationRelatedOrderTypes: Array<RelatedGroupedRelatedOptionDTOIntegerStringString>;
    /**
     * 
     * @type {Array<OptionDTOInteriorMaterial>}
     * @memberof InteriorFilterOptionsDTO
     */
    materials: Array<OptionDTOInteriorMaterial>;
    /**
     * 
     * @type {Array<OptionDTOString>}
     * @memberof InteriorFilterOptionsDTO
     */
    colorTags: Array<OptionDTOString>;
}

export function InteriorFilterOptionsDTOFromJSON(json: any): InteriorFilterOptionsDTO {
    return InteriorFilterOptionsDTOFromJSONTyped(json, false);
}

export function InteriorFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorFilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelGenerations': ((json['modelGenerations'] as Array<any>).map(OptionDTOStringFromJSON)),
        'modelYears': ((json['modelYears'] as Array<any>).map(OptionDTOIntegerFromJSON)),
        'modelYearAndGenerationRelatedOrderTypes': ((json['modelYearAndGenerationRelatedOrderTypes'] as Array<any>).map(RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSON)),
        'materials': ((json['materials'] as Array<any>).map(OptionDTOInteriorMaterialFromJSON)),
        'colorTags': ((json['colorTags'] as Array<any>).map(OptionDTOStringFromJSON)),
    };
}

export function InteriorFilterOptionsDTOToJSON(value?: InteriorFilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelGenerations': ((value.modelGenerations as Array<any>).map(OptionDTOStringToJSON)),
        'modelYears': ((value.modelYears as Array<any>).map(OptionDTOIntegerToJSON)),
        'modelYearAndGenerationRelatedOrderTypes': ((value.modelYearAndGenerationRelatedOrderTypes as Array<any>).map(RelatedGroupedRelatedOptionDTOIntegerStringStringToJSON)),
        'materials': ((value.materials as Array<any>).map(OptionDTOInteriorMaterialToJSON)),
        'colorTags': ((value.colorTags as Array<any>).map(OptionDTOStringToJSON)),
    };
}


