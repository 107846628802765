/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    CreateResponseDTO,
    CreateResponseDTOFromJSON,
    CreateResponseDTOToJSON,
    CustomExteriorColorCreateOptionsDTO,
    CustomExteriorColorCreateOptionsDTOFromJSON,
    CustomExteriorColorCreateOptionsDTOToJSON,
    CustomExteriorColorCreateRequestDTO,
    CustomExteriorColorCreateRequestDTOFromJSON,
    CustomExteriorColorCreateRequestDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface CreateCustomExteriorColorRequest {
    customExteriorColorCreateRequestDTO: CustomExteriorColorCreateRequestDTO;
}

export interface GetCustomExteriorColorCreateOptionsRequest {
    languageTag: string;
}

/**
 * 
 */
export class CustomExteriorColorCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new CustomExteriorColor
     */
    async createCustomExteriorColorRaw(requestParameters: CreateCustomExteriorColorRequest): Promise<runtime.ApiResponse<CreateResponseDTO>> {
        if (requestParameters.customExteriorColorCreateRequestDTO === null || requestParameters.customExteriorColorCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('customExteriorColorCreateRequestDTO','Required parameter requestParameters.customExteriorColorCreateRequestDTO was null or undefined when calling createCustomExteriorColor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/custom-exterior-colors/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomExteriorColorCreateRequestDTOToJSON(requestParameters.customExteriorColorCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResponseDTOFromJSON(jsonValue));
    }

    /**
     * Create a new CustomExteriorColor
     */
    async createCustomExteriorColor(requestParameters: CreateCustomExteriorColorRequest): Promise<CreateResponseDTO> {
        const response = await this.createCustomExteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get select options to create CustomExteriorColors
     */
    async getCustomExteriorColorCreateOptionsRaw(requestParameters: GetCustomExteriorColorCreateOptionsRequest): Promise<runtime.ApiResponse<CustomExteriorColorCreateOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getCustomExteriorColorCreateOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-exterior-colors/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomExteriorColorCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create CustomExteriorColors
     */
    async getCustomExteriorColorCreateOptions(requestParameters: GetCustomExteriorColorCreateOptionsRequest): Promise<CustomExteriorColorCreateOptionsDTO> {
        const response = await this.getCustomExteriorColorCreateOptionsRaw(requestParameters);
        return await response.value();
    }

}
