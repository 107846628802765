import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { DerivativeCategoryCreateContainer } from "./create/DerivativeCategoryCreateContainer"
import { DerivativeCategoryDetailsContainer } from "./details/DerivativeCategoryDetailsContainer"
import { DerivativeCategoryListContainer } from "./list/DerivativeCategoryListContainer"
import { DerivativeCategorySortContainer } from "./sort/DerivativeCategorySortContainer"
import { FC } from "react"

export const DerivativeCategoryRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Derivative Categories",
        List: DerivativeCategoryListContainer,
        Details: DerivativeCategoryDetailsContainer,
        Create: DerivativeCategoryCreateContainer,
        Sort: DerivativeCategorySortContainer,
    })
