import { PButtonPure, PFlex, PLinkPure, PText } from "@porsche-design-system/components-react"
import { FC } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

export interface Crumb {
    name: string
    path?: string
}

export interface BreadcrumbProps {
    parentCrumbs?: Crumb[]
    name: string
}

export const Breadcrumb: FC<BreadcrumbProps> = (props) => {
    const allCrumbs = [...(props.parentCrumbs ?? []), { name: props.name }]
    const firstCrumb = allCrumbs[0]

    return (
        <PFlex>
            {firstCrumb.path ? (
                <StyledLink key={firstCrumb.name} to={firstCrumb.path}>
                    <PText>{firstCrumb.name}</PText>
                </StyledLink>
            ) : (
                <PText color={"neutral-contrast-medium"}>{firstCrumb.name}</PText>
            )}
            {allCrumbs.slice(1).map((crumb) =>
                crumb.path ? (
                    <Link key={crumb.name} to={crumb.path}>
                        <PLinkPure icon={"arrow-head-right"}>{crumb.name}</PLinkPure>
                    </Link>
                ) : (
                    <PButtonPure key={crumb.name} icon={"arrow-head-right"} disabled={true}>
                        {crumb.name}
                    </PButtonPure>
                )
            )}
        </PFlex>
    )
}

const StyledLink = styled(Link)`
    text-decoration: none;
`
