/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderTypeMediaDTOEngineSoundsDTO,
    OrderTypeMediaDTOEngineSoundsDTOFromJSON,
    OrderTypeMediaDTOEngineSoundsDTOFromJSONTyped,
    OrderTypeMediaDTOEngineSoundsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeMediaDTOEquipmentRelationDTO
 */
export interface OrderTypeMediaDTOEquipmentRelationDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeMediaDTOEquipmentRelationDTO
     */
    optionCodes: Array<string>;
    /**
     * 
     * @type {OrderTypeMediaDTOEngineSoundsDTO}
     * @memberof OrderTypeMediaDTOEquipmentRelationDTO
     */
    values: OrderTypeMediaDTOEngineSoundsDTO;
}

export function OrderTypeMediaDTOEquipmentRelationDTOFromJSON(json: any): OrderTypeMediaDTOEquipmentRelationDTO {
    return OrderTypeMediaDTOEquipmentRelationDTOFromJSONTyped(json, false);
}

export function OrderTypeMediaDTOEquipmentRelationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeMediaDTOEquipmentRelationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCodes': json['optionCodes'],
        'values': OrderTypeMediaDTOEngineSoundsDTOFromJSON(json['values']),
    };
}

export function OrderTypeMediaDTOEquipmentRelationDTOToJSON(value?: OrderTypeMediaDTOEquipmentRelationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCodes': value.optionCodes,
        'values': OrderTypeMediaDTOEngineSoundsDTOToJSON(value.values),
    };
}


