/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionMarketplaceSpecificsIndexDTOValues,
    OptionMarketplaceSpecificsIndexDTOValuesFromJSON,
    OptionMarketplaceSpecificsIndexDTOValuesFromJSONTyped,
    OptionMarketplaceSpecificsIndexDTOValuesToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionMarketplaceSpecificsIndexDTO
 */
export interface OptionMarketplaceSpecificsIndexDTO {
    /**
     * 
     * @type {OptionMarketplaceSpecificsIndexDTOValues}
     * @memberof OptionMarketplaceSpecificsIndexDTO
     */
    globalValues?: OptionMarketplaceSpecificsIndexDTOValues;
    /**
     * 
     * @type {{ [key: string]: OptionMarketplaceSpecificsIndexDTOValues; }}
     * @memberof OptionMarketplaceSpecificsIndexDTO
     */
    marketplaceValues: { [key: string]: OptionMarketplaceSpecificsIndexDTOValues; };
}

export function OptionMarketplaceSpecificsIndexDTOFromJSON(json: any): OptionMarketplaceSpecificsIndexDTO {
    return OptionMarketplaceSpecificsIndexDTOFromJSONTyped(json, false);
}

export function OptionMarketplaceSpecificsIndexDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionMarketplaceSpecificsIndexDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'globalValues': !exists(json, 'globalValues') ? undefined : OptionMarketplaceSpecificsIndexDTOValuesFromJSON(json['globalValues']),
        'marketplaceValues': (mapValues(json['marketplaceValues'], OptionMarketplaceSpecificsIndexDTOValuesFromJSON)),
    };
}

export function OptionMarketplaceSpecificsIndexDTOToJSON(value?: OptionMarketplaceSpecificsIndexDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'globalValues': OptionMarketplaceSpecificsIndexDTOValuesToJSON(value.globalValues),
        'marketplaceValues': (mapValues(value.marketplaceValues, OptionMarketplaceSpecificsIndexDTOValuesToJSON)),
    };
}


