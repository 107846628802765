/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    LocalizationsItemDTO,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    ModelSeriesAttributesDTO,
    ModelSeriesAttributesDTOFromJSON,
    ModelSeriesAttributesDTOToJSON,
    ModelSeriesAttributesUpdateDTO,
    ModelSeriesAttributesUpdateDTOFromJSON,
    ModelSeriesAttributesUpdateDTOToJSON,
    ModelSeriesLinksDTO,
    ModelSeriesLinksDTOFromJSON,
    ModelSeriesLinksDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface DeleteModelSeriesRequest {
    key: string;
}

export interface GetAttributesOfModelSeriesRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfModelSeriesRequest {
    key: string;
    languageTag: string;
}

export interface GetLinksOfModelSeriesRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfModelSeriesRequest {
    key: string;
}

export interface UpdateAttributesOfModelSeriesRequest {
    key: string;
    modelSeriesAttributesUpdateDTO: ModelSeriesAttributesUpdateDTO;
}

export interface UpdateNameOfModelSeriesRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class ModelSeriesDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a ModelSeries by a given key
     */
    async deleteModelSeriesRaw(requestParameters: DeleteModelSeriesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteModelSeries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-series/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a ModelSeries by a given key
     */
    async deleteModelSeries(requestParameters: DeleteModelSeriesRequest): Promise<void> {
        await this.deleteModelSeriesRaw(requestParameters);
    }

    /**
     * Get attributes of ModelSeries with given key
     */
    async getAttributesOfModelSeriesRaw(requestParameters: GetAttributesOfModelSeriesRequest): Promise<runtime.ApiResponse<ModelSeriesAttributesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfModelSeries.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfModelSeries.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-series/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelSeriesAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of ModelSeries with given key
     */
    async getAttributesOfModelSeries(requestParameters: GetAttributesOfModelSeriesRequest): Promise<ModelSeriesAttributesDTO> {
        const response = await this.getAttributesOfModelSeriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of ModelSeries with given key
     */
    async getBaseInformationOfModelSeriesRaw(requestParameters: GetBaseInformationOfModelSeriesRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfModelSeries.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfModelSeries.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-series/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of ModelSeries with given key
     */
    async getBaseInformationOfModelSeries(requestParameters: GetBaseInformationOfModelSeriesRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfModelSeriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get links of ModelSeries with given key
     */
    async getLinksOfModelSeriesRaw(requestParameters: GetLinksOfModelSeriesRequest): Promise<runtime.ApiResponse<ModelSeriesLinksDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLinksOfModelSeries.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinksOfModelSeries.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-series/details/{key}/links`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelSeriesLinksDTOFromJSON(jsonValue));
    }

    /**
     * Get links of ModelSeries with given key
     */
    async getLinksOfModelSeries(requestParameters: GetLinksOfModelSeriesRequest): Promise<ModelSeriesLinksDTO> {
        const response = await this.getLinksOfModelSeriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of ModelSeries with given key
     */
    async getLocalizationsOfNameOfModelSeriesRaw(requestParameters: GetLocalizationsOfNameOfModelSeriesRequest): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfModelSeries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-series/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of ModelSeries with given key
     */
    async getLocalizationsOfNameOfModelSeries(requestParameters: GetLocalizationsOfNameOfModelSeriesRequest): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfModelSeriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing ModelSeries
     */
    async updateAttributesOfModelSeriesRaw(requestParameters: UpdateAttributesOfModelSeriesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfModelSeries.');
        }

        if (requestParameters.modelSeriesAttributesUpdateDTO === null || requestParameters.modelSeriesAttributesUpdateDTO === undefined) {
            throw new runtime.RequiredError('modelSeriesAttributesUpdateDTO','Required parameter requestParameters.modelSeriesAttributesUpdateDTO was null or undefined when calling updateAttributesOfModelSeries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/model-series/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ModelSeriesAttributesUpdateDTOToJSON(requestParameters.modelSeriesAttributesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing ModelSeries
     */
    async updateAttributesOfModelSeries(requestParameters: UpdateAttributesOfModelSeriesRequest): Promise<void> {
        await this.updateAttributesOfModelSeriesRaw(requestParameters);
    }

    /**
     * Updates name of an existing ModelSeries
     */
    async updateNameOfModelSeriesRaw(requestParameters: UpdateNameOfModelSeriesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfModelSeries.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfModelSeries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/model-series/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing ModelSeries
     */
    async updateNameOfModelSeries(requestParameters: UpdateNameOfModelSeriesRequest): Promise<void> {
        await this.updateNameOfModelSeriesRaw(requestParameters);
    }

}
