import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { InteriorColorTagCreateRequestDTO } from "../../../../generated/pdsapi"
import { interiorColorTagApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { InteriorColorTagCreateAttributesCard } from "./attributes/InteriorColorTagCreateAttributesCard"

export const InteriorColorTagProductCreateContainerConfig: ProductCreateContainerConfig<InteriorColorTagCreateRequestDTO, unknown> = {
    headline: "Create new Interior Color Tag",

    create: (item) =>
        interiorColorTagApi.create.create({
            interiorColorTagCreateRequestDTO: item,
        }),
    getCreateOptions: async () => ({}),

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <InteriorColorTagCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const InteriorColorTagCreateContainer = getProductCreateContainer(InteriorColorTagProductCreateContainerConfig)
