export const getCognitoConfig = (environment: "DEV" | "PROD") => {
    switch (environment) {
        case "PROD":
            return {
                host: "carsales-prod.auth.eu-central-1.amazoncognito.com",
                clientId: "5k3kpagrq84tvrcq4ch69gaqnq",
                redirectUri: "https://web.product.finder.porsche.com/",
            }
        case "DEV":
            return {
                host: "carsales-dev.auth.eu-central-1.amazoncognito.com",
                clientId: "6m2mdi94lpsf7utauj06iade3g",
                redirectUri: "https://web.product.finder-k.porsche.com/",
            }
    }
}
