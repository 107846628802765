/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TechnicalDataAttributeValueType {
    INT = 'INT',
    DOUBLE = 'DOUBLE',
    INT_RANGE = 'INT_RANGE',
    ENGINE_DESIGN = 'ENGINE_DESIGN',
    ENGINE_LAYOUT = 'ENGINE_LAYOUT',
    TRANSMISSION = 'TRANSMISSION',
    FUEL_TYPE = 'FUEL_TYPE'
}

export function TechnicalDataAttributeValueTypeFromJSON(json: any): TechnicalDataAttributeValueType {
    return TechnicalDataAttributeValueTypeFromJSONTyped(json, false);
}

export function TechnicalDataAttributeValueTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataAttributeValueType {
    return json as TechnicalDataAttributeValueType;
}

export function TechnicalDataAttributeValueTypeToJSON(value?: TechnicalDataAttributeValueType | null): any {
    return value as any;
}

