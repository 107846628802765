import { Path } from "react-hook-form"

export type Property<ROOT, VALUE> = {
    readonly _path: Path<ROOT>
} & ArrayOrObjectType<ROOT, NonNullable<VALUE>>

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const propertiesOf = <T>(obj?: T) => pathProxy() as Property<T, T>

type ArrayOrObjectType<ROOT, VALUE> = VALUE extends boolean | number | string | null | undefined
    ? Record<string, unknown>
    : VALUE extends ArrayLike<infer S>
      ? { _index: (index: number) => Property<ROOT, S> }
      : VALUE extends { [key: string]: infer S }
        ? { _get: (key: string) => Property<ROOT, S> } & Properties<ROOT, VALUE>
        : Properties<ROOT, VALUE>

export type Properties<ROOT, VALUE> = {
    readonly [P in keyof VALUE & string]: Property<ROOT, VALUE[P]>
}

const pathProxy = (property?: Property<any, any>): any =>
    new Proxy(
        {},
        {
            get: (_, childProp) => {
                if (childProp === "_path") {
                    return property?._path
                } else if (childProp === "_index") {
                    return (index: number) => pathProxy({ _path: property ? `${property?._path}.${index}` : index.toString() })
                } else if (childProp === "_get") {
                    return (key: string) => pathProxy({ _path: property ? `${property?._path}.${key}` : key })
                } else {
                    return pathProxy(property ? { _path: `${property?._path}.${childProp.toString()}` } : { _path: childProp.toString() })
                }
            },
            set: () => {
                throw Error("set not supported")
            },
        }
    )
