import { booleanConverterGroup, useURLSearchParamState } from "../../../common/useURLSearchParamState"
import { Box } from "../../layouts/box/Box"
import { ColumnGroupsWrapper } from "../../layouts/columngroupswrapper/ColumnGroupsWrapper"
import { SpacedItems } from "../../layouts/spaceditems/SpacedItems"
import { GroupedLanguageTagSelector } from "../../selectors/groupedlanguagetagselector/GroupedLanguageTagSelector"
import { debounce } from "@finder/ui-kit"
import { PButton, PTextFieldWrapper } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { ChangeEvent, Dispatch, FC, ReactNode, SetStateAction, useCallback, useMemo, useState } from "react"
import styled from "styled-components"

export interface FilterFindParams {
    languageTag: string
    search?: string
}

export interface FilterWrapperProps {
    defaultShowsFilter: boolean
    urlParamsPrefix: string

    filter: FilterFindParams
    setFilter: Dispatch<SetStateAction<FilterFindParams>>

    filterTags?: JSX.Element[]
    flexWidth?: "one-third" | "one-quarter"

    children: ReactNode
}

export const FilterWrapper = ({ children, defaultShowsFilter, filterTags, filter, setFilter, urlParamsPrefix, flexWidth }: FilterWrapperProps) => {
    const [showsFilter, setShowsFilter] = useURLSearchParamState(urlParamsPrefix + ".showsFilter", defaultShowsFilter, booleanConverterGroup.required)

    const [text, setText] = useState(filter.search ?? "")
    // empty value need to be set if searchString is undefined
    // (otherwise it would be an uncontrolled component)

    const debounceSetSearch = useMemo(
        () =>
            debounce(
                (search: string) =>
                    setFilter((filter) => ({
                        ...filter,
                        search,
                    })),
                500
            ),
        [setFilter]
    )

    const setLanguageTag = useCallback(
        (languageTag: string) =>
            setFilter((filter) => ({
                ...filter,
                languageTag,
            })),
        [setFilter]
    )

    const boxes = []
    boxes.push(
        <PTextFieldWrapper key={"text-search"}>
            <input
                placeholder={"Search"}
                type={"search"}
                name={"search"}
                value={text}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value
                    setText(value)
                    debounceSetSearch(value)
                }}
            />
        </PTextFieldWrapper>
    )
    boxes.push(<GroupedLanguageTagSelector key={"language-select"} value={filter.languageTag} onChange={setLanguageTag} />)

    return (
        <>
            <ColumnGroupsWrapper flexWidth={flexWidth}>
                {boxes}
                {flexWidth !== "one-third" ? [<div key={"spacer"} />] : []}
                <AlignRight>
                    <PButton icon={"filter"} variant={"tertiary"} onClick={() => setShowsFilter(!showsFilter)}>
                        {showsFilter ? "Hide filter" : "Show filter"}
                    </PButton>
                </AlignRight>
            </ColumnGroupsWrapper>

            {showsFilter && <Box height={spacing[16]} />}
            {showsFilter && children}
            {filterTags && filterTags.length > 0 && <Box height={spacing["8"]} />}
            <FilterTags>{filterTags}</FilterTags>
        </>
    )
}

interface FilterTagsProps {
    children: ReactNode
}

export const FilterTags: FC<FilterTagsProps> = (props) => (
    <SpacedItems direction={"row"} wrap={true} itemSpacing={spacing[16]}>
        {props.children}
    </SpacedItems>
)

const AlignRight = styled.div`
    display: flex;
    justify-content: flex-end;
`
