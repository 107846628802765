import { OptionsInFamilyDTO } from "../../../../../../../generated/pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard, ProductDetailsCardContentProps } from "../../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { OptionsInFamilyCardContent } from "./content/OptionsInFamilyCardContent"
import { FC, useCallback } from "react"

type Key = { orderTypeKey: string; optionCode: string }
interface OptionsInFamilyCardProps {
    getData: (props: Key & { languageTag: string }) => Promise<OptionsInFamilyDTO>
    getRoute: (orderTypeKey: string, optionCode: string) => string
}

export const OptionsInFamilyCard: FC<ProductDetailsTabConfigContentProps<Key> & OptionsInFamilyCardProps> = (props) => {
    const Content: FC<ProductDetailsCardContentProps<OptionsInFamilyDTO, object>> = useCallback(
        (contentProps) => <OptionsInFamilyCardContent {...contentProps} getRoute={props.getRoute} />,
        [props.getRoute]
    )

    return <ProductDetailsCard<OptionsInFamilyDTO, object, Key> {...props} label={"Options in Family"} Content={Content} />
}
