/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaintToSampleExteriorColorColor
 */
export interface PaintToSampleExteriorColorColor {
    /**
     * 
     * @type {string}
     * @memberof PaintToSampleExteriorColorColor
     */
    colorCode: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PaintToSampleExteriorColorColor
     */
    name: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PaintToSampleExteriorColorColor
     */
    orderText?: string;
    /**
     * 
     * @type {string}
     * @memberof PaintToSampleExteriorColorColor
     */
    brushCode?: string;
}

export function PaintToSampleExteriorColorColorFromJSON(json: any): PaintToSampleExteriorColorColor {
    return PaintToSampleExteriorColorColorFromJSONTyped(json, false);
}

export function PaintToSampleExteriorColorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaintToSampleExteriorColorColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'colorCode': json['colorCode'],
        'name': json['name'],
        'orderText': !exists(json, 'orderText') ? undefined : json['orderText'],
        'brushCode': !exists(json, 'brushCode') ? undefined : json['brushCode'],
    };
}

export function PaintToSampleExteriorColorColorToJSON(value?: PaintToSampleExteriorColorColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'colorCode': value.colorCode,
        'name': value.name,
        'orderText': value.orderText,
        'brushCode': value.brushCode,
    };
}


