/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    SelectDTOEquipmentTagCategory,
    SelectDTOEquipmentTagCategoryFromJSON,
    SelectDTOEquipmentTagCategoryFromJSONTyped,
    SelectDTOEquipmentTagCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface EquipmentTagAttributesDTO
 */
export interface EquipmentTagAttributesDTO {
    /**
     * 
     * @type {string}
     * @memberof EquipmentTagAttributesDTO
     */
    key: string;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof EquipmentTagAttributesDTO
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {SelectDTOEquipmentTagCategory}
     * @memberof EquipmentTagAttributesDTO
     */
    category: SelectDTOEquipmentTagCategory;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentTagAttributesDTO
     */
    active: boolean;
}

export function EquipmentTagAttributesDTOFromJSON(json: any): EquipmentTagAttributesDTO {
    return EquipmentTagAttributesDTOFromJSONTyped(json, false);
}

export function EquipmentTagAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentTagAttributesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': BaseNameDTOFromJSON(json['name']),
        'category': SelectDTOEquipmentTagCategoryFromJSON(json['category']),
        'active': json['active'],
    };
}

export function EquipmentTagAttributesDTOToJSON(value?: EquipmentTagAttributesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': BaseNameDTOToJSON(value.name),
        'category': SelectDTOEquipmentTagCategoryToJSON(value.category),
        'active': value.active,
    };
}


