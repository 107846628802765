import { ViewState } from "../../../../../../common/ViewState"
import { showErrorToast } from "../../../../../../common/errorToastHelper"
import { browserLanguage } from "../../../../../../common/global"
import { modelSeriesRoutes } from "../../../../../../common/routes"
import { EmptyView } from "../../../../../../components/emptyview/EmptyView"
import { TagFormElementView } from "../../../../../../components/formelements/tagformelement/TagFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { WidgetWrapper } from "../../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { DerivativeLinksDTO } from "../../../../../../generated/pdsapi"
import { derivativeApi } from "../../../../../../pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../../viewtemplates/details/ProductDetailsContainer"
import { useToast } from "@finder/ui-kit"
import { FC, useEffect, useRef, useState } from "react"

export const DerivativeLinksCard: FC<ProductDetailsTabConfigContentProps> = ({ itemKey: { key } }) => {
    const toastRef = useRef(useToast())
    const [links, setLinks] = useState<DerivativeLinksDTO>()

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const links = await derivativeApi.details.getLinks({
                    key,
                    languageTag: browserLanguage,
                })
                setLinks(links)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchLinks()
    }, [key, toastRef])

    return <WidgetWrapper label={"Links"}>{links ? <DerivativeLinksCardContent links={links} /> : <EmptyView viewState={ViewState.LOADING} />}</WidgetWrapper>
}

export const DerivativeLinksCardContent = ({ links }: { links: DerivativeLinksDTO }) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <RowGroupWrapper label={"Model Series"} childrenSize={12}>
            <TagFormElementView
                label={undefined}
                tag={{
                    label: links.modelSeries.label,
                    route: modelSeriesRoutes.details(links.modelSeries.key),
                }}
            />
        </RowGroupWrapper>
    </SpacedItems>
)
