import { orderTypeRoutes } from "../../../common/routes"
import { Crumb } from "../../../components/breadcrumb/Breadcrumb"
import { useOrderTypeName } from "../../../hooks/useOrderTypeName"
import { ProductCreateContainerProps } from "../../../viewtemplates/create/ProductCreateContainer"
import { ProductDetailsContainerProps } from "../../../viewtemplates/details/ProductDetailsContainer"
import { OrderTypeOptionsContext } from "./OrderTypeOptionsContext"
import { FC, useCallback } from "react"
import { Route, Routes, useNavigate, useParams, useResolvedPath } from "react-router-dom"

export interface OrderTypeOptionRoutesProps {
    Create: FC<ProductCreateContainerProps<{ orderTypeKey: string; optionCode: string }>>
    Details: FC<ProductDetailsContainerProps<{ orderTypeKey: string; optionCode: string }>>
    optionListCrumb: Crumb
    customRoutes?: JSX.Element[]
}

export const OrderTypeOptionRoutes: FC<OrderTypeOptionRoutesProps> = ({ Create, Details, optionListCrumb, customRoutes }) => {
    const navigate = useNavigate()
    const { orderTypeKey } = useParams()
    const orderTypeName = useOrderTypeName(orderTypeKey)
    const { pathname } = useResolvedPath("")

    const parentCrumbs: Crumb[] = [
        { name: "Order Types", path: orderTypeRoutes.list },
        { name: orderTypeName, path: orderTypeRoutes.details(orderTypeKey) },
        optionListCrumb,
    ]

    const buildDetailsPath = useCallback(({ optionCode }: { optionCode: string }) => `${pathname}/${optionCode}`, [pathname])
    const handleCancel = useCallback(() => navigate(orderTypeRoutes.details(orderTypeKey)), [orderTypeKey, navigate])
    const createRoute = (
        <Route
            key={"create_route"}
            path={"create"}
            element={<Create handleCancel={handleCancel} buildDetailsPath={buildDetailsPath} parentCrumbs={parentCrumbs} />}
        />
    )

    const detailsRoute = (
        <Route
            key={"details_route"}
            path={":optionCode"}
            element={
                <Details
                    listPath={optionListCrumb.path ?? orderTypeRoutes.details(orderTypeKey)}
                    getItemKey={(params) => ({ optionCode: params.optionCode, orderTypeKey })}
                    parentCrumbs={parentCrumbs}
                />
            }
        />
    )

    return (
        <OrderTypeOptionsContext.Provider value={{ orderTypeKey, orderTypeName }}>
            <Routes>
                {customRoutes}
                {createRoute}
                {detailsRoute}
            </Routes>
        </OrderTypeOptionsContext.Provider>
    )
}
