/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    InteriorColorTagAttributesDTO,
    InteriorColorTagAttributesDTOFromJSON,
    InteriorColorTagAttributesDTOToJSON,
    InteriorColorTagAttributesUpdateDTO,
    InteriorColorTagAttributesUpdateDTOFromJSON,
    InteriorColorTagAttributesUpdateDTOToJSON,
    LocalizationsItemDTO,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface DeleteInteriorColorTagRequest {
    key: string;
}

export interface GetAttributesOfInteriorColorTagRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfInteriorColorTagRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfInteriorColorTagRequest {
    key: string;
}

export interface UpdateAttributesOfInteriorColorTagRequest {
    key: string;
    interiorColorTagAttributesUpdateDTO: InteriorColorTagAttributesUpdateDTO;
}

export interface UpdateNameOfInteriorColorTagRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class InteriorColorTagDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a InteriorColorTag by a given key
     */
    async deleteInteriorColorTagRaw(requestParameters: DeleteInteriorColorTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteInteriorColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/interior-color-tags/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a InteriorColorTag by a given key
     */
    async deleteInteriorColorTag(requestParameters: DeleteInteriorColorTagRequest): Promise<void> {
        await this.deleteInteriorColorTagRaw(requestParameters);
    }

    /**
     * Get attributes of InteriorColorTag with given key
     */
    async getAttributesOfInteriorColorTagRaw(requestParameters: GetAttributesOfInteriorColorTagRequest): Promise<runtime.ApiResponse<InteriorColorTagAttributesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfInteriorColorTag.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfInteriorColorTag.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/interior-color-tags/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InteriorColorTagAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of InteriorColorTag with given key
     */
    async getAttributesOfInteriorColorTag(requestParameters: GetAttributesOfInteriorColorTagRequest): Promise<InteriorColorTagAttributesDTO> {
        const response = await this.getAttributesOfInteriorColorTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of InteriorColorTag with given key
     */
    async getBaseInformationOfInteriorColorTagRaw(requestParameters: GetBaseInformationOfInteriorColorTagRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfInteriorColorTag.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfInteriorColorTag.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/interior-color-tags/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of InteriorColorTag with given key
     */
    async getBaseInformationOfInteriorColorTag(requestParameters: GetBaseInformationOfInteriorColorTagRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfInteriorColorTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of InteriorColorTag with given key
     */
    async getLocalizationsOfNameOfInteriorColorTagRaw(requestParameters: GetLocalizationsOfNameOfInteriorColorTagRequest): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfInteriorColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/interior-color-tags/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of InteriorColorTag with given key
     */
    async getLocalizationsOfNameOfInteriorColorTag(requestParameters: GetLocalizationsOfNameOfInteriorColorTagRequest): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfInteriorColorTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing InteriorColorTag
     */
    async updateAttributesOfInteriorColorTagRaw(requestParameters: UpdateAttributesOfInteriorColorTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfInteriorColorTag.');
        }

        if (requestParameters.interiorColorTagAttributesUpdateDTO === null || requestParameters.interiorColorTagAttributesUpdateDTO === undefined) {
            throw new runtime.RequiredError('interiorColorTagAttributesUpdateDTO','Required parameter requestParameters.interiorColorTagAttributesUpdateDTO was null or undefined when calling updateAttributesOfInteriorColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/interior-color-tags/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InteriorColorTagAttributesUpdateDTOToJSON(requestParameters.interiorColorTagAttributesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing InteriorColorTag
     */
    async updateAttributesOfInteriorColorTag(requestParameters: UpdateAttributesOfInteriorColorTagRequest): Promise<void> {
        await this.updateAttributesOfInteriorColorTagRaw(requestParameters);
    }

    /**
     * Updates name of an existing InteriorColorTag
     */
    async updateNameOfInteriorColorTagRaw(requestParameters: UpdateNameOfInteriorColorTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfInteriorColorTag.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfInteriorColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/interior-color-tags/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing InteriorColorTag
     */
    async updateNameOfInteriorColorTag(requestParameters: UpdateNameOfInteriorColorTagRequest): Promise<void> {
        await this.updateNameOfInteriorColorTagRaw(requestParameters);
    }

}
