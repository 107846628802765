/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EngineType {
    PETROL = 'PETROL',
    DIESEL = 'DIESEL',
    PLUG_IN_HYBRID = 'PLUG_IN_HYBRID',
    MILD_HYBRID = 'MILD_HYBRID',
    ELECTRIC = 'ELECTRIC'
}

export function EngineTypeFromJSON(json: any): EngineType {
    return EngineTypeFromJSONTyped(json, false);
}

export function EngineTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineType {
    return json as EngineType;
}

export function EngineTypeToJSON(value?: EngineType | null): any {
    return value as any;
}

