/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TechnicalDataItemDTO,
    TechnicalDataItemDTOFromJSON,
    TechnicalDataItemDTOFromJSONTyped,
    TechnicalDataItemDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageDTOTechnicalDataItemDTO
 */
export interface PageDTOTechnicalDataItemDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOTechnicalDataItemDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<TechnicalDataItemDTO>}
     * @memberof PageDTOTechnicalDataItemDTO
     */
    content: Array<TechnicalDataItemDTO>;
}

export function PageDTOTechnicalDataItemDTOFromJSON(json: any): PageDTOTechnicalDataItemDTO {
    return PageDTOTechnicalDataItemDTOFromJSONTyped(json, false);
}

export function PageDTOTechnicalDataItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOTechnicalDataItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(TechnicalDataItemDTOFromJSON)),
    };
}

export function PageDTOTechnicalDataItemDTOToJSON(value?: PageDTOTechnicalDataItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItemCount': value.totalItemCount,
        'content': ((value.content as Array<any>).map(TechnicalDataItemDTOToJSON)),
    };
}


