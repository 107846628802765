/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    OrderTypeNameItemDTO,
    OrderTypeNameItemDTOFromJSON,
    OrderTypeNameItemDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface GetNameOfOrderTypeRequest {
    key: string;
}

export interface UpdateManualOfNameOfOrderTypeRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class OrderTypeLocalizationDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get name of OrderType with given key
     */
    async getNameOfOrderTypeRaw(requestParameters: GetNameOfOrderTypeRequest): Promise<runtime.ApiResponse<{ [key: string]: OrderTypeNameItemDTO; }>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getNameOfOrderType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/localization/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, OrderTypeNameItemDTOFromJSON));
    }

    /**
     * Get name of OrderType with given key
     */
    async getNameOfOrderType(requestParameters: GetNameOfOrderTypeRequest): Promise<{ [key: string]: OrderTypeNameItemDTO; }> {
        const response = await this.getNameOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates manualOfName of an existing OrderType
     */
    async updateManualOfNameOfOrderTypeRaw(requestParameters: UpdateManualOfNameOfOrderTypeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateManualOfNameOfOrderType.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateManualOfNameOfOrderType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/details/{key}/localization/name/manual`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates manualOfName of an existing OrderType
     */
    async updateManualOfNameOfOrderType(requestParameters: UpdateManualOfNameOfOrderTypeRequest): Promise<void> {
        await this.updateManualOfNameOfOrderTypeRaw(requestParameters);
    }

}
