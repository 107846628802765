/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SimilarRoofColorCustomizationsDTOCustomization,
    SimilarRoofColorCustomizationsDTOCustomizationFromJSON,
    SimilarRoofColorCustomizationsDTOCustomizationFromJSONTyped,
    SimilarRoofColorCustomizationsDTOCustomizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface SimilarRoofColorCustomizationsDTO
 */
export interface SimilarRoofColorCustomizationsDTO {
    /**
     * 
     * @type {Array<SimilarRoofColorCustomizationsDTOCustomization>}
     * @memberof SimilarRoofColorCustomizationsDTO
     */
    customizationsForOtherModelGenerations: Array<SimilarRoofColorCustomizationsDTOCustomization>;
}

export function SimilarRoofColorCustomizationsDTOFromJSON(json: any): SimilarRoofColorCustomizationsDTO {
    return SimilarRoofColorCustomizationsDTOFromJSONTyped(json, false);
}

export function SimilarRoofColorCustomizationsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarRoofColorCustomizationsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customizationsForOtherModelGenerations': ((json['customizationsForOtherModelGenerations'] as Array<any>).map(SimilarRoofColorCustomizationsDTOCustomizationFromJSON)),
    };
}

export function SimilarRoofColorCustomizationsDTOToJSON(value?: SimilarRoofColorCustomizationsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customizationsForOtherModelGenerations': ((value.customizationsForOtherModelGenerations as Array<any>).map(SimilarRoofColorCustomizationsDTOCustomizationToJSON)),
    };
}


