import { joinIndexed } from "../../common/global"
import { Box } from "../layouts/box/Box"
import { FlyoutItem } from "./PdsHeader"
import { PFlex, PLinkPure } from "@porsche-design-system/components-react"
import { color, spacing } from "@porsche-design-system/utilities"
import { FC } from "react"
import styled from "styled-components"

export interface PdsHeaderFlyoutViewProps {
    basePath: string
    items: FlyoutItem[]
    onMouseEnter: () => void
    onMouseLeave: () => void
}

export const PdsHeaderFlyoutView: FC<PdsHeaderFlyoutViewProps> = (props) => {
    const buildPath = (firstLevel: string, secondLevel?: string, customRef?: string) => {
        if (customRef) {
            return customRef
        }

        return `${firstLevel}${secondLevel ?? ""}`
    }

    return (
        <>
            <FlyoutOverlayContainer onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
                <PFlex direction={"column"}>
                    {joinIndexed(
                        props.items.map((item, index) => (
                            <PLinkPure key={`headerLink_${index}`} href={buildPath(props.basePath, item.to, item.customRef)} icon={"arrow-head-right"}>
                                {item.label}
                            </PLinkPure>
                        )),
                        (index) => (
                            <Box key={`flyoutColumn_${index}`} height={spacing[8]} />
                        )
                    )}
                </PFlex>
            </FlyoutOverlayContainer>
        </>
    )
}

const FlyoutOverlayContainer = styled.div`
    position: absolute;
    padding: 1rem;
    white-space: nowrap;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: ${color.background.default};
    z-index: 1;
    border-bottom: 1px solid ${color.neutralContrast.low};
    border-left: 1px solid ${color.neutralContrast.low};
    border-right: 1px solid ${color.neutralContrast.low};
`
