/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VehicleEquipmentCategory {
    PACKAGES = 'PACKAGES',
    EXTERIOR_COLOR = 'EXTERIOR_COLOR',
    FOILS = 'FOILS',
    INTERIOR_COLOR = 'INTERIOR_COLOR',
    EXTERIOR = 'EXTERIOR',
    TRANSMISSION_CHASSIS = 'TRANSMISSION_CHASSIS',
    WHEELS = 'WHEELS',
    INTERIOR = 'INTERIOR',
    INTERIOR_BLACK = 'INTERIOR_BLACK',
    INTERIOR_LEATHER = 'INTERIOR_LEATHER',
    INTERIOR_ALCANTARA = 'INTERIOR_ALCANTARA',
    INTERIOR_RACE_TEX = 'INTERIOR_RACE_TEX',
    INTERIOR_WOOD = 'INTERIOR_WOOD',
    INTERIOR_CARBON = 'INTERIOR_CARBON',
    INTERIOR_ALUMINIUM = 'INTERIOR_ALUMINIUM',
    AUDIO_COMMUNICATION = 'AUDIO_COMMUNICATION',
    E_MOBILITY = 'E_MOBILITY',
    FACTORY_COLLECTION = 'FACTORY_COLLECTION',
    PCCD_OTHERS = 'PCCD_OTHERS',
    STEERING_CODES = 'STEERING_CODES',
    TEST_VEHICLES = 'TEST_VEHICLES',
    Z_OPTIONS_INTERNAL = 'Z_OPTIONS_INTERNAL',
    COLOUR_LEATHER_STITCHINGS = 'COLOUR_LEATHER_STITCHINGS',
    LIGHTS_VISION = 'LIGHTS_VISION',
    COMFORT_ASSISTANCE = 'COMFORT_ASSISTANCE',
    PORSCHE_APPROVED = 'PORSCHE_APPROVED',
    OTHERS = 'OTHERS'
}

export function VehicleEquipmentCategoryFromJSON(json: any): VehicleEquipmentCategory {
    return VehicleEquipmentCategoryFromJSONTyped(json, false);
}

export function VehicleEquipmentCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleEquipmentCategory {
    return json as VehicleEquipmentCategory;
}

export function VehicleEquipmentCategoryToJSON(value?: VehicleEquipmentCategory | null): any {
    return value as any;
}

