import { Property } from "../../../../../../../common/property"
import { NotLoadingFormDisplayData } from "../../../../../../../components/formelements/FormDisplayData"
import { NotLoadingFormElementWrapper } from "../../../../../../../components/formelements/NotLoadingFormElementWrapper"
import { getNumberFormElement } from "../../../../../../../components/formelements/numberformelement/NumberFormElement"
import { getNumberRangeFormElement } from "../../../../../../../components/formelements/numberrangeformelement/NumberRangeFormElement"
import { toOption } from "../../../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { Box } from "../../../../../../../components/layouts/box/Box"
import {
    MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue,
    OptionDTOString,
    OrderTypeTechnicalDataAttributeUpdateDTO,
    OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation,
    TechnicalDataAttributeDataColumnDefinition,
    TechnicalDataAttributeValueType,
} from "../../../../../../../generated/pdsapi"
import { findOption } from "../../../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { useOrderTypeTechnicalDataTabContext } from "../../OrderTypeTechnicalDataTabContext"
import { OrderTypeTechnicalDataAttributeTableItem } from "../OrderTypeTechnicalDataAttributeCardContent"
import { Tooltip } from "@finder/ui-kit"
import { PFlex, PIcon, PText } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import styled from "styled-components"

export interface OrderTypeTechnicalDataValueTableFormElementProps {
    formDisplayData: NotLoadingFormDisplayData<OrderTypeTechnicalDataAttributeTableItem, OrderTypeTechnicalDataAttributeUpdateDTO>
    field: Property<OrderTypeTechnicalDataAttributeUpdateDTO, OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation>
    columnDefinition: TechnicalDataAttributeDataColumnDefinition
}

export const OrderTypeTechnicalDataValueTableFormElement = ({ formDisplayData, field, columnDefinition }: OrderTypeTechnicalDataValueTableFormElementProps) => {
    const { engineLayouts, engineDesigns, fuelTypes, transmissions } = useOrderTypeTechnicalDataTabContext()
    const fieldProperty: any = field.values._get(columnDefinition.field)
    const value: MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue | undefined = formDisplayData.data.values[columnDefinition.field]

    const buildSelectOptionalTextFormElement = (options: OptionDTOString[]) => (
        <NotLoadingFormElementWrapper
            label={undefined}
            formDisplayData={formDisplayData}
            field={fieldProperty}
            FormElement={getSelectTextFormElement<OrderTypeTechnicalDataAttributeTableItem, OrderTypeTechnicalDataAttributeUpdateDTO>()}
            getViewProps={() => ({
                text: findOption(options, value!.specificValue.value)?.label ?? value!.specificValue.value ?? "-",
                showAsHint: formDisplayData.data.isGlobalValue,
            })}
            getEditProps={() => ({
                options: [{ label: "-", value: "" }, ...options.map(toOption)],
                optional: true,
                value: value?.specificValue.rawValue ?? "",
                readOnly: formDisplayData.data.isGlobalValue,
            })}
        />
    )

    let formElement: JSX.Element
    switch (columnDefinition.type) {
        case TechnicalDataAttributeValueType.DOUBLE:
        case TechnicalDataAttributeValueType.INT:
            formElement = (
                <NotLoadingFormElementWrapper
                    label={undefined}
                    formDisplayData={formDisplayData}
                    field={fieldProperty}
                    FormElement={getNumberFormElement<OrderTypeTechnicalDataAttributeTableItem, OrderTypeTechnicalDataAttributeUpdateDTO>()}
                    getViewProps={() => {
                        const isComputedValue = value?.specificValue.rawValue === undefined && value?.specificValue.value !== undefined
                        return {
                            value: value!.specificValue.value,
                            showAsHint: formDisplayData.data.isGlobalValue || isComputedValue,
                        }
                    }}
                    getEditProps={() => ({
                        optional: true,
                        value: value?.specificValue.rawValue,
                        step: columnDefinition.type === TechnicalDataAttributeValueType.INT ? 1 : "any",
                        readOnly: formDisplayData.data.isGlobalValue,
                    })}
                />
            )
            break
        case TechnicalDataAttributeValueType.INT_RANGE:
            formElement = (
                <NotLoadingFormElementWrapper
                    label={undefined}
                    formDisplayData={formDisplayData}
                    field={fieldProperty}
                    FormElement={getNumberRangeFormElement<OrderTypeTechnicalDataAttributeTableItem, OrderTypeTechnicalDataAttributeUpdateDTO>()}
                    getViewProps={() => ({
                        value: value!.specificValue.value,
                        showAsHint: formDisplayData.data.isGlobalValue,
                    })}
                    getEditProps={() => ({
                        value: value?.specificValue.rawValue,
                        readOnly: formDisplayData.data.isGlobalValue,
                        optional: true,
                    })}
                />
            )
            break
        case TechnicalDataAttributeValueType.ENGINE_DESIGN:
            formElement = buildSelectOptionalTextFormElement(engineDesigns)
            break
        case TechnicalDataAttributeValueType.ENGINE_LAYOUT:
            formElement = buildSelectOptionalTextFormElement(engineLayouts)
            break
        case TechnicalDataAttributeValueType.TRANSMISSION:
            formElement = buildSelectOptionalTextFormElement(transmissions)
            break
        case TechnicalDataAttributeValueType.FUEL_TYPE:
            formElement = buildSelectOptionalTextFormElement(fuelTypes)
            break
    }

    // hover-tooltip as hint to global default value
    let toolTipValue: string | undefined = undefined
    if (value?.globalValue) {
        const tValue = formDisplayData.kind === "EDIT" ? value.globalValue.rawValue : value.globalValue.value

        switch (columnDefinition.type) {
            case TechnicalDataAttributeValueType.INT_RANGE:
                toolTipValue = tValue ? `${tValue.from} to ${tValue.to}` : undefined
                break
            case TechnicalDataAttributeValueType.ENGINE_DESIGN:
                toolTipValue = findOption(engineDesigns, tValue)?.label ?? tValue
                break
            case TechnicalDataAttributeValueType.ENGINE_LAYOUT:
                toolTipValue = findOption(engineLayouts, tValue)?.label ?? tValue
                break
            case TechnicalDataAttributeValueType.TRANSMISSION:
                toolTipValue = findOption(transmissions, tValue)?.label ?? tValue
                break
            case TechnicalDataAttributeValueType.FUEL_TYPE:
                toolTipValue = findOption(fuelTypes, tValue)?.label ?? tValue
                break
            default:
                toolTipValue = tValue?.toString()
        }
    }

    return (
        <PFlex>
            {formElement}
            {toolTipValue && (
                <Center>
                    <Box width={spacing[4]} />
                    <Tooltip content={<PText>Default: {toolTipValue}</PText>}>
                        <PIcon name={"information"} />
                    </Tooltip>
                </Center>
            )}
        </PFlex>
    )
}

const Center = styled.div`
    align-self: center;
`
