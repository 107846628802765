import { DefaultFormElementLoading, EditLabeledContentWrapper, EditProps, FormElement, ViewLabeledContentWrapper, ViewProps } from "../FormElementWrapper"
import { PContentWrapper, PFlex, PFlexItem, PText, PTextFieldWrapper } from "@porsche-design-system/components-react"
import { layout } from "@porsche-design-system/utilities"
import { FieldValues } from "react-hook-form"
import styled from "styled-components"

interface NumberRange {
    start: number
    end: number
}

export const getNumberRangeFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    NumberRange,
    NumberRangeFormElementViewProps,
    NumberRangeFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: NumberRangeFormElementView,
    Edit: NumberRangeFormElementEdit,
})

interface NumberRangeFormElementViewProps {
    value: NumberRange | undefined
    showAsHint?: boolean
}

export const NumberRangeFormElementView = ({ label, value, showAsHint }: ViewProps<NumberRangeFormElementViewProps>) => {
    const formattedRange = value ? `${value.start} to ${value.end}` : "-"

    return (
        <ViewLabeledContentWrapper label={label}>
            <PText color={showAsHint ? "neutral-contrast-low" : "default"}>{showAsHint ? `${formattedRange} *` : formattedRange}</PText>
        </ViewLabeledContentWrapper>
    )
}

interface NumberRangeFormElementEditProps {
    value: NumberRange | undefined

    showAsHint?: boolean
    readOnly?: boolean
    optional?: boolean
    min?: number
    max?: number
}

export const NumberRangeFormElementEdit = <DATA, UPDATE extends FieldValues>({
    label,
    field,
    min,
    max,
    optional,
    readOnly,
    value,
    showAsHint,
    validationErrors,
    register,
}: EditProps<DATA, UPDATE, NumberRange, NumberRangeFormElementEditProps>) => {
    const errorMessage = validationErrors?.get(field._path)

    return (
        <EditLabeledContentWrapper label={label} optional={optional}>
            <StyledPContentWrapper width={"full"}>
                <PFlex direction={"row"}>
                    <PFlexItem grow={1}>
                        <PTextFieldWrapper state={errorMessage ? "error" : "none"} message={errorMessage}>
                            <input
                                {...register(`${field._path}.start` as any, {
                                    required: !optional,
                                    min: min,
                                    max: max,
                                })}
                                type={"number"}
                                defaultValue={showAsHint ? undefined : value?.start}
                                readOnly={readOnly}
                            />
                        </PTextFieldWrapper>
                    </PFlexItem>
                    <StyledPText align={"center"}>to</StyledPText>
                    <PFlexItem grow={1}>
                        <PTextFieldWrapper state={errorMessage ? "error" : "none"} message={errorMessage}>
                            <input
                                {...register(`${field._path}.end` as any, {
                                    required: !optional,
                                    min: min,
                                    max: max,
                                })}
                                type={"number"}
                                defaultValue={showAsHint ? undefined : value?.end}
                                readOnly={readOnly}
                            />
                        </PTextFieldWrapper>
                    </PFlexItem>
                </PFlex>
            </StyledPContentWrapper>
        </EditLabeledContentWrapper>
    )
}

const StyledPText = styled(PText)`
    width: ${layout.large};
    height: 100%;
    margin-top: 0.8rem;
`

const StyledPContentWrapper = styled(PContentWrapper)`
    width: 100%;
`
