/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TechnicalDataAttributeDataColumnDefinition,
    TechnicalDataAttributeDataColumnDefinitionFromJSON,
    TechnicalDataAttributeDataColumnDefinitionFromJSONTyped,
    TechnicalDataAttributeDataColumnDefinitionToJSON,
} from './';

/**
 * 
 * @export
 * @interface TechnicalDataAttributeDataDTO
 */
export interface TechnicalDataAttributeDataDTO {
    /**
     * 
     * @type {string}
     * @memberof TechnicalDataAttributeDataDTO
     */
    label: string;
    /**
     * 
     * @type {Array<TechnicalDataAttributeDataColumnDefinition>}
     * @memberof TechnicalDataAttributeDataDTO
     */
    columns: Array<TechnicalDataAttributeDataColumnDefinition>;
}

export function TechnicalDataAttributeDataDTOFromJSON(json: any): TechnicalDataAttributeDataDTO {
    return TechnicalDataAttributeDataDTOFromJSONTyped(json, false);
}

export function TechnicalDataAttributeDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataAttributeDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'columns': ((json['columns'] as Array<any>).map(TechnicalDataAttributeDataColumnDefinitionFromJSON)),
    };
}

export function TechnicalDataAttributeDataDTOToJSON(value?: TechnicalDataAttributeDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'columns': ((value.columns as Array<any>).map(TechnicalDataAttributeDataColumnDefinitionToJSON)),
    };
}


