import { roofColorTagRoutes } from "../../../../common/routes"
import { booleanConverterGroup, getEnumConverterGroup, getObjectURLParamConverter } from "../../../../common/useURLSearchParamState"
import { RoofColorTagItemDTO, SortDirection } from "../../../../generated/pdsapi"
import { roofColorTagApi, RoofColorTagFilter, RoofColorTagSortField, RoofColorTagSortFieldEnum } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { RoofColorTagListColumnsConfig } from "./columns/RoofColorTagListColumns"
import { RoofColorTagListFilter } from "./filter/RoofColorTagListFilter"
import { RoofColorTagListFilterTagsConfig } from "./filter/RoofColorTagListFilterTagsConfig"

export const RoofColorTagProductListContainerConfig: ProductListContainerConfig<RoofColorTagItemDTO, RoofColorTagFilter, unknown, RoofColorTagSortFieldEnum> = {
    listName: "Roof Color Tags",
    createItemLabel: "Add new RoofColor Tag",

    buildDetailsPath: (roofColorTag) => roofColorTagRoutes.details(roofColorTag.key),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<RoofColorTagFilter>({
        active: booleanConverterGroup.optional,
    }),
    defaultSorting: {
        sortField: RoofColorTagSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(RoofColorTagSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: roofColorTagApi.list.getPage,
    fetchFilterOptions: async () => ({}),

    defaultShowsFilter: false,
    Filter: RoofColorTagListFilter,
    filterTagsConfig: RoofColorTagListFilterTagsConfig,

    columnsConfig: RoofColorTagListColumnsConfig,
}

export const RoofColorTagListContainer = getProductListContainer(RoofColorTagProductListContainerConfig)
