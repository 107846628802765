/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOString,
    OptionDTOStringFromJSON,
    OptionDTOStringFromJSONTyped,
    OptionDTOStringToJSON,
    RelatedOptionGroupDTOStringString,
    RelatedOptionGroupDTOStringStringFromJSON,
    RelatedOptionGroupDTOStringStringFromJSONTyped,
    RelatedOptionGroupDTOStringStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface RelatedGroupedOptionalSelectDTOStringString
 */
export interface RelatedGroupedOptionalSelectDTOStringString {
    /**
     * 
     * @type {OptionDTOString}
     * @memberof RelatedGroupedOptionalSelectDTOStringString
     */
    value?: OptionDTOString;
    /**
     * 
     * @type {Array<RelatedOptionGroupDTOStringString>}
     * @memberof RelatedGroupedOptionalSelectDTOStringString
     */
    relatedCategorizedOptions: Array<RelatedOptionGroupDTOStringString>;
}

export function RelatedGroupedOptionalSelectDTOStringStringFromJSON(json: any): RelatedGroupedOptionalSelectDTOStringString {
    return RelatedGroupedOptionalSelectDTOStringStringFromJSONTyped(json, false);
}

export function RelatedGroupedOptionalSelectDTOStringStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedGroupedOptionalSelectDTOStringString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : OptionDTOStringFromJSON(json['value']),
        'relatedCategorizedOptions': ((json['relatedCategorizedOptions'] as Array<any>).map(RelatedOptionGroupDTOStringStringFromJSON)),
    };
}

export function RelatedGroupedOptionalSelectDTOStringStringToJSON(value?: RelatedGroupedOptionalSelectDTOStringString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': OptionDTOStringToJSON(value.value),
        'relatedCategorizedOptions': ((value.relatedCategorizedOptions as Array<any>).map(RelatedOptionGroupDTOStringStringToJSON)),
    };
}


