/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
} from '../models';

export interface DeleteRoofColorRequest {
    orderTypeKey: string;
    optionCode: string;
}

export interface GetBaseInformationOfRoofColorRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

/**
 * 
 */
export class OrderTypeRoofColorDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a RoofColor by a given key
     */
    async deleteRoofColorRaw(requestParameters: DeleteRoofColorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling deleteRoofColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling deleteRoofColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/roof-color`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a RoofColor by a given key
     */
    async deleteRoofColor(requestParameters: DeleteRoofColorRequest): Promise<void> {
        await this.deleteRoofColorRaw(requestParameters);
    }

    /**
     * Get baseInformation of RoofColor with given key
     */
    async getBaseInformationOfRoofColorRaw(requestParameters: GetBaseInformationOfRoofColorRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getBaseInformationOfRoofColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getBaseInformationOfRoofColor.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfRoofColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/roof-color/base-information`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of RoofColor with given key
     */
    async getBaseInformationOfRoofColor(requestParameters: GetBaseInformationOfRoofColorRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfRoofColorRaw(requestParameters);
        return await response.value();
    }

}
