/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TechnicalDataAttributeValueType,
    TechnicalDataAttributeValueTypeFromJSON,
    TechnicalDataAttributeValueTypeFromJSONTyped,
    TechnicalDataAttributeValueTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface TechnicalDataAttributeDataColumnDefinition
 */
export interface TechnicalDataAttributeDataColumnDefinition {
    /**
     * 
     * @type {string}
     * @memberof TechnicalDataAttributeDataColumnDefinition
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalDataAttributeDataColumnDefinition
     */
    label: string;
    /**
     * 
     * @type {TechnicalDataAttributeValueType}
     * @memberof TechnicalDataAttributeDataColumnDefinition
     */
    type: TechnicalDataAttributeValueType;
}

export function TechnicalDataAttributeDataColumnDefinitionFromJSON(json: any): TechnicalDataAttributeDataColumnDefinition {
    return TechnicalDataAttributeDataColumnDefinitionFromJSONTyped(json, false);
}

export function TechnicalDataAttributeDataColumnDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataAttributeDataColumnDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': json['field'],
        'label': json['label'],
        'type': TechnicalDataAttributeValueTypeFromJSON(json['type']),
    };
}

export function TechnicalDataAttributeDataColumnDefinitionToJSON(value?: TechnicalDataAttributeDataColumnDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'label': value.label,
        'type': TechnicalDataAttributeValueTypeToJSON(value.type),
    };
}


