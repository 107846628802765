/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExteriorColorCustomizationItemDTOValue,
    ExteriorColorCustomizationItemDTOValueFromJSON,
    ExteriorColorCustomizationItemDTOValueFromJSONTyped,
    ExteriorColorCustomizationItemDTOValueToJSON,
    OptionCustomizationConditionalValueDTOExteriorColorCustomizationItemDTOValue,
    OptionCustomizationConditionalValueDTOExteriorColorCustomizationItemDTOValueFromJSON,
    OptionCustomizationConditionalValueDTOExteriorColorCustomizationItemDTOValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOExteriorColorCustomizationItemDTOValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExteriorColorCustomizationItemDTO
 */
export interface ExteriorColorCustomizationItemDTO {
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorCustomizationItemDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorCustomizationItemDTO
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExteriorColorCustomizationItemDTO
     */
    modelGenerationNames: Array<string>;
    /**
     * 
     * @type {ExteriorColorCustomizationItemDTOValue}
     * @memberof ExteriorColorCustomizationItemDTO
     */
    defaultValue: ExteriorColorCustomizationItemDTOValue;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOExteriorColorCustomizationItemDTOValue>}
     * @memberof ExteriorColorCustomizationItemDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOExteriorColorCustomizationItemDTOValue>;
}

export function ExteriorColorCustomizationItemDTOFromJSON(json: any): ExteriorColorCustomizationItemDTO {
    return ExteriorColorCustomizationItemDTOFromJSONTyped(json, false);
}

export function ExteriorColorCustomizationItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExteriorColorCustomizationItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'optionCode': json['optionCode'],
        'modelGenerationNames': json['modelGenerationNames'],
        'defaultValue': ExteriorColorCustomizationItemDTOValueFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOExteriorColorCustomizationItemDTOValueFromJSON)),
    };
}

export function ExteriorColorCustomizationItemDTOToJSON(value?: ExteriorColorCustomizationItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'optionCode': value.optionCode,
        'modelGenerationNames': value.modelGenerationNames,
        'defaultValue': ExteriorColorCustomizationItemDTOValueToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOExteriorColorCustomizationItemDTOValueToJSON)),
    };
}


