import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { CustomExteriorColorFilterOptionsDTO, ExteriorColorType } from "../../../../../generated/pdsapi"
import { CustomExteriorColorFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const CustomExteriorColorListFilter: FunctionComponent<TableFilterProps<CustomExteriorColorFilter, CustomExteriorColorFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const exteriorColorTypeFilter = (
        <MultiSelect
            label={"Exterior color type"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.exteriorColorTypes.map(toOption) ?? []}
            value={filter.exteriorColorTypes ?? []}
            onChange={(exteriorColorTypes) =>
                setFilter({
                    ...filter,
                    exteriorColorTypes: exteriorColorTypes.length ? exteriorColorTypes.map((type) => type as ExteriorColorType) : undefined,
                })
            }
        />
    )
    const exteriorColorTagKeysFilter = (
        <MultiSelect
            label={"Exterior color tag"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.exteriorColorTags.map(toOption) ?? []}
            value={filter.exteriorColorTagKeys ?? []}
            onChange={(exteriorColorTagKeys) =>
                setFilter({
                    ...filter,
                    exteriorColorTagKeys: exteriorColorTagKeys.length ? exteriorColorTagKeys : undefined,
                })
            }
        />
    )
    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Exterior color tag"}>{exteriorColorTagKeysFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Exterior color type"}>{exteriorColorTypeFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
