import { key as FlatpickrLanguage } from "flatpickr/dist/types/locale"

export const joinIndexed = <T>(array: T[], withSeparatorFn: (index: number) => T): T[] =>
    array.flatMap((value, index) => (index === 0 ? [value] : [withSeparatorFn(index), value]))

export const isObject = (a: any) => a && typeof a === "object" && !Array.isArray(a) && !(a instanceof Date)

export const removeEmptyFields = <T extends { [index: string]: any }>(obj: T): T => {
    Object.entries(obj).forEach(([k, v]) => {
        if (isObject(v)) {
            removeEmptyFields(v)
        } else if (Array.isArray(v)) {
            v.forEach((item) => removeEmptyFields(item))
        } else if (v === "") {
            delete obj[k]
        }
    })

    return obj
}

export const getEnumKeyByEnumValue = <
    T extends {
        [index: string]: string
    },
>(
    myEnum: T,
    enumValue: string
): keyof T | undefined => Object.keys(myEnum).find((x) => myEnum[x] === enumValue)

export const getStandardLanguageCode = (languageCode: string) => {
    const languageCodeParts = languageCode.split("-")
    if (languageCodeParts.length === 1) {
        return languageCodeParts[0]
    } else {
        return `${languageCodeParts[0]}-${languageCodeParts[1].toUpperCase()}`
    }
}

export const browserLanguage = getStandardLanguageCode(navigator.language)

export const getFlatpickrLanguage = (languageCode: string): FlatpickrLanguage => {
    const languageCodeParts = languageCode.split("-")
    return languageCodeParts[0] as FlatpickrLanguage
}
export const browserFlatpickrLanguage = getFlatpickrLanguage(navigator.language)

const utcDateTimeFormatter = Intl.DateTimeFormat("de-DE", { timeZone: "UTC", dateStyle: "medium", timeStyle: "long" })
export const formatAsUtcDateTime = (date?: Date): string | undefined => {
    if (date == undefined || isNaN(+date)) return undefined // an invalid date will be represented as NaN internally in JavasScript
    return utcDateTimeFormatter.format(date)
}

export const couldBeOfType = <T>(given: unknown): given is Partial<Record<keyof T, unknown>> => typeof given === "object" && given !== null

export const hasMessage = (e: unknown): e is { message: string } =>
    couldBeOfType<{
        message: string
    }>(e) && typeof e.message === "string"
