import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../components/tag/Tag"
import { CustomInteriorColorFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { CustomInteriorColorFilter } from "../../../../../pdsapi"

export const CustomInteriorColorListFilterTagsConfig: TableFilterTagsConfig<CustomInteriorColorFilter, CustomInteriorColorFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const filterTags: JSX.Element[] = []

    filter.interiorColorTagKeys?.forEach((interiorColorTagKey) => {
        filterTags.push(
            <Tag
                onRemove={() => {
                    const newTags = filter.interiorColorTagKeys?.filter((key) => interiorColorTagKey !== key)
                    setFilter({
                        ...filter,
                        interiorColorTagKeys: newTags?.length ? newTags : undefined,
                    })
                }}
            >
                {filterOptions?.interiorColorTags?.find((it) => it.key === interiorColorTagKey)?.label ?? interiorColorTagKey}
            </Tag>
        )
    })

    return filterTags
}
