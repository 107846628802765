import { getLanguageGroups } from "../../../../../common/getLanguageGroups"
import { propertiesOf } from "../../../../../common/property"
import { FormElementWrapper } from "../../../../../components/formelements/FormElementWrapper"
import { getTextFormElement } from "../../../../../components/formelements/textformelement/TextFormElement"
import { RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { ProductDetailsCardContentProps } from "../../ProductDetailsCard"
import { spacing } from "@porsche-design-system/utilities"
import { FC, useMemo } from "react"
import { useIntl } from "react-intl"
import { CustomizationVideoUpdateDTO } from "../../../../../generated/pdsapi"
import { PLinkPure } from "@porsche-design-system/components-react"
import styled from "styled-components"
import { VideoLocalizationsDTO } from "../../../../../pdsapi"

export const OptionVideosCardContent: FC<ProductDetailsCardContentProps<VideoLocalizationsDTO, CustomizationVideoUpdateDTO>> = ({ formDisplayData }) => {
    const intl = useIntl()
    const properties = propertiesOf<CustomizationVideoUpdateDTO>()

    const languageGroups = useMemo(() => getLanguageGroups(Object.keys(formDisplayData.data ?? {}), intl, true), [formDisplayData.data?.video, intl])

    return (
        <SpacedItems lineSpacing={spacing[32]} direction={"column"}>
            {languageGroups.map(({ label, languages }) => (
                <RowGroupWrapper key={label} label={label} childrenSize={6}>
                    {languages.map(({ label, languageTag }) => (
                        <fieldset key={languageTag}>
                            <legend>{label}</legend>
                            <FormElementWrapper
                                label={`Vimeo ID`}
                                formDisplayData={formDisplayData}
                                field={properties.video._get(languageTag).vimeoId}
                                FormElement={getTextFormElement<VideoLocalizationsDTO, CustomizationVideoUpdateDTO>()}
                                getViewProps={(data) => ({
                                    value: data[languageTag]?.withFallback?.vimeoId ?? "",
                                    showAsHint: !data[languageTag]?.raw,
                                })}
                                getEditProps={(data) => ({
                                    value: data[languageTag]?.raw?.vimeoId ?? "",
                                    optional: true,
                                })}
                            />
                            <FormElementWrapper
                                label={`Vimeo Hash`}
                                formDisplayData={formDisplayData}
                                field={properties.video._get(languageTag).hash}
                                FormElement={getTextFormElement<VideoLocalizationsDTO, CustomizationVideoUpdateDTO>()}
                                getViewProps={(data) => ({
                                    value: data[languageTag]?.withFallback?.hash ?? "",
                                    showAsHint: !data[languageTag]?.raw,
                                })}
                                getEditProps={(data) => ({
                                    value: data[languageTag]?.raw?.hash ?? "",
                                    optional: true,
                                })}
                            />
                            {formDisplayData.data?.[languageTag]?.raw?.vimeoId && formDisplayData.data?.[languageTag]?.raw?.hash && (
                                <VimeoLink
                                    icon="external"
                                    target="_blank"
                                    href={`https://vimeo.com/${formDisplayData.data[languageTag]?.raw?.vimeoId}/${formDisplayData.data[languageTag]?.raw?.hash}`}
                                >
                                    Open in Vimeo
                                </VimeoLink>
                            )}
                        </fieldset>
                    ))}
                </RowGroupWrapper>
            ))}
        </SpacedItems>
    )
}

const VimeoLink = styled(PLinkPure)`
    margin-top: ${spacing["8"]};
`
