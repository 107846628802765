import { Sorting } from "../../../../common/Sorting"
import { SortDirection } from "../../../../generated/pdsapi"
import { TableCell } from "../cell/TableCell"
import { PIcon } from "@porsche-design-system/components-react"
import { PropsWithChildren } from "react"
import styled from "styled-components"

export interface TableHeaderCellSorting<SORTING_TYPE> {
    field: SORTING_TYPE
    value: Sorting<SORTING_TYPE>
    setValue: (sorting: Sorting<SORTING_TYPE>) => void
}

export interface TableSortingHeaderCellProps<SORTING_TYPE> {
    width?: number
    grow?: number
    sorting?: TableHeaderCellSorting<SORTING_TYPE>
}

export const TableSortingHeaderCell = <SORTING_TYPE,>(props: PropsWithChildren<TableSortingHeaderCellProps<SORTING_TYPE>>) => {
    const { width, grow, sorting, children } = props

    const onSortingChange =
        sorting &&
        (() => {
            sorting.setValue({
                sortField: sorting.field,
                sortDirection: sorting.value.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
            })
        })

    let sortDirection: "ascending" | "descending" | undefined
    if (sorting && sorting.value.sortField === sorting.field) {
        sortDirection = sorting.value.sortDirection === SortDirection.DESC ? "descending" : "ascending"
    }

    return (
        <TableCell width={width} grow={grow} onClick={onSortingChange}>
            {children}
            {sortDirection && <StyledPIcon color="inherit" size="inherit" name={sortDirection === "ascending" ? "arrow-up" : "arrow-down"} />}
        </TableCell>
    )
}

const StyledPIcon = styled(PIcon)`
    width: 20px;
`
