import { usePdsBootContext } from "../../../common/PdsBootContext"
import { showErrorToast } from "../../../common/errorToastHelper"
import { flattenDeepMapIntoValidationErrorMap } from "../../../common/forms"
import { browserLanguage, removeEmptyFields } from "../../../common/global"
import { EditFormDisplayData, FormDisplayData, FormDisplayMode } from "../../../components/formelements/FormDisplayData"
import { WidgetWrapper } from "../../../components/layouts/widgetwrapper/WidgetWrapper"
import { UserAuthority } from "../../../generated/pdsapi"
import { convertValidationErrorsToMap, isValidationApiError } from "../../../pdsapi/apiHelper"
import { useToast } from "@finder/ui-kit"
import { PButton, PButtonPure } from "@porsche-design-system/components-react"
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from "react"
import { FieldValues, Path, useForm } from "react-hook-form"
import { FieldPath } from "react-hook-form/dist/types/path"

export interface ProductDetailsCardContentProps<DATA, UPDATE extends FieldValues> {
    formDisplayData: FormDisplayData<DATA, UPDATE>
    urlParamsPrefix: string
    setLabel: Dispatch<SetStateAction<string | undefined>>
}

export interface ProductDetailsCardProps<DATA, UPDATE extends FieldValues, KEY = { key: string }> {
    label: string | undefined
    isDataEditable: boolean
    urlPathParamsPrefix: string

    itemKey: KEY
    reloadDataIndicator: any
    triggerReload: () => void
    getData: (props: KEY & { languageTag: string }) => Promise<DATA>
    updateData?: (props: KEY & { update: UPDATE }) => Promise<void>

    Content: FC<ProductDetailsCardContentProps<DATA, UPDATE>>
}

export const ProductDetailsCard = <DATA, UPDATE extends FieldValues, KEY = { key: string }>({
    label,
    isDataEditable,
    urlPathParamsPrefix,
    itemKey,
    reloadDataIndicator,
    triggerReload,
    getData,
    updateData,
    Content,
}: ProductDetailsCardProps<DATA, UPDATE, KEY>) => {
    const toastRef = useRef(useToast())

    const [formDisplayMode, setFormDisplayMode] = useState<FormDisplayMode>("LOADING")
    const [data, setData] = useState<DATA | undefined>(undefined)
    const [currentLabel, setLabel] = useState(label)

    useEffect(() => {
        const fetchData = async () => {
            setFormDisplayMode("LOADING")

            try {
                const fetchedData = await getData({ ...itemKey, languageTag: browserLanguage })
                setData(fetchedData)
                setFormDisplayMode("VIEW")
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchData()
    }, [getData, itemKey, reloadDataIndicator])

    switch (formDisplayMode) {
        case "LOADING":
            return <Loading label={currentLabel} urlPathParamsPrefix={urlPathParamsPrefix} Content={Content} setLabel={setLabel} />
        case "VIEW":
            return (
                <View
                    label={currentLabel}
                    isDataEditable={isDataEditable && updateData !== undefined}
                    urlPathParamsPrefix={urlPathParamsPrefix}
                    Content={Content}
                    data={data!}
                    setFormDisplayMode={setFormDisplayMode}
                    setLabel={setLabel}
                />
            )
        case "EDIT":
            return (
                <Edit
                    label={currentLabel}
                    urlPathParamsPrefix={urlPathParamsPrefix}
                    itemKey={itemKey}
                    triggerReload={triggerReload}
                    updateData={updateData}
                    Content={Content}
                    data={data!}
                    setFormDisplayMode={setFormDisplayMode}
                    setLabel={setLabel}
                />
            )
    }
}

const Loading = <DATA, UPDATE extends FieldValues>({
    label,
    urlPathParamsPrefix,
    Content,
    setLabel,
}: Pick<ProductDetailsCardProps<DATA, UPDATE>, "label" | "urlPathParamsPrefix" | "Content"> & {
    setLabel: Dispatch<SetStateAction<string | undefined>>
}) => (
    <WidgetWrapper label={label}>
        <Content formDisplayData={{ kind: "LOADING" }} urlParamsPrefix={`${urlPathParamsPrefix}.content`} setLabel={setLabel} />
    </WidgetWrapper>
)

const View = <DATA, UPDATE extends FieldValues>({
    label,
    isDataEditable,
    urlPathParamsPrefix,
    Content,
    data,
    setFormDisplayMode,
    setLabel,
}: Pick<ProductDetailsCardProps<DATA, UPDATE>, "label" | "isDataEditable" | "urlPathParamsPrefix" | "Content"> & {
    data: DATA
    setFormDisplayMode: (formDisplayMode: FormDisplayMode) => void
    setLabel: Dispatch<SetStateAction<string | undefined>>
}) => {
    const { hasAuthority } = usePdsBootContext()

    const actionButtons = useMemo(() => {
        if (!isDataEditable || !hasAuthority(UserAuthority.MAINTAIN_DATA)) {
            return []
        }
        return [
            <PButtonPure weight={"semibold"} key={"editButton"} icon={"edit"} onClick={() => setFormDisplayMode("EDIT")}>
                Edit
            </PButtonPure>,
        ]
    }, [hasAuthority, isDataEditable, setFormDisplayMode])

    return (
        <WidgetWrapper label={label} buttons={actionButtons}>
            <Content formDisplayData={{ kind: "VIEW", data }} urlParamsPrefix={`${urlPathParamsPrefix}.content`} setLabel={setLabel} />
        </WidgetWrapper>
    )
}

const Edit = <DATA, UPDATE extends FieldValues, KEY>({
    label,
    urlPathParamsPrefix,
    itemKey,
    triggerReload,
    updateData,
    Content,
    data,
    setFormDisplayMode,
    setLabel,
}: Pick<ProductDetailsCardProps<DATA, UPDATE, KEY>, "label" | "urlPathParamsPrefix" | "itemKey" | "triggerReload" | "updateData" | "Content"> & {
    data: DATA
    setFormDisplayMode: (formDisplayMode: FormDisplayMode) => void
    setLabel: Dispatch<SetStateAction<string | undefined>>
}) => {
    const toast = useToast()

    const { register, control, reset, handleSubmit, formState, setValue, getValues, watch } = useForm<UPDATE>({ mode: "onBlur" })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [validationErrors, setValidationErrors] = useState<Map<Path<UPDATE>, string>>(new Map())

    const flatErrors = flattenDeepMapIntoValidationErrorMap(formState.errors)
    const errorKeys = Array.from(flatErrors.keys()).toSorted().join(",")
    useEffect(() => {
        setValidationErrors(flatErrors)
    }, [errorKeys])

    const onSubmit = handleSubmit(async (update) => {
        if (!updateData) {
            return
        }

        setIsSubmitting(true)

        const cleanedUpdate = removeEmptyFields(update)
        try {
            await updateData({ ...itemKey, update: cleanedUpdate })
            triggerReload()
            toast.show("success", "Saved successfully.")
        } catch (e) {
            if (isValidationApiError(e)) {
                setValidationErrors(convertValidationErrorsToMap(e.validationErrors!))
            }

            showErrorToast(toast, e)
        } finally {
            setIsSubmitting(false)
        }
    })

    const actionButtons = [
        <PButton
            key={"clearButton"}
            icon={"reset"}
            variant={"tertiary"}
            onClick={() => {
                clearValues()
            }}
            type={"button"}
        >
            Clear
        </PButton>,
        <PButton
            key={"closeButton"}
            icon={"close"}
            variant={"tertiary"}
            onClick={() => {
                reset()
                setFormDisplayMode("VIEW")
            }}
            type={"button"}
        >
            Cancel
        </PButton>,
        <PButton key={"saveButton"} icon={"save"} type={"submit"} loading={isSubmitting}>
            Save
        </PButton>,
    ]
    const clearValues = () => {
        const values = getValues()
        Object.keys(values).forEach((key) => {
            const keyPath = key as FieldPath<UPDATE>
            const value = values[key]
            if (typeof value === "string") {
                // @ts-expect-error ts is unable to detect that keyPath has a string type
                setValue(keyPath, "")
            }
        })
    }

    const formDisplayData: EditFormDisplayData<DATA, UPDATE> = {
        kind: "EDIT",
        data,
        register,
        setValue,
        watch,
        control,
        validationErrors,
    }

    return (
        <form onSubmit={onSubmit}>
            <WidgetWrapper label={label} buttons={actionButtons}>
                <Content formDisplayData={formDisplayData} urlParamsPrefix={`${urlPathParamsPrefix}.content`} setLabel={setLabel} />
            </WidgetWrapper>
        </form>
    )
}
