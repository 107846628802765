/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NumberOfSeats {
    ONE = 'ONE',
    TWO = 'TWO',
    TWO_PLUS_TWO = 'TWO_PLUS_TWO',
    FOUR = 'FOUR',
    FOUR_PLUS_ONE = 'FOUR_PLUS_ONE',
    FIVE = 'FIVE'
}

export function NumberOfSeatsFromJSON(json: any): NumberOfSeats {
    return NumberOfSeatsFromJSONTyped(json, false);
}

export function NumberOfSeatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NumberOfSeats {
    return json as NumberOfSeats;
}

export function NumberOfSeatsToJSON(value?: NumberOfSeats | null): any {
    return value as any;
}

