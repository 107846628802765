import { WidgetWrapper } from "../../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { LoadableVehicleImageList } from "../../../../../../components/loadablevehicleimagelist/LoadableVehicleImageList"
import { customInteriorColorApi } from "../../../../../../pdsapi"
import { FC, useCallback } from "react"

export const CustomInteriorColorDetailsVehicleImagesCard: FC<{ itemKey: string }> = ({ itemKey }) => {
    const load = useCallback(
        () =>
            customInteriorColorApi.details.loadVehicleImages({
                key: itemKey,
            }),
        [itemKey]
    )
    return (
        <WidgetWrapper label={"Vehicle Images"}>
            <LoadableVehicleImageList load={load} />
        </WidgetWrapper>
    )
}
