/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EquipmentMarketplaceSpecificsIndexDTO,
    EquipmentMarketplaceSpecificsIndexDTOFromJSON,
    EquipmentMarketplaceSpecificsIndexDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
} from '../models';

export interface GetMarketplaceSpecificsOfEquipmentRequest {
    orderTypeKey: string;
    optionCode: string;
}

/**
 * 
 */
export class OrderTypeEquipmentMarketplaceSpecificsDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get marketplaceSpecifics of Equipment with given key
     */
    async getMarketplaceSpecificsOfEquipmentRaw(requestParameters: GetMarketplaceSpecificsOfEquipmentRequest): Promise<runtime.ApiResponse<EquipmentMarketplaceSpecificsIndexDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getMarketplaceSpecificsOfEquipment.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getMarketplaceSpecificsOfEquipment.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/equipment/marketplace-specifics`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentMarketplaceSpecificsIndexDTOFromJSON(jsonValue));
    }

    /**
     * Get marketplaceSpecifics of Equipment with given key
     */
    async getMarketplaceSpecificsOfEquipment(requestParameters: GetMarketplaceSpecificsOfEquipmentRequest): Promise<EquipmentMarketplaceSpecificsIndexDTO> {
        const response = await this.getMarketplaceSpecificsOfEquipmentRaw(requestParameters);
        return await response.value();
    }

}
