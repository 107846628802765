import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { RoofColorCustomizationCreateOptionsDTO, RoofColorCustomizationCreateRequestDTO } from "../../../../generated/pdsapi"
import { roofColorCustomizationApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { VehicleImagesCreateCard } from "../../../../viewtemplates/create/cards/vehicleimagescard/VehicleImagesCreateCard"
import { RoofColorCustomizationCreateAttributesCard } from "./attributes/RoofColorCustomizationCreateAttributesCard"

export const RoofColorCustomizationProductCreateContainerConfig: ProductCreateContainerConfig<
    RoofColorCustomizationCreateRequestDTO,
    RoofColorCustomizationCreateOptionsDTO
> = {
    headline: "Create new Roof Color Customization",

    create: (item) =>
        roofColorCustomizationApi.create.create({
            roofColorCustomizationCreateRequestDTO: item,
        }),
    getCreateOptions: roofColorCustomizationApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <RoofColorCustomizationCreateAttributesCard {...props} />
            <VehicleImagesCreateCard formDisplayData={props.formDisplayData} getVehicleImages={roofColorCustomizationApi.create.getVehicleImages} />
        </DefaultCardViewContentWrapper>
    ),
}

export const RoofColorCustomizationCreateContainer = getProductCreateContainer(RoofColorCustomizationProductCreateContainerConfig)
