/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncompleteOrderTypeDTO
 */
export interface IncompleteOrderTypeDTO {
    /**
     * 
     * @type {string}
     * @memberof IncompleteOrderTypeDTO
     */
    key: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IncompleteOrderTypeDTO
     */
    codes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IncompleteOrderTypeDTO
     */
    modelYear: number;
}

export function IncompleteOrderTypeDTOFromJSON(json: any): IncompleteOrderTypeDTO {
    return IncompleteOrderTypeDTOFromJSONTyped(json, false);
}

export function IncompleteOrderTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncompleteOrderTypeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'codes': json['codes'],
        'modelYear': json['modelYear'],
    };
}

export function IncompleteOrderTypeDTOToJSON(value?: IncompleteOrderTypeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'codes': value.codes,
        'modelYear': value.modelYear,
    };
}


