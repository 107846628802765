import { ViewState } from "../../common/ViewState"
import { Centered, CenteredProps } from "../layouts/centered/Centered"
import { SpacedItems } from "../layouts/spaceditems/SpacedItems"
import { PIcon, PSpinner, PText } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC } from "react"

export interface EmptyViewProps extends CenteredProps {
    viewState: ViewState
    errorMessage?: string
}

export const EmptyView: FC<EmptyViewProps> = (props) => {
    const getContent = (viewState: ViewState): { upper: JSX.Element; lower: JSX.Element } => {
        switch (viewState) {
            case ViewState.CONTENT:
                return {
                    upper: <PIcon key={"emptyIcon"} name={"information"} size={"large"} />,
                    lower: (
                        <PText key={"emptyMessage"} color={"neutral-contrast-medium"} align={"center"}>
                            Unfortunately there is no data yet.
                        </PText>
                    ),
                }
            case ViewState.LOADING:
                return {
                    upper: <PSpinner key={"loadingSpinner"} size={"medium"} />,
                    lower: (
                        <PText key={"loadingMessage"} color={"neutral-contrast-medium"} align={"center"}>
                            Loading data. Please be patient.
                        </PText>
                    ),
                }
            case ViewState.ERROR:
                return {
                    upper: <PIcon key={"errorIcon"} name={"warning"} size={"large"} />,
                    lower: (
                        <SpacedItems direction={"column"} lineSpacing={spacing[16]}>
                            <PText key={"errorMessage1"} color={"neutral-contrast-medium"} align={"center"}>
                                There has been an error while loading data. Please try again later.
                            </PText>
                            <PText key={"errorMessage2"} color={"neutral-contrast-high"} weight={"semi-bold"} align={"center"}>
                                {props.errorMessage ?? ""}
                            </PText>
                        </SpacedItems>
                    ),
                }
        }
    }

    const content = getContent(props.viewState)

    return (
        <Centered height={props.height}>
            <Centered height={"70px"}>{content.upper}</Centered>
            {content.lower}
        </Centered>
    )
}
