/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    ExteriorColorType,
    ExteriorColorTypeFromJSON,
    ExteriorColorTypeFromJSONTyped,
    ExteriorColorTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomExteriorColorAttributesUpdateDTO
 */
export interface CustomExteriorColorAttributesUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorAttributesUpdateDTO
     */
    key: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomExteriorColorAttributesUpdateDTO
     */
    knownNames: Array<string>;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof CustomExteriorColorAttributesUpdateDTO
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorAttributesUpdateDTO
     */
    hexCode: string;
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorAttributesUpdateDTO
     */
    exteriorColorTagKey?: string;
    /**
     * 
     * @type {ExteriorColorType}
     * @memberof CustomExteriorColorAttributesUpdateDTO
     */
    exteriorColorType?: ExteriorColorType;
}

export function CustomExteriorColorAttributesUpdateDTOFromJSON(json: any): CustomExteriorColorAttributesUpdateDTO {
    return CustomExteriorColorAttributesUpdateDTOFromJSONTyped(json, false);
}

export function CustomExteriorColorAttributesUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomExteriorColorAttributesUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'knownNames': json['knownNames'],
        'name': BaseNameDTOFromJSON(json['name']),
        'hexCode': json['hexCode'],
        'exteriorColorTagKey': !exists(json, 'exteriorColorTagKey') ? undefined : json['exteriorColorTagKey'],
        'exteriorColorType': !exists(json, 'exteriorColorType') ? undefined : ExteriorColorTypeFromJSON(json['exteriorColorType']),
    };
}

export function CustomExteriorColorAttributesUpdateDTOToJSON(value?: CustomExteriorColorAttributesUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'knownNames': value.knownNames,
        'name': BaseNameDTOToJSON(value.name),
        'hexCode': value.hexCode,
        'exteriorColorTagKey': value.exteriorColorTagKey,
        'exteriorColorType': ExteriorColorTypeToJSON(value.exteriorColorType),
    };
}


