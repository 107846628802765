import { TableFilterTagsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../../../components/tag/Tag"
import { OrderTypeInteriorFilterOptionsDTO } from "../../../../../../../generated/pdsapi"
import { OrderTypeInteriorsFilter } from "../../../../../../../pdsapi"
import { findOption } from "../../../../../../../viewtemplates/list/filter/ListFilterTagsConfig"

export const OrderTypeInteriorsCardFilterTagsConfig: TableFilterTagsConfig<OrderTypeInteriorsFilter, OrderTypeInteriorFilterOptionsDTO> = ({
    filterOptions,
    filter,
    setFilter,
}) => {
    const filterTags: JSX.Element[] = []

    filter.materials?.forEach((material) => {
        filterTags.push(
            <Tag
                key={`filterTag_interiorMaterial_${material}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newMaterials = filter.materials?.filter((key) => material !== key)
                        return {
                            ...filter,
                            materials: newMaterials?.length !== 0 ? newMaterials : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.materials, material)?.label ?? material}
            </Tag>
        )
    })

    filter.colorTagKeys?.forEach((interiorColorTagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_interiorColorTagKey_${interiorColorTagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newInteriorColorTagKeys = filter.colorTagKeys?.filter((key) => interiorColorTagKey !== key)
                        return {
                            ...filter,
                            tagKeys: newInteriorColorTagKeys?.length !== 0 ? newInteriorColorTagKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.colorTags, interiorColorTagKey)?.label ?? interiorColorTagKey}
            </Tag>
        )
    })

    return filterTags
}
