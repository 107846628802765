import styled from "styled-components"

export interface BoxProps {
    backgroundColor?: string
    width?: string
    height?: string
}

export const Box = styled.div<BoxProps>`
    ${(props) => (props.backgroundColor ? `background-color: ${props.backgroundColor};` : "")}
    ${(props) => (props.width ? `width: ${props.width};` : "")}
        ${(props) => (props.height ? `height: ${props.height};` : "")}
`
