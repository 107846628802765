/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    CreateResponseDTO,
    CreateResponseDTOFromJSON,
    CreateResponseDTOToJSON,
    ExteriorColorTagCreateRequestDTO,
    ExteriorColorTagCreateRequestDTOFromJSON,
    ExteriorColorTagCreateRequestDTOToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface CreateExteriorColorTagRequest {
    exteriorColorTagCreateRequestDTO: ExteriorColorTagCreateRequestDTO;
}

/**
 * 
 */
export class ExteriorColorTagCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new ExteriorColorTag
     */
    async createExteriorColorTagRaw(requestParameters: CreateExteriorColorTagRequest): Promise<runtime.ApiResponse<CreateResponseDTO>> {
        if (requestParameters.exteriorColorTagCreateRequestDTO === null || requestParameters.exteriorColorTagCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('exteriorColorTagCreateRequestDTO','Required parameter requestParameters.exteriorColorTagCreateRequestDTO was null or undefined when calling createExteriorColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/exterior-color-tags/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExteriorColorTagCreateRequestDTOToJSON(requestParameters.exteriorColorTagCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResponseDTOFromJSON(jsonValue));
    }

    /**
     * Create a new ExteriorColorTag
     */
    async createExteriorColorTag(requestParameters: CreateExteriorColorTagRequest): Promise<CreateResponseDTO> {
        const response = await this.createExteriorColorTagRaw(requestParameters);
        return await response.value();
    }

}
