import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../components/tag/Tag"
import { RoofColorCustomizationFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { RoofColorCustomizationFilter } from "../../../../../pdsapi"
import { findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"

export const RoofColorCustomizationListFilterTagsConfig: TableFilterTagsConfig<RoofColorCustomizationFilter, RoofColorCustomizationFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const filterTags: JSX.Element[] = []

    filter.modelGenerationKeys?.forEach((modelGenerationKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelGenerationKey_${modelGenerationKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelGenerationKeys = filter.modelGenerationKeys?.filter((key) => modelGenerationKey !== key)
                        return {
                            ...filter,
                            modelGenerationKeys: newModelGenerationKeys?.length !== 0 ? newModelGenerationKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.modelGenerations, modelGenerationKey)?.label ?? modelGenerationKey}
            </Tag>
        )
    })

    filter.roofColorTagKeys?.forEach((roofColorTagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_roofColorTagKey_${roofColorTagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newRoofColorTagKeys = filter.roofColorTagKeys?.filter((key) => roofColorTagKey !== key)
                        return {
                            ...filter,
                            roofColorTagKeys: newRoofColorTagKeys?.length !== 0 ? newRoofColorTagKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.roofColorTags, roofColorTagKey)?.label ?? roofColorTagKey}
            </Tag>
        )
    })

    return filterTags
}
