import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { CustomInteriorColorFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { CustomInteriorColorFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const CustomInteriorColorListFilter: FunctionComponent<TableFilterProps<CustomInteriorColorFilter, CustomInteriorColorFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const interiorColorTagKeysFilter = (
        <MultiSelect
            label={"Interior color tag"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.interiorColorTags.map(toOption) ?? []}
            value={filter.interiorColorTagKeys ?? []}
            onChange={(interiorColorTagKeys) =>
                setFilter({
                    ...filter,
                    interiorColorTagKeys: interiorColorTagKeys.length ? interiorColorTagKeys : undefined,
                })
            }
        />
    )
    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Interior color tag"}>{interiorColorTagKeysFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
