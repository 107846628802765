/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    VehicleEquipmentCategory,
    VehicleEquipmentCategoryFromJSON,
    VehicleEquipmentCategoryFromJSONTyped,
    VehicleEquipmentCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface EquipmentCreateRequestDTO
 */
export interface EquipmentCreateRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof EquipmentCreateRequestDTO
     */
    orderTypeKey: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCreateRequestDTO
     */
    optionCode: string;
    /**
     * 
     * @type {VehicleEquipmentCategory}
     * @memberof EquipmentCreateRequestDTO
     */
    category?: VehicleEquipmentCategory;
    /**
     * 
     * @type {number}
     * @memberof EquipmentCreateRequestDTO
     */
    sortIndex?: number;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof EquipmentCreateRequestDTO
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof EquipmentCreateRequestDTO
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCreateRequestDTO
     */
    family?: string;
}

export function EquipmentCreateRequestDTOFromJSON(json: any): EquipmentCreateRequestDTO {
    return EquipmentCreateRequestDTOFromJSONTyped(json, false);
}

export function EquipmentCreateRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentCreateRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderTypeKey': json['orderTypeKey'],
        'optionCode': json['optionCode'],
        'category': !exists(json, 'category') ? undefined : VehicleEquipmentCategoryFromJSON(json['category']),
        'sortIndex': !exists(json, 'sortIndex') ? undefined : json['sortIndex'],
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'family': !exists(json, 'family') ? undefined : json['family'],
    };
}

export function EquipmentCreateRequestDTOToJSON(value?: EquipmentCreateRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderTypeKey': value.orderTypeKey,
        'optionCode': value.optionCode,
        'category': VehicleEquipmentCategoryToJSON(value.category),
        'sortIndex': value.sortIndex,
        'name': BaseNameDTOToJSON(value.name),
        'description': BaseNameDTOToJSON(value.description),
        'family': value.family,
    };
}


