/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderTypePackagesDTOPackage,
    OrderTypePackagesDTOPackageFromJSON,
    OrderTypePackagesDTOPackageFromJSONTyped,
    OrderTypePackagesDTOPackageToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypePackagesDTO
 */
export interface OrderTypePackagesDTO {
    /**
     * 
     * @type {Array<OrderTypePackagesDTOPackage>}
     * @memberof OrderTypePackagesDTO
     */
    salesEquipment: Array<OrderTypePackagesDTOPackage>;
    /**
     * 
     * @type {Array<OrderTypePackagesDTOPackage>}
     * @memberof OrderTypePackagesDTO
     */
    technicalEquipment: Array<OrderTypePackagesDTOPackage>;
}

export function OrderTypePackagesDTOFromJSON(json: any): OrderTypePackagesDTO {
    return OrderTypePackagesDTOFromJSONTyped(json, false);
}

export function OrderTypePackagesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypePackagesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'salesEquipment': ((json['salesEquipment'] as Array<any>).map(OrderTypePackagesDTOPackageFromJSON)),
        'technicalEquipment': ((json['technicalEquipment'] as Array<any>).map(OrderTypePackagesDTOPackageFromJSON)),
    };
}

export function OrderTypePackagesDTOToJSON(value?: OrderTypePackagesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'salesEquipment': ((value.salesEquipment as Array<any>).map(OrderTypePackagesDTOPackageToJSON)),
        'technicalEquipment': ((value.technicalEquipment as Array<any>).map(OrderTypePackagesDTOPackageToJSON)),
    };
}


