export interface TagOption {
    value: string
    label: string
    route: string
}

export const toTagOption = (data: { key: string; label: string }, toRoute: (key: string) => string) => ({
    value: data.key,
    label: data.label,
    route: toRoute(data.key),
})
