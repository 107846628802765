import { propertiesOf } from "../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../components/formelements/EditFormElementWrapper"
import { getBoolFormElement } from "../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { getTagFormElement } from "../../../../../components/formelements/tagformelement/TagFormElement"
import { getTagsFormElement } from "../../../../../components/formelements/tagsformelement/TagsFormElement"
import { getTextFormElement } from "../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { ExteriorColorCustomizationCreateOptionsDTO, ExteriorColorCustomizationCreateRequestDTO } from "../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import { FC } from "react"
import { useLocation } from "react-router-dom"

export const ExteriorColorCustomizationCreateAttributesCard: FC<
    ProductCreateContentProps<ExteriorColorCustomizationCreateRequestDTO, ExteriorColorCustomizationCreateOptionsDTO>
> = ({ formDisplayData }) => {
    const properties = propertiesOf<ExteriorColorCustomizationCreateRequestDTO>()
    const searchParams = new URLSearchParams(useLocation().search)

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    field={properties.optionCode}
                    FormElement={getTextFormElement<ExteriorColorCustomizationCreateOptionsDTO, ExteriorColorCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: searchParams.get("optionCode") ?? "",
                    })}
                />
                <EditFormElementWrapper
                    label={"Model Generations"}
                    formDisplayData={formDisplayData}
                    field={properties.modelGenerationKeys}
                    FormElement={getTagsFormElement<ExteriorColorCustomizationCreateOptionsDTO, ExteriorColorCustomizationCreateRequestDTO>()}
                    getEditProps={(createOptions) => ({
                        options: createOptions.modelGenerations.map(toOption),
                        value: searchParams.getAll("modelGenerationKeys"),
                        emptyLabel: "-",
                        clearable: true,
                    })}
                />
                <EditFormElementWrapper
                    label={"Default Value Status"}
                    formDisplayData={formDisplayData}
                    field={properties.isDefaultValueActive}
                    FormElement={getBoolFormElement<ExteriorColorCustomizationCreateOptionsDTO, ExteriorColorCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: true,
                    })}
                />
            </RowGroupWrapper>

            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                    optional: true,
                })}
            />

            <CreateDefaultLocalizationsRow
                label={"Description"}
                formDisplayData={formDisplayData}
                field={properties.description}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                    optional: true,
                })}
            />

            <RowGroupWrapper label={"Color"}>
                <EditFormElementWrapper
                    label={"Hex Code"}
                    formDisplayData={formDisplayData}
                    field={properties.hexCode}
                    FormElement={getColorPickerFormElement<ExteriorColorCustomizationCreateOptionsDTO, ExteriorColorCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        hexValue: "#000000",
                        disableGlossy: true,
                    })}
                />
                <EditFormElementWrapper
                    label={"Exterior Color Tag"}
                    formDisplayData={formDisplayData}
                    field={properties.exteriorColorTagKey}
                    FormElement={getTagFormElement<ExteriorColorCustomizationCreateOptionsDTO, ExteriorColorCustomizationCreateRequestDTO>()}
                    getEditProps={(createOptions) => ({
                        options: createOptions.exteriorColorTags.map(toOption),
                        value: createOptions.exteriorColorTags[0].key,
                    })}
                />
                <EditFormElementWrapper
                    label={"Exterior Color Type"}
                    formDisplayData={formDisplayData}
                    field={properties.exteriorColorType}
                    FormElement={getTagFormElement<ExteriorColorCustomizationCreateOptionsDTO, ExteriorColorCustomizationCreateRequestDTO>()}
                    getEditProps={(createOptions) => ({
                        options: createOptions.exteriorColorTypes.map(toOption),
                        value: createOptions.exteriorColorTypes[0].key,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <EditFormElementWrapper
                    label={"Custom Color"}
                    formDisplayData={formDisplayData}
                    field={properties.isCustomColor}
                    FormElement={getBoolFormElement<ExteriorColorCustomizationCreateOptionsDTO, ExteriorColorCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        trueMessage: "Yes",
                        falseMessage: "No",
                        value: false,
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
