/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    OrderTypeManualPressEmbargoesUpdateDTO,
    OrderTypeManualPressEmbargoesUpdateDTOFromJSON,
    OrderTypeManualPressEmbargoesUpdateDTOToJSON,
    OrderTypePressEmbargoesDTO,
    OrderTypePressEmbargoesDTOFromJSON,
    OrderTypePressEmbargoesDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface GetOfOrderTypeRequest {
    key: string;
}

export interface UpdateManualOfOrderTypeRequest {
    key: string;
    orderTypeManualPressEmbargoesUpdateDTO: OrderTypeManualPressEmbargoesUpdateDTO;
}

/**
 * 
 */
export class OrderTypePressEmbargoDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get  of OrderType with given key
     */
    async getOfOrderTypeRaw(requestParameters: GetOfOrderTypeRequest): Promise<runtime.ApiResponse<OrderTypePressEmbargoesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getOfOrderType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/press-embargoes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypePressEmbargoesDTOFromJSON(jsonValue));
    }

    /**
     * Get  of OrderType with given key
     */
    async getOfOrderType(requestParameters: GetOfOrderTypeRequest): Promise<OrderTypePressEmbargoesDTO> {
        const response = await this.getOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates manual of an existing OrderType
     */
    async updateManualOfOrderTypeRaw(requestParameters: UpdateManualOfOrderTypeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateManualOfOrderType.');
        }

        if (requestParameters.orderTypeManualPressEmbargoesUpdateDTO === null || requestParameters.orderTypeManualPressEmbargoesUpdateDTO === undefined) {
            throw new runtime.RequiredError('orderTypeManualPressEmbargoesUpdateDTO','Required parameter requestParameters.orderTypeManualPressEmbargoesUpdateDTO was null or undefined when calling updateManualOfOrderType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/details/{key}/press-embargoes/manual`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderTypeManualPressEmbargoesUpdateDTOToJSON(requestParameters.orderTypeManualPressEmbargoesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates manual of an existing OrderType
     */
    async updateManualOfOrderType(requestParameters: UpdateManualOfOrderTypeRequest): Promise<void> {
        await this.updateManualOfOrderTypeRaw(requestParameters);
    }

}
