/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MasterDataSource,
    MasterDataSourceFromJSON,
    MasterDataSourceFromJSONTyped,
    MasterDataSourceToJSON,
    OptionDTOString,
    OptionDTOStringFromJSON,
    OptionDTOStringFromJSONTyped,
    OptionDTOStringToJSON,
    OptionalSelectDTODriveTrain,
    OptionalSelectDTODriveTrainFromJSON,
    OptionalSelectDTODriveTrainFromJSONTyped,
    OptionalSelectDTODriveTrainToJSON,
    OptionalSelectDTOEngineType,
    OptionalSelectDTOEngineTypeFromJSON,
    OptionalSelectDTOEngineTypeFromJSONTyped,
    OptionalSelectDTOEngineTypeToJSON,
    OptionalSelectDTONumberOfSeats,
    OptionalSelectDTONumberOfSeatsFromJSON,
    OptionalSelectDTONumberOfSeatsFromJSONTyped,
    OptionalSelectDTONumberOfSeatsToJSON,
    OrderTypeAttributesDTOBaseName,
    OrderTypeAttributesDTOBaseNameFromJSON,
    OrderTypeAttributesDTOBaseNameFromJSONTyped,
    OrderTypeAttributesDTOBaseNameToJSON,
    RelatedGroupedOptionalSelectDTOStringString,
    RelatedGroupedOptionalSelectDTOStringStringFromJSON,
    RelatedGroupedOptionalSelectDTOStringStringFromJSONTyped,
    RelatedGroupedOptionalSelectDTOStringStringToJSON,
    RelatedOptionalSelectDTOStringString,
    RelatedOptionalSelectDTOStringStringFromJSON,
    RelatedOptionalSelectDTOStringStringFromJSONTyped,
    RelatedOptionalSelectDTOStringStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeAttributesDTO
 */
export interface OrderTypeAttributesDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeAttributesDTO
     */
    codes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrderTypeAttributesDTO
     */
    modelYear: number;
    /**
     * 
     * @type {Array<OptionDTOString>}
     * @memberof OrderTypeAttributesDTO
     */
    furtherModelYears: Array<OptionDTOString>;
    /**
     * 
     * @type {RelatedGroupedOptionalSelectDTOStringString}
     * @memberof OrderTypeAttributesDTO
     */
    modelSeriesRelatedModelGeneration: RelatedGroupedOptionalSelectDTOStringString;
    /**
     * 
     * @type {RelatedOptionalSelectDTOStringString}
     * @memberof OrderTypeAttributesDTO
     */
    modelSeriesRelatedDerivative: RelatedOptionalSelectDTOStringString;
    /**
     * 
     * @type {MasterDataSource}
     * @memberof OrderTypeAttributesDTO
     */
    dataSource: MasterDataSource;
    /**
     * 
     * @type {OptionalSelectDTODriveTrain}
     * @memberof OrderTypeAttributesDTO
     */
    driveTrain: OptionalSelectDTODriveTrain;
    /**
     * 
     * @type {OptionalSelectDTOEngineType}
     * @memberof OrderTypeAttributesDTO
     */
    engineType: OptionalSelectDTOEngineType;
    /**
     * 
     * @type {OptionalSelectDTONumberOfSeats}
     * @memberof OrderTypeAttributesDTO
     */
    numberOfSeats: OptionalSelectDTONumberOfSeats;
    /**
     * 
     * @type {OrderTypeAttributesDTOBaseName}
     * @memberof OrderTypeAttributesDTO
     */
    name: OrderTypeAttributesDTOBaseName;
    /**
     * 
     * @type {boolean}
     * @memberof OrderTypeAttributesDTO
     */
    special: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderTypeAttributesDTO
     */
    limited: boolean;
}

export function OrderTypeAttributesDTOFromJSON(json: any): OrderTypeAttributesDTO {
    return OrderTypeAttributesDTOFromJSONTyped(json, false);
}

export function OrderTypeAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeAttributesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codes': json['codes'],
        'modelYear': json['modelYear'],
        'furtherModelYears': ((json['furtherModelYears'] as Array<any>).map(OptionDTOStringFromJSON)),
        'modelSeriesRelatedModelGeneration': RelatedGroupedOptionalSelectDTOStringStringFromJSON(json['modelSeriesRelatedModelGeneration']),
        'modelSeriesRelatedDerivative': RelatedOptionalSelectDTOStringStringFromJSON(json['modelSeriesRelatedDerivative']),
        'dataSource': MasterDataSourceFromJSON(json['dataSource']),
        'driveTrain': OptionalSelectDTODriveTrainFromJSON(json['driveTrain']),
        'engineType': OptionalSelectDTOEngineTypeFromJSON(json['engineType']),
        'numberOfSeats': OptionalSelectDTONumberOfSeatsFromJSON(json['numberOfSeats']),
        'name': OrderTypeAttributesDTOBaseNameFromJSON(json['name']),
        'special': json['special'],
        'limited': json['limited'],
    };
}

export function OrderTypeAttributesDTOToJSON(value?: OrderTypeAttributesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codes': value.codes,
        'modelYear': value.modelYear,
        'furtherModelYears': ((value.furtherModelYears as Array<any>).map(OptionDTOStringToJSON)),
        'modelSeriesRelatedModelGeneration': RelatedGroupedOptionalSelectDTOStringStringToJSON(value.modelSeriesRelatedModelGeneration),
        'modelSeriesRelatedDerivative': RelatedOptionalSelectDTOStringStringToJSON(value.modelSeriesRelatedDerivative),
        'dataSource': MasterDataSourceToJSON(value.dataSource),
        'driveTrain': OptionalSelectDTODriveTrainToJSON(value.driveTrain),
        'engineType': OptionalSelectDTOEngineTypeToJSON(value.engineType),
        'numberOfSeats': OptionalSelectDTONumberOfSeatsToJSON(value.numberOfSeats),
        'name': OrderTypeAttributesDTOBaseNameToJSON(value.name),
        'special': value.special,
        'limited': value.limited,
    };
}


