/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    OrderTypeAttributesDTO,
    OrderTypeAttributesDTOFromJSON,
    OrderTypeAttributesDTOToJSON,
    OrderTypeAttributesUpdateDTO,
    OrderTypeAttributesUpdateDTOFromJSON,
    OrderTypeAttributesUpdateDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface GetAttributesOfOrderTypeRequest {
    key: string;
    languageTag: string;
}

export interface UpdateAttributesOfOrderTypeRequest {
    key: string;
    orderTypeAttributesUpdateDTO: OrderTypeAttributesUpdateDTO;
}

/**
 * 
 */
export class OrderTypeDataDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get attributes of OrderType with given key
     */
    async getAttributesOfOrderTypeRaw(requestParameters: GetAttributesOfOrderTypeRequest): Promise<runtime.ApiResponse<OrderTypeAttributesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/data/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of OrderType with given key
     */
    async getAttributesOfOrderType(requestParameters: GetAttributesOfOrderTypeRequest): Promise<OrderTypeAttributesDTO> {
        const response = await this.getAttributesOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing OrderType
     */
    async updateAttributesOfOrderTypeRaw(requestParameters: UpdateAttributesOfOrderTypeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfOrderType.');
        }

        if (requestParameters.orderTypeAttributesUpdateDTO === null || requestParameters.orderTypeAttributesUpdateDTO === undefined) {
            throw new runtime.RequiredError('orderTypeAttributesUpdateDTO','Required parameter requestParameters.orderTypeAttributesUpdateDTO was null or undefined when calling updateAttributesOfOrderType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/details/{key}/data/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderTypeAttributesUpdateDTOToJSON(requestParameters.orderTypeAttributesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing OrderType
     */
    async updateAttributesOfOrderType(requestParameters: UpdateAttributesOfOrderTypeRequest): Promise<void> {
        await this.updateAttributesOfOrderTypeRaw(requestParameters);
    }

}
