import { modelSeriesApi } from "../../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../../viewtemplates/sort/ProductSortContainer"

export const ModelSeriesSortContainerTemplateProps: ProductSortContainerConfig = {
    listName: "Model Series",
    fetchSortables: modelSeriesApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => modelSeriesApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

export const ModelSeriesSortContainer = getProductSortContainer(ModelSeriesSortContainerTemplateProps)
