import { exteriorColorRoutes } from "../../../../common/routes"
import { exteriorColorCustomizationApi } from "../../../../pdsapi"
import { getCustomizationDetailsContainer } from "../../../../viewtemplates/details/CustomizationDetailsContainer"
import { ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLinkedOptionsCard } from "../../../../viewtemplates/details/cards/linkedoptionscard/DetailsLinkedOptionsCard"
import { DetailsVehicleImagesCard } from "../../../../viewtemplates/details/cards/vehicleimagescard/DetailsVehicleImagesCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { DetailsOptionCustomizationLocalizationTab } from "../../../../viewtemplates/details/tabs/optioncustomizationlocalizationtab/DetailsOptionCustomizationLocalizationTab"
import { ExteriorColorCustomizationDetailsAttributesCardContent } from "./data/attributes/ExteriorColorCustomizationDetailsAttributesCardContent"
import { CustomizationVideosTab } from "../../../../viewtemplates/details/tabs/optionscustomizationvideotab/CustomizationVideosTab"

export const ExteriorColorCustomizationProductDetailsContainerConfig: ProductDetailsContainerConfig = {
    getBaseInformation: exteriorColorCustomizationApi.details.getBaseInformation,
    deleteByKey: exteriorColorCustomizationApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={exteriorColorCustomizationApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            exteriorColorCustomizationApi.details.updateAttributes({
                                key,
                                optionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO: update,
                            })
                        }
                        Content={ExteriorColorCustomizationDetailsAttributesCardContent}
                    />
                    <DetailsLinkedOptionsCard {...props} getData={exteriorColorCustomizationApi.details.getLinks} getRoute={exteriorColorRoutes.details} />
                    <DetailsVehicleImagesCard {...props} load={exteriorColorCustomizationApi.details.getVehicleImages} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={exteriorColorCustomizationApi.details.getName}
                    updateDefaultValue={exteriorColorCustomizationApi.details.updateDefaultName}
                    updateConditionalValue={exteriorColorCustomizationApi.details.updateConditionalValueName}
                />
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={exteriorColorCustomizationApi.details.getDescription}
                    updateDefaultValue={exteriorColorCustomizationApi.details.updateDefaultDescription}
                    updateConditionalValue={exteriorColorCustomizationApi.details.updateConditionalValueDescription}
                />
            ),
        },
        {
            label: "Videos",
            Content: (props) => (
                <CustomizationVideosTab
                    {...props}
                    getData={exteriorColorCustomizationApi.details.getVideos}
                    updateDefaultValue={exteriorColorCustomizationApi.details.updateDefaultVideo}
                    updateConditionalValue={exteriorColorCustomizationApi.details.updateConditionalVideo}
                />
            ),
        },
    ],
}

export const ExteriorColorCustomizationDetailsContainer = getCustomizationDetailsContainer(ExteriorColorCustomizationProductDetailsContainerConfig)
