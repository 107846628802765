import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { ModelSeriesCreateContainer } from "./create/ModelSeriesCreateContainer"
import { ModelSeriesDetailsContainer } from "./details/ModelSeriesDetailsContainer"
import { ModelSeriesListContainer } from "./list/ModelSeriesListContainer"
import { ModelSeriesSortContainer } from "./sort/container/ModelSeriesSortContainer"
import { FC } from "react"

export const ModelSeriesRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Model Series",
        List: ModelSeriesListContainer,
        Details: ModelSeriesDetailsContainer,
        Create: ModelSeriesCreateContainer,
        Sort: ModelSeriesSortContainer,
    })
