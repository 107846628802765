/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    OrderTypeDetailsContextDTO,
    OrderTypeDetailsContextDTOFromJSON,
    OrderTypeDetailsContextDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
} from '../models';

export interface DeleteOrderTypeRequest {
    key: string;
}

export interface GetBaseInformationOfOrderTypeRequest {
    key: string;
    languageTag: string;
}

export interface GetContextOfOrderTypeRequest {
    key: string;
}

/**
 * 
 */
export class OrderTypeDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a OrderType by a given key
     */
    async deleteOrderTypeRaw(requestParameters: DeleteOrderTypeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteOrderType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a OrderType by a given key
     */
    async deleteOrderType(requestParameters: DeleteOrderTypeRequest): Promise<void> {
        await this.deleteOrderTypeRaw(requestParameters);
    }

    /**
     * Get baseInformation of OrderType with given key
     */
    async getBaseInformationOfOrderTypeRaw(requestParameters: GetBaseInformationOfOrderTypeRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of OrderType with given key
     */
    async getBaseInformationOfOrderType(requestParameters: GetBaseInformationOfOrderTypeRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get context of OrderType with given key
     */
    async getContextOfOrderTypeRaw(requestParameters: GetContextOfOrderTypeRequest): Promise<runtime.ApiResponse<OrderTypeDetailsContextDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getContextOfOrderType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/context`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeDetailsContextDTOFromJSON(jsonValue));
    }

    /**
     * Get context of OrderType with given key
     */
    async getContextOfOrderType(requestParameters: GetContextOfOrderTypeRequest): Promise<OrderTypeDetailsContextDTO> {
        const response = await this.getContextOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

}
