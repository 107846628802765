import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { DerivativeCreateContainer } from "./create/DerivativeCreateContainer"
import { DerivativeDetailsContainer } from "./details/DerivativeDetailsContainer"
import { DerivativeListContainer } from "./list/DerivativeListContainer"
import { DerivativeSortContainer } from "./sort/DerivativeSortContainer"
import { FC } from "react"

export const DerivativeRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Derivatives",
        List: DerivativeListContainer,
        Details: DerivativeDetailsContainer,
        Create: DerivativeCreateContainer,
        Sort: DerivativeSortContainer,
    })
