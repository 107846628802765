import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { PText } from "@porsche-design-system/components-react"
import { FC } from "react"
import { useIntl } from "react-intl"

export interface TechnicalDataAttributeDisclaimerProps {
    disclaimer: { [key: string]: string }
}

export const TechnicalDataAttributeDisclaimer: FC<TechnicalDataAttributeDisclaimerProps> = (props) => {
    const intl = useIntl()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            {Object.entries(props.disclaimer ?? {}).map(([languageCode, text]) => (
                <RowGroupWrapper childrenSize={12} key={languageCode} label={intl.formatDisplayName(languageCode, { type: "language" }) ?? languageCode}>
                    <PText>{text}</PText>
                </RowGroupWrapper>
            ))}
        </SpacedItems>
    )
}
