import { equipmentCustomizationRoutes } from "../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import { EquipmentCustomizationFilterOptionsDTO, EquipmentCustomizationItemDTO, SortDirection } from "../../../../generated/pdsapi"
import {
    equipmentCustomizationApi,
    EquipmentCustomizationFilter,
    EquipmentCustomizationSortField,
    EquipmentCustomizationSortFieldEnum,
} from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { EquipmentCustomizationListColumnsConfig } from "./columns/EquipmentCustomizationListColumns"
import { EquipmentCustomizationListFilter } from "./filter/EquipmentCustomizationListFilter"
import { EquipmentCustomizationListFilterTagsConfig } from "./filter/EquipmentCustomizationListFilterTagsConfig"

export const EquipmentCustomizationProductListContainerConfig: ProductListContainerConfig<
    EquipmentCustomizationItemDTO,
    EquipmentCustomizationFilter,
    EquipmentCustomizationFilterOptionsDTO,
    EquipmentCustomizationSortFieldEnum
> = {
    listName: "Equipment Customizations",
    createItemLabel: "Add new Equipment Customization",

    buildDetailsPath: (equipmentCustomization) => equipmentCustomizationRoutes.details(equipmentCustomization.key),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<EquipmentCustomizationFilter>({
        optionCodes: stringConverterGroup.optionalArray,
        modelGenerationKeys: stringConverterGroup.optionalArray,
        equipmentTagKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: EquipmentCustomizationSortField.OPTION_CODE,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(EquipmentCustomizationSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: equipmentCustomizationApi.list.getPage,
    fetchFilterOptions: equipmentCustomizationApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: EquipmentCustomizationListFilter,
    filterTagsConfig: EquipmentCustomizationListFilterTagsConfig,

    columnsConfig: EquipmentCustomizationListColumnsConfig,
}

export const EquipmentCustomizationListContainer = getProductListContainer(EquipmentCustomizationProductListContainerConfig)
