import { getProductDetailsContainer, ProductDetailsContainerConfig, ProductDetailsContainerProps } from "./ProductDetailsContainer"
import { color } from "@porsche-design-system/utilities"
import styled from "styled-components"

export const getCustomizationDetailsContainer =
    <KEY,>(config: ProductDetailsContainerConfig<KEY>) =>
    (props: ProductDetailsContainerProps<KEY>) => <Border>{getProductDetailsContainer(config)(props)}</Border>

const Border = styled.div`
    margin-top: -15px;
    padding-top: 15px;
    border-left: 12px solid ${color.brand};
`
