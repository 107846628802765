import { Property } from "../../../../../common/property"
import { FormDisplayData } from "../../../../../components/formelements/FormDisplayData"
import { FormElementWrapper } from "../../../../../components/formelements/FormElementWrapper"
import { getTextFormElement } from "../../../../../components/formelements/textformelement/TextFormElement"
import { RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { BaseNameDTO } from "../../../../../generated/pdsapi"
import { FieldValues } from "react-hook-form"

interface DefaultLocalizationsEditFormRowViewProps {
    value: BaseNameDTO
}

interface DefaultLocalizationsEditFormRowEditProps {
    value: BaseNameDTO
    optional?: boolean
}

export interface DefaultLocalizationsEditFormRowProps<DATA, UPDATE extends FieldValues> {
    label: string
    formDisplayData: FormDisplayData<DATA, UPDATE>
    field: Property<UPDATE, BaseNameDTO>
    getViewProps: (data: DATA) => DefaultLocalizationsEditFormRowViewProps
    getEditProps: (data: DATA) => DefaultLocalizationsEditFormRowEditProps
}

export const DetailsDefaultLocalizationsFormRow = <DATA, UPDATE extends FieldValues>({
    label,
    formDisplayData,
    field,
    getViewProps,
    getEditProps,
}: DefaultLocalizationsEditFormRowProps<DATA, UPDATE>) => (
    <>
        <RowGroupWrapper label={label}>
            <FormElementWrapper
                label={`${label} - de`}
                formDisplayData={formDisplayData}
                field={field.de}
                FormElement={getTextFormElement<DATA, UPDATE>()}
                getViewProps={(data) => {
                    const defaultNamesProps = getViewProps(data)

                    return {
                        value: defaultNamesProps.value.de,
                    }
                }}
                getEditProps={(data) => {
                    const defaultNamesProps = getEditProps(data)

                    return {
                        value: defaultNamesProps.value.de ?? "",
                        optional: defaultNamesProps.optional,
                    }
                }}
            />
            <FormElementWrapper
                label={`${label} - en`}
                formDisplayData={formDisplayData}
                field={field.en}
                FormElement={getTextFormElement<DATA, UPDATE>()}
                getViewProps={(data) => {
                    const defaultNamesProps = getViewProps(data)

                    return {
                        value: defaultNamesProps.value.en,
                    }
                }}
                getEditProps={(data) => {
                    const defaultNamesProps = getEditProps(data)

                    return {
                        value: defaultNamesProps.value.en ?? "",
                        optional: defaultNamesProps.optional,
                    }
                }}
            />
        </RowGroupWrapper>
    </>
)
