import { TableFilterProps } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toGroupedOption } from "../../../../../../../components/formelements/groupedselecttextformelement/GroupedSelectOption"
import { toOption } from "../../../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { VehicleEquipmentCategory, OrderTypeEquipmentFilterOptionsDTO } from "../../../../../../../generated/pdsapi"
import { OrderTypeEquipmentFilter } from "../../../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const OrderTypeEquipmentCardFilter: FunctionComponent<TableFilterProps<OrderTypeEquipmentFilter, OrderTypeEquipmentFilterOptionsDTO>> = (props) => {
    const categoryFilter = (
        <MultiSelect
            label={"Category"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={props.filterOptions?.categories.map(toOption)}
            value={props.filter.categories ?? []}
            onChange={(categories) => {
                props.setFilter((filter) => ({
                    ...filter,
                    categories: categories.length === 0 ? undefined : (categories as VehicleEquipmentCategory[]),
                }))
            }}
        />
    )

    const tagFilter = (
        <MultiSelect
            label={"Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={props.filterOptions?.tags.map(toGroupedOption)}
            value={props.filter.tagKeys ?? []}
            onChange={(tagKeys) =>
                props.setFilter((filter) => ({
                    ...filter,
                    tagKeys: tagKeys.length === 0 ? undefined : tagKeys,
                }))
            }
        />
    )
    const familyFilter = (
        <MultiSelect
            label={"Family"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={props.filterOptions?.families.map((family) => ({ value: family, label: family }))}
            value={props.filter.families ?? []}
            onChange={(families) => {
                props.setFilter((filter) => ({
                    ...filter,
                    families: families.length === 0 ? undefined : (families as string[]),
                }))
            }}
        />
    )

    return (
        <ColumnGroupsWrapper>
            {categoryFilter}
            {tagFilter}
            {familyFilter}
        </ColumnGroupsWrapper>
    )
}
