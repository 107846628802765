import { TableFilterTagsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../../../components/tag/Tag"
import { OrderTypeRoofColorFilterOptionsDTO } from "../../../../../../../generated/pdsapi"
import { OrderTypeRoofColorsFilter } from "../../../../../../../pdsapi"
import { findOption } from "../../../../../../../viewtemplates/list/filter/ListFilterTagsConfig"

export const OrderTypeRoofColorsCardFilterTagsConfig: TableFilterTagsConfig<OrderTypeRoofColorsFilter, OrderTypeRoofColorFilterOptionsDTO> = ({
    filterOptions,
    filter,
    setFilter,
}) => {
    const filterTags: JSX.Element[] = []

    filter.tagKeys?.forEach((roofColorTagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_roofColorTagKey_${roofColorTagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newRoofColorTagKeys = filter.tagKeys?.filter((key) => roofColorTagKey !== key)
                        return {
                            ...filter,
                            tagKeys: newRoofColorTagKeys?.length !== 0 ? newRoofColorTagKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.tags, roofColorTagKey)?.label ?? roofColorTagKey}
            </Tag>
        )
    })

    return filterTags
}
