/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LinkedPaintToSampleExteriorColorCustomizationDTOCustomization,
    LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationFromJSON,
    LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationFromJSONTyped,
    LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface LinkedPaintToSampleExteriorColorCustomizationDTO
 */
export interface LinkedPaintToSampleExteriorColorCustomizationDTO {
    /**
     * 
     * @type {LinkedPaintToSampleExteriorColorCustomizationDTOCustomization}
     * @memberof LinkedPaintToSampleExteriorColorCustomizationDTO
     */
    customization?: LinkedPaintToSampleExteriorColorCustomizationDTOCustomization;
    /**
     * 
     * @type {string}
     * @memberof LinkedPaintToSampleExteriorColorCustomizationDTO
     */
    modelGenerationKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LinkedPaintToSampleExteriorColorCustomizationDTO
     */
    hasSimilarCustomizations: boolean;
}

export function LinkedPaintToSampleExteriorColorCustomizationDTOFromJSON(json: any): LinkedPaintToSampleExteriorColorCustomizationDTO {
    return LinkedPaintToSampleExteriorColorCustomizationDTOFromJSONTyped(json, false);
}

export function LinkedPaintToSampleExteriorColorCustomizationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedPaintToSampleExteriorColorCustomizationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customization': !exists(json, 'customization') ? undefined : LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationFromJSON(json['customization']),
        'modelGenerationKey': !exists(json, 'modelGenerationKey') ? undefined : json['modelGenerationKey'],
        'hasSimilarCustomizations': json['hasSimilarCustomizations'],
    };
}

export function LinkedPaintToSampleExteriorColorCustomizationDTOToJSON(value?: LinkedPaintToSampleExteriorColorCustomizationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customization': LinkedPaintToSampleExteriorColorCustomizationDTOCustomizationToJSON(value.customization),
        'modelGenerationKey': value.modelGenerationKey,
        'hasSimilarCustomizations': value.hasSimilarCustomizations,
    };
}


