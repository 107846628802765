import { FC, useCallback, useEffect, useRef, useState } from "react"
import { ProductDetailsTabConfigContentProps } from "../../../../../viewtemplates/details/ProductDetailsContainer"
import { ViewContentWrapper } from "../../../../../components/layouts/viewcontentwrapper/ViewContentWrapper"
import { PFlex, PFlexItem, PIcon, PText } from "@porsche-design-system/components-react"
import { StyledCard } from "../../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import styled from "styled-components"
import { color, spacing } from "@porsche-design-system/utilities"
import { OrderTypeMediaSoundCard } from "./sound/OrderTypeMediaSoundCard"
import { ProductDetailsCard } from "../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { Accordion } from "../../../../../components/accordion/Accordion"
import { orderTypeApi } from "../../../../../pdsapi"
import { OrderTypeMediaRelatedDataDTO } from "../../../../../generated/pdsapi"
import { showErrorToast } from "../../../../../common/errorToastHelper"
import { useToast } from "@finder/ui-kit"
import { OrderTypeMediaContext } from "./OrderTypeMediaContext"

export const OrderTypeMediaCard: FC<ProductDetailsTabConfigContentProps> = (props: ProductDetailsTabConfigContentProps) => {
    const toastRef = useRef(useToast())

    const [selectedEntryIndex, setSelectedEntryIndex] = useState(0)

    const [relatedData, setRelatedData] = useState<OrderTypeMediaRelatedDataDTO>({
        possibleOptionCodes: [],
        mediaBasePath: "",
    })

    useEffect(() => {
        const fetchRelatedData = async () => {
            try {
                const relatedData = await orderTypeApi.details.media.getRelatedData({
                    ...props.itemKey,
                })
                setRelatedData(relatedData)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchRelatedData()
    }, [props.itemKey])

    const getEngineSounds = useCallback(async () => {
        const data = await orderTypeApi.details.media.getEngineSounds({
            ...props.itemKey,
        })
        return data
    }, [props.itemKey])

    return (
        <ViewContentWrapper>
            <PFlex>
                <PFlexItem width={"one-quarter"}>
                    <ContentArea>
                        <StyledCard>
                            <PFlex direction={"column"}>
                                <Accordion
                                    expandAll={false}
                                    initiallyExpandedIndex={0}
                                    urlParamsPrefix={`${props.urlPathParamsPrefix}.accordion`}
                                    items={[
                                        {
                                            label: "Sound",
                                            children: [
                                                <StyledMenuItem key="sound">
                                                    <PFlex justifyContent={"space-between"} onClick={() => setSelectedEntryIndex(0)}>
                                                        <PText weight={selectedEntryIndex === 0 ? "semibold" : "regular"}>Engine Sound</PText>
                                                        <PIcon name={"arrow-head-right"} />
                                                    </PFlex>
                                                </StyledMenuItem>,
                                            ],
                                        },
                                    ]}
                                />
                            </PFlex>
                        </StyledCard>
                    </ContentArea>
                </PFlexItem>
                <PFlexItem width={"three-quarters"}>
                    <PFlex direction={"column"}>
                        <ContentArea>
                            <StyledCard>
                                <OrderTypeMediaContext.Provider value={relatedData}>
                                    {selectedEntryIndex === 0 && (
                                        <ProductDetailsCard
                                            {...props}
                                            label={"Engine Sound"}
                                            getData={getEngineSounds}
                                            updateData={async ({ key, update }) => {
                                                await orderTypeApi.details.media.updateEngineSounds({
                                                    key,
                                                    orderTypeMediaUpdateDTO: update,
                                                })
                                                return
                                            }}
                                            Content={OrderTypeMediaSoundCard}
                                        />
                                    )}
                                </OrderTypeMediaContext.Provider>
                            </StyledCard>
                        </ContentArea>
                    </PFlex>
                </PFlexItem>
            </PFlex>
        </ViewContentWrapper>
    )
}

const ContentArea = styled.div`
    padding-right: ${spacing[16]};
    padding-bottom: ${spacing[16]};
`

const StyledMenuItem = styled.div`
    margin: 1rem 1rem 1rem 3rem;

    &:hover {
        cursor: pointer;
        color: ${color.brand};
    }
`
