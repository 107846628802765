import { RoofColorCatalogueRoutes } from "./catalogue/RoofColorCatalogueRoutes"
import { RoofColorCustomizationRoutes } from "./customizations/RoofColorCustomizationRoutes"
import { RoofColorTagRoutes } from "./tags/RoofColorTagRoutes"
import { FC } from "react"
import { Route, Routes } from "react-router-dom"

export const RoofColorRoutes: FC = () => (
    <Routes>
        <Route path={"customizations/*"} element={<RoofColorCustomizationRoutes />} />
        <Route path={"tags/*"} element={<RoofColorTagRoutes />} />
        <Route path={"catalogue/*"} element={<RoofColorCatalogueRoutes />} />
    </Routes>
)
