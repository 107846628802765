import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toGroupedOption } from "../../../../../components/formelements/groupedselecttextformelement/GroupedSelectOption"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { TechnicalDataFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { TechnicalDataFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const TechnicalDataListFilter: FunctionComponent<TableFilterProps<TechnicalDataFilter, TechnicalDataFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const modelSeriesKeysFilter = (
        <MultiSelect
            label={"Model Series"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelSeries.map(toOption)}
            value={filter.modelSeriesKeys ?? []}
            onChange={(modelSeriesKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelSeriesKeys: modelSeriesKeys.length === 0 ? undefined : modelSeriesKeys,
                    modelGenerationKeys: undefined,
                    orderTypeCodes: undefined,
                }))
            }
        />
    )

    const relevantModelGenerations = filter.modelSeriesKeys
        ? filterOptions?.modelSeriesRelatedModelGenerations.filter((modelSeriesGroup) => filter.modelSeriesKeys!.includes(modelSeriesGroup.relatedKey))
        : filterOptions?.modelSeriesRelatedModelGenerations
    const modelGenerationKeysFilter = (
        <MultiSelect
            label={"Model Generations"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={relevantModelGenerations?.map(toGroupedOption)}
            value={filter.modelGenerationKeys ?? []}
            onChange={(modelGenerationKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelGenerationKeys: modelGenerationKeys.length === 0 ? undefined : modelGenerationKeys,
                }))
            }
        />
    )

    const modelYearsFilter = (
        <MultiSelect
            label={"Model Years"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelYears.map((modelYearOption) => ({
                value: modelYearOption.key.toString(),
                label: modelYearOption.label,
            }))}
            value={filter.modelYears?.map((modelYear) => modelYear.toString()) ?? []}
            onChange={(modelYears) =>
                setFilter((filter) => ({
                    ...filter,
                    modelYears: modelYears.length === 0 ? undefined : modelYears.map((my) => +my),
                    orderTypeCodes: undefined,
                }))
            }
        />
    )

    const relevantOrderTypes =
        filter.modelGenerationKeys || filter.modelYears
            ? filterOptions?.modelYearAndGenerationRelatedOrderTypes
                  .map((modelYearGroup) => ({
                      ...modelYearGroup,
                      options: modelYearGroup.options.filter((relatedOption) => filter.modelGenerationKeys?.includes(relatedOption.relatedKey) ?? true),
                  }))
                  .filter((modelYearGroup) => modelYearGroup.options.length && (filter.modelYears?.includes(modelYearGroup.relatedKey) ?? true))
            : filterOptions?.modelYearAndGenerationRelatedOrderTypes
    const orderTypesFilter = (
        <MultiSelect
            label={"Order Types"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={relevantOrderTypes?.map(toGroupedOption)}
            value={filter.orderTypeKeys ?? []}
            onChange={(orderTypeKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    orderTypeKeys: orderTypeKeys.length === 0 ? undefined : orderTypeKeys,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper flexWidth={"one-third"}>
            <ColumnGroupWrapper label={"Model"}>
                {modelSeriesKeysFilter}
                {modelGenerationKeysFilter}
            </ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Order Type"}>
                {modelYearsFilter}
                {orderTypesFilter}
            </ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
