import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { OrderTypeCreateOptionsDTO, OrderTypeCreateRequestDTO } from "../../../../generated/pdsapi"
import { orderTypeApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { OrderTypeCreateAttributesCard } from "./attributes/OrderTypeCreateAttributesCard"

export const OrderTypeProductCreateContainerConfig: ProductCreateContainerConfig<OrderTypeCreateRequestDTO, OrderTypeCreateOptionsDTO> = {
    headline: "Create new Order Type",

    create: (item) =>
        orderTypeApi.create.create({
            orderTypeCreateRequestDTO: item,
        }),
    getCreateOptions: orderTypeApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <OrderTypeCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const OrderTypeCreateContainer = getProductCreateContainer(OrderTypeProductCreateContainerConfig)
