import { OrderTypeTechnicalDataRelatedDataDTO } from "../../../../../generated/pdsapi"
import { createContext, useContext } from "react"

export type OrderTypeTechnicalDataTabContextProps = OrderTypeTechnicalDataRelatedDataDTO

export const OrderTypeTechnicalDataTabContext = createContext<OrderTypeTechnicalDataTabContextProps>({
    possibleOptionCodes: [],
    engineDesigns: [],
    engineLayouts: [],
    fuelTypes: [],
    transmissions: [],
})

export const useOrderTypeTechnicalDataTabContext = () => {
    const context = useContext(OrderTypeTechnicalDataTabContext)
    if (!context) {
        throw new Error("useOrderTypeTechnicalDataTabContext requires a <OrderTypeTechnicalDataTabContext.Provider> to be in the component hierarchy.")
    }

    return context
}
