/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    CreateResponseDTO,
    CreateResponseDTOFromJSON,
    CreateResponseDTOToJSON,
    EquipmentCustomizationCreateOptionsDTO,
    EquipmentCustomizationCreateOptionsDTOFromJSON,
    EquipmentCustomizationCreateOptionsDTOToJSON,
    EquipmentCustomizationCreateRequestDTO,
    EquipmentCustomizationCreateRequestDTOFromJSON,
    EquipmentCustomizationCreateRequestDTOToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface CreateEquipmentCustomizationRequest {
    equipmentCustomizationCreateRequestDTO: EquipmentCustomizationCreateRequestDTO;
}

export interface GetEquipmentCustomizationCreateOptionsRequest {
    languageTag: string;
}

/**
 * 
 */
export class EquipmentCustomizationCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new EquipmentCustomization
     */
    async createEquipmentCustomizationRaw(requestParameters: CreateEquipmentCustomizationRequest): Promise<runtime.ApiResponse<CreateResponseDTO>> {
        if (requestParameters.equipmentCustomizationCreateRequestDTO === null || requestParameters.equipmentCustomizationCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('equipmentCustomizationCreateRequestDTO','Required parameter requestParameters.equipmentCustomizationCreateRequestDTO was null or undefined when calling createEquipmentCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/equipment-customizations/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EquipmentCustomizationCreateRequestDTOToJSON(requestParameters.equipmentCustomizationCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResponseDTOFromJSON(jsonValue));
    }

    /**
     * Create a new EquipmentCustomization
     */
    async createEquipmentCustomization(requestParameters: CreateEquipmentCustomizationRequest): Promise<CreateResponseDTO> {
        const response = await this.createEquipmentCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get select options to create EquipmentCustomizations
     */
    async getEquipmentCustomizationCreateOptionsRaw(requestParameters: GetEquipmentCustomizationCreateOptionsRequest): Promise<runtime.ApiResponse<EquipmentCustomizationCreateOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getEquipmentCustomizationCreateOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-customizations/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentCustomizationCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create EquipmentCustomizations
     */
    async getEquipmentCustomizationCreateOptions(requestParameters: GetEquipmentCustomizationCreateOptionsRequest): Promise<EquipmentCustomizationCreateOptionsDTO> {
        const response = await this.getEquipmentCustomizationCreateOptionsRaw(requestParameters);
        return await response.value();
    }

}
