import { FormDisplayData } from "./FormDisplayData"
import { LoadingProps, ViewProps } from "./FormElementWrapper"

export interface ReadonlyFormElement<VIEW_PROPS> {
    Loading: (props: LoadingProps) => JSX.Element | null
    View: (props: ViewProps<VIEW_PROPS>) => JSX.Element | null
}

export const ReadonlyFormElementWrapper = <DATA, VIEW_PROPS>({
    label,
    formDisplayData,
    FormElement,
    getViewProps,
}: {
    label: string | undefined
    formDisplayData: FormDisplayData<DATA, any>
    FormElement: ReadonlyFormElement<VIEW_PROPS>
    getViewProps: (data: DATA) => VIEW_PROPS
}) => {
    switch (formDisplayData.kind) {
        case "LOADING":
            return <FormElement.Loading label={label} />
        case "VIEW":
        case "EDIT":
            return <FormElement.View label={label} {...getViewProps(formDisplayData.data)} />
    }
}
