import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { CustomInteriorColorCreateOptionsDTO, CustomInteriorColorCreateRequestDTO } from "../../../../generated/pdsapi"
import { customInteriorColorApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { CustomInteriorColorCreateAttributesCard } from "./attributes/CustomInteriorColorCreateAttributesCard"
import { CustomInteriorColorCreateVehicleImagesCardContent } from "./vehicleimages/CustomInteriorColorCreateVehicleImagesCardContent"

export const CustomInteriorColorProductCreateContainerConfig: ProductCreateContainerConfig<
    CustomInteriorColorCreateRequestDTO,
    CustomInteriorColorCreateOptionsDTO
> = {
    headline: "Create new Custom Interior Color",

    create: (item) =>
        customInteriorColorApi.create.create({
            customInteriorColorCreateRequestDTO: item,
        }),
    getCreateOptions: customInteriorColorApi.create.getOptions,

    Content: (props) => (
        <>
            <DefaultCardViewContentWrapper>
                <CustomInteriorColorCreateAttributesCard {...props} />
            </DefaultCardViewContentWrapper>
            <DefaultCardViewContentWrapper>
                <CustomInteriorColorCreateVehicleImagesCardContent {...props} />
            </DefaultCardViewContentWrapper>
        </>
    ),
}

export const CustomInteriorColorCreateContainer = getProductCreateContainer(CustomInteriorColorProductCreateContainerConfig)
