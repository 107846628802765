import { TextSelector } from "../../selectors/textselector/TextSelector"
import { DefaultFormElementLoading, EditLabeledContentWrapper, EditProps, FormElement, ViewLabeledContentWrapper, ViewProps } from "../FormElementWrapper"
import { SelectOption } from "@finder/ui-kit/build/types/components/forms/multiSelect/MultiSelect"
import { PText } from "@porsche-design-system/components-react"
import { Controller, FieldValues } from "react-hook-form"

export const getSelectTextFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string,
    SelectTextFormElementViewProps,
    SelectTextFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: SelectTextFormElementView,
    Edit: SelectTextFormElementEdit,
})

export interface SelectTextFormElementViewProps {
    text: string

    showAsHint?: boolean
}

export const SelectTextFormElementView = ({ label, text, showAsHint }: ViewProps<SelectTextFormElementViewProps>) => (
    <ViewLabeledContentWrapper label={label}>
        <PText color={showAsHint ? "neutral-contrast-low" : "default"}>{showAsHint ? `${text} *` : text}</PText>
    </ViewLabeledContentWrapper>
)

export interface SelectTextFormElementEditProps {
    options: SelectOption[]
    value: string

    onChange?: (value: string) => void

    showAsHint?: boolean
    readOnly?: boolean
    optional?: boolean
}

export const SelectTextFormElementEdit = <DATA, UPDATE extends FieldValues>({
    label,
    field,
    value,
    options,
    onChange,
    readOnly,
    optional,
    control,
    validationErrors,
}: EditProps<DATA, UPDATE, string, SelectTextFormElementEditProps>) => (
    <EditLabeledContentWrapper label={label} optional={optional}>
        <Controller
            control={control}
            name={field._path}
            defaultValue={value as any}
            rules={{ required: !optional }}
            render={({ field }) => (
                <TextSelector
                    options={options}
                    value={field.value as any}
                    onChange={(newValue) => {
                        field.onChange(newValue)
                        if (onChange) onChange(newValue)
                    }}
                    readOnly={readOnly}
                    errorMessage={validationErrors.get(field.name)}
                />
            )}
        />
    </EditLabeledContentWrapper>
)
