/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ModelGenerationAttributesUpdateDTO
 */
export interface ModelGenerationAttributesUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof ModelGenerationAttributesUpdateDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGenerationAttributesUpdateDTO
     */
    modelSeriesKey: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGenerationAttributesUpdateDTO
     */
    faceliftOf?: string;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof ModelGenerationAttributesUpdateDTO
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {boolean}
     * @memberof ModelGenerationAttributesUpdateDTO
     */
    classic: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ModelGenerationAttributesUpdateDTO
     */
    pointOfSalesDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ModelGenerationAttributesUpdateDTO
     */
    endOfProductionDate?: Date;
}

export function ModelGenerationAttributesUpdateDTOFromJSON(json: any): ModelGenerationAttributesUpdateDTO {
    return ModelGenerationAttributesUpdateDTOFromJSONTyped(json, false);
}

export function ModelGenerationAttributesUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelGenerationAttributesUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelSeriesKey': json['modelSeriesKey'],
        'faceliftOf': !exists(json, 'faceliftOf') ? undefined : json['faceliftOf'],
        'name': BaseNameDTOFromJSON(json['name']),
        'classic': json['classic'],
        'pointOfSalesDate': !exists(json, 'pointOfSalesDate') ? undefined : (new Date(json['pointOfSalesDate'])),
        'endOfProductionDate': !exists(json, 'endOfProductionDate') ? undefined : (new Date(json['endOfProductionDate'])),
    };
}

export function ModelGenerationAttributesUpdateDTOToJSON(value?: ModelGenerationAttributesUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'modelSeriesKey': value.modelSeriesKey,
        'faceliftOf': value.faceliftOf,
        'name': BaseNameDTOToJSON(value.name),
        'classic': value.classic,
        'pointOfSalesDate': value.pointOfSalesDate === undefined ? undefined : (value.pointOfSalesDate.toISOString().substr(0,10)),
        'endOfProductionDate': value.endOfProductionDate === undefined ? undefined : (value.endOfProductionDate.toISOString().substr(0,10)),
    };
}


