/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderTypePaintToSampleExteriorColorItemDTO
 */
export interface OrderTypePaintToSampleExteriorColorItemDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderTypePaintToSampleExteriorColorItemDTO
     */
    imageKey?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderTypePaintToSampleExteriorColorItemDTO
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTypePaintToSampleExteriorColorItemDTO
     */
    optionCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypePaintToSampleExteriorColorItemDTO
     */
    name?: string;
}

export function OrderTypePaintToSampleExteriorColorItemDTOFromJSON(json: any): OrderTypePaintToSampleExteriorColorItemDTO {
    return OrderTypePaintToSampleExteriorColorItemDTOFromJSONTyped(json, false);
}

export function OrderTypePaintToSampleExteriorColorItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypePaintToSampleExteriorColorItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageKey': !exists(json, 'imageKey') ? undefined : json['imageKey'],
        'sortIndex': json['sortIndex'],
        'optionCode': json['optionCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function OrderTypePaintToSampleExteriorColorItemDTOToJSON(value?: OrderTypePaintToSampleExteriorColorItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imageKey': value.imageKey,
        'sortIndex': value.sortIndex,
        'optionCode': value.optionCode,
        'name': value.name,
    };
}


