import { DefaultCardViewContentWrapper } from "../../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { CreateOptionResponseDTO, RoofColorCreateRequestDTO } from "../../../../../generated/pdsapi"
import { roofColorApi } from "../../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { OrderTypeRoofColorCreateOptionCard } from "./option/OrderTypeRoofColorCreateOptionCard"

export const OrderTypeRoofColorProductCreateContainerConfig: ProductCreateContainerConfig<RoofColorCreateRequestDTO, unknown, CreateOptionResponseDTO> = {
    headline: "Create new Roof Color",

    create: (item) =>
        roofColorApi.create.create({
            roofColorCreateRequestDTO: item,
        }),
    getCreateOptions: async () => ({}),

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <OrderTypeRoofColorCreateOptionCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const OrderTypeRoofColorCreateContainer = getProductCreateContainer(OrderTypeRoofColorProductCreateContainerConfig)
