/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OrderTypePackagesDTO,
    OrderTypePackagesDTOFromJSON,
    OrderTypePackagesDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
} from '../models';

export interface GetEquipmentPackagesOfOrderTypeRequest {
    key: string;
    languageTag: string;
}

/**
 * 
 */
export class OrderTypePackageDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get equipmentPackages of OrderType with given key
     */
    async getEquipmentPackagesOfOrderTypeRaw(requestParameters: GetEquipmentPackagesOfOrderTypeRequest): Promise<runtime.ApiResponse<OrderTypePackagesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getEquipmentPackagesOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getEquipmentPackagesOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/packages/equipment-packages`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypePackagesDTOFromJSON(jsonValue));
    }

    /**
     * Get equipmentPackages of OrderType with given key
     */
    async getEquipmentPackagesOfOrderType(requestParameters: GetEquipmentPackagesOfOrderTypeRequest): Promise<OrderTypePackagesDTO> {
        const response = await this.getEquipmentPackagesOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

}
