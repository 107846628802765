import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { DerivativeCreateOptionsDTO, DerivativeCreateRequestDTO } from "../../../../generated/pdsapi"
import { derivativeApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { DerivativeCreateAttributesCard } from "./attributes/DerivativeCreateAttributesCard"

export const DerivativeProductCreateContainerConfig: ProductCreateContainerConfig<DerivativeCreateRequestDTO, DerivativeCreateOptionsDTO> = {
    headline: "Create new Derivative",

    create: (item) =>
        derivativeApi.create.create({
            derivativeCreateRequestDTO: item,
        }),
    getCreateOptions: derivativeApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <DerivativeCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const DerivativeCreateContainer = getProductCreateContainer(DerivativeProductCreateContainerConfig)
