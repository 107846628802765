import { getSimpleFormWatch } from "../../../../../components/formelements/FormDisplayData"
import { WidgetWrapper } from "../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { LoadableVehicleImageList } from "../../../../../components/loadablevehicleimagelist/LoadableVehicleImageList"
import { CustomExteriorColorCreateOptionsDTO, CustomExteriorColorCreateRequestDTO } from "../../../../../generated/pdsapi"
import { customExteriorColorApi } from "../../../../../pdsapi"
import { ProductCreateContentProps } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { FC, useCallback } from "react"

export const CustomExteriorColorCreateVehicleImagesCardContent: FC<
    ProductCreateContentProps<CustomExteriorColorCreateRequestDTO, CustomExteriorColorCreateOptionsDTO>
> = ({ formDisplayData }) => {
    const customColorCode = getSimpleFormWatch(formDisplayData)("key")
    const load = useCallback(
        async () =>
            (customColorCode &&
                (await customExteriorColorApi.details.loadVehicleImages({
                    key: customColorCode,
                }))) ||
            [],
        [customColorCode]
    )
    return <WidgetWrapper label={"Vehicle Images"}>{customColorCode ? <LoadableVehicleImageList load={load} /> : null}</WidgetWrapper>
}
