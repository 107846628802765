/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserAuthority {
    VIEW_DATA = 'VIEW_DATA',
    VIEW_UNPUBLISHED_DATA = 'VIEW_UNPUBLISHED_DATA',
    MAINTAIN_DATA = 'MAINTAIN_DATA',
    ADMINISTRATE_SERVICE = 'ADMINISTRATE_SERVICE'
}

export function UserAuthorityFromJSON(json: any): UserAuthority {
    return UserAuthorityFromJSONTyped(json, false);
}

export function UserAuthorityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthority {
    return json as UserAuthority;
}

export function UserAuthorityToJSON(value?: UserAuthority | null): any {
    return value as any;
}

