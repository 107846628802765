/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MasterDataSource {
    PCCD = 'PCCD',
    IPLONE = 'IPLONE',
    VIS = 'VIS',
    MANUAL = 'MANUAL'
}

export function MasterDataSourceFromJSON(json: any): MasterDataSource {
    return MasterDataSourceFromJSONTyped(json, false);
}

export function MasterDataSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterDataSource {
    return json as MasterDataSource;
}

export function MasterDataSourceToJSON(value?: MasterDataSource | null): any {
    return value as any;
}

