import { ViewHeadline } from "../../components/headlines/viewheadline/ViewHeadline"
import { DefaultCardViewContentWrapper } from "../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { IncompleteExteriorColorCustomizationsTableConfig } from "./configs/IncompleteExteriorColorCustomizationsTableConfig"
import { IncompleteInteriorCustomizationsTableConfig } from "./configs/IncompleteInteriorCustomizationsTableConfig"
import { IncompleteOrderTypesTableConfig } from "./configs/IncompleteOrderTypesTableConfig"
import { MissingExteriorColorCustomizationsTableConfig } from "./configs/MissingExteriorColorCustomizationsTableConfig"
import { MissingInteriorCustomizationsTableConfig } from "./configs/MissingInteriorCustomizationsTableConfig"
import { MissingRoofColorCustomizationsTableConfig } from "./configs/MissingRoofColorCustomizationsTableConfig"
import { UnmaintainedCustomExteriorColorsTableConfig } from "./configs/UnmaintainedCustomExteriorColorsTableConfig"
import { UnmaintainedCustomInteriorColorsTableConfig } from "./configs/UnmaintainedCustomInteriorColorsTableConfig"
import { DashboardPagedTable } from "./dashboardpagedtable/DashboardPagedTable"
import { PFlex, PFlexItem } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { Children, PropsWithChildren } from "react"
import styled from "styled-components"

export const Dashboard = () => (
    <>
        <ViewHeadline headline={"Dashboard"} />

        <DefaultCardViewContentWrapper>
            <DashboardCard>
                <DashboardPagedTable {...IncompleteExteriorColorCustomizationsTableConfig} />
                <DashboardPagedTable {...MissingExteriorColorCustomizationsTableConfig} />
            </DashboardCard>
            <DashboardCard>
                <></>
                <DashboardPagedTable {...MissingRoofColorCustomizationsTableConfig} />
            </DashboardCard>
            <DashboardCard>
                <DashboardPagedTable {...IncompleteInteriorCustomizationsTableConfig} />
                <DashboardPagedTable {...MissingInteriorCustomizationsTableConfig} />
            </DashboardCard>
            <DashboardCard>
                <DashboardPagedTable {...UnmaintainedCustomExteriorColorsTableConfig} />
                <DashboardPagedTable {...UnmaintainedCustomInteriorColorsTableConfig} />
            </DashboardCard>
            <DashboardCard>
                <DashboardPagedTable {...IncompleteOrderTypesTableConfig} />
            </DashboardCard>
        </DefaultCardViewContentWrapper>
    </>
)

const DashboardCard = (props: PropsWithChildren<object>) => {
    const children = Children.toArray(props.children)
    if (children.length > 2) {
        console.error("found more than 2 children in dashboard card. This is not yet supported")
    }

    return (
        <PFlex direction={"row"}>
            <Left width={"half"}>{children.length > 0 ? children[0] : null}</Left>
            <Right width={"half"}>{children.length > 1 ? children[1] : null}</Right>
        </PFlex>
    )
}

const Left = styled(PFlexItem)`
    padding-right: ${spacing[16]};
    border-right: 1px solid #cccccc;
`
const Right = styled(PFlexItem)`
    padding-left: ${spacing[16]};
    border-left: 1px solid #cccccc;
`
