/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOEquipmentTagCategory,
    OptionDTOEquipmentTagCategoryFromJSON,
    OptionDTOEquipmentTagCategoryFromJSONTyped,
    OptionDTOEquipmentTagCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface EquipmentTagFilterOptionsDTO
 */
export interface EquipmentTagFilterOptionsDTO {
    /**
     * 
     * @type {Array<OptionDTOEquipmentTagCategory>}
     * @memberof EquipmentTagFilterOptionsDTO
     */
    categories: Array<OptionDTOEquipmentTagCategory>;
}

export function EquipmentTagFilterOptionsDTOFromJSON(json: any): EquipmentTagFilterOptionsDTO {
    return EquipmentTagFilterOptionsDTOFromJSONTyped(json, false);
}

export function EquipmentTagFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentTagFilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categories': ((json['categories'] as Array<any>).map(OptionDTOEquipmentTagCategoryFromJSON)),
    };
}

export function EquipmentTagFilterOptionsDTOToJSON(value?: EquipmentTagFilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categories': ((value.categories as Array<any>).map(OptionDTOEquipmentTagCategoryToJSON)),
    };
}


