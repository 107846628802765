/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoofColorTagItemDTO
 */
export interface RoofColorTagItemDTO {
    /**
     * 
     * @type {string}
     * @memberof RoofColorTagItemDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof RoofColorTagItemDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoofColorTagItemDTO
     */
    hexCode: string;
    /**
     * 
     * @type {number}
     * @memberof RoofColorTagItemDTO
     */
    sortIndex: number;
    /**
     * 
     * @type {boolean}
     * @memberof RoofColorTagItemDTO
     */
    active: boolean;
}

export function RoofColorTagItemDTOFromJSON(json: any): RoofColorTagItemDTO {
    return RoofColorTagItemDTOFromJSONTyped(json, false);
}

export function RoofColorTagItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoofColorTagItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'hexCode': json['hexCode'],
        'sortIndex': json['sortIndex'],
        'active': json['active'],
    };
}

export function RoofColorTagItemDTOToJSON(value?: RoofColorTagItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'hexCode': value.hexCode,
        'sortIndex': value.sortIndex,
        'active': value.active,
    };
}


