import { modelGenerationApi } from "../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLocalizationCard } from "../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { ModelGenerationDetailsAttributesCardContent } from "./data/attributes/ModelGenerationDetailsAttributesCardContent"

export const ModelGenerationProductDetailsContainerConfig: ProductDetailsContainerConfig = {
    getBaseInformation: modelGenerationApi.details.getBaseInformation,
    deleteByKey: modelGenerationApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={modelGenerationApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            modelGenerationApi.details.updateAttributes({
                                key,
                                modelGenerationAttributesUpdateDTO: update,
                            })
                        }
                        Content={ModelGenerationDetailsAttributesCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={modelGenerationApi.details.getName}
                        updateData={(update) =>
                            modelGenerationApi.details.updateName({
                                key: update.key,
                                requestBody: update.update,
                            })
                        }
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

export const ModelGenerationDetailsContainer = getProductDetailsContainer(ModelGenerationProductDetailsContainerConfig)
