import { DefaultCardViewContentWrapper } from "../../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { CreateOptionResponseDTO, ExteriorColorCreateOptionsDTO, ExteriorColorCreateRequestDTO } from "../../../../../generated/pdsapi"
import { exteriorColorApi } from "../../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { OrderTypeExteriorColorCreateOptionCard } from "./option/OrderTypeExteriorColorCreateOptionCard"

export const OrderTypeExteriorColorProductCreateContainerConfig: ProductCreateContainerConfig<
    ExteriorColorCreateRequestDTO,
    ExteriorColorCreateOptionsDTO,
    CreateOptionResponseDTO
> = {
    headline: "Create new ExteriorColor",

    create: (item) =>
        exteriorColorApi.create.create({
            exteriorColorCreateRequestDTO: item,
        }),
    getCreateOptions: exteriorColorApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <OrderTypeExteriorColorCreateOptionCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const OrderTypeExteriorColorCreateContainer = getProductCreateContainer(OrderTypeExteriorColorProductCreateContainerConfig)
