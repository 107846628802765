import { ConfirmationModal } from "../modals/confirmationmodal/ConfirmationModal"
import { PButton } from "@porsche-design-system/components-react"
import { FC, useState } from "react"
import { useNavigate } from "react-router-dom"

interface NewCustomizationButtonProps {
    createPath: string
    buttonLabel: string | JSX.Element
    hasSimilarCustomizations: boolean
}

export const NewCustomizationButton: FC<NewCustomizationButtonProps> = (props) => {
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false)
    const createCustomizationAction = () => navigate(props.createPath)
    return (
        <div>
            <PButton
                key={"createButton"}
                type={"button"}
                icon={"plus"}
                onClick={props.hasSimilarCustomizations ? () => setOpenModal(true) : createCustomizationAction}
            >
                {props.buttonLabel}
            </PButton>
            {props.hasSimilarCustomizations && (
                <ConfirmationModal
                    headline={"Warning"}
                    content={
                        "There are already existing customizations for this option code in different model generations. " +
                        "If one of these matches this option, please add the missing model generation to the existing customization instead of creating a new one."
                    }
                    confirmIcon={"plus"}
                    confirmLabel={"Add new Customization"}
                    onCancel={() => setOpenModal(false)}
                    onConfirm={() => {
                        setOpenModal(false)
                        createCustomizationAction()
                    }}
                    isOpen={openModal}
                />
            )}
        </div>
    )
}
