/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    CreateResponseDTO,
    CreateResponseDTOFromJSON,
    CreateResponseDTOToJSON,
    ExteriorColorCustomizationCreateOptionsDTO,
    ExteriorColorCustomizationCreateOptionsDTOFromJSON,
    ExteriorColorCustomizationCreateOptionsDTOToJSON,
    ExteriorColorCustomizationCreateRequestDTO,
    ExteriorColorCustomizationCreateRequestDTOFromJSON,
    ExteriorColorCustomizationCreateRequestDTOToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    VehicleStateSearchResult,
    VehicleStateSearchResultFromJSON,
    VehicleStateSearchResultToJSON,
} from '../models';

export interface CreateExteriorColorCustomizationRequest {
    exteriorColorCustomizationCreateRequestDTO: ExteriorColorCustomizationCreateRequestDTO;
}

export interface GetExteriorColorCustomizationCreateOptionsRequest {
    languageTag: string;
}

export interface GetVehicleImagesForExteriorColorCustomizationRequest {
    optionCode: string;
    modelGenerationKeys: Array<string>;
    limit?: number;
}

/**
 * 
 */
export class ExteriorColorCustomizationCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new ExteriorColorCustomization
     */
    async createExteriorColorCustomizationRaw(requestParameters: CreateExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<CreateResponseDTO>> {
        if (requestParameters.exteriorColorCustomizationCreateRequestDTO === null || requestParameters.exteriorColorCustomizationCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('exteriorColorCustomizationCreateRequestDTO','Required parameter requestParameters.exteriorColorCustomizationCreateRequestDTO was null or undefined when calling createExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/exterior-color-customizations/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExteriorColorCustomizationCreateRequestDTOToJSON(requestParameters.exteriorColorCustomizationCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResponseDTOFromJSON(jsonValue));
    }

    /**
     * Create a new ExteriorColorCustomization
     */
    async createExteriorColorCustomization(requestParameters: CreateExteriorColorCustomizationRequest): Promise<CreateResponseDTO> {
        const response = await this.createExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get select options to create ExteriorColorCustomizations
     */
    async getExteriorColorCustomizationCreateOptionsRaw(requestParameters: GetExteriorColorCustomizationCreateOptionsRequest): Promise<runtime.ApiResponse<ExteriorColorCustomizationCreateOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getExteriorColorCustomizationCreateOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color-customizations/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExteriorColorCustomizationCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create ExteriorColorCustomizations
     */
    async getExteriorColorCustomizationCreateOptions(requestParameters: GetExteriorColorCustomizationCreateOptionsRequest): Promise<ExteriorColorCustomizationCreateOptionsDTO> {
        const response = await this.getExteriorColorCustomizationCreateOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getVehicleImagesForExteriorColorCustomizationRaw(requestParameters: GetVehicleImagesForExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<Array<VehicleStateSearchResult>>> {
        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getVehicleImagesForExteriorColorCustomization.');
        }

        if (requestParameters.modelGenerationKeys === null || requestParameters.modelGenerationKeys === undefined) {
            throw new runtime.RequiredError('modelGenerationKeys','Required parameter requestParameters.modelGenerationKeys was null or undefined when calling getVehicleImagesForExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.modelGenerationKeys) {
            queryParameters['modelGenerationKeys'] = requestParameters.modelGenerationKeys;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color-customizations/create/vehicle-images`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateSearchResultFromJSON));
    }

    /**
     */
    async getVehicleImagesForExteriorColorCustomization(requestParameters: GetVehicleImagesForExteriorColorCustomizationRequest): Promise<Array<VehicleStateSearchResult>> {
        const response = await this.getVehicleImagesForExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

}
