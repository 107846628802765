import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { EquipmentCustomizationItemDTO } from "../../../../../generated/pdsapi"
import { EquipmentCustomizationSortField, EquipmentCustomizationSortFieldEnum } from "../../../../../pdsapi"

type EquipmentCustomizationColumnBuilder = ColumnBuilder<EquipmentCustomizationItemDTO, EquipmentCustomizationSortFieldEnum>

export const EquipmentCustomizationListColumnsConfig: TableColumnsConfig<EquipmentCustomizationItemDTO, EquipmentCustomizationSortFieldEnum> = ({
    sorting,
    setSorting,
}) => {
    const optionCodeColumn: EquipmentCustomizationColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Option Code",
            sorting: {
                field: EquipmentCustomizationSortField.OPTION_CODE,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (equipmentCustomization) => equipmentCustomization.optionCode,
    }
    const modelGenerationColumn: EquipmentCustomizationColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Model Generations",
        },
        buildCellContent: (equipmentCustomization) => equipmentCustomization.modelGenerationNames.join(", "),
    }
    const equipmentTagsColumn: EquipmentCustomizationColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Equipment Tags",
        },
        buildCellContent: (equipmentCustomization) => equipmentCustomization.defaultValue.equipmentTagNames.join(", "),
    }

    return [optionCodeColumn, modelGenerationColumn, equipmentTagsColumn]
}
