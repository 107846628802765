import { modelGenerationApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"

export const ModelGenerationSortContainerTemplateProps: ProductSortContainerConfig = {
    listName: "Model Generations",
    fetchSortables: modelGenerationApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => modelGenerationApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

export const ModelGenerationSortContainer = getProductSortContainer(ModelGenerationSortContainerTemplateProps)
