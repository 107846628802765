/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UnmaintainedCustomColorDTO,
    UnmaintainedCustomColorDTOFromJSON,
    UnmaintainedCustomColorDTOFromJSONTyped,
    UnmaintainedCustomColorDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageDTOUnmaintainedCustomColorDTO
 */
export interface PageDTOUnmaintainedCustomColorDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOUnmaintainedCustomColorDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<UnmaintainedCustomColorDTO>}
     * @memberof PageDTOUnmaintainedCustomColorDTO
     */
    content: Array<UnmaintainedCustomColorDTO>;
}

export function PageDTOUnmaintainedCustomColorDTOFromJSON(json: any): PageDTOUnmaintainedCustomColorDTO {
    return PageDTOUnmaintainedCustomColorDTOFromJSONTyped(json, false);
}

export function PageDTOUnmaintainedCustomColorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOUnmaintainedCustomColorDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(UnmaintainedCustomColorDTOFromJSON)),
    };
}

export function PageDTOUnmaintainedCustomColorDTOToJSON(value?: PageDTOUnmaintainedCustomColorDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItemCount': value.totalItemCount,
        'content': ((value.content as Array<any>).map(UnmaintainedCustomColorDTOToJSON)),
    };
}


