export type EnvironmentNames = "PROD" | "DEV" | "LOCAL"

type Environment = {
    name: EnvironmentNames
    backendUrl: string
    imageBaseUrl: string
}

export const getEnvironment = (host: string): Environment => {
    switch (true) {
        case /\.finder\.porsche\.com/.test(host):
            return { name: "PROD", backendUrl: "https://product.finder.porsche.com", imageBaseUrl: "https://cdn.images.slfinpub.aws.porsche.cloud" }
        case /\.finder-k\.porsche\.com/.test(host):
            return { name: "DEV", backendUrl: "https://product.finder-k.porsche.com", imageBaseUrl: "https://cdn.images.slfinpub.aws.porsche-preview.cloud" }
        default:
            return { name: "LOCAL", backendUrl: "http://localhost:8080", imageBaseUrl: "https://cdn.images.slfinpub.aws.porsche.cloud" }
    }
}

export const getCurrentEnvironment = (): Environment => getEnvironment(window.location.host)
