import { GroupedTextSelector } from "../../selectors/groupedtextselector/GroupedTextSelector"
import { DefaultFormElementLoading, EditLabeledContentWrapper, EditProps, FormElement } from "../FormElementWrapper"
import { SelectTextFormElementView, SelectTextFormElementViewProps } from "../selecttextformelement/SelectTextFormElement"
import { GroupedSelectOption } from "@finder/ui-kit/build/types/components/forms/multiSelect/MultiSelect"
import { Controller, FieldValues } from "react-hook-form"

export const getGroupedSelectTextFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string,
    SelectTextFormElementViewProps,
    GroupedSelectTextFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: SelectTextFormElementView,
    Edit: GroupedSelectTextFormElementEdit,
})

export interface GroupedSelectTextFormElementEditProps {
    options: GroupedSelectOption[]
    value: string

    readOnly?: boolean
}

export const GroupedSelectTextFormElementEdit = <DATA, UPDATE extends FieldValues>({
    label,
    control,
    field,
    options,
    readOnly,
    validationErrors,
    value,
}: EditProps<DATA, UPDATE, string, GroupedSelectTextFormElementEditProps>) => {
    const errorMessage = validationErrors.get(field._path)

    return (
        <EditLabeledContentWrapper label={label}>
            <Controller
                control={control}
                name={field._path}
                defaultValue={value as any}
                render={({ field }) => (
                    <GroupedTextSelector
                        groups={options.map((groupedSelectOption) => ({
                            name: groupedSelectOption.label,
                            options: groupedSelectOption.options,
                        }))}
                        value={field.value as any}
                        onChange={field.onChange}
                        readOnly={readOnly}
                        errorMessage={errorMessage}
                    />
                )}
            />
        </EditLabeledContentWrapper>
    )
}
