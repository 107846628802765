import { interiorColorTagApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"

export const InteriorColorTagSortContainerTemplateProps: ProductSortContainerConfig = {
    listName: "Interior Color Tags",
    fetchSortables: interiorColorTagApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => interiorColorTagApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

export const InteriorColorTagSortContainer = getProductSortContainer(InteriorColorTagSortContainerTemplateProps)
