import { DeepMap, FieldError, FieldErrors, FieldValues, Path } from "react-hook-form"

const mapFieldErrorToValidationError = (fieldError: FieldError): string => {
    // other types in field error are not implemented so far, feel free to extend if needed
    if (fieldError.type === "required") {
        return "This field is required"
    }

    return fieldError.message || "Validation of field failed"
}

export const flattenDeepMapIntoValidationErrorMap = <T extends FieldValues>(
    obj: FieldErrors<T>,
    memo: Map<Path<T>, string> = new Map<Path<T>, string>(),
    paths: string[] = []
) => {
    Object.entries(obj).forEach(([fieldName, fieldError]) => {
        if ((fieldError as FieldError).type === undefined) {
            flattenDeepMapIntoValidationErrorMap(fieldError as DeepMap<any, any>, memo, paths.concat([fieldName]))
        } else {
            memo.set(paths.concat([fieldName]).join(".") as Path<T>, mapFieldErrorToValidationError(fieldError as FieldError))
        }
    })
    return memo
}
