import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { InteriorCustomizationCreateOptionsDTO, InteriorCustomizationCreateRequestDTO } from "../../../../generated/pdsapi"
import { interiorCustomizationApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { VehicleImagesCreateCard } from "../../../../viewtemplates/create/cards/vehicleimagescard/VehicleImagesCreateCard"
import { InteriorCustomizationCreateAttributesCard } from "./attributes/InteriorCustomizationCreateAttributesCard"

export const InteriorCustomizationProductCreateContainerConfig: ProductCreateContainerConfig<
    InteriorCustomizationCreateRequestDTO,
    InteriorCustomizationCreateOptionsDTO
> = {
    headline: "Create new Interior Customization",

    create: (item) =>
        interiorCustomizationApi.create.create({
            interiorCustomizationCreateRequestDTO: item,
        }),
    getCreateOptions: interiorCustomizationApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <InteriorCustomizationCreateAttributesCard {...props} />
            <VehicleImagesCreateCard formDisplayData={props.formDisplayData} getVehicleImages={interiorCustomizationApi.create.getVehicleImages} />
        </DefaultCardViewContentWrapper>
    ),
}

export const InteriorCustomizationCreateContainer = getProductCreateContainer(InteriorCustomizationProductCreateContainerConfig)
