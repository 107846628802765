import { Bool3Selector } from "../../../selectors/bool3selector/Bool3Selector"
import { FC } from "react"

export interface ActiveFilterProps {
    active?: boolean
    onChange: (active?: boolean) => void
}

export const ActiveFilter: FC<ActiveFilterProps> = (props) => (
    <Bool3Selector
        label={"Active"}
        value={props.active}
        onChange={props.onChange}
        trueMessage={"Active Only"}
        falseMessage={"Inactive Only"}
        undefinedMessage={"All"}
    />
)
