import { exteriorColorCustomizationRoutes } from "../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import { ExteriorColorCustomizationFilterOptionsDTO, ExteriorColorCustomizationItemDTO, SortDirection } from "../../../../generated/pdsapi"
import {
    exteriorColorCustomizationApi,
    ExteriorColorCustomizationFilter,
    ExteriorColorCustomizationSortField,
    ExteriorColorCustomizationSortFieldEnum,
} from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { ExteriorColorCustomizationListColumnsConfig } from "./columns/ExteriorColorCustomizationListColumns"
import { ExteriorColorCustomizationListFilter } from "./filter/ExteriorColorCustomizationListFilter"
import { ExteriorColorCustomizationListFilterTagsConfig } from "./filter/ExteriorColorCustomizationListFilterTagsConfig"

export const ExteriorColorCustomizationProductListContainerConfig: ProductListContainerConfig<
    ExteriorColorCustomizationItemDTO,
    ExteriorColorCustomizationFilter,
    ExteriorColorCustomizationFilterOptionsDTO,
    ExteriorColorCustomizationSortFieldEnum
> = {
    listName: "Exterior Color Customizations",
    createItemLabel: "Add new Exterior Color Customization",

    buildDetailsPath: (exteriorColorCustomization) => exteriorColorCustomizationRoutes.details(exteriorColorCustomization.key),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<ExteriorColorCustomizationFilter>({
        optionCodes: stringConverterGroup.optionalArray,
        modelGenerationKeys: stringConverterGroup.optionalArray,
        exteriorColorTagKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: ExteriorColorCustomizationSortField.OPTION_CODE,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(ExteriorColorCustomizationSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: exteriorColorCustomizationApi.list.getPage,
    fetchFilterOptions: exteriorColorCustomizationApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: ExteriorColorCustomizationListFilter,
    filterTagsConfig: ExteriorColorCustomizationListFilterTagsConfig,

    columnsConfig: ExteriorColorCustomizationListColumnsConfig,
}

export const ExteriorColorCustomizationListContainer = getProductListContainer(ExteriorColorCustomizationProductListContainerConfig)
