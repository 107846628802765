/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorTileDTO,
    ColorTileDTOFromJSON,
    ColorTileDTOFromJSONTyped,
    ColorTileDTOToJSON,
    InteriorColorTileWithLabelDTO,
    InteriorColorTileWithLabelDTOFromJSON,
    InteriorColorTileWithLabelDTOFromJSONTyped,
    InteriorColorTileWithLabelDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeInteriorItemDTO
 */
export interface OrderTypeInteriorItemDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderTypeInteriorItemDTO
     */
    imageKey?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderTypeInteriorItemDTO
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeInteriorItemDTO
     */
    optionCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeInteriorItemDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeInteriorItemDTO
     */
    materialName?: string;
    /**
     * 
     * @type {InteriorColorTileWithLabelDTO}
     * @memberof OrderTypeInteriorItemDTO
     */
    color?: InteriorColorTileWithLabelDTO;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof OrderTypeInteriorItemDTO
     */
    colorTag?: ColorTileDTO;
}

export function OrderTypeInteriorItemDTOFromJSON(json: any): OrderTypeInteriorItemDTO {
    return OrderTypeInteriorItemDTOFromJSONTyped(json, false);
}

export function OrderTypeInteriorItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeInteriorItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageKey': !exists(json, 'imageKey') ? undefined : json['imageKey'],
        'sortIndex': json['sortIndex'],
        'optionCode': json['optionCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'materialName': !exists(json, 'materialName') ? undefined : json['materialName'],
        'color': !exists(json, 'color') ? undefined : InteriorColorTileWithLabelDTOFromJSON(json['color']),
        'colorTag': !exists(json, 'colorTag') ? undefined : ColorTileDTOFromJSON(json['colorTag']),
    };
}

export function OrderTypeInteriorItemDTOToJSON(value?: OrderTypeInteriorItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imageKey': value.imageKey,
        'sortIndex': value.sortIndex,
        'optionCode': value.optionCode,
        'name': value.name,
        'materialName': value.materialName,
        'color': InteriorColorTileWithLabelDTOToJSON(value.color),
        'colorTag': ColorTileDTOToJSON(value.colorTag),
    };
}


