import { equipmentRoutes } from "../../../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../../../common/useURLSearchParamState"
import { OrderTypeEquipmentFilterOptionsDTO, OrderTypeEquipmentItemDTO, VehicleEquipmentCategory } from "../../../../../../generated/pdsapi"
import { orderTypeApi, OrderTypeEquipmentFilter } from "../../../../../../pdsapi"
import { OrderTypeOptionsCardProps } from "../OrderTypeOptionsCard"
import { OrderTypeEquipmentCardColumnsConfig } from "./columns/OrderTypeEquipmentCardColumns"
import { OrderTypeEquipmentCardFilter } from "./filter/OrderTypeEquipmentCardFilter"
import { OrderTypeEquipmentCardFilterTagsConfig } from "./filter/OrderTypeEquipmentCardFilterTagsConfig"

export const orderTypeEquipmentCardConfig: OrderTypeOptionsCardProps<OrderTypeEquipmentItemDTO, OrderTypeEquipmentFilter, OrderTypeEquipmentFilterOptionsDTO> =
    {
        label: "Equipment",

        getCreateRoute: equipmentRoutes.create,
        getDetailsRoute: equipmentRoutes.details,
        getOptions: orderTypeApi.details.options.getEquipment,
        getFilterOptions: orderTypeApi.details.options.getEquipmentFilterOptions,

        defaultFilter: {},
        filterUrlParamsConverter: getObjectURLParamConverter<OrderTypeEquipmentFilter>({
            tagKeys: stringConverterGroup.optionalArray,
            categories: getEnumConverterGroup(VehicleEquipmentCategory).optionalArray,
            families: stringConverterGroup.optionalArray,
        }),

        columnsConfig: OrderTypeEquipmentCardColumnsConfig,
        FilterComponent: OrderTypeEquipmentCardFilter,
        filterTagsConfig: OrderTypeEquipmentCardFilterTagsConfig,

        isInitiallyExpanded: false,
    }
