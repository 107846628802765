/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExteriorColorCustomizationAttributesValueUpdateDTO,
    ExteriorColorCustomizationAttributesValueUpdateDTOFromJSON,
    ExteriorColorCustomizationAttributesValueUpdateDTOFromJSONTyped,
    ExteriorColorCustomizationAttributesValueUpdateDTOToJSON,
    IntRange,
    IntRangeFromJSON,
    IntRangeFromJSONTyped,
    IntRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTO
 */
export interface OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTO
     */
    key: string;
    /**
     * 
     * @type {IntRange}
     * @memberof OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTO
     */
    modelYearRange?: IntRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTO
     */
    derivativeKeys?: Array<string>;
    /**
     * 
     * @type {ExteriorColorCustomizationAttributesValueUpdateDTO}
     * @memberof OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTO
     */
    value: ExteriorColorCustomizationAttributesValueUpdateDTO;
}

export function OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTOFromJSON(json: any): OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTO {
    return OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTOFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': !exists(json, 'modelYearRange') ? undefined : IntRangeFromJSON(json['modelYearRange']),
        'derivativeKeys': !exists(json, 'derivativeKeys') ? undefined : json['derivativeKeys'],
        'value': ExteriorColorCustomizationAttributesValueUpdateDTOFromJSON(json['value']),
    };
}

export function OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTOToJSON(value?: OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'modelYearRange': IntRangeToJSON(value.modelYearRange),
        'derivativeKeys': value.derivativeKeys,
        'value': ExteriorColorCustomizationAttributesValueUpdateDTOToJSON(value.value),
    };
}


