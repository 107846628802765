/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOString,
    OptionDTOStringFromJSON,
    OptionDTOStringFromJSONTyped,
    OptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface DerivativeCategoryLinksDTO
 */
export interface DerivativeCategoryLinksDTO {
    /**
     * 
     * @type {Array<OptionDTOString>}
     * @memberof DerivativeCategoryLinksDTO
     */
    derivatives: Array<OptionDTOString>;
}

export function DerivativeCategoryLinksDTOFromJSON(json: any): DerivativeCategoryLinksDTO {
    return DerivativeCategoryLinksDTOFromJSONTyped(json, false);
}

export function DerivativeCategoryLinksDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DerivativeCategoryLinksDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'derivatives': ((json['derivatives'] as Array<any>).map(OptionDTOStringFromJSON)),
    };
}

export function DerivativeCategoryLinksDTOToJSON(value?: DerivativeCategoryLinksDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'derivatives': ((value.derivatives as Array<any>).map(OptionDTOStringToJSON)),
    };
}


