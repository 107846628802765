/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExteriorColorGroup {
    CONTRASTS = 'CONTRASTS',
    SHADES = 'SHADES',
    DREAMS = 'DREAMS',
    LEGENDS = 'LEGENDS'
}

export function ExteriorColorGroupFromJSON(json: any): ExteriorColorGroup {
    return ExteriorColorGroupFromJSONTyped(json, false);
}

export function ExteriorColorGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExteriorColorGroup {
    return json as ExteriorColorGroup;
}

export function ExteriorColorGroupToJSON(value?: ExteriorColorGroup | null): any {
    return value as any;
}

