/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExteriorColorCustomizationAttributesValueUpdateDTO,
    ExteriorColorCustomizationAttributesValueUpdateDTOFromJSON,
    ExteriorColorCustomizationAttributesValueUpdateDTOFromJSONTyped,
    ExteriorColorCustomizationAttributesValueUpdateDTOToJSON,
    OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTO,
    OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTOFromJSON,
    OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTOFromJSONTyped,
    OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO
 */
export interface OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {ExteriorColorCustomizationAttributesValueUpdateDTO}
     * @memberof OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO
     */
    defaultValue: ExteriorColorCustomizationAttributesValueUpdateDTO;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTO>}
     * @memberof OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTO>;
}

export function OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTOFromJSON(json: any): OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO {
    return OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTOFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'isDefaultValueActive': json['isDefaultValueActive'],
        'defaultValue': ExteriorColorCustomizationAttributesValueUpdateDTOFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTOFromJSON)),
    };
}

export function OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTOToJSON(value?: OptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerationKeys': value.modelGenerationKeys,
        'isDefaultValueActive': value.isDefaultValueActive,
        'defaultValue': ExteriorColorCustomizationAttributesValueUpdateDTOToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesValueUpdateDTOToJSON)),
    };
}


