import { DefaultFormElementLoading, EditLabeledContentWrapper, EditProps, FormElement, ViewLabeledContentWrapper, ViewProps } from "../FormElementWrapper"
import { PText, PTextFieldWrapper } from "@porsche-design-system/components-react"
import { FieldValues } from "react-hook-form"
import { formatAsUtcDateTime } from "../../../common/global"

export const getDateTimeFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    Date,
    DateFormElementViewProps,
    DateFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: DateTimeFormElementView,
    Edit: DateTimeFormElementEdit,
})

interface DateFormElementViewProps {
    value: Date | undefined
}

export const DateTimeFormElementView = ({ label, value }: ViewProps<DateFormElementViewProps>) => (
    <ViewLabeledContentWrapper label={label}>
        <PText>{formatAsUtcDateTime(value) ?? "-"}</PText>
    </ViewLabeledContentWrapper>
)

interface DateFormElementEditProps {
    value: Date | undefined

    readOnly?: boolean
    optional?: boolean
}

export const DateTimeFormElementEdit = <DATA, UPDATE extends FieldValues>({
    label,
    field,
    value,
    optional,
    readOnly,
    validationErrors,
    register,
}: EditProps<DATA, UPDATE, Date, DateFormElementEditProps>) => {
    const fieldPath = field._path
    const errorMessage = validationErrors.get(fieldPath)
    const registeredInput = register(field._path, {
        required: !optional,
        setValueAs: (v) => {
            if (!v) return null
            return new Date(v)
        },
        validate: {
            isValid: (value?: Date) => {
                if (value == null || !isNaN(+value)) {
                    return true
                }
                return `Please enter a valid date in ISO 8601 format (e.g. "2024-12-24T09:00:00.000Z")`
            },
        },
    })

    return (
        <EditLabeledContentWrapper label={label} optional={optional}>
            <PTextFieldWrapper state={errorMessage ? "error" : "none"} message={validationErrors.get(fieldPath)}>
                <input {...registeredInput} type={"text"} defaultValue={value?.toISOString()} readOnly={readOnly} placeholder="e.g. 2024-12-24T09:00:00.000Z" />
            </PTextFieldWrapper>
        </EditLabeledContentWrapper>
    )
}
