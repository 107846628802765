/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOString,
    OptionDTOStringFromJSON,
    OptionDTOStringFromJSONTyped,
    OptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface DerivativeCategoryFilterOptionsDTO
 */
export interface DerivativeCategoryFilterOptionsDTO {
    /**
     * 
     * @type {Array<OptionDTOString>}
     * @memberof DerivativeCategoryFilterOptionsDTO
     */
    modelSeries: Array<OptionDTOString>;
}

export function DerivativeCategoryFilterOptionsDTOFromJSON(json: any): DerivativeCategoryFilterOptionsDTO {
    return DerivativeCategoryFilterOptionsDTOFromJSONTyped(json, false);
}

export function DerivativeCategoryFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DerivativeCategoryFilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelSeries': ((json['modelSeries'] as Array<any>).map(OptionDTOStringFromJSON)),
    };
}

export function DerivativeCategoryFilterOptionsDTOToJSON(value?: DerivativeCategoryFilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelSeries': ((value.modelSeries as Array<any>).map(OptionDTOStringToJSON)),
    };
}


