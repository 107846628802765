import { ReadonlyFormElementWrapper } from "../../../../../../../components/formelements/ReadonlyFormElementWrapper"
import { getTagsFormElement } from "../../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { ModelSeriesLinksDTO } from "../../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { FC } from "react"

export const ModelSeriesLinksCardContent: FC<
    ProductDetailsCardContentProps<ModelSeriesLinksDTO, Record<string, never>> & {
        getModelGenerationRoute: (key: string) => string
        getDerivativeCategoryRoute: (key: string) => string
    }
> = ({ formDisplayData, getModelGenerationRoute, getDerivativeCategoryRoute }) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <RowGroupWrapper label={"Model Generations"} childrenSize={12}>
            <ReadonlyFormElementWrapper
                label={undefined}
                formDisplayData={formDisplayData}
                FormElement={getTagsFormElement()}
                getViewProps={(data) => ({
                    emptyLabel: "-",
                    tags: data.modelGenerations.map((modelGeneration) => ({
                        label: modelGeneration.label,
                        route: getModelGenerationRoute(modelGeneration.key),
                    })),
                })}
            />
        </RowGroupWrapper>
        <RowGroupWrapper label={"Derivative categories"} childrenSize={12}>
            <ReadonlyFormElementWrapper
                label={undefined}
                formDisplayData={formDisplayData}
                FormElement={getTagsFormElement()}
                getViewProps={(data) => ({
                    emptyLabel: "-",
                    tags: data.derivativeCategories.map((derivativeCategory) => ({
                        label: derivativeCategory.label,
                        route: getDerivativeCategoryRoute(derivativeCategory.key),
                    })),
                })}
            />
        </RowGroupWrapper>
    </SpacedItems>
)
