/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExteriorColorCustomizationItemDTOValueTag,
    ExteriorColorCustomizationItemDTOValueTagFromJSON,
    ExteriorColorCustomizationItemDTOValueTagFromJSONTyped,
    ExteriorColorCustomizationItemDTOValueTagToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExteriorColorCustomizationItemDTOValue
 */
export interface ExteriorColorCustomizationItemDTOValue {
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorCustomizationItemDTOValue
     */
    exteriorColorTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorCustomizationItemDTOValue
     */
    hexCode: string;
    /**
     * 
     * @type {ExteriorColorCustomizationItemDTOValueTag}
     * @memberof ExteriorColorCustomizationItemDTOValue
     */
    tag?: ExteriorColorCustomizationItemDTOValueTag;
}

export function ExteriorColorCustomizationItemDTOValueFromJSON(json: any): ExteriorColorCustomizationItemDTOValue {
    return ExteriorColorCustomizationItemDTOValueFromJSONTyped(json, false);
}

export function ExteriorColorCustomizationItemDTOValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExteriorColorCustomizationItemDTOValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exteriorColorTypeName': !exists(json, 'exteriorColorTypeName') ? undefined : json['exteriorColorTypeName'],
        'hexCode': json['hexCode'],
        'tag': !exists(json, 'tag') ? undefined : ExteriorColorCustomizationItemDTOValueTagFromJSON(json['tag']),
    };
}

export function ExteriorColorCustomizationItemDTOValueToJSON(value?: ExteriorColorCustomizationItemDTOValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exteriorColorTypeName': value.exteriorColorTypeName,
        'hexCode': value.hexCode,
        'tag': ExteriorColorCustomizationItemDTOValueTagToJSON(value.tag),
    };
}


