/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TechnicalDataAttributeDataValueObject
 */
export interface TechnicalDataAttributeDataValueObject {
    /**
     * 
     * @type {any}
     * @memberof TechnicalDataAttributeDataValueObject
     */
    value?: any;
    /**
     * 
     * @type {any}
     * @memberof TechnicalDataAttributeDataValueObject
     */
    rawValue?: any;
}

export function TechnicalDataAttributeDataValueObjectFromJSON(json: any): TechnicalDataAttributeDataValueObject {
    return TechnicalDataAttributeDataValueObjectFromJSONTyped(json, false);
}

export function TechnicalDataAttributeDataValueObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataAttributeDataValueObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'rawValue': !exists(json, 'rawValue') ? undefined : json['rawValue'],
    };
}

export function TechnicalDataAttributeDataValueObjectToJSON(value?: TechnicalDataAttributeDataValueObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'rawValue': value.rawValue,
    };
}


