import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { NamedColorTile } from "../../../../../components/tiles/namedcolortile/NamedColorTile"
import { RoofColorCustomizationItemDTO } from "../../../../../generated/pdsapi"
import { RoofColorCustomizationSortField, RoofColorCustomizationSortFieldEnum } from "../../../../../pdsapi"

type RoofColorCustomizationColumnBuilder = ColumnBuilder<RoofColorCustomizationItemDTO, RoofColorCustomizationSortFieldEnum>

export const RoofColorCustomizationListColumnsConfig: TableColumnsConfig<RoofColorCustomizationItemDTO, RoofColorCustomizationSortFieldEnum> = ({
    sorting,
    setSorting,
}) => {
    const optionCodeColumn: RoofColorCustomizationColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Option Codes",
            sorting: {
                field: RoofColorCustomizationSortField.OPTION_CODE,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (roofColorCustomization) => roofColorCustomization.optionCode,
    }
    const modelGenerationColumn: RoofColorCustomizationColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Model Generations",
        },
        buildCellContent: (roofColorCustomization) => roofColorCustomization.modelGenerationNames.join(", "),
    }
    const roofColorTagColumn: RoofColorCustomizationColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Roof Color Tag",
        },
        buildCellContent: (roofColorCustomization) => {
            const roofColorTag = roofColorCustomization.defaultValue.tag

            return roofColorTag ? <NamedColorTile label={roofColorTag.name} hexCode={roofColorTag.hexCode} /> : "-"
        },
    }

    return [optionCodeColumn, modelGenerationColumn, roofColorTagColumn]
}
