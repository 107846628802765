import { BreakpointCustomizable, PFlex } from "@porsche-design-system/components-react"
import { Children, FC, ReactNode } from "react"
import styled from "styled-components"

export interface SpacedPFlexProps {
    wrap?: boolean
    lineSpacing?: string
    itemSpacing?: string
    direction: BreakpointCustomizable<"row" | "column">
    alignContent?: BreakpointCustomizable<"stretch" | "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly">
    alignItems?: BreakpointCustomizable<"stretch" | "flex-start" | "flex-end" | "center" | "baseline">
    justifyContent?: BreakpointCustomizable<"flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly">
    children: ReactNode
}

export const SpacedItems: FC<SpacedPFlexProps> = ({ alignContent, alignItems, children, direction, itemSpacing, justifyContent, lineSpacing, wrap }) => (
    <StyledContainer lineSpacing={lineSpacing} itemSpacing={itemSpacing} direction={direction}>
        <PFlex wrap={wrap ? "wrap" : undefined} alignContent={alignContent} alignItems={alignItems} justifyContent={justifyContent} direction={direction}>
            {Children.toArray(children)
                .filter((child) => child !== undefined)
                .map((child, index) => (
                    <StyledItem key={`spacing_adjustment_${index}`} lineSpacing={lineSpacing} itemSpacing={itemSpacing} direction={direction}>
                        {child}
                    </StyledItem>
                ))}
        </PFlex>
    </StyledContainer>
)

const StyledContainer = styled.div<SpacedPFlexProps>`
    ${(props) => (props.lineSpacing ? `margin-top: calc(-${props.lineSpacing}/2);` : "")}
    ${(props) => (props.lineSpacing ? `margin-bottom: calc(-${props.lineSpacing}/2);` : "")}
  ${(props) => (props.itemSpacing ? `margin-left: calc(-${props.itemSpacing}/2);` : "")}
  ${(props) => (props.itemSpacing ? `margin-right: calc(-${props.itemSpacing}/2);` : "")}
`

const StyledItem = styled.div<SpacedPFlexProps>`
    ${(props) => (props.lineSpacing ? `margin-top: calc(${props.lineSpacing}/2);` : "")}
    ${(props) => (props.lineSpacing ? `margin-bottom: calc(${props.lineSpacing}/2);` : "")}
  ${(props) => (props.itemSpacing ? `margin-left: calc(${props.itemSpacing}/2);` : "")}
  ${(props) => (props.itemSpacing ? `margin-right: calc(${props.itemSpacing}/2);` : "")}
`
