/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    InteriorOptionDTO,
    InteriorOptionDTOFromJSON,
    InteriorOptionDTOToJSON,
    InteriorOptionUpdateDTO,
    InteriorOptionUpdateDTOFromJSON,
    InteriorOptionUpdateDTOToJSON,
    LinkedInteriorCustomizationDTO,
    LinkedInteriorCustomizationDTOFromJSON,
    LinkedInteriorCustomizationDTOToJSON,
    SimilarInteriorCustomizationsDTO,
    SimilarInteriorCustomizationsDTOFromJSON,
    SimilarInteriorCustomizationsDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface GetLinkedCustomizationOfInteriorRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

export interface GetOptionOfInteriorRequest {
    orderTypeKey: string;
    optionCode: string;
}

export interface GetSimilarCustomizationsOfInteriorRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

export interface UpdateOptionOfInteriorRequest {
    orderTypeKey: string;
    optionCode: string;
    interiorOptionUpdateDTO: InteriorOptionUpdateDTO;
}

/**
 * 
 */
export class OrderTypeInteriorDataDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get linkedCustomization of Interior with given key
     */
    async getLinkedCustomizationOfInteriorRaw(requestParameters: GetLinkedCustomizationOfInteriorRequest): Promise<runtime.ApiResponse<LinkedInteriorCustomizationDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getLinkedCustomizationOfInterior.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getLinkedCustomizationOfInterior.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinkedCustomizationOfInterior.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/interior/data/linked-customization`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkedInteriorCustomizationDTOFromJSON(jsonValue));
    }

    /**
     * Get linkedCustomization of Interior with given key
     */
    async getLinkedCustomizationOfInterior(requestParameters: GetLinkedCustomizationOfInteriorRequest): Promise<LinkedInteriorCustomizationDTO> {
        const response = await this.getLinkedCustomizationOfInteriorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get option of Interior with given key
     */
    async getOptionOfInteriorRaw(requestParameters: GetOptionOfInteriorRequest): Promise<runtime.ApiResponse<InteriorOptionDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getOptionOfInterior.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getOptionOfInterior.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/interior/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InteriorOptionDTOFromJSON(jsonValue));
    }

    /**
     * Get option of Interior with given key
     */
    async getOptionOfInterior(requestParameters: GetOptionOfInteriorRequest): Promise<InteriorOptionDTO> {
        const response = await this.getOptionOfInteriorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get similarCustomizations of Interior with given key
     */
    async getSimilarCustomizationsOfInteriorRaw(requestParameters: GetSimilarCustomizationsOfInteriorRequest): Promise<runtime.ApiResponse<SimilarInteriorCustomizationsDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getSimilarCustomizationsOfInterior.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getSimilarCustomizationsOfInterior.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getSimilarCustomizationsOfInterior.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/interior/data/similar-customizations`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SimilarInteriorCustomizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get similarCustomizations of Interior with given key
     */
    async getSimilarCustomizationsOfInterior(requestParameters: GetSimilarCustomizationsOfInteriorRequest): Promise<SimilarInteriorCustomizationsDTO> {
        const response = await this.getSimilarCustomizationsOfInteriorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates option of an existing Interior
     */
    async updateOptionOfInteriorRaw(requestParameters: UpdateOptionOfInteriorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling updateOptionOfInterior.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling updateOptionOfInterior.');
        }

        if (requestParameters.interiorOptionUpdateDTO === null || requestParameters.interiorOptionUpdateDTO === undefined) {
            throw new runtime.RequiredError('interiorOptionUpdateDTO','Required parameter requestParameters.interiorOptionUpdateDTO was null or undefined when calling updateOptionOfInterior.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/interior/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InteriorOptionUpdateDTOToJSON(requestParameters.interiorOptionUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates option of an existing Interior
     */
    async updateOptionOfInterior(requestParameters: UpdateOptionOfInteriorRequest): Promise<void> {
        await this.updateOptionOfInteriorRaw(requestParameters);
    }

}
