/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOEngineDesign,
    OptionDTOEngineDesignFromJSON,
    OptionDTOEngineDesignFromJSONTyped,
    OptionDTOEngineDesignToJSON,
    OptionDTOEngineLayout,
    OptionDTOEngineLayoutFromJSON,
    OptionDTOEngineLayoutFromJSONTyped,
    OptionDTOEngineLayoutToJSON,
    OptionDTOFuelType,
    OptionDTOFuelTypeFromJSON,
    OptionDTOFuelTypeFromJSONTyped,
    OptionDTOFuelTypeToJSON,
    OptionDTOTransmission,
    OptionDTOTransmissionFromJSON,
    OptionDTOTransmissionFromJSONTyped,
    OptionDTOTransmissionToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeTechnicalDataRelatedDataDTO
 */
export interface OrderTypeTechnicalDataRelatedDataDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    possibleOptionCodes: Array<string>;
    /**
     * 
     * @type {Array<OptionDTOEngineDesign>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    engineDesigns: Array<OptionDTOEngineDesign>;
    /**
     * 
     * @type {Array<OptionDTOEngineLayout>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    engineLayouts: Array<OptionDTOEngineLayout>;
    /**
     * 
     * @type {Array<OptionDTOTransmission>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    transmissions: Array<OptionDTOTransmission>;
    /**
     * 
     * @type {Array<OptionDTOFuelType>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    fuelTypes: Array<OptionDTOFuelType>;
}

export function OrderTypeTechnicalDataRelatedDataDTOFromJSON(json: any): OrderTypeTechnicalDataRelatedDataDTO {
    return OrderTypeTechnicalDataRelatedDataDTOFromJSONTyped(json, false);
}

export function OrderTypeTechnicalDataRelatedDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeTechnicalDataRelatedDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'possibleOptionCodes': json['possibleOptionCodes'],
        'engineDesigns': ((json['engineDesigns'] as Array<any>).map(OptionDTOEngineDesignFromJSON)),
        'engineLayouts': ((json['engineLayouts'] as Array<any>).map(OptionDTOEngineLayoutFromJSON)),
        'transmissions': ((json['transmissions'] as Array<any>).map(OptionDTOTransmissionFromJSON)),
        'fuelTypes': ((json['fuelTypes'] as Array<any>).map(OptionDTOFuelTypeFromJSON)),
    };
}

export function OrderTypeTechnicalDataRelatedDataDTOToJSON(value?: OrderTypeTechnicalDataRelatedDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'possibleOptionCodes': value.possibleOptionCodes,
        'engineDesigns': ((value.engineDesigns as Array<any>).map(OptionDTOEngineDesignToJSON)),
        'engineLayouts': ((value.engineLayouts as Array<any>).map(OptionDTOEngineLayoutToJSON)),
        'transmissions': ((value.transmissions as Array<any>).map(OptionDTOTransmissionToJSON)),
        'fuelTypes': ((value.fuelTypes as Array<any>).map(OptionDTOFuelTypeToJSON)),
    };
}


