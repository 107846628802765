import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { ExteriorColorCustomizationFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { ExteriorColorCustomizationFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const ExteriorColorCustomizationListFilter: FunctionComponent<
    TableFilterProps<ExteriorColorCustomizationFilter, ExteriorColorCustomizationFilterOptionsDTO>
> = ({ filter, setFilter, filterOptions }) => {
    const modelGenerationFilter = (
        <MultiSelect
            label={"Model Generations"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelGenerations?.map(toOption)}
            value={filter.modelGenerationKeys ?? []}
            onChange={(modelGenerationKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelGenerationKeys: modelGenerationKeys.length === 0 ? undefined : modelGenerationKeys,
                }))
            }
        />
    )

    const exteriorColorTagsFilter = (
        <MultiSelect
            label={"Exterior Color Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.exteriorColorTags?.map(toOption)}
            value={filter.exteriorColorTagKeys ?? []}
            onChange={(exteriorColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    exteriorColorTagKeys: exteriorColorTagKeys.length === 0 ? undefined : exteriorColorTagKeys,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>{modelGenerationFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Tag"}>{exteriorColorTagsFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
