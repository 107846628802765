import { roofColorTagApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"

export const RoofColorTagSortContainerTemplateProps: ProductSortContainerConfig = {
    listName: "Roof Color Tags",
    fetchSortables: roofColorTagApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => roofColorTagApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

export const RoofColorTagSortContainer = getProductSortContainer(RoofColorTagSortContainerTemplateProps)
