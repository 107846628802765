import { useCallback, useEffect, useMemo, useState } from "react"

interface Request {
    request: () => Promise<any>
    requestHandler: RequestHandler<any> | undefined
    state: "queued" | "running"
}

export interface RequestHandler<T> {
    onSuccess?: (result: T) => void
    onError?: (error: unknown) => void
    onSkip?: () => void
}

export interface RequestQueueConfig {
    /**
     * If set to true, the queue only executes the most recently added request and skips over older ones.
     * @default false
     */
    skipRequests?: boolean
}

const defaultConfig: RequestQueueConfig = {
    skipRequests: false,
}

export const useRequestQueue = (config?: RequestQueueConfig) => {
    const { skipRequests } = { ...defaultConfig, ...config }
    const [requestQueue, setRequestQueue] = useState<Request[]>([])

    const addToRequestQueue = useCallback(<T>(request: () => Promise<T>, requestHandler?: RequestHandler<T>) => {
        setRequestQueue((currentValue) => {
            return [...currentValue, { request, requestHandler, state: "queued" }]
        })
    }, [])

    useEffect(() => {
        const pendingRequest = requestQueue.find((item) => item.state === "running")
        if (requestQueue.length > 0 && !pendingRequest) {
            const nextRequest = requestQueue[skipRequests ? requestQueue.length - 1 : 0]
            setRequestQueue((currentQueue) => {
                if (skipRequests) {
                    // cancel previous requests
                    const previousRequests = currentQueue.splice(0, currentQueue.length - 1)
                    previousRequests.forEach((request) => {
                        try {
                            request.requestHandler?.onSkip?.()
                        } catch (e) {
                            console.error("Failed to handle cancelled request", e)
                        }
                    })
                }
                // mark current request as running
                const next: Request = { ...nextRequest, state: "running" }
                return skipRequests ? [next] : [next, ...requestQueue.slice(1)]
            })

            // Start latest request in Queue
            nextRequest
                .request()
                .then(nextRequest.requestHandler?.onSuccess)
                .catch(nextRequest.requestHandler?.onError)
                .finally(() => setRequestQueue((currentValue) => currentValue.slice(1)))
        }
    }, [requestQueue, skipRequests])

    const isEmpty = useMemo(() => requestQueue.length === 0, [requestQueue.length])

    return { addToRequestQueue, isEmpty }
}
