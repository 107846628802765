import { derivativeCategoryApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"

export const DerivativeCategorySortContainerTemplateProps: ProductSortContainerConfig = {
    listName: "Derivative Categories",
    fetchSortables: derivativeCategoryApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => derivativeCategoryApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

export const DerivativeCategorySortContainer = getProductSortContainer(DerivativeCategorySortContainerTemplateProps)
