/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DriveTrain,
    DriveTrainFromJSON,
    DriveTrainFromJSONTyped,
    DriveTrainToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionDTODriveTrain
 */
export interface OptionDTODriveTrain {
    /**
     * 
     * @type {DriveTrain}
     * @memberof OptionDTODriveTrain
     */
    key: DriveTrain;
    /**
     * 
     * @type {string}
     * @memberof OptionDTODriveTrain
     */
    label: string;
}

export function OptionDTODriveTrainFromJSON(json: any): OptionDTODriveTrain {
    return OptionDTODriveTrainFromJSONTyped(json, false);
}

export function OptionDTODriveTrainFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionDTODriveTrain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': DriveTrainFromJSON(json['key']),
        'label': json['label'],
    };
}

export function OptionDTODriveTrainToJSON(value?: OptionDTODriveTrain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': DriveTrainToJSON(value.key),
        'label': value.label,
    };
}


