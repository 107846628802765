/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    CreateResponseDTO,
    CreateResponseDTOFromJSON,
    CreateResponseDTOToJSON,
    RoofColorTagCreateRequestDTO,
    RoofColorTagCreateRequestDTOFromJSON,
    RoofColorTagCreateRequestDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface CreateRoofColorTagRequest {
    roofColorTagCreateRequestDTO: RoofColorTagCreateRequestDTO;
}

/**
 * 
 */
export class RoofColorTagCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new RoofColorTag
     */
    async createRoofColorTagRaw(requestParameters: CreateRoofColorTagRequest): Promise<runtime.ApiResponse<CreateResponseDTO>> {
        if (requestParameters.roofColorTagCreateRequestDTO === null || requestParameters.roofColorTagCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('roofColorTagCreateRequestDTO','Required parameter requestParameters.roofColorTagCreateRequestDTO was null or undefined when calling createRoofColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/roof-color-tags/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoofColorTagCreateRequestDTOToJSON(requestParameters.roofColorTagCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResponseDTOFromJSON(jsonValue));
    }

    /**
     * Create a new RoofColorTag
     */
    async createRoofColorTag(requestParameters: CreateRoofColorTagRequest): Promise<CreateResponseDTO> {
        const response = await this.createRoofColorTagRaw(requestParameters);
        return await response.value();
    }

}
