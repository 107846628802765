import { exteriorColorTagApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"

export const ExteriorColorTagSortContainerTemplateProps: ProductSortContainerConfig = {
    listName: "Exterior Color Tags",
    fetchSortables: exteriorColorTagApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => exteriorColorTagApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

export const ExteriorColorTagSortContainer = getProductSortContainer(ExteriorColorTagSortContainerTemplateProps)
