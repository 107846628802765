/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExteriorColorType,
    ExteriorColorTypeFromJSON,
    ExteriorColorTypeFromJSONTyped,
    ExteriorColorTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionDTOExteriorColorType
 */
export interface OptionDTOExteriorColorType {
    /**
     * 
     * @type {ExteriorColorType}
     * @memberof OptionDTOExteriorColorType
     */
    key: ExteriorColorType;
    /**
     * 
     * @type {string}
     * @memberof OptionDTOExteriorColorType
     */
    label: string;
}

export function OptionDTOExteriorColorTypeFromJSON(json: any): OptionDTOExteriorColorType {
    return OptionDTOExteriorColorTypeFromJSONTyped(json, false);
}

export function OptionDTOExteriorColorTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionDTOExteriorColorType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': ExteriorColorTypeFromJSON(json['key']),
        'label': json['label'],
    };
}

export function OptionDTOExteriorColorTypeToJSON(value?: OptionDTOExteriorColorType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': ExteriorColorTypeToJSON(value.key),
        'label': value.label,
    };
}


