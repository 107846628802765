/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation,
    OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationFromJSON,
    OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationFromJSONTyped,
    OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeTechnicalDataAttributeUpdateDTO
 */
export interface OrderTypeTechnicalDataAttributeUpdateDTO {
    /**
     * 
     * @type {Array<OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation>}
     * @memberof OrderTypeTechnicalDataAttributeUpdateDTO
     */
    equipmentRelations: Array<OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation>;
}

export function OrderTypeTechnicalDataAttributeUpdateDTOFromJSON(json: any): OrderTypeTechnicalDataAttributeUpdateDTO {
    return OrderTypeTechnicalDataAttributeUpdateDTOFromJSONTyped(json, false);
}

export function OrderTypeTechnicalDataAttributeUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeTechnicalDataAttributeUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equipmentRelations': ((json['equipmentRelations'] as Array<any>).map(OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationFromJSON)),
    };
}

export function OrderTypeTechnicalDataAttributeUpdateDTOToJSON(value?: OrderTypeTechnicalDataAttributeUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equipmentRelations': ((value.equipmentRelations as Array<any>).map(OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationToJSON)),
    };
}


