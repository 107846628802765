import { FC, useCallback, useMemo } from "react"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { OrderTypeMediaDTO, OrderTypeMediaDTOEquipmentRelationDTO, OrderTypeMediaUpdateDTO } from "../../../../../../generated/pdsapi"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { ColumnBuilder, Table } from "../../../../../../components/layouts/table/Table"
import { PButton, PButtonPure, PLink } from "@porsche-design-system/components-react"
import { ViewState } from "../../../../../../common/ViewState"
import { spacing } from "@porsche-design-system/utilities"
import { rowGroupsSpacing } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { getEditData, NotLoadingFormDisplayData } from "../../../../../../components/formelements/FormDisplayData"
import { propertiesOf } from "../../../../../../common/property"
import { NotLoadingFormElementWrapper } from "../../../../../../components/formelements/NotLoadingFormElementWrapper"
import { getMultiSelectTextFormElement } from "../../../../../../components/formelements/multiselecttextformelement/MultiSelectTextFormElement"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { useOrderTypeMediaContext } from "../OrderTypeMediaContext"
import { useSyncState } from "../../../../../../hooks/useSyncState"
import { useToast } from "@finder/ui-kit"
import { hasMessage } from "../../../../../../common/global"
import styled from "styled-components"

export const OrderTypeMediaSoundCard: FC<ProductDetailsCardContentProps<OrderTypeMediaDTO, OrderTypeMediaUpdateDTO>> = ({ formDisplayData }) => {
    const { possibleOptionCodes, mediaBasePath } = useOrderTypeMediaContext()
    const toast = useToast()
    const [equipmentRelations, setEquipmentRelations] = useSyncState(formDisplayData.data?.equipmentRelations)

    const onCopyClick = async (value?: string) => {
        if (!value) return
        try {
            await navigator.clipboard.writeText(value)
            toast.show("neutral", "Copied to clipboard")
        } catch (error) {
            toast.show("error", hasMessage(error) ? error.message : "Something went wrong")
        }
    }

    const tableItems: NotLoadingFormDisplayData<OrderTypeMediaDTOEquipmentRelationDTO, OrderTypeMediaUpdateDTO>[] =
        formDisplayData.kind === "LOADING"
            ? []
            : (equipmentRelations?.map((relation) => ({
                  ...formDisplayData,
                  data: relation,
              })) ?? [])

    const columns: ColumnBuilder<NotLoadingFormDisplayData<OrderTypeMediaDTOEquipmentRelationDTO, OrderTypeMediaUpdateDTO>, never>[] = useMemo(() => {
        const properties = propertiesOf<OrderTypeMediaDTO>()
        return [
            {
                grow: 2,
                headerCellProps: {
                    content: "Option Codes",
                },
                buildCellContent: (formDisplayData, rowIndex) => (
                    <NotLoadingFormElementWrapper
                        label={undefined}
                        formDisplayData={formDisplayData}
                        field={properties.equipmentRelations._index(rowIndex).optionCodes}
                        FormElement={getMultiSelectTextFormElement<OrderTypeMediaDTOEquipmentRelationDTO, OrderTypeMediaUpdateDTO>()}
                        getViewProps={() => ({
                            emptyLabel: "-",
                            showAsHint: false,
                            texts: formDisplayData.data.optionCodes,
                        })}
                        getEditProps={() => ({
                            emptyLabel: "-",
                            value: formDisplayData.data.optionCodes,
                            options: possibleOptionCodes.map((optionCode) => ({
                                label: optionCode,
                                value: optionCode,
                            })),
                            clearable: true,
                            optional: true,
                        })}
                    />
                ),
            },

            {
                grow: 2,
                headerCellProps: {
                    content: "Indoor",
                },
                buildCellContent: (formDisplayData, rowIndex) => (
                    <>
                        {formDisplayData.kind === "VIEW" && (
                            <>
                                <ActionButton
                                    icon="copy"
                                    variant="ghost"
                                    hideLabel
                                    compact
                                    disabled={!formDisplayData.data.values.indoor}
                                    onClick={() => onCopyClick(formDisplayData.data.values.indoor)}
                                />

                                {formDisplayData.data.values.indoor ? (
                                    <ActionLink
                                        icon="volume-up"
                                        variant="ghost"
                                        hideLabel
                                        compact
                                        target="_blank"
                                        href={`${mediaBasePath}${formDisplayData.data.values.indoor}`}
                                    />
                                ) : (
                                    <ActionButton icon="volume-up" variant="ghost" hideLabel compact disabled />
                                )}
                            </>
                        )}
                        <FormElementWrapper
                            label={undefined}
                            formDisplayData={formDisplayData}
                            field={properties.equipmentRelations._index(rowIndex).values.indoor}
                            FormElement={getTextFormElement<OrderTypeMediaDTOEquipmentRelationDTO, OrderTypeMediaUpdateDTO>()}
                            getViewProps={(data) => ({
                                value: data.values.indoor,
                                showAsHint: false,
                            })}
                            getEditProps={(data) => ({
                                value: data.values.indoor ?? "",
                                readOnly: false,
                                optional: true,
                            })}
                        />
                    </>
                ),
            },
            {
                grow: 2,
                headerCellProps: {
                    content: "Outdoor",
                },
                buildCellContent: (formDisplayData, rowIndex) => (
                    <>
                        {formDisplayData.kind === "VIEW" && (
                            <>
                                <ActionButton
                                    icon="copy"
                                    variant="ghost"
                                    hideLabel
                                    compact
                                    disabled={!formDisplayData.data.values.outdoor}
                                    onClick={() => onCopyClick(formDisplayData.data.values.outdoor)}
                                />

                                {formDisplayData.data.values.outdoor ? (
                                    <ActionLink
                                        icon="volume-up"
                                        variant="ghost"
                                        hideLabel
                                        compact
                                        target="_blank"
                                        href={`${mediaBasePath}${formDisplayData.data.values.outdoor}`}
                                    />
                                ) : (
                                    <ActionButton icon="volume-up" variant="ghost" hideLabel compact disabled />
                                )}
                            </>
                        )}
                        <FormElementWrapper
                            label={undefined}
                            formDisplayData={formDisplayData}
                            field={properties.equipmentRelations._index(rowIndex).values.outdoor}
                            FormElement={getTextFormElement<OrderTypeMediaDTOEquipmentRelationDTO, OrderTypeMediaUpdateDTO>()}
                            getViewProps={(data) => ({
                                value: data.values.outdoor,
                                showAsHint: false,
                            })}
                            getEditProps={(data) => ({
                                value: data.values.outdoor ?? "",
                                readOnly: false,
                                optional: true,
                            })}
                        />
                    </>
                ),
            },
            ...(formDisplayData.kind === "EDIT"
                ? [
                      {
                          grow: 2,
                          headerCellProps: {
                              content: "",
                          },
                          buildCellContent: (
                              formDisplayData: NotLoadingFormDisplayData<OrderTypeMediaDTOEquipmentRelationDTO, OrderTypeMediaUpdateDTO>,
                              rowIndex: number
                          ) => (
                              <PButtonPure
                                  onClick={() => {
                                      const edit = getEditData(formDisplayData)!
                                      edit.setValue(
                                          "equipmentRelations",
                                          edit.watch("equipmentRelations").filter((_, i) => i !== rowIndex)
                                      )
                                      setEquipmentRelations((equipmentRelations) => equipmentRelations?.filter((_, i) => i !== rowIndex))
                                  }}
                                  icon={"delete"}
                                  weight={"semibold"}
                                  type={"button"}
                              >
                                  Remove
                              </PButtonPure>
                          ),
                      },
                  ]
                : []),
        ]
    }, [formDisplayData.kind, possibleOptionCodes])

    const getItemKey = useCallback(
        (tableItem: NotLoadingFormDisplayData<OrderTypeMediaDTOEquipmentRelationDTO, OrderTypeMediaUpdateDTO>) => tableItem.data.optionCodes.join(", "),
        []
    )

    const handleAddRowItemButtonClick = () => {
        const edit = getEditData(formDisplayData)!
        setEquipmentRelations((specificEquipmentRelations) => [
            ...(specificEquipmentRelations ?? []),
            {
                optionCodes: [],
                values: {},
            },
        ])
        edit.setValue("equipmentRelations", [
            ...(edit.watch("equipmentRelations") ?? []),
            {
                optionCodes: [],
                values: {},
            },
        ])
    }

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <SpacedItems direction={"column"} lineSpacing={spacing[16]}>
                <Table
                    viewState={formDisplayData.kind === "LOADING" ? ViewState.LOADING : ViewState.CONTENT}
                    items={tableItems ?? []}
                    columns={columns}
                    getItemKey={getItemKey}
                />
                {formDisplayData.kind === "EDIT" && (
                    <PButtonPure onClick={handleAddRowItemButtonClick} icon={"plus"} weight={"semibold"} type={"button"}>
                        Add equipment related value
                    </PButtonPure>
                )}
            </SpacedItems>
        </SpacedItems>
    )
}

const ActionButton = styled(PButton)`
    margin-right: ${spacing[4]};
`
const ActionLink = styled(PLink)`
    margin-right: ${spacing[4]};
`
