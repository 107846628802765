/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LinkedExteriorColorCustomizationDTOCustomizationValue,
    LinkedExteriorColorCustomizationDTOCustomizationValueFromJSON,
    LinkedExteriorColorCustomizationDTOCustomizationValueFromJSONTyped,
    LinkedExteriorColorCustomizationDTOCustomizationValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface LinkedExteriorColorCustomizationDTOCustomization
 */
export interface LinkedExteriorColorCustomizationDTOCustomization {
    /**
     * 
     * @type {string}
     * @memberof LinkedExteriorColorCustomizationDTOCustomization
     */
    key: string;
    /**
     * 
     * @type {LinkedExteriorColorCustomizationDTOCustomizationValue}
     * @memberof LinkedExteriorColorCustomizationDTOCustomization
     */
    value?: LinkedExteriorColorCustomizationDTOCustomizationValue;
}

export function LinkedExteriorColorCustomizationDTOCustomizationFromJSON(json: any): LinkedExteriorColorCustomizationDTOCustomization {
    return LinkedExteriorColorCustomizationDTOCustomizationFromJSONTyped(json, false);
}

export function LinkedExteriorColorCustomizationDTOCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedExteriorColorCustomizationDTOCustomization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': !exists(json, 'value') ? undefined : LinkedExteriorColorCustomizationDTOCustomizationValueFromJSON(json['value']),
    };
}

export function LinkedExteriorColorCustomizationDTOCustomizationToJSON(value?: LinkedExteriorColorCustomizationDTOCustomization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': LinkedExteriorColorCustomizationDTOCustomizationValueToJSON(value.value),
    };
}


