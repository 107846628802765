/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InteriorMaterial {
    LEATHER = 'LEATHER',
    LEATHER_ALCANTARA = 'LEATHER_ALCANTARA',
    LEATHER_FREE = 'LEATHER_FREE',
    STANDARD_PARTIAL_LEATHER = 'STANDARD_PARTIAL_LEATHER',
    STANDARD_ALCANTARA = 'STANDARD_ALCANTARA',
    OTHERS = 'OTHERS'
}

export function InteriorMaterialFromJSON(json: any): InteriorMaterial {
    return InteriorMaterialFromJSONTyped(json, false);
}

export function InteriorMaterialFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorMaterial {
    return json as InteriorMaterial;
}

export function InteriorMaterialToJSON(value?: InteriorMaterial | null): any {
    return value as any;
}

