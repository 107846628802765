import { DefaultFormElementLoading, EditLabeledContentWrapper, EditProps, FormElement } from "../FormElementWrapper"
import { MultiSelectTextFormElementView, MultiSelectTextFormElementViewProps } from "../multiselecttextformelement/MultiSelectTextFormElement"
import { MultiSelect } from "@finder/ui-kit"
import { GroupedSelectOption } from "@finder/ui-kit/build/types/components/forms/multiSelect/MultiSelect"
import { Controller, FieldValues } from "react-hook-form"

export const getGroupedMultiSelectTextFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string[],
    MultiSelectTextFormElementViewProps,
    GroupedMultiSelectTextFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: MultiSelectTextFormElementView,
    Edit: GroupedMultiSelectTextFormElementEdit,
})

export interface GroupedMultiSelectTextFormElementEditProps {
    emptyLabel: string

    options: GroupedSelectOption[]
    value: string[]

    clearable: boolean
    readOnly?: boolean
}

export const GroupedMultiSelectTextFormElementEdit = <DATA, UPDATE extends FieldValues>({
    label,
    clearable,
    control,
    emptyLabel,
    field,
    options,
    readOnly,
    validationErrors,
    value,
}: EditProps<DATA, UPDATE, string[], GroupedMultiSelectTextFormElementEditProps>) => {
    const errorMessage = validationErrors.get(field._path)

    return (
        <EditLabeledContentWrapper label={label}>
            <Controller
                control={control}
                name={field._path}
                defaultValue={value as any}
                render={({ field }) => (
                    <MultiSelect
                        options={options}
                        value={field.value as any}
                        onChange={field.onChange}
                        placeholder={emptyLabel}
                        clearable={clearable}
                        searchable={true}
                        disabled={readOnly}
                        state={errorMessage ? "error" : "none"}
                        message={errorMessage}
                    />
                )}
            />
        </EditLabeledContentWrapper>
    )
}
