import { DefaultFormElementLoading, FormElement } from "../FormElementWrapper"
import {
    GroupedMultiSelectTextFormElementEdit,
    GroupedMultiSelectTextFormElementEditProps,
} from "../groupedmultiselecttextformelement/GroupedMultiSelectTextFormElement"
import { TagsFormElementView, TagsFormElementViewProps } from "../tagsformelement/TagsFormElement"
import { FieldValues } from "react-hook-form"

export const getGroupedTagsFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string[],
    TagsFormElementViewProps,
    GroupedMultiSelectTextFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: TagsFormElementView,
    Edit: GroupedMultiSelectTextFormElementEdit,
})
