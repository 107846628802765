import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { EquipmentTagCreateOptionsDTO, EquipmentTagCreateRequestDTO } from "../../../../generated/pdsapi"
import { equipmentTagApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { EquipmentTagCreateAttributesCard } from "./attributes/EquipmentTagCreateAttributesCard"

export const EquipmentTagProductCreateContainerConfig: ProductCreateContainerConfig<EquipmentTagCreateRequestDTO, EquipmentTagCreateOptionsDTO> = {
    headline: "Create new Equipment Tag",

    create: (item) =>
        equipmentTagApi.create.create({
            equipmentTagCreateRequestDTO: item,
        }),
    getCreateOptions: equipmentTagApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <EquipmentTagCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const EquipmentTagCreateContainer = getProductCreateContainer(EquipmentTagProductCreateContainerConfig)
