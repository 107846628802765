/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderTypeStandardEquipmentDTOFreeTextCategory,
    OrderTypeStandardEquipmentDTOFreeTextCategoryFromJSON,
    OrderTypeStandardEquipmentDTOFreeTextCategoryFromJSONTyped,
    OrderTypeStandardEquipmentDTOFreeTextCategoryToJSON,
    OrderTypeStandardEquipmentDTOOptionsCategory,
    OrderTypeStandardEquipmentDTOOptionsCategoryFromJSON,
    OrderTypeStandardEquipmentDTOOptionsCategoryFromJSONTyped,
    OrderTypeStandardEquipmentDTOOptionsCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeStandardEquipmentDTO
 */
export interface OrderTypeStandardEquipmentDTO {
    /**
     * 
     * @type {Array<OrderTypeStandardEquipmentDTOFreeTextCategory>}
     * @memberof OrderTypeStandardEquipmentDTO
     */
    freeTexts: Array<OrderTypeStandardEquipmentDTOFreeTextCategory>;
    /**
     * 
     * @type {Array<OrderTypeStandardEquipmentDTOOptionsCategory>}
     * @memberof OrderTypeStandardEquipmentDTO
     */
    options: Array<OrderTypeStandardEquipmentDTOOptionsCategory>;
}

export function OrderTypeStandardEquipmentDTOFromJSON(json: any): OrderTypeStandardEquipmentDTO {
    return OrderTypeStandardEquipmentDTOFromJSONTyped(json, false);
}

export function OrderTypeStandardEquipmentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeStandardEquipmentDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'freeTexts': ((json['freeTexts'] as Array<any>).map(OrderTypeStandardEquipmentDTOFreeTextCategoryFromJSON)),
        'options': ((json['options'] as Array<any>).map(OrderTypeStandardEquipmentDTOOptionsCategoryFromJSON)),
    };
}

export function OrderTypeStandardEquipmentDTOToJSON(value?: OrderTypeStandardEquipmentDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'freeTexts': ((value.freeTexts as Array<any>).map(OrderTypeStandardEquipmentDTOFreeTextCategoryToJSON)),
        'options': ((value.options as Array<any>).map(OrderTypeStandardEquipmentDTOOptionsCategoryToJSON)),
    };
}


