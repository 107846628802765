/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOInteriorMaterial,
    OptionDTOInteriorMaterialFromJSON,
    OptionDTOInteriorMaterialFromJSONTyped,
    OptionDTOInteriorMaterialToJSON,
    OptionDTOString,
    OptionDTOStringFromJSON,
    OptionDTOStringFromJSONTyped,
    OptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeInteriorFilterOptionsDTO
 */
export interface OrderTypeInteriorFilterOptionsDTO {
    /**
     * 
     * @type {Array<OptionDTOInteriorMaterial>}
     * @memberof OrderTypeInteriorFilterOptionsDTO
     */
    materials: Array<OptionDTOInteriorMaterial>;
    /**
     * 
     * @type {Array<OptionDTOString>}
     * @memberof OrderTypeInteriorFilterOptionsDTO
     */
    colorTags: Array<OptionDTOString>;
}

export function OrderTypeInteriorFilterOptionsDTOFromJSON(json: any): OrderTypeInteriorFilterOptionsDTO {
    return OrderTypeInteriorFilterOptionsDTOFromJSONTyped(json, false);
}

export function OrderTypeInteriorFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeInteriorFilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'materials': ((json['materials'] as Array<any>).map(OptionDTOInteriorMaterialFromJSON)),
        'colorTags': ((json['colorTags'] as Array<any>).map(OptionDTOStringFromJSON)),
    };
}

export function OrderTypeInteriorFilterOptionsDTOToJSON(value?: OrderTypeInteriorFilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'materials': ((value.materials as Array<any>).map(OptionDTOInteriorMaterialToJSON)),
        'colorTags': ((value.colorTags as Array<any>).map(OptionDTOStringToJSON)),
    };
}


