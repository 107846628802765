import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { PaintToSampleExteriorColorCustomizationCreateContainer } from "./create/PaintToSampleExteriorColorCustomizationCreateContainer"
import { PaintToSampleExteriorColorCustomizationDetailsContainer } from "./details/PaintToSampleExteriorColorCustomizationDetailsContainer"
import { FC } from "react"
import { Centered } from "../../../components/layouts/centered/Centered"
import { PText } from "@porsche-design-system/components-react"

export const PaintToSampleExteriorColorCustomizationRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Paint to Sample Exterior Color Customizations",
        List: () => (
            <Centered height={"50px"}>
                <PText>If this page is required, please contact Tobias Bauer to create a ticket for it 😊</PText>
            </Centered>
        ),
        Details: PaintToSampleExteriorColorCustomizationDetailsContainer,
        Create: PaintToSampleExteriorColorCustomizationCreateContainer,
    })
