/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    VehicleEquipmentCategory,
    VehicleEquipmentCategoryFromJSON,
    VehicleEquipmentCategoryFromJSONTyped,
    VehicleEquipmentCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface EquipmentOptionUpdateDTO
 */
export interface EquipmentOptionUpdateDTO {
    /**
     * 
     * @type {VehicleEquipmentCategory}
     * @memberof EquipmentOptionUpdateDTO
     */
    category?: VehicleEquipmentCategory;
    /**
     * 
     * @type {number}
     * @memberof EquipmentOptionUpdateDTO
     */
    sortIndex: number;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof EquipmentOptionUpdateDTO
     */
    optionName: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof EquipmentOptionUpdateDTO
     */
    optionDescription: BaseNameDTO;
}

export function EquipmentOptionUpdateDTOFromJSON(json: any): EquipmentOptionUpdateDTO {
    return EquipmentOptionUpdateDTOFromJSONTyped(json, false);
}

export function EquipmentOptionUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentOptionUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : VehicleEquipmentCategoryFromJSON(json['category']),
        'sortIndex': json['sortIndex'],
        'optionName': BaseNameDTOFromJSON(json['optionName']),
        'optionDescription': BaseNameDTOFromJSON(json['optionDescription']),
    };
}

export function EquipmentOptionUpdateDTOToJSON(value?: EquipmentOptionUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': VehicleEquipmentCategoryToJSON(value.category),
        'sortIndex': value.sortIndex,
        'optionName': BaseNameDTOToJSON(value.optionName),
        'optionDescription': BaseNameDTOToJSON(value.optionDescription),
    };
}


