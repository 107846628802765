/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderTypeExteriorColorItemDTO,
    OrderTypeExteriorColorItemDTOFromJSON,
    OrderTypeExteriorColorItemDTOFromJSONTyped,
    OrderTypeExteriorColorItemDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageDTOOrderTypeExteriorColorItemDTO
 */
export interface PageDTOOrderTypeExteriorColorItemDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOOrderTypeExteriorColorItemDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<OrderTypeExteriorColorItemDTO>}
     * @memberof PageDTOOrderTypeExteriorColorItemDTO
     */
    content: Array<OrderTypeExteriorColorItemDTO>;
}

export function PageDTOOrderTypeExteriorColorItemDTOFromJSON(json: any): PageDTOOrderTypeExteriorColorItemDTO {
    return PageDTOOrderTypeExteriorColorItemDTOFromJSONTyped(json, false);
}

export function PageDTOOrderTypeExteriorColorItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOOrderTypeExteriorColorItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(OrderTypeExteriorColorItemDTOFromJSON)),
    };
}

export function PageDTOOrderTypeExteriorColorItemDTOToJSON(value?: PageDTOOrderTypeExteriorColorItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItemCount': value.totalItemCount,
        'content': ((value.content as Array<any>).map(OrderTypeExteriorColorItemDTOToJSON)),
    };
}


