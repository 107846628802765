/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    CreateResponseDTO,
    CreateResponseDTOFromJSON,
    CreateResponseDTOToJSON,
    PaintToSampleExteriorColorCustomizationCreateOptionsDTO,
    PaintToSampleExteriorColorCustomizationCreateOptionsDTOFromJSON,
    PaintToSampleExteriorColorCustomizationCreateOptionsDTOToJSON,
    PaintToSampleExteriorColorCustomizationCreateRequestDTO,
    PaintToSampleExteriorColorCustomizationCreateRequestDTOFromJSON,
    PaintToSampleExteriorColorCustomizationCreateRequestDTOToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    VehicleStateSearchResult,
    VehicleStateSearchResultFromJSON,
    VehicleStateSearchResultToJSON,
} from '../models';

export interface CreatePaintToSampleExteriorColorCustomizationRequest {
    paintToSampleExteriorColorCustomizationCreateRequestDTO: PaintToSampleExteriorColorCustomizationCreateRequestDTO;
}

export interface GetPaintToSampleExteriorColorCustomizationCreateOptionsRequest {
    languageTag: string;
}

export interface GetVehicleImagesForPaintToSampleExteriorColorCustomizationRequest {
    optionCode: string;
    modelGenerationKeys: Array<string>;
    limit?: number;
}

/**
 * 
 */
export class PaintToSampleExteriorColorCustomizationCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new PaintToSampleExteriorColorCustomization
     */
    async createPaintToSampleExteriorColorCustomizationRaw(requestParameters: CreatePaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<CreateResponseDTO>> {
        if (requestParameters.paintToSampleExteriorColorCustomizationCreateRequestDTO === null || requestParameters.paintToSampleExteriorColorCustomizationCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('paintToSampleExteriorColorCustomizationCreateRequestDTO','Required parameter requestParameters.paintToSampleExteriorColorCustomizationCreateRequestDTO was null or undefined when calling createPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaintToSampleExteriorColorCustomizationCreateRequestDTOToJSON(requestParameters.paintToSampleExteriorColorCustomizationCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResponseDTOFromJSON(jsonValue));
    }

    /**
     * Create a new PaintToSampleExteriorColorCustomization
     */
    async createPaintToSampleExteriorColorCustomization(requestParameters: CreatePaintToSampleExteriorColorCustomizationRequest): Promise<CreateResponseDTO> {
        const response = await this.createPaintToSampleExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get select options to create PaintToSampleExteriorColorCustomizations
     */
    async getPaintToSampleExteriorColorCustomizationCreateOptionsRaw(requestParameters: GetPaintToSampleExteriorColorCustomizationCreateOptionsRequest): Promise<runtime.ApiResponse<PaintToSampleExteriorColorCustomizationCreateOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getPaintToSampleExteriorColorCustomizationCreateOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaintToSampleExteriorColorCustomizationCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create PaintToSampleExteriorColorCustomizations
     */
    async getPaintToSampleExteriorColorCustomizationCreateOptions(requestParameters: GetPaintToSampleExteriorColorCustomizationCreateOptionsRequest): Promise<PaintToSampleExteriorColorCustomizationCreateOptionsDTO> {
        const response = await this.getPaintToSampleExteriorColorCustomizationCreateOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getVehicleImagesForPaintToSampleExteriorColorCustomizationRaw(requestParameters: GetVehicleImagesForPaintToSampleExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<Array<VehicleStateSearchResult>>> {
        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getVehicleImagesForPaintToSampleExteriorColorCustomization.');
        }

        if (requestParameters.modelGenerationKeys === null || requestParameters.modelGenerationKeys === undefined) {
            throw new runtime.RequiredError('modelGenerationKeys','Required parameter requestParameters.modelGenerationKeys was null or undefined when calling getVehicleImagesForPaintToSampleExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.modelGenerationKeys) {
            queryParameters['modelGenerationKeys'] = requestParameters.modelGenerationKeys;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/paint-to-sample-exterior-color-customizations/create/vehicle-images`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateSearchResultFromJSON));
    }

    /**
     */
    async getVehicleImagesForPaintToSampleExteriorColorCustomization(requestParameters: GetVehicleImagesForPaintToSampleExteriorColorCustomizationRequest): Promise<Array<VehicleStateSearchResult>> {
        const response = await this.getVehicleImagesForPaintToSampleExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

}
