/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    LocalizationsItemDTO,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    ModelGenerationAttributesDTO,
    ModelGenerationAttributesDTOFromJSON,
    ModelGenerationAttributesDTOToJSON,
    ModelGenerationAttributesUpdateDTO,
    ModelGenerationAttributesUpdateDTOFromJSON,
    ModelGenerationAttributesUpdateDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface DeleteModelGenerationRequest {
    key: string;
}

export interface GetAttributesOfModelGenerationRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfModelGenerationRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfModelGenerationRequest {
    key: string;
}

export interface UpdateAttributesOfModelGenerationRequest {
    key: string;
    modelGenerationAttributesUpdateDTO: ModelGenerationAttributesUpdateDTO;
}

export interface UpdateNameOfModelGenerationRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class ModelGenerationDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a ModelGeneration by a given key
     */
    async deleteModelGenerationRaw(requestParameters: DeleteModelGenerationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteModelGeneration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-generations/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a ModelGeneration by a given key
     */
    async deleteModelGeneration(requestParameters: DeleteModelGenerationRequest): Promise<void> {
        await this.deleteModelGenerationRaw(requestParameters);
    }

    /**
     * Get attributes of ModelGeneration with given key
     */
    async getAttributesOfModelGenerationRaw(requestParameters: GetAttributesOfModelGenerationRequest): Promise<runtime.ApiResponse<ModelGenerationAttributesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfModelGeneration.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfModelGeneration.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-generations/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelGenerationAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of ModelGeneration with given key
     */
    async getAttributesOfModelGeneration(requestParameters: GetAttributesOfModelGenerationRequest): Promise<ModelGenerationAttributesDTO> {
        const response = await this.getAttributesOfModelGenerationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of ModelGeneration with given key
     */
    async getBaseInformationOfModelGenerationRaw(requestParameters: GetBaseInformationOfModelGenerationRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfModelGeneration.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfModelGeneration.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-generations/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of ModelGeneration with given key
     */
    async getBaseInformationOfModelGeneration(requestParameters: GetBaseInformationOfModelGenerationRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfModelGenerationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of ModelGeneration with given key
     */
    async getLocalizationsOfNameOfModelGenerationRaw(requestParameters: GetLocalizationsOfNameOfModelGenerationRequest): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfModelGeneration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-generations/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of ModelGeneration with given key
     */
    async getLocalizationsOfNameOfModelGeneration(requestParameters: GetLocalizationsOfNameOfModelGenerationRequest): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfModelGenerationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing ModelGeneration
     */
    async updateAttributesOfModelGenerationRaw(requestParameters: UpdateAttributesOfModelGenerationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfModelGeneration.');
        }

        if (requestParameters.modelGenerationAttributesUpdateDTO === null || requestParameters.modelGenerationAttributesUpdateDTO === undefined) {
            throw new runtime.RequiredError('modelGenerationAttributesUpdateDTO','Required parameter requestParameters.modelGenerationAttributesUpdateDTO was null or undefined when calling updateAttributesOfModelGeneration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/model-generations/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ModelGenerationAttributesUpdateDTOToJSON(requestParameters.modelGenerationAttributesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing ModelGeneration
     */
    async updateAttributesOfModelGeneration(requestParameters: UpdateAttributesOfModelGenerationRequest): Promise<void> {
        await this.updateAttributesOfModelGenerationRaw(requestParameters);
    }

    /**
     * Updates name of an existing ModelGeneration
     */
    async updateNameOfModelGenerationRaw(requestParameters: UpdateNameOfModelGenerationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfModelGeneration.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfModelGeneration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/model-generations/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing ModelGeneration
     */
    async updateNameOfModelGeneration(requestParameters: UpdateNameOfModelGenerationRequest): Promise<void> {
        await this.updateNameOfModelGenerationRaw(requestParameters);
    }

}
