import { Box } from "../layouts/box/Box"
import { PFlex, PFlexItem, PPagination, PSelectWrapper, PText } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { ChangeEvent, FC, ReactNode } from "react"
import styled from "styled-components"

export interface PagingWrapperProps {
    itemsName: string
    totalItemsCount: number
    currentItemsCount: number
    activePage: number
    itemsPerPage: number
    onActivePageChange: (activePage: number) => void
    onItemsPerPageChange: (itemsPerPage: number) => void
    children: ReactNode
}

export const PagingWrapper: FC<PagingWrapperProps> = (props) => {
    const onActivePageChange = (event: CustomEvent) => {
        props.onActivePageChange(event.detail.page)
    }

    const onItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
        props.onItemsPerPageChange(parseInt(event.target.value))
    }
    const start = props.totalItemsCount === 0 ? 0 : (props.activePage - 1) * props.itemsPerPage + 1
    const end = (props.activePage - 1) * props.itemsPerPage + props.currentItemsCount
    const count = props.totalItemsCount
    const rowName = props.itemsName
    return (
        <>
            <PFlex justifyContent={"flex-start"}>
                <PFlexItem>
                    <PText>
                        <b>
                            {start} - {end}
                        </b>{" "}
                        of{" "}
                        <b>
                            {count} {rowName}
                        </b>
                    </PText>
                </PFlexItem>
            </PFlex>

            <Box height={spacing[24]} />

            {props.children}

            <Box height={spacing[24]} />

            <PaginationWrapper>
                <PPagination
                    style={{ flexGrow: 1 }}
                    totalItemsCount={props.totalItemsCount}
                    itemsPerPage={props.itemsPerPage}
                    activePage={props.activePage}
                    onPageChange={onActivePageChange}
                    maxNumberOfPageLinks={{ base: 5, xl: 7 }}
                />
                <PSelectWrapper style={{ flexShrink: 0 }}>
                    <select name={"items-per-page"} onChange={onItemsPerPageChange} value={props.itemsPerPage}>
                        <option value={"10"}>10</option>
                        <option value={"25"}>25</option>
                        <option value={"50"}>50</option>
                    </select>
                </PSelectWrapper>
            </PaginationWrapper>
        </>
    )
}

const PaginationWrapper = styled("div")`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
`
