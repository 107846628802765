import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { ModelSeriesCreateRequestDTO } from "../../../../generated/pdsapi"
import { modelSeriesApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { ModelSeriesCreateAttributesCard } from "./attributes/ModelSeriesCreateAttributesCard"

export const ModelSeriesProductCreateContainerConfig: ProductCreateContainerConfig<ModelSeriesCreateRequestDTO, unknown> = {
    headline: "Create new Model Series",

    create: (item) =>
        modelSeriesApi.create.create({
            modelSeriesCreateRequestDTO: item,
        }),
    getCreateOptions: async () => ({}),

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <ModelSeriesCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const ModelSeriesCreateContainer = getProductCreateContainer(ModelSeriesProductCreateContainerConfig)
