import { PFlex, PText } from "@porsche-design-system/components-react"
import { FC, ReactNode } from "react"
import styled from "styled-components"

export interface LabeledContentWrapperProps {
    label?: string
    labelColor: "neutral-contrast-high" | "neutral-contrast-medium" | "neutral-contrast-low"
    optional?: boolean
    children: ReactNode
}

export const LabeledContentWrapper: FC<LabeledContentWrapperProps> = (props) => (
    <StyledPFlex direction={"column"}>
        {props.label && (
            <PFlex>
                <UnselectablePText size={"small"} color={props.labelColor}>
                    {props.label}
                    {props.optional ? " (optional)" : ""}
                </UnselectablePText>
            </PFlex>
        )}
        {props.children}
    </StyledPFlex>
)

const StyledPFlex = styled(PFlex)`
    width: 100%;
`

const UnselectablePText = styled(PText)`
    user-select: none;
`
