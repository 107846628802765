import { DefaultCardViewContentWrapper } from "../../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { CreateOptionResponseDTO, InteriorCreateRequestDTO } from "../../../../../generated/pdsapi"
import { interiorApi } from "../../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { OrderTypeInteriorCreateOptionCard } from "./option/OrderTypeInteriorCreateOptionCard"

export const OrderTypeInteriorProductCreateContainerConfig: ProductCreateContainerConfig<InteriorCreateRequestDTO, unknown, CreateOptionResponseDTO> = {
    headline: "Create new Interior",

    create: (item) =>
        interiorApi.create.create({
            interiorCreateRequestDTO: item,
        }),
    getCreateOptions: async () => ({}),

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <OrderTypeInteriorCreateOptionCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const OrderTypeInteriorCreateContainer = getProductCreateContainer(OrderTypeInteriorProductCreateContainerConfig)
