/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    DerivativeAttributesDTO,
    DerivativeAttributesDTOFromJSON,
    DerivativeAttributesDTOToJSON,
    DerivativeAttributesUpdateDTO,
    DerivativeAttributesUpdateDTOFromJSON,
    DerivativeAttributesUpdateDTOToJSON,
    DerivativeLinksDTO,
    DerivativeLinksDTOFromJSON,
    DerivativeLinksDTOToJSON,
    LocalizationsItemDTO,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface DeleteDerivativeRequest {
    key: string;
}

export interface GetAttributesOfDerivativeRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfDerivativeRequest {
    key: string;
    languageTag: string;
}

export interface GetLinksOfDerivativeRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfDerivativeRequest {
    key: string;
}

export interface UpdateAttributesOfDerivativeRequest {
    key: string;
    derivativeAttributesUpdateDTO: DerivativeAttributesUpdateDTO;
}

export interface UpdateNameOfDerivativeRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class DerivativeDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a Derivative by a given key
     */
    async deleteDerivativeRaw(requestParameters: DeleteDerivativeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteDerivative.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Derivative by a given key
     */
    async deleteDerivative(requestParameters: DeleteDerivativeRequest): Promise<void> {
        await this.deleteDerivativeRaw(requestParameters);
    }

    /**
     * Get attributes of Derivative with given key
     */
    async getAttributesOfDerivativeRaw(requestParameters: GetAttributesOfDerivativeRequest): Promise<runtime.ApiResponse<DerivativeAttributesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfDerivative.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfDerivative.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DerivativeAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of Derivative with given key
     */
    async getAttributesOfDerivative(requestParameters: GetAttributesOfDerivativeRequest): Promise<DerivativeAttributesDTO> {
        const response = await this.getAttributesOfDerivativeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of Derivative with given key
     */
    async getBaseInformationOfDerivativeRaw(requestParameters: GetBaseInformationOfDerivativeRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfDerivative.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfDerivative.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of Derivative with given key
     */
    async getBaseInformationOfDerivative(requestParameters: GetBaseInformationOfDerivativeRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfDerivativeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get links of Derivative with given key
     */
    async getLinksOfDerivativeRaw(requestParameters: GetLinksOfDerivativeRequest): Promise<runtime.ApiResponse<DerivativeLinksDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLinksOfDerivative.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinksOfDerivative.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/details/{key}/links`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DerivativeLinksDTOFromJSON(jsonValue));
    }

    /**
     * Get links of Derivative with given key
     */
    async getLinksOfDerivative(requestParameters: GetLinksOfDerivativeRequest): Promise<DerivativeLinksDTO> {
        const response = await this.getLinksOfDerivativeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of Derivative with given key
     */
    async getLocalizationsOfNameOfDerivativeRaw(requestParameters: GetLocalizationsOfNameOfDerivativeRequest): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfDerivative.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of Derivative with given key
     */
    async getLocalizationsOfNameOfDerivative(requestParameters: GetLocalizationsOfNameOfDerivativeRequest): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfDerivativeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing Derivative
     */
    async updateAttributesOfDerivativeRaw(requestParameters: UpdateAttributesOfDerivativeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfDerivative.');
        }

        if (requestParameters.derivativeAttributesUpdateDTO === null || requestParameters.derivativeAttributesUpdateDTO === undefined) {
            throw new runtime.RequiredError('derivativeAttributesUpdateDTO','Required parameter requestParameters.derivativeAttributesUpdateDTO was null or undefined when calling updateAttributesOfDerivative.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivatives/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DerivativeAttributesUpdateDTOToJSON(requestParameters.derivativeAttributesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing Derivative
     */
    async updateAttributesOfDerivative(requestParameters: UpdateAttributesOfDerivativeRequest): Promise<void> {
        await this.updateAttributesOfDerivativeRaw(requestParameters);
    }

    /**
     * Updates name of an existing Derivative
     */
    async updateNameOfDerivativeRaw(requestParameters: UpdateNameOfDerivativeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfDerivative.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfDerivative.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivatives/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing Derivative
     */
    async updateNameOfDerivative(requestParameters: UpdateNameOfDerivativeRequest): Promise<void> {
        await this.updateNameOfDerivativeRaw(requestParameters);
    }

}
