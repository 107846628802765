import { LocalizationsDTO, LocalizationsUpdateDTO } from "../../../../pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../ProductDetailsContainer"
import { ProductDetailsCard } from "../ProductDetailsCard"
import { DetailsLocalizationCardContent } from "./content/DetailsLocalizationCardContent"

interface DetailsLocalizationCardProps<KEY = { key: string }> {
    label: string
    getData: (props: KEY & { languageTag: string }) => Promise<LocalizationsDTO>
    updateData?: (props: KEY & { update: LocalizationsUpdateDTO }) => Promise<void>
}

export const DetailsLocalizationCard = <KEY,>(props: ProductDetailsTabConfigContentProps<KEY> & DetailsLocalizationCardProps<KEY>) => (
    <ProductDetailsCard {...props} Content={DetailsLocalizationCardContent} />
)
