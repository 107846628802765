/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization,
    MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSON,
    MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSONTyped,
    MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
 */
export interface PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    /**
     * 
     * @type {number}
     * @memberof PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization>}
     * @memberof PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
     */
    content: Array<MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization>;
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSON(json: any): PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    return PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSONTyped(json, false);
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSON)),
    };
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSON(value?: PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItemCount': value.totalItemCount,
        'content': ((value.content as Array<any>).map(MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSON)),
    };
}


