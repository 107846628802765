import { IntlShape } from "react-intl"

export interface LanguageGroup {
    label: string
    languages: {
        languageTag: string
        label: string
    }[]
}

const prioritizedLanguageTags = ["en", "de", "es-ES", "pt-PT", "fr-FR", "ja-JP", "it-IT", "ru-RU", "zh-CN"]

export const getLanguageGroups = (languageTags: string[], intl: IntlShape, prioritizeFrequentLanguages: boolean = false): LanguageGroup[] => {
    const groupedLanguageTags: Record<string, string[]> = {}

    languageTags
        .filter((languageTag) => !prioritizeFrequentLanguages || !prioritizedLanguageTags.includes(languageTag))
        .forEach((languageTag) => {
            const languageCode = languageTag.split("-")[0]

            if (!(languageCode in groupedLanguageTags)) {
                groupedLanguageTags[languageCode] = []
            }

            groupedLanguageTags[languageCode].push(languageTag)
        })

    const result = Object.entries(groupedLanguageTags)
        .map<LanguageGroup>(([languageCode, languageTags]) => ({
            label: intl.formatDisplayName(languageCode, { type: "language" }) ?? languageCode,
            languages: languageTags
                .map((languageTag) => ({
                    languageTag,
                    label: `${intl.formatDisplayName(languageTag, { type: "language" })} (${languageTag})`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

    if (prioritizeFrequentLanguages) {
        const primaryGroup: LanguageGroup = {
            label: "Frequent languages",
            languages: prioritizedLanguageTags
                .filter((languageTag) => languageTags.includes(languageTag))
                .map((languageTag) => ({
                    languageTag,
                    label: `${intl.formatDisplayName(languageTag, { type: "language" })} (${languageTag})`,
                })),
        }
        result.unshift(primaryGroup)
    }

    return result
}
