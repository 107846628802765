import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { PaintToSampleExteriorColorCustomizationCreateOptionsDTO, PaintToSampleExteriorColorCustomizationCreateRequestDTO } from "../../../../generated/pdsapi"
import { paintToSampleExteriorColorCustomizationApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { VehicleImagesCreateCard } from "../../../../viewtemplates/create/cards/vehicleimagescard/VehicleImagesCreateCard"
import { PaintToSampleExteriorColorCustomizationCreateAttributesCard } from "./attributes/PaintToSampleExteriorColorCustomizationCreateAttributesCard"

export const PaintToSampleExteriorColorCustomizationProductCreateContainerConfig: ProductCreateContainerConfig<
    PaintToSampleExteriorColorCustomizationCreateRequestDTO,
    PaintToSampleExteriorColorCustomizationCreateOptionsDTO
> = {
    headline: "Create new Paint to Sample Exterior Color Customization",

    create: (item) =>
        paintToSampleExteriorColorCustomizationApi.create.create({
            paintToSampleExteriorColorCustomizationCreateRequestDTO: item,
        }),
    getCreateOptions: paintToSampleExteriorColorCustomizationApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <PaintToSampleExteriorColorCustomizationCreateAttributesCard {...props} />
            <VehicleImagesCreateCard
                formDisplayData={props.formDisplayData}
                getVehicleImages={paintToSampleExteriorColorCustomizationApi.create.getVehicleImages}
            />
        </DefaultCardViewContentWrapper>
    ),
}

export const PaintToSampleExteriorColorCustomizationCreateContainer = getProductCreateContainer(
    PaintToSampleExteriorColorCustomizationProductCreateContainerConfig
)
