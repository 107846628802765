/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimilarRoofColorCustomizationsDTOCustomization
 */
export interface SimilarRoofColorCustomizationsDTOCustomization {
    /**
     * 
     * @type {string}
     * @memberof SimilarRoofColorCustomizationsDTOCustomization
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof SimilarRoofColorCustomizationsDTOCustomization
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof SimilarRoofColorCustomizationsDTOCustomization
     */
    hexCode: string;
}

export function SimilarRoofColorCustomizationsDTOCustomizationFromJSON(json: any): SimilarRoofColorCustomizationsDTOCustomization {
    return SimilarRoofColorCustomizationsDTOCustomizationFromJSONTyped(json, false);
}

export function SimilarRoofColorCustomizationsDTOCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarRoofColorCustomizationsDTOCustomization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'label': json['label'],
        'hexCode': json['hexCode'],
    };
}

export function SimilarRoofColorCustomizationsDTOCustomizationToJSON(value?: SimilarRoofColorCustomizationsDTOCustomization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'label': value.label,
        'hexCode': value.hexCode,
    };
}


