import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { RoofColorTagCreateContainer } from "./create/RoofColorTagCreateContainer"
import { RoofColorTagDetailsContainer } from "./details/RoofColorTagDetailsContainer"
import { RoofColorTagListContainer } from "./list/RoofColorTagListContainer"
import { RoofColorTagSortContainer } from "./sort/RoofColorTagSortContainer"
import { FC } from "react"

export const RoofColorTagRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Roof Color Tags",
        List: RoofColorTagListContainer,
        Details: RoofColorTagDetailsContainer,
        Create: RoofColorTagCreateContainer,
        Sort: RoofColorTagSortContainer,
    })
