import { roofColorCustomizationRoutes } from "../../../../../common/routes"
import { NameItem } from "../../../../../components/formelements/derivednameformelement/DerivedNameFormElement"
import { LinkedCustomizationCard } from "../../../../../components/linkedcustomizationcard/LinkedCustomizationCard"
import { LocalizationsItemDTO, OptionLocalizationItemDTO } from "../../../../../generated/pdsapi"
import { roofColorApi } from "../../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDerivedLocalizationCard } from "../../../../../viewtemplates/details/cards/derivedlocalizationcard/DetailsDerivedLocalizationCard"
import { ProductDetailsTab } from "../../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { LinkedRoofColorCustomizationCardContent } from "./data/linkedcustomization/LinkedRoofColorCustomizationCardContent"
import { RoofColorOptionCardContent } from "./data/option/RoofColorOptionCardContent"
import { RoofColorSimilarCustomizationsCard } from "./data/similarcustomizations/RoofColorSimilarCustomizationsCard"
import { OptionMarketplaceSpecificsCardContent } from "../../../../../components/optionmarketplacespecificscard/content/OptionMarketplaceSpecificsCardContent"

const nameItem = (label: string | undefined, item: LocalizationsItemDTO): NameItem => ({
    label,
    isDerived: item.raw === undefined,
    value: item.withFallback,
})

export const RoofColorProductDetailsContainerConfig: ProductDetailsContainerConfig<{
    orderTypeKey: string
    optionCode: string
}> = {
    getBaseInformation: roofColorApi.details.getBaseInformation,

    deleteByKey: roofColorApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Option"}
                        getData={roofColorApi.details.getOption}
                        updateData={(data) =>
                            roofColorApi.details.updateOption({
                                orderTypeKey: data.orderTypeKey,
                                optionCode: data.optionCode,
                                roofColorOptionUpdateDTO: data.update,
                            })
                        }
                        Content={RoofColorOptionCardContent}
                    />
                    <LinkedCustomizationCard
                        {...props}
                        getLinkedCustomization={roofColorApi.details.getLinkedCustomization}
                        CustomizationContent={LinkedRoofColorCustomizationCardContent}
                        getCreateCustomizationRoute={roofColorCustomizationRoutes.create}
                    />
                    <RoofColorSimilarCustomizationsCard {...props} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsDerivedLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={roofColorApi.details.getName}
                        updateData={({ orderTypeKey, optionCode, update }) =>
                            roofColorApi.details.updateName({
                                orderTypeKey,
                                optionCode,
                                requestBody: update,
                            })
                        }
                        getViewProps={(data, languageTag) => {
                            const name: OptionLocalizationItemDTO = data[languageTag]
                            return {
                                primaryName: nameItem(undefined, name.merged),
                                secondaryNames: [nameItem("Option", name.option), nameItem("Customization", name.customization ?? {})],
                            }
                        }}
                        getEditProps={(data, languageTag) => ({
                            rawName: data[languageTag].option.raw,
                        })}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsDerivedLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={roofColorApi.details.getDescription}
                        updateData={({ orderTypeKey, optionCode, update }) =>
                            roofColorApi.details.updateDescription({
                                orderTypeKey,
                                optionCode,
                                requestBody: update,
                            })
                        }
                        getViewProps={(data, languageTag) => {
                            const name: OptionLocalizationItemDTO = data[languageTag]
                            return {
                                primaryName: nameItem(undefined, name.merged),
                                secondaryNames: [nameItem("Option", name.option), nameItem("Customization", name.customization ?? {})],
                            }
                        }}
                        getEditProps={(data, languageTag) => ({
                            rawName: data[languageTag].option.raw,
                        })}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Market Specifics",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        isDataEditable={false}
                        urlPathParamsPrefix={props.urlPathParamsPrefix}
                        itemKey={props.itemKey}
                        reloadDataIndicator={props.reloadDataIndicator}
                        triggerReload={props.triggerReload}
                        label={"Market Specifics"}
                        getData={roofColorApi.details.getMarketplaceSpecifics}
                        Content={OptionMarketplaceSpecificsCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

export const RoofColorDetailsContainer = getProductDetailsContainer(RoofColorProductDetailsContainerConfig)
