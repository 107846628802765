import { equipmentTagApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"

export const EquipmentTagSortContainerTemplateProps: ProductSortContainerConfig = {
    listName: "Equipment Tags",
    fetchSortables: equipmentTagApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => equipmentTagApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
    disclaimer:
        "Updating equipment tag sorting is a longer running process triggered in the background. The updated order will only be visible after a refresh in a couple of minutes.",
}

export const EquipmentTagSortContainer = getProductSortContainer(EquipmentTagSortContainerTemplateProps)
