import { OrderTypeDetailsContextDTO } from "../../../../generated/pdsapi"
import { createContext, useContext } from "react"

export const OrderTypeDetailsContext = createContext<OrderTypeDetailsContextDTO>({
    isEditable: false,
    isTechnicalDataEditable: false,
})

export const useOrderTypeDetailsContext = () => {
    const context = useContext(OrderTypeDetailsContext)
    if (!context) {
        throw new Error("useOrderTypeDetailsContext requires a <OrderTypeOptionsContext.Provider> to be in the component hierarchy.")
    }

    return context
}
