import { CustomizationLinksDTO } from "../../../../generated/pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../ProductDetailsContainer"
import { ProductDetailsCard, ProductDetailsCardContentProps } from "../ProductDetailsCard"
import { DetailsLinkedOptionsCardContent } from "./content/DetailsLinkedOptionsCardContent"
import { FC, useCallback } from "react"

interface DetailsLinkedOptionsCardProps {
    getData: (props: { key: string; languageTag: string }) => Promise<CustomizationLinksDTO>
    getRoute: (orderTypeKey: string, optionCode: string) => string
}

export const DetailsLinkedOptionsCard: FC<ProductDetailsTabConfigContentProps & DetailsLinkedOptionsCardProps> = (props) => {
    const Content: FC<ProductDetailsCardContentProps<CustomizationLinksDTO, Record<string, any>>> = useCallback(
        (contentProps) => <DetailsLinkedOptionsCardContent {...contentProps} getRoute={props.getRoute} />,
        [props.getRoute]
    )

    return <ProductDetailsCard {...props} label={"Links"} Content={Content} />
}
