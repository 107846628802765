import { interiorColorTagRoutes } from "../../../../common/routes"
import { booleanConverterGroup, getEnumConverterGroup, getObjectURLParamConverter } from "../../../../common/useURLSearchParamState"
import { InteriorColorTagItemDTO, SortDirection } from "../../../../generated/pdsapi"
import { interiorColorTagApi, InteriorColorTagFilter, InteriorColorTagSortField, InteriorColorTagSortFieldEnum } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { InteriorColorTagListColumnsConfig } from "./columns/InteriorColorTagListColumns"
import { InteriorColorTagListFilter } from "./filter/InteriorColorTagListFilter"
import { InteriorColorTagListFilterTagsConfig } from "./filter/InteriorColorTagListFilterTagsConfig"

export const InteriorColorTagProductListContainerConfig: ProductListContainerConfig<
    InteriorColorTagItemDTO,
    InteriorColorTagFilter,
    unknown,
    InteriorColorTagSortFieldEnum
> = {
    listName: "Interior Color Tags",
    createItemLabel: "Add new InteriorColor Tag",

    buildDetailsPath: (interiorColorTag) => interiorColorTagRoutes.details(interiorColorTag.key),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<InteriorColorTagFilter>({
        active: booleanConverterGroup.optional,
    }),
    defaultSorting: {
        sortField: InteriorColorTagSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(InteriorColorTagSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: interiorColorTagApi.list.getPage,
    fetchFilterOptions: async () => ({}),

    defaultShowsFilter: false,
    Filter: InteriorColorTagListFilter,
    filterTagsConfig: InteriorColorTagListFilterTagsConfig,

    columnsConfig: InteriorColorTagListColumnsConfig,
}

export const InteriorColorTagListContainer = getProductListContainer(InteriorColorTagProductListContainerConfig)
