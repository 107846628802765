import { roofColorTagApi } from "../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLocalizationCard } from "../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { RoofColorTagDetailsAttributesCardContent } from "./data/attributes/RoofColorTagDetailsAttributesCardContent"

export const RoofColorTagProductDetailsContainerConfig: ProductDetailsContainerConfig = {
    getBaseInformation: roofColorTagApi.details.getBaseInformation,
    deleteByKey: roofColorTagApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={roofColorTagApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            roofColorTagApi.details.updateAttributes({
                                key,
                                roofColorTagAttributesUpdateDTO: update,
                            })
                        }
                        Content={RoofColorTagDetailsAttributesCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={roofColorTagApi.details.getNameLocalizations}
                        updateData={(update) => roofColorTagApi.details.updateName({ key: update.key, requestBody: update.update })}
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

export const RoofColorTagDetailsContainer = getProductDetailsContainer(RoofColorTagProductDetailsContainerConfig)
