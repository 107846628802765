import { Property } from "../../common/property"
import { NotLoadingFormDisplayData } from "./FormDisplayData"
import { FormElement } from "./FormElementWrapper"
import { FieldValues } from "react-hook-form"

export const NotLoadingFormElementWrapper = <DATA, UPDATE extends FieldValues, T, VIEW_PROPS, EDIT_PROPS>({
    label,
    formDisplayData,
    field,
    FormElement,
    getViewProps,
    getEditProps,
}: {
    label: string | undefined
    formDisplayData: NotLoadingFormDisplayData<DATA, UPDATE>
    field: Property<UPDATE, T>
    FormElement: FormElement<DATA, UPDATE, T, VIEW_PROPS, EDIT_PROPS>
    getViewProps: () => VIEW_PROPS
    getEditProps: () => EDIT_PROPS
}) => {
    switch (formDisplayData.kind) {
        case "VIEW":
            return <FormElement.View label={label} {...getViewProps()} />
        case "EDIT":
            return <FormElement.Edit label={label} field={field} {...formDisplayData} {...getEditProps()} />
    }
}
