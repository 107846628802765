import { interiorCustomizationRoutes } from "../../../../../../../common/routes"
import { ViewLabeledContentWrapper } from "../../../../../../../components/formelements/FormElementWrapper"
import { TagFormElementView } from "../../../../../../../components/formelements/tagformelement/TagFormElement"
import { TextFormElementView } from "../../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { NamedColorTile } from "../../../../../../../components/tiles/namedcolortile/NamedColorTile"
import { LinkedInteriorCustomizationDTOCustomization } from "../../../../../../../generated/pdsapi"
import { InteriorColorTile } from "@finder/ui-kit"

export const LinkedInteriorCustomizationCardContent = ({ customization }: { customization: LinkedInteriorCustomizationDTOCustomization }) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <RowGroupWrapper label={"Configuration"}>
            <TagFormElementView
                label={undefined}
                tag={{
                    label: "Jump to customization",
                    route: interiorCustomizationRoutes.details(customization.key),
                }}
            />
        </RowGroupWrapper>
        <RowGroupWrapper label={"Name"}>
            {(["de", "en"] as ("en" | "de")[]).map((languageTag) => (
                <TextFormElementView key={languageTag} label={`Name - ${languageTag}`} value={customization.value?.name[languageTag]} />
            ))}
        </RowGroupWrapper>
        <RowGroupWrapper label={"Attributes"} childrenSize={12}>
            <TextFormElementView label={"Interior Material"} value={customization.value?.materialName ?? "-"} />
            <ViewLabeledContentWrapper label={"Color"}>
                {customization.value ? (
                    <InteriorColorTile
                        seatColor={customization.value.color.seatStitchingColorHexCode}
                        seatStitchingColor={customization.value.color.seatStitchingColorHexCode}
                        cockpitColor={customization.value.color.cockpitColorHexCode}
                        cockpitStitchingColor={customization.value.color.cockpitStitchingColorHexCode}
                    />
                ) : (
                    "-"
                )}
            </ViewLabeledContentWrapper>
            <ViewLabeledContentWrapper label={"Interior Color Tag"}>
                {customization.value?.colorTag ? (
                    <NamedColorTile label={customization.value.colorTag.name ?? "-"} hexCode={customization.value.colorTag.hexCode} />
                ) : (
                    "-"
                )}
            </ViewLabeledContentWrapper>
        </RowGroupWrapper>
    </SpacedItems>
)
