import App from "./App"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"

const root = createRoot(document.getElementById("root")!)
root.render(
    <StrictMode>
        <App />
    </StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
