import { SpacedItems } from "../../layouts/spaceditems/SpacedItems"
import { InteriorColorTile } from "@finder/ui-kit"
import { PText } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FunctionComponent } from "react"

export interface NamedInteriorColorTileProps {
    label: string
    seatColor: string
    seatStitchingColor?: string
    cockpitColor?: string
    cockpitStitchingColor?: string
}

export const NamedInteriorColorTile: FunctionComponent<NamedInteriorColorTileProps> = (props) => (
    <SpacedItems direction={"row"} itemSpacing={spacing[8]}>
        <InteriorColorTile
            seatColor={props.seatColor}
            seatStitchingColor={props.seatStitchingColor}
            cockpitColor={props.cockpitColor}
            cockpitStitchingColor={props.cockpitStitchingColor}
        />
        <PText>{props.label}</PText>
    </SpacedItems>
)
