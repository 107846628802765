/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleStateSearchResult
 */
export interface VehicleStateSearchResult {
    /**
     * 
     * @type {string}
     * @memberof VehicleStateSearchResult
     */
    inventoryId: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleStateSearchResult
     */
    vehicleIdentifier: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleStateSearchResult
     */
    vehicleImageIds: Array<string>;
}

export function VehicleStateSearchResultFromJSON(json: any): VehicleStateSearchResult {
    return VehicleStateSearchResultFromJSONTyped(json, false);
}

export function VehicleStateSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleStateSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inventoryId': json['inventoryId'],
        'vehicleIdentifier': json['vehicleIdentifier'],
        'vehicleImageIds': json['vehicleImageIds'],
    };
}

export function VehicleStateSearchResultToJSON(value?: VehicleStateSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inventoryId': value.inventoryId,
        'vehicleIdentifier': value.vehicleIdentifier,
        'vehicleImageIds': value.vehicleImageIds,
    };
}


