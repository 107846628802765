/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderTypeStandardEquipmentDTOOptionsCategoryOption,
    OrderTypeStandardEquipmentDTOOptionsCategoryOptionFromJSON,
    OrderTypeStandardEquipmentDTOOptionsCategoryOptionFromJSONTyped,
    OrderTypeStandardEquipmentDTOOptionsCategoryOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeStandardEquipmentDTOOptionsCategory
 */
export interface OrderTypeStandardEquipmentDTOOptionsCategory {
    /**
     * 
     * @type {string}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategory
     */
    label: string;
    /**
     * 
     * @type {Array<OrderTypeStandardEquipmentDTOOptionsCategoryOption>}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategory
     */
    content: Array<OrderTypeStandardEquipmentDTOOptionsCategoryOption>;
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryFromJSON(json: any): OrderTypeStandardEquipmentDTOOptionsCategory {
    return OrderTypeStandardEquipmentDTOOptionsCategoryFromJSONTyped(json, false);
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeStandardEquipmentDTOOptionsCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'content': ((json['content'] as Array<any>).map(OrderTypeStandardEquipmentDTOOptionsCategoryOptionFromJSON)),
    };
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryToJSON(value?: OrderTypeStandardEquipmentDTOOptionsCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'content': ((value.content as Array<any>).map(OrderTypeStandardEquipmentDTOOptionsCategoryOptionToJSON)),
    };
}


