import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toGroupedOption } from "../../../../../components/formelements/groupedselecttextformelement/GroupedSelectOption"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { InteriorFilterOptionsDTO, InteriorMaterial } from "../../../../../generated/pdsapi"
import { InteriorFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const InteriorCatalogueFilter: FunctionComponent<TableFilterProps<InteriorFilter, InteriorFilterOptionsDTO>> = ({
    filterOptions,
    filter,
    setFilter,
}) => {
    const modelGenerationFilter = (
        <MultiSelect
            label={"Model Generations"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelGenerations.map(toOption)}
            value={filter.modelGenerationKeys ?? []}
            onChange={(modelGenerationKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelGenerationKeys: modelGenerationKeys.length === 0 ? undefined : modelGenerationKeys,
                    orderTypeKeys: undefined,
                }))
            }
        />
    )

    const modelYearFilter = (
        <MultiSelect
            label={"Model Years"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelYears.map((modelYearOption) => ({
                value: modelYearOption.key.toString(),
                label: modelYearOption.label,
            }))}
            value={filter.modelYears?.map((modelYear) => modelYear.toString()) ?? []}
            onChange={(modelYears) =>
                setFilter((filter) => ({
                    ...filter,
                    modelYears: modelYears.length === 0 ? undefined : modelYears.map((my) => +my),
                    orderTypeKeys: undefined,
                }))
            }
        />
    )

    const relevantOrderTypes =
        filter.modelGenerationKeys || filter.modelYears
            ? filterOptions?.modelYearAndGenerationRelatedOrderTypes
                  .map((modelYearGroup) => ({
                      ...modelYearGroup,
                      options: modelYearGroup.options.filter((relatedOption) => filter.modelGenerationKeys?.includes(relatedOption.relatedKey) ?? true),
                  }))
                  .filter((modelYearGroup) => modelYearGroup.options.length && (filter.modelYears?.includes(modelYearGroup.relatedKey) ?? true))
            : filterOptions?.modelYearAndGenerationRelatedOrderTypes
    const orderTypeFilter = (
        <MultiSelect
            label={"Order Types"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={relevantOrderTypes?.map(toGroupedOption)}
            value={filter.orderTypeKeys ?? []}
            onChange={(orderTypeKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    orderTypeKeys: orderTypeKeys.length === 0 ? undefined : orderTypeKeys,
                }))
            }
        />
    )

    const materialFilter = (
        <MultiSelect
            label={"Materials"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.materials.map(toOption)}
            value={filter.materials ?? []}
            onChange={(materials) =>
                setFilter((filter) => ({
                    ...filter,
                    materials: materials.length === 0 ? undefined : (materials as InteriorMaterial[]),
                }))
            }
        />
    )

    const colorTagFilter = (
        <MultiSelect
            label={"Color Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.colorTags.map(toOption)}
            value={filter.colorTagKeys ?? []}
            onChange={(colorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    colorTagKeys: colorTagKeys.length === 0 ? undefined : colorTagKeys,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>
                {modelGenerationFilter}
                {modelYearFilter}
                {orderTypeFilter}
            </ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Option"}>
                {materialFilter}
                {colorTagFilter}
            </ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
