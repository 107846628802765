/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOString,
    OptionDTOStringFromJSON,
    OptionDTOStringFromJSONTyped,
    OptionDTOStringToJSON,
    OptionGroupDTOString,
    OptionGroupDTOStringFromJSON,
    OptionGroupDTOStringFromJSONTyped,
    OptionGroupDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface GroupedSelectDTOString
 */
export interface GroupedSelectDTOString {
    /**
     * 
     * @type {OptionDTOString}
     * @memberof GroupedSelectDTOString
     */
    value: OptionDTOString;
    /**
     * 
     * @type {Array<OptionGroupDTOString>}
     * @memberof GroupedSelectDTOString
     */
    categorizedOptions: Array<OptionGroupDTOString>;
}

export function GroupedSelectDTOStringFromJSON(json: any): GroupedSelectDTOString {
    return GroupedSelectDTOStringFromJSONTyped(json, false);
}

export function GroupedSelectDTOStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupedSelectDTOString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': OptionDTOStringFromJSON(json['value']),
        'categorizedOptions': ((json['categorizedOptions'] as Array<any>).map(OptionGroupDTOStringFromJSON)),
    };
}

export function GroupedSelectDTOStringToJSON(value?: GroupedSelectDTOString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': OptionDTOStringToJSON(value.value),
        'categorizedOptions': ((value.categorizedOptions as Array<any>).map(OptionGroupDTOStringToJSON)),
    };
}


