/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RelatedOptionDTOStringString
 */
export interface RelatedOptionDTOStringString {
    /**
     * 
     * @type {string}
     * @memberof RelatedOptionDTOStringString
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedOptionDTOStringString
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedOptionDTOStringString
     */
    relatedKey: string;
}

export function RelatedOptionDTOStringStringFromJSON(json: any): RelatedOptionDTOStringString {
    return RelatedOptionDTOStringStringFromJSONTyped(json, false);
}

export function RelatedOptionDTOStringStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedOptionDTOStringString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'label': json['label'],
        'relatedKey': json['relatedKey'],
    };
}

export function RelatedOptionDTOStringStringToJSON(value?: RelatedOptionDTOStringString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'label': value.label,
        'relatedKey': value.relatedKey,
    };
}


