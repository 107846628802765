/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BodyType,
    BodyTypeFromJSON,
    BodyTypeToJSON,
    DerivativeFilterOptionsDTO,
    DerivativeFilterOptionsDTOFromJSON,
    DerivativeFilterOptionsDTOToJSON,
    PageDTODerivativeItemDTO,
    PageDTODerivativeItemDTOFromJSON,
    PageDTODerivativeItemDTOToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    SortFieldCombinedEnum,
    SortFieldCombinedEnumFromJSON,
    SortFieldCombinedEnumToJSON,
} from '../models';

export interface GetDerivativeFilterOptionsRequest {
    languageTag: string;
}

export interface GetDerivativesPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: SortFieldCombinedEnum;
    sortDirection: SortDirection;
    search?: string;
    modelSeriesKeys?: Array<string>;
    derivativeCategoryKeys?: Array<string>;
    bodyTypes?: Array<BodyType>;
}

/**
 * 
 */
export class DerivativeListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter Derivatives
     */
    async getDerivativeFilterOptionsRaw(requestParameters: GetDerivativeFilterOptionsRequest): Promise<runtime.ApiResponse<DerivativeFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getDerivativeFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DerivativeFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter Derivatives
     */
    async getDerivativeFilterOptions(requestParameters: GetDerivativeFilterOptionsRequest): Promise<DerivativeFilterOptionsDTO> {
        const response = await this.getDerivativeFilterOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get page of Derivatives
     */
    async getDerivativesPageRaw(requestParameters: GetDerivativesPageRequest): Promise<runtime.ApiResponse<PageDTODerivativeItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getDerivativesPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getDerivativesPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getDerivativesPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getDerivativesPage.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getDerivativesPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.modelSeriesKeys) {
            queryParameters['modelSeriesKeys'] = requestParameters.modelSeriesKeys;
        }

        if (requestParameters.derivativeCategoryKeys) {
            queryParameters['derivativeCategoryKeys'] = requestParameters.derivativeCategoryKeys;
        }

        if (requestParameters.bodyTypes) {
            queryParameters['bodyTypes'] = requestParameters.bodyTypes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTODerivativeItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of Derivatives
     */
    async getDerivativesPage(requestParameters: GetDerivativesPageRequest): Promise<PageDTODerivativeItemDTO> {
        const response = await this.getDerivativesPageRaw(requestParameters);
        return await response.value();
    }

}
