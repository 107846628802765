import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../components/tag/Tag"
import { InteriorCustomizationFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { InteriorCustomizationFilter } from "../../../../../pdsapi"
import { findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"

export const InteriorCustomizationListFilterTagsConfig: TableFilterTagsConfig<InteriorCustomizationFilter, InteriorCustomizationFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const filterTags: JSX.Element[] = []

    filter.modelGenerationKeys?.forEach((modelGenerationKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelGenerationKey_${modelGenerationKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelGenerationKeys = filter.modelGenerationKeys?.filter((key) => modelGenerationKey !== key)
                        return {
                            ...filter,
                            modelGenerationKeys: newModelGenerationKeys?.length !== 0 ? newModelGenerationKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.modelGenerations, modelGenerationKey)?.label ?? modelGenerationKey}
            </Tag>
        )
    })

    filter.interiorColorTagKeys?.forEach((interiorColorTagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_interiorColorTagKey_${interiorColorTagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newInteriorColorTagKeys = filter.interiorColorTagKeys?.filter((key) => interiorColorTagKey !== key)
                        return {
                            ...filter,
                            interiorColorTagKeys: newInteriorColorTagKeys?.length !== 0 ? newInteriorColorTagKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.interiorColorTags, interiorColorTagKey)?.label ?? interiorColorTagKey}
            </Tag>
        )
    })

    filter.interiorMaterials?.forEach((interiorMaterial) => {
        filterTags.push(
            <Tag
                key={`filterTag_InteriorMaterial_${interiorMaterial}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newInteriorMaterialKeys = filter.interiorMaterials?.filter((key) => interiorMaterial !== key)
                        return {
                            ...filter,
                            interiorMaterials: newInteriorMaterialKeys?.length !== 0 ? newInteriorMaterialKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.interiorMaterials, interiorMaterial)?.label ?? interiorMaterial}
            </Tag>
        )
    })

    return filterTags
}
