/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalizationsItemDTO,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOFromJSONTyped,
    LocalizationsItemDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionLocalizationItemDTO
 */
export interface OptionLocalizationItemDTO {
    /**
     * 
     * @type {LocalizationsItemDTO}
     * @memberof OptionLocalizationItemDTO
     */
    merged: LocalizationsItemDTO;
    /**
     * 
     * @type {LocalizationsItemDTO}
     * @memberof OptionLocalizationItemDTO
     */
    option: LocalizationsItemDTO;
    /**
     * 
     * @type {LocalizationsItemDTO}
     * @memberof OptionLocalizationItemDTO
     */
    customization?: LocalizationsItemDTO;
}

export function OptionLocalizationItemDTOFromJSON(json: any): OptionLocalizationItemDTO {
    return OptionLocalizationItemDTOFromJSONTyped(json, false);
}

export function OptionLocalizationItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionLocalizationItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'merged': LocalizationsItemDTOFromJSON(json['merged']),
        'option': LocalizationsItemDTOFromJSON(json['option']),
        'customization': !exists(json, 'customization') ? undefined : LocalizationsItemDTOFromJSON(json['customization']),
    };
}

export function OptionLocalizationItemDTOToJSON(value?: OptionLocalizationItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'merged': LocalizationsItemDTOToJSON(value.merged),
        'option': LocalizationsItemDTOToJSON(value.option),
        'customization': LocalizationsItemDTOToJSON(value.customization),
    };
}


