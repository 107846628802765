import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { InteriorCustomizationCreateContainer } from "./create/InteriorCustomizationCreateContainer"
import { InteriorCustomizationDetailsContainer } from "./details/InteriorCustomizationDetailsContainer"
import { InteriorCustomizationListContainer } from "./list/InteriorCustomizationListContainer"
import { FC } from "react"

export const InteriorCustomizationRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Interior Customizations",
        List: InteriorCustomizationListContainer,
        Details: InteriorCustomizationDetailsContainer,
        Create: InteriorCustomizationCreateContainer,
    })
