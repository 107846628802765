/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InteriorColorTileDTO,
    InteriorColorTileDTOFromJSON,
    InteriorColorTileDTOFromJSONTyped,
    InteriorColorTileDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface SimilarInteriorCustomizationsDTOCustomization
 */
export interface SimilarInteriorCustomizationsDTOCustomization {
    /**
     * 
     * @type {string}
     * @memberof SimilarInteriorCustomizationsDTOCustomization
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof SimilarInteriorCustomizationsDTOCustomization
     */
    label: string;
    /**
     * 
     * @type {InteriorColorTileDTO}
     * @memberof SimilarInteriorCustomizationsDTOCustomization
     */
    color: InteriorColorTileDTO;
}

export function SimilarInteriorCustomizationsDTOCustomizationFromJSON(json: any): SimilarInteriorCustomizationsDTOCustomization {
    return SimilarInteriorCustomizationsDTOCustomizationFromJSONTyped(json, false);
}

export function SimilarInteriorCustomizationsDTOCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarInteriorCustomizationsDTOCustomization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'label': json['label'],
        'color': InteriorColorTileDTOFromJSON(json['color']),
    };
}

export function SimilarInteriorCustomizationsDTOCustomizationToJSON(value?: SimilarInteriorCustomizationsDTOCustomization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'label': value.label,
        'color': InteriorColorTileDTOToJSON(value.color),
    };
}


