import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../components/tag/Tag"
import { CustomExteriorColorFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { CustomExteriorColorFilter } from "../../../../../pdsapi"

export const CustomExteriorColorListFilterTagsConfig: TableFilterTagsConfig<CustomExteriorColorFilter, CustomExteriorColorFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const filterTags: JSX.Element[] = []

    filter.exteriorColorTagKeys?.forEach((exteriorColorTagKey) => {
        filterTags.push(
            <Tag
                onRemove={() => {
                    const newTags = filter.exteriorColorTagKeys?.filter((key) => exteriorColorTagKey !== key)
                    setFilter({
                        ...filter,
                        exteriorColorTagKeys: newTags?.length ? newTags : undefined,
                    })
                }}
            >
                {filterOptions?.exteriorColorTags?.find((it) => it.key === exteriorColorTagKey)?.label ?? exteriorColorTagKey}
            </Tag>
        )
    })

    filter.exteriorColorTypes?.forEach((exteriorColorType) => {
        filterTags.push(
            <Tag
                onRemove={() => {
                    const newTypes = filter.exteriorColorTypes?.filter((key) => exteriorColorType !== key)
                    setFilter({
                        ...filter,
                        exteriorColorTypes: newTypes?.length ? newTypes : undefined,
                    })
                }}
            >
                {filterOptions?.exteriorColorTypes?.find((it) => it.key === exteriorColorType)?.label ?? exteriorColorType}
            </Tag>
        )
    })

    return filterTags
}
