import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { ExteriorColorCustomizationCreateOptionsDTO, ExteriorColorCustomizationCreateRequestDTO } from "../../../../generated/pdsapi"
import { exteriorColorCustomizationApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { VehicleImagesCreateCard } from "../../../../viewtemplates/create/cards/vehicleimagescard/VehicleImagesCreateCard"
import { ExteriorColorCustomizationCreateAttributesCard } from "./attributes/ExteriorColorCustomizationCreateAttributesCard"

export const ExteriorColorCustomizationProductCreateContainerConfig: ProductCreateContainerConfig<
    ExteriorColorCustomizationCreateRequestDTO,
    ExteriorColorCustomizationCreateOptionsDTO
> = {
    headline: "Create new Exterior Color Customization",

    create: (item) =>
        exteriorColorCustomizationApi.create.create({
            exteriorColorCustomizationCreateRequestDTO: item,
        }),
    getCreateOptions: exteriorColorCustomizationApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <ExteriorColorCustomizationCreateAttributesCard {...props} />
            <VehicleImagesCreateCard formDisplayData={props.formDisplayData} getVehicleImages={exteriorColorCustomizationApi.create.getVehicleImages} />
        </DefaultCardViewContentWrapper>
    ),
}

export const ExteriorColorCustomizationCreateContainer = getProductCreateContainer(ExteriorColorCustomizationProductCreateContainerConfig)
