/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TechnicalDataAttributeDataColumnDefinition,
    TechnicalDataAttributeDataColumnDefinitionFromJSON,
    TechnicalDataAttributeDataColumnDefinitionFromJSONTyped,
    TechnicalDataAttributeDataColumnDefinitionToJSON,
    TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject,
    TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectFromJSON,
    TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectFromJSONTyped,
    TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface GlobalOrderTypeTechnicalDataAttributeDTO
 */
export interface GlobalOrderTypeTechnicalDataAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof GlobalOrderTypeTechnicalDataAttributeDTO
     */
    attributeName?: string;
    /**
     * 
     * @type {Array<TechnicalDataAttributeDataColumnDefinition>}
     * @memberof GlobalOrderTypeTechnicalDataAttributeDTO
     */
    columnDefinitions: Array<TechnicalDataAttributeDataColumnDefinition>;
    /**
     * 
     * @type {Array<TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject>}
     * @memberof GlobalOrderTypeTechnicalDataAttributeDTO
     */
    equipmentRelations: Array<TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GlobalOrderTypeTechnicalDataAttributeDTO
     */
    disclaimer: { [key: string]: string; };
}

export function GlobalOrderTypeTechnicalDataAttributeDTOFromJSON(json: any): GlobalOrderTypeTechnicalDataAttributeDTO {
    return GlobalOrderTypeTechnicalDataAttributeDTOFromJSONTyped(json, false);
}

export function GlobalOrderTypeTechnicalDataAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalOrderTypeTechnicalDataAttributeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeName': !exists(json, 'attributeName') ? undefined : json['attributeName'],
        'columnDefinitions': ((json['columnDefinitions'] as Array<any>).map(TechnicalDataAttributeDataColumnDefinitionFromJSON)),
        'equipmentRelations': ((json['equipmentRelations'] as Array<any>).map(TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectFromJSON)),
        'disclaimer': json['disclaimer'],
    };
}

export function GlobalOrderTypeTechnicalDataAttributeDTOToJSON(value?: GlobalOrderTypeTechnicalDataAttributeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeName': value.attributeName,
        'columnDefinitions': ((value.columnDefinitions as Array<any>).map(TechnicalDataAttributeDataColumnDefinitionToJSON)),
        'equipmentRelations': ((value.equipmentRelations as Array<any>).map(TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectToJSON)),
        'disclaimer': value.disclaimer,
    };
}


