import { getSimpleFormWatch } from "../../../../../components/formelements/FormDisplayData"
import { WidgetWrapper } from "../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { LoadableVehicleImageList } from "../../../../../components/loadablevehicleimagelist/LoadableVehicleImageList"
import { CustomInteriorColorCreateOptionsDTO, CustomInteriorColorCreateRequestDTO } from "../../../../../generated/pdsapi"
import { customInteriorColorApi } from "../../../../../pdsapi"
import { ProductCreateContentProps } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { FC, useCallback } from "react"

export const CustomInteriorColorCreateVehicleImagesCardContent: FC<
    ProductCreateContentProps<CustomInteriorColorCreateRequestDTO, CustomInteriorColorCreateOptionsDTO>
> = ({ formDisplayData }) => {
    const customColorCode = getSimpleFormWatch(formDisplayData)("key")
    const load = useCallback(
        async () =>
            (customColorCode &&
                (await customInteriorColorApi.details.loadVehicleImages({
                    key: customColorCode,
                }))) ||
            [],
        [customColorCode]
    )
    return <WidgetWrapper label={"Vehicle Images"}>{customColorCode ? <LoadableVehicleImageList load={load} /> : null}</WidgetWrapper>
}
