import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { OrderTypeCreateContainer } from "./create/OrderTypeCreateContainer"
import { OrderTypeDetailsContainer } from "./details/OrderTypeDetailsContainer"
import { OrderTypeEquipmentRoutes } from "./equipment/OrderTypeEquipmentRoutes"
import { OrderTypeExteriorColorsRoutes } from "./exteriorcolors/OrderTypeExteriorColorsRoutes"
import { OrderTypeInteriorsRoutes } from "./interiors/OrderTypeInteriorsRoutes"
import { OrderTypeListContainer } from "./list/OrderTypeListContainer"
import { OrderTypeRoofColorsRoutes } from "./roofcolors/OrderTypeRoofColorsRoutes"
import { FC } from "react"
import { Route } from "react-router-dom"
import { OrderTypePaintToSampleExteriorColorsRoutes } from "./painttosampleexteriorcolors/OrderTypePaintToSampleExteriorColorsRoutes"

export const OrderTypeRoutes: FC = (props) => {
    return ProductRoutes({
        ...props,
        listName: "Order Types",
        List: OrderTypeListContainer,
        Details: OrderTypeDetailsContainer,
        Create: OrderTypeCreateContainer,
        customRoutes: [
            <Route key={"equipment_routes"} path={":orderTypeKey/equipment/*"} element={<OrderTypeEquipmentRoutes />} />,
            <Route key={"exterior_color_details_route"} path={":orderTypeKey/exterior-colors/*"} element={<OrderTypeExteriorColorsRoutes />} />,
            <Route
                key={"paint_to_sample_exterior_color_details_route"}
                path={":orderTypeKey/paint-to-sample-exterior-colors/*"}
                element={<OrderTypePaintToSampleExteriorColorsRoutes />}
            />,
            <Route key={"interior_details_route"} path={":orderTypeKey/interiors/*"} element={<OrderTypeInteriorsRoutes />} />,
            <Route key={"roof_color_details_route"} path={":orderTypeKey/roof-colors/*"} element={<OrderTypeRoofColorsRoutes />} />,
        ],
    })
}
