/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    InteriorCustomizationAttributesValueUpdateDTOColor,
    InteriorCustomizationAttributesValueUpdateDTOColorFromJSON,
    InteriorCustomizationAttributesValueUpdateDTOColorFromJSONTyped,
    InteriorCustomizationAttributesValueUpdateDTOColorToJSON,
    InteriorMaterial,
    InteriorMaterialFromJSON,
    InteriorMaterialFromJSONTyped,
    InteriorMaterialToJSON,
} from './';

/**
 * 
 * @export
 * @interface InteriorCustomizationAttributesValueUpdateDTO
 */
export interface InteriorCustomizationAttributesValueUpdateDTO {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof InteriorCustomizationAttributesValueUpdateDTO
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof InteriorCustomizationAttributesValueUpdateDTO
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {InteriorCustomizationAttributesValueUpdateDTOColor}
     * @memberof InteriorCustomizationAttributesValueUpdateDTO
     */
    color: InteriorCustomizationAttributesValueUpdateDTOColor;
    /**
     * 
     * @type {InteriorMaterial}
     * @memberof InteriorCustomizationAttributesValueUpdateDTO
     */
    material: InteriorMaterial;
}

export function InteriorCustomizationAttributesValueUpdateDTOFromJSON(json: any): InteriorCustomizationAttributesValueUpdateDTO {
    return InteriorCustomizationAttributesValueUpdateDTOFromJSONTyped(json, false);
}

export function InteriorCustomizationAttributesValueUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorCustomizationAttributesValueUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'color': InteriorCustomizationAttributesValueUpdateDTOColorFromJSON(json['color']),
        'material': InteriorMaterialFromJSON(json['material']),
    };
}

export function InteriorCustomizationAttributesValueUpdateDTOToJSON(value?: InteriorCustomizationAttributesValueUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
        'description': BaseNameDTOToJSON(value.description),
        'color': InteriorCustomizationAttributesValueUpdateDTOColorToJSON(value.color),
        'material': InteriorMaterialToJSON(value.material),
    };
}


