/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOInteger,
    OptionDTOIntegerFromJSON,
    OptionDTOIntegerFromJSONTyped,
    OptionDTOIntegerToJSON,
    OptionDTOString,
    OptionDTOStringFromJSON,
    OptionDTOStringFromJSONTyped,
    OptionDTOStringToJSON,
    OptionDTOVehicleEquipmentCategory,
    OptionDTOVehicleEquipmentCategoryFromJSON,
    OptionDTOVehicleEquipmentCategoryFromJSONTyped,
    OptionDTOVehicleEquipmentCategoryToJSON,
    OptionGroupDTOString,
    OptionGroupDTOStringFromJSON,
    OptionGroupDTOStringFromJSONTyped,
    OptionGroupDTOStringToJSON,
    RelatedGroupedRelatedOptionDTOIntegerStringString,
    RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSON,
    RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSONTyped,
    RelatedGroupedRelatedOptionDTOIntegerStringStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface EquipmentFilterOptionsDTO
 */
export interface EquipmentFilterOptionsDTO {
    /**
     * 
     * @type {Array<OptionDTOString>}
     * @memberof EquipmentFilterOptionsDTO
     */
    modelGenerations: Array<OptionDTOString>;
    /**
     * 
     * @type {Array<OptionDTOInteger>}
     * @memberof EquipmentFilterOptionsDTO
     */
    modelYears: Array<OptionDTOInteger>;
    /**
     * 
     * @type {Array<RelatedGroupedRelatedOptionDTOIntegerStringString>}
     * @memberof EquipmentFilterOptionsDTO
     */
    modelYearAndGenerationRelatedOrderTypes: Array<RelatedGroupedRelatedOptionDTOIntegerStringString>;
    /**
     * 
     * @type {Array<OptionDTOVehicleEquipmentCategory>}
     * @memberof EquipmentFilterOptionsDTO
     */
    categories: Array<OptionDTOVehicleEquipmentCategory>;
    /**
     * 
     * @type {Array<OptionGroupDTOString>}
     * @memberof EquipmentFilterOptionsDTO
     */
    tags: Array<OptionGroupDTOString>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EquipmentFilterOptionsDTO
     */
    families: Array<string>;
}

export function EquipmentFilterOptionsDTOFromJSON(json: any): EquipmentFilterOptionsDTO {
    return EquipmentFilterOptionsDTOFromJSONTyped(json, false);
}

export function EquipmentFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentFilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelGenerations': ((json['modelGenerations'] as Array<any>).map(OptionDTOStringFromJSON)),
        'modelYears': ((json['modelYears'] as Array<any>).map(OptionDTOIntegerFromJSON)),
        'modelYearAndGenerationRelatedOrderTypes': ((json['modelYearAndGenerationRelatedOrderTypes'] as Array<any>).map(RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSON)),
        'categories': ((json['categories'] as Array<any>).map(OptionDTOVehicleEquipmentCategoryFromJSON)),
        'tags': ((json['tags'] as Array<any>).map(OptionGroupDTOStringFromJSON)),
        'families': json['families'],
    };
}

export function EquipmentFilterOptionsDTOToJSON(value?: EquipmentFilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelGenerations': ((value.modelGenerations as Array<any>).map(OptionDTOStringToJSON)),
        'modelYears': ((value.modelYears as Array<any>).map(OptionDTOIntegerToJSON)),
        'modelYearAndGenerationRelatedOrderTypes': ((value.modelYearAndGenerationRelatedOrderTypes as Array<any>).map(RelatedGroupedRelatedOptionDTOIntegerStringStringToJSON)),
        'categories': ((value.categories as Array<any>).map(OptionDTOVehicleEquipmentCategoryToJSON)),
        'tags': ((value.tags as Array<any>).map(OptionGroupDTOStringToJSON)),
        'families': value.families,
    };
}


