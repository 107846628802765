import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { EquipmentTagItemDTO } from "../../../../../generated/pdsapi"
import { EquipmentTagSortField, EquipmentTagSortFieldEnum } from "../../../../../pdsapi"
import { activeColumn } from "../../../../../viewtemplates/list/columns/activeColumn"

type EquipmentTagColumnBuilder = ColumnBuilder<EquipmentTagItemDTO, EquipmentTagSortFieldEnum>

export const EquipmentTagListColumnsConfig: TableColumnsConfig<EquipmentTagItemDTO, EquipmentTagSortFieldEnum> = ({ sorting, setSorting }) => {
    const sortIndexColumn: EquipmentTagColumnBuilder = {
        width: 40,
        headerCellProps: {
            content: "#",
            sorting: {
                field: EquipmentTagSortField.SORT_INDEX,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (equipmentTag) => equipmentTag.sortIndex.toString(),
    }
    const nameColumn: EquipmentTagColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (equipmentTag) => equipmentTag.name,
    }
    const categoryColumn: EquipmentTagColumnBuilder = {
        width: 250,
        headerCellProps: {
            content: "Category",
        },
        buildCellContent: (equipmentTag) => equipmentTag.category,
    }

    return [sortIndexColumn, nameColumn, categoryColumn, activeColumn]
}
