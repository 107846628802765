/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InteriorCustomizationItemDTOValueColor,
    InteriorCustomizationItemDTOValueColorFromJSON,
    InteriorCustomizationItemDTOValueColorFromJSONTyped,
    InteriorCustomizationItemDTOValueColorToJSON,
} from './';

/**
 * 
 * @export
 * @interface InteriorCustomizationItemDTOValue
 */
export interface InteriorCustomizationItemDTOValue {
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationItemDTOValue
     */
    materialName: string;
    /**
     * 
     * @type {InteriorCustomizationItemDTOValueColor}
     * @memberof InteriorCustomizationItemDTOValue
     */
    color: InteriorCustomizationItemDTOValueColor;
}

export function InteriorCustomizationItemDTOValueFromJSON(json: any): InteriorCustomizationItemDTOValue {
    return InteriorCustomizationItemDTOValueFromJSONTyped(json, false);
}

export function InteriorCustomizationItemDTOValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorCustomizationItemDTOValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'materialName': json['materialName'],
        'color': InteriorCustomizationItemDTOValueColorFromJSON(json['color']),
    };
}

export function InteriorCustomizationItemDTOValueToJSON(value?: InteriorCustomizationItemDTOValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'materialName': value.materialName,
        'color': InteriorCustomizationItemDTOValueColorToJSON(value.color),
    };
}


