import { PSelectWrapper } from "@porsche-design-system/components-react"
import { ChangeEvent, FC } from "react"

export interface Bool3SelectorProps {
    label: string
    value?: boolean
    onChange: (newValue?: boolean) => void
    trueMessage: string
    falseMessage: string
    undefinedMessage: string
}

export const Bool3Selector: FC<Bool3SelectorProps> = (props) => (
    <PSelectWrapper label={props.label} hideLabel={false}>
        <select
            value={props.value?.toString() ?? "undefined"}
            onChange={(changeEvent: ChangeEvent<HTMLSelectElement>) => {
                let value: boolean | undefined
                switch (changeEvent.target.value) {
                    case "true":
                        value = true
                        break
                    case "false":
                        value = false
                        break
                    default:
                        value = undefined
                }

                props.onChange(value)
            }}
        >
            <option value={"undefined"}>{props.undefinedMessage}</option>
            <option value={"true"}>{props.trueMessage}</option>
            <option value={"false"}>{props.falseMessage}</option>
        </select>
    </PSelectWrapper>
)
