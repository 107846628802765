/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InteriorFilterOptionsDTO,
    InteriorFilterOptionsDTOFromJSON,
    InteriorFilterOptionsDTOToJSON,
    InteriorMaterial,
    InteriorMaterialFromJSON,
    InteriorMaterialToJSON,
    PageDTOInteriorItemDTO,
    PageDTOInteriorItemDTOFromJSON,
    PageDTOInteriorItemDTOToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    SortFieldCombinedEnum,
    SortFieldCombinedEnumFromJSON,
    SortFieldCombinedEnumToJSON,
} from '../models';

export interface GetInteriorFilterOptionsRequest {
    languageTag: string;
}

export interface GetInteriorsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: SortFieldCombinedEnum;
    sortDirection: SortDirection;
    search?: string;
    modelGenerationKeys?: Array<string>;
    modelYears?: Array<number>;
    orderTypeKeys?: Array<string>;
    materials?: Array<InteriorMaterial>;
    colorTagKeys?: Array<string>;
}

/**
 * 
 */
export class InteriorCatalogueWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter Interiors
     */
    async getInteriorFilterOptionsRaw(requestParameters: GetInteriorFilterOptionsRequest): Promise<runtime.ApiResponse<InteriorFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getInteriorFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/interior/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InteriorFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter Interiors
     */
    async getInteriorFilterOptions(requestParameters: GetInteriorFilterOptionsRequest): Promise<InteriorFilterOptionsDTO> {
        const response = await this.getInteriorFilterOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get page of Interiors
     */
    async getInteriorsPageRaw(requestParameters: GetInteriorsPageRequest): Promise<runtime.ApiResponse<PageDTOInteriorItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getInteriorsPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getInteriorsPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getInteriorsPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getInteriorsPage.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getInteriorsPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.modelGenerationKeys) {
            queryParameters['modelGenerationKeys'] = requestParameters.modelGenerationKeys;
        }

        if (requestParameters.modelYears) {
            queryParameters['modelYears'] = requestParameters.modelYears;
        }

        if (requestParameters.orderTypeKeys) {
            queryParameters['orderTypeKeys'] = requestParameters.orderTypeKeys;
        }

        if (requestParameters.materials) {
            queryParameters['materials'] = requestParameters.materials;
        }

        if (requestParameters.colorTagKeys) {
            queryParameters['colorTagKeys'] = requestParameters.colorTagKeys;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/interior/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOInteriorItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of Interiors
     */
    async getInteriorsPage(requestParameters: GetInteriorsPageRequest): Promise<PageDTOInteriorItemDTO> {
        const response = await this.getInteriorsPageRaw(requestParameters);
        return await response.value();
    }

}
