/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SimilarInteriorCustomizationsDTOCustomization,
    SimilarInteriorCustomizationsDTOCustomizationFromJSON,
    SimilarInteriorCustomizationsDTOCustomizationFromJSONTyped,
    SimilarInteriorCustomizationsDTOCustomizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface SimilarInteriorCustomizationsDTO
 */
export interface SimilarInteriorCustomizationsDTO {
    /**
     * 
     * @type {Array<SimilarInteriorCustomizationsDTOCustomization>}
     * @memberof SimilarInteriorCustomizationsDTO
     */
    customizationsForOtherModelGenerations: Array<SimilarInteriorCustomizationsDTOCustomization>;
}

export function SimilarInteriorCustomizationsDTOFromJSON(json: any): SimilarInteriorCustomizationsDTO {
    return SimilarInteriorCustomizationsDTOFromJSONTyped(json, false);
}

export function SimilarInteriorCustomizationsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarInteriorCustomizationsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customizationsForOtherModelGenerations': ((json['customizationsForOtherModelGenerations'] as Array<any>).map(SimilarInteriorCustomizationsDTOCustomizationFromJSON)),
    };
}

export function SimilarInteriorCustomizationsDTOToJSON(value?: SimilarInteriorCustomizationsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customizationsForOtherModelGenerations': ((value.customizationsForOtherModelGenerations as Array<any>).map(SimilarInteriorCustomizationsDTOCustomizationToJSON)),
    };
}


