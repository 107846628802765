import { FlexItemWidth, PFlex, PFlexItem } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { Children, FC, ReactNode } from "react"
import styled from "styled-components"

interface ColumnGroupsWrapperProps {
    children: ReactNode
    flexWidth?: FlexItemWidth
}

export const ColumnGroupsWrapper: FC<ColumnGroupsWrapperProps> = (props) => {
    return (
        <FlexSpacing>
            <PFlex wrap={"wrap"}>
                {Children.map(props.children, (child) => (
                    <PFlexItem width={props.flexWidth ?? "one-quarter"}>
                        <FlexItemSpacing>{child}</FlexItemSpacing>
                    </PFlexItem>
                ))}
            </PFlex>
        </FlexSpacing>
    )
}

const flexSpacing = spacing[16]
const FlexSpacing = styled.div`
    margin-left: -${flexSpacing};
    margin-right: -${flexSpacing};
`
const FlexItemSpacing = styled.div`
    padding-left: ${flexSpacing};
    padding-right: ${flexSpacing};
    margin-bottom: ${flexSpacing};
`
