/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTODriveTrain,
    OptionDTODriveTrainFromJSON,
    OptionDTODriveTrainFromJSONTyped,
    OptionDTODriveTrainToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionalSelectDTODriveTrain
 */
export interface OptionalSelectDTODriveTrain {
    /**
     * 
     * @type {OptionDTODriveTrain}
     * @memberof OptionalSelectDTODriveTrain
     */
    value?: OptionDTODriveTrain;
    /**
     * 
     * @type {Array<OptionDTODriveTrain>}
     * @memberof OptionalSelectDTODriveTrain
     */
    options: Array<OptionDTODriveTrain>;
}

export function OptionalSelectDTODriveTrainFromJSON(json: any): OptionalSelectDTODriveTrain {
    return OptionalSelectDTODriveTrainFromJSONTyped(json, false);
}

export function OptionalSelectDTODriveTrainFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalSelectDTODriveTrain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : OptionDTODriveTrainFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(OptionDTODriveTrainFromJSON)),
    };
}

export function OptionalSelectDTODriveTrainToJSON(value?: OptionalSelectDTODriveTrain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': OptionDTODriveTrainToJSON(value.value),
        'options': ((value.options as Array<any>).map(OptionDTODriveTrainToJSON)),
    };
}


