import { SelectOption } from "@finder/ui-kit/build/types/components/forms/multiSelect/MultiSelect"
import { PSelectWrapper } from "@porsche-design-system/components-react"
import { ChangeEvent, FC } from "react"

export interface TextSelectorProps {
    label?: string
    options: SelectOption[]
    value?: string
    defaultValue?: string
    onChange: (value: string) => void
    readOnly?: boolean
    errorMessage?: string
}

export const TextSelector: FC<TextSelectorProps> = (props) => (
    <PSelectWrapper label={props.label} hideLabel={props.label === undefined} state={props.errorMessage ? "error" : "none"} message={props.errorMessage}>
        <select
            value={props.value}
            defaultValue={props.defaultValue}
            onChange={(changeEvent: ChangeEvent<HTMLSelectElement>) => props.onChange(changeEvent.target.value)}
            disabled={props.readOnly}
        >
            {props.options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    </PSelectWrapper>
)
