import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { EquipmentCustomizationCreateContainer } from "./create/EquipmentCustomizationCreateContainer"
import { EquipmentCustomizationDetailsContainer } from "./details/EquipmentCustomizationDetailsContainer"
import { EquipmentCustomizationListContainer } from "./list/EquipmentCustomizationListContainer"
import { FC } from "react"

export const EquipmentCustomizationRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Equipment Customizations",
        List: EquipmentCustomizationListContainer,
        Details: EquipmentCustomizationDetailsContainer,
        Create: EquipmentCustomizationCreateContainer,
    })
