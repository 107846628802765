import { TableHeaderCellSorting } from "../../layouts/table/sortingheadercell/TableSortingHeaderCell"
import { PIcon } from "@porsche-design-system/components-react"

export const getSortIndexColumnBuilder = <ITEM, SORTING_FIELD = undefined>(
    getSortIndex: (item: ITEM) => number,
    sorting?: TableHeaderCellSorting<SORTING_FIELD>
) => ({
    width: 40,
    headerCellProps: {
        content: "#",
        sorting,
    },
    buildCellContent: (item: ITEM) => getSortIndex(item).toString(),
})

export const getActiveColumnBuilder = <ITEM,>(getActive: (item: ITEM) => boolean) => ({
    width: 50,
    headerCellProps: {
        content: "Active",
    },
    buildCellContent: (item: ITEM) => (getActive(item) ? <PIcon name={"check"} aria-label={"active icon"} /> : "-"),
})
