import { ConfirmationModal } from "../confirmationmodal/ConfirmationModal"
import { FC } from "react"

interface SafetyRequestModalProps {
    itemKey: string
    onCancel: () => void
    onDelete: () => void
    isOpen: boolean
}

export const SafetyRequestModal: FC<SafetyRequestModalProps> = (props) => {
    return (
        <ConfirmationModal
            headline={"Confirmation"}
            content={`Do you really want to delete the item with key ${props.itemKey}?`}
            confirmIcon={"delete"}
            confirmLabel={"Yes"}
            onCancel={props.onCancel}
            onConfirm={props.onDelete}
            isOpen={props.isOpen}
        />
    )
}
