import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { CustomExteriorColorCreateOptionsDTO, CustomExteriorColorCreateRequestDTO } from "../../../../generated/pdsapi"
import { customExteriorColorApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { CustomExteriorColorCreateAttributesCard } from "./attributes/CustomExteriorColorCreateAttributesCard"
import { CustomExteriorColorCreateVehicleImagesCardContent } from "./vehicleimages/CustomExteriorColorCreateVehicleImagesCardContent"

export const CustomExteriorColorProductCreateContainerConfig: ProductCreateContainerConfig<
    CustomExteriorColorCreateRequestDTO,
    CustomExteriorColorCreateOptionsDTO
> = {
    headline: "Create new Custom Exterior Color",

    create: (item) =>
        customExteriorColorApi.create.create({
            customExteriorColorCreateRequestDTO: item,
        }),
    getCreateOptions: customExteriorColorApi.create.getOptions,

    Content: (props) => (
        <>
            <DefaultCardViewContentWrapper>
                <CustomExteriorColorCreateAttributesCard {...props} />
            </DefaultCardViewContentWrapper>
            <DefaultCardViewContentWrapper>
                <CustomExteriorColorCreateVehicleImagesCardContent {...props} />
            </DefaultCardViewContentWrapper>
        </>
    ),
}

export const CustomExteriorColorCreateContainer = getProductCreateContainer(CustomExteriorColorProductCreateContainerConfig)
