import { Box } from "../box/Box"
import { SpacedItems } from "../spaceditems/SpacedItems"
import { PHeadline } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC, ReactNode } from "react"

export interface RowGroupWrapperProps {
    label: string
    children: ReactNode
}

export const ColumnGroupWrapper: FC<RowGroupWrapperProps> = (props) => (
    <>
        <PHeadline variant={"headline-5"}>{props.label}</PHeadline>

        <Box height={spacing[8]} />

        <SpacedItems direction={"column"} lineSpacing={spacing[8]}>
            {props.children}
        </SpacedItems>
    </>
)
