import { booleanConverterGroup, useURLSearchParamState } from "../../common/useURLSearchParamState"
import { SpacedItems } from "../layouts/spaceditems/SpacedItems"
import { PFlex, PLinkPure } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC, ReactNode } from "react"
import styled from "styled-components"

/**
 * An AccordionItem is an entry in an Accordion that has a label and shows/hides its content when the expanded state
 * is toggled by clicking the label
 */
export interface AccordionItemProps {
    /** the label of the accordion item. */
    label: string
    /** flag if the item should be initially shown expanded. */
    isInitiallyExpanded: boolean
    /** flag overriding the internal current expanded state. */
    showExpanded?: boolean
    /** context prefix to use for saving state in url */
    urlParamsPrefix: string
    /** optional action elements that are displayed when accordion is expanded */
    actions?: JSX.Element | JSX.Element[]
    children: ReactNode
}

export const AccordionItem: FC<AccordionItemProps> = (props) => {
    const [isExpanded, setIsExpanded] = useURLSearchParamState(`${props.urlParamsPrefix}.isExpanded`, props.isInitiallyExpanded, booleanConverterGroup.required)
    const showExpanded = props.showExpanded || isExpanded

    return (
        <PFlex direction={"column"}>
            <PFlex justifyContent={"space-between"} alignItems={"center"} wrap={"nowrap"}>
                <StyledAccordionItemLabel
                    icon={showExpanded ? "arrow-head-up" : "arrow-head-down"}
                    weight={"semibold"}
                    size={"medium"}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {props.label}
                </StyledAccordionItemLabel>
                {showExpanded && (
                    <SpacedItems direction={"row"} itemSpacing={spacing[24]} justifyContent={"flex-end"}>
                        {props.actions}
                    </SpacedItems>
                )}
            </PFlex>
            <div hidden={!showExpanded}>{props.children}</div>
        </PFlex>
    )
}

const StyledAccordionItemLabel = styled(PLinkPure)`
    margin: ${spacing[8]};
`
