import { usePdsBootContext } from "../../../common/PdsBootContext"
import { TextSelector } from "../textselector/TextSelector"
import { SelectOption } from "@finder/ui-kit/build/types/components/forms/multiSelect/MultiSelect"
import { PFlex, PFlexItem } from "@porsche-design-system/components-react"
import { useCallback, useMemo } from "react"
import { Marketplace } from "../../../generated/pdsapi"

interface MarketSelectorProps {
    marketplace: Marketplace | undefined
    onChange: (marketplace: Marketplace | undefined) => void
    includeGlobal: boolean
}

const globalValue = "GLOBAL"

export const MarketSelector = ({ marketplace, onChange, includeGlobal }: MarketSelectorProps) => {
    const { supportedMarketplaces } = usePdsBootContext()

    const onTextChange = useCallback((value: string) => (value === globalValue ? onChange(undefined) : onChange(value as Marketplace)), [onChange])
    const options: SelectOption[] = useMemo(
        () => [
            ...(includeGlobal ? [{ value: globalValue, label: "Global" }] : []),
            ...supportedMarketplaces.map((marketplaceDTO) => ({
                value: marketplaceDTO.marketplace,
                label: marketplaceDTO.label,
            })),
        ],
        [supportedMarketplaces, includeGlobal]
    )

    return (
        <PFlex alignItems={"center"}>
            <PFlexItem width={"full"}>
                <TextSelector label={"Market"} value={marketplace ?? "GLOBAL"} onChange={onTextChange} options={options} />
            </PFlexItem>
        </PFlex>
    )
}
