import { responsiveOptionImageVariants } from "../../common/responsiveImageVariants"
import placeholder from "./placeholder.jpg"
import { Picture } from "@finder/ui-kit"
import { color } from "@porsche-design-system/utilities"
import { FC } from "react"

export const OptionImage: FC<{ imageKey: string | undefined; sizes: string | undefined }> = ({ imageKey, sizes }) => {
    if (imageKey) {
        const variants = responsiveOptionImageVariants(imageKey)
        return (
            <Picture aspectRatio="16:9" alt={""} lazy>
                <source type="image/webp" srcSet={variants.srcSet.webp} sizes={sizes} />
                <source type="image/jpeg" srcSet={variants.srcSet.jpg} sizes={sizes} />
            </Picture>
        )
    } else {
        return (
            // This contraption is for a gray 16:9 placeholder while the image is loading
            <div
                style={{
                    display: "flex",
                    position: "relative",
                    backgroundColor: color.background.surface,
                    width: "100%",
                    paddingTop: "56.25%",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                >
                    <img src={placeholder} alt={"Porsche Crest"} width={"100%"} />
                </div>
            </div>
        )
    }
}
