import { SpacedItems } from "../../layouts/spaceditems/SpacedItems"
import { Tag } from "../../tag/Tag"
import { DefaultFormElementLoading, FormElement, ViewLabeledContentWrapper, ViewProps } from "../FormElementWrapper"
import { MultiSelectTextFormElementEdit, MultiSelectTextFormElementEditProps } from "../multiselecttextformelement/MultiSelectTextFormElement"
import { PText } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FieldValues } from "react-hook-form"
import { useNavigate } from "react-router-dom"

export const getTagsFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string[],
    TagsFormElementViewProps,
    MultiSelectTextFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: TagsFormElementView,
    Edit: MultiSelectTextFormElementEdit,
})

export interface TagsFormElementViewProps {
    emptyLabel: string

    tags: { label: string; route?: string }[]

    openInNewTab?: boolean
    scrollToTop?: boolean
}

export const TagsFormElementView = ({ label, tags, emptyLabel, openInNewTab, scrollToTop = false }: ViewProps<TagsFormElementViewProps>) => {
    const navigate = useNavigate()

    return (
        <ViewLabeledContentWrapper label={label}>
            {tags.length === 0 ? (
                <PText>{emptyLabel}</PText>
            ) : (
                <SpacedItems direction={"row"} wrap={true} itemSpacing={spacing[16]} lineSpacing={spacing[8]}>
                    {tags.map(({ label, route }, index) => (
                        <Tag
                            key={`Tag_${index}`}
                            onClick={
                                route !== undefined
                                    ? () => {
                                          if (openInNewTab) {
                                              window.open(route)
                                          } else {
                                              if (scrollToTop) {
                                                  window.scrollTo(0, 0)
                                              }
                                              navigate(route)
                                          }
                                      }
                                    : undefined
                            }
                        >
                            {label}
                        </Tag>
                    ))}
                </SpacedItems>
            )}
        </ViewLabeledContentWrapper>
    )
}
