/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Transmission {
    MANUAL = 'MANUAL',
    TIPTRONIC = 'TIPTRONIC',
    PDK = 'PDK'
}

export function TransmissionFromJSON(json: any): Transmission {
    return TransmissionFromJSONTyped(json, false);
}

export function TransmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transmission {
    return json as Transmission;
}

export function TransmissionToJSON(value?: Transmission | null): any {
    return value as any;
}

