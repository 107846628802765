/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOptionResponseDTO
 */
export interface CreateOptionResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateOptionResponseDTO
     */
    orderTypeKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOptionResponseDTO
     */
    optionCode: string;
}

export function CreateOptionResponseDTOFromJSON(json: any): CreateOptionResponseDTO {
    return CreateOptionResponseDTOFromJSONTyped(json, false);
}

export function CreateOptionResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOptionResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderTypeKey': json['orderTypeKey'],
        'optionCode': json['optionCode'],
    };
}

export function CreateOptionResponseDTOToJSON(value?: CreateOptionResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderTypeKey': value.orderTypeKey,
        'optionCode': value.optionCode,
    };
}


