import { DerivativeCategoryRoutes } from "./derivativecategories/DerivativeCategoryRoutes"
import { DerivativeRoutes } from "./derivatives/DerivativeRoutes"
import { ModelGenerationRoutes } from "./modelgenerations/ModelGenerationRoutes"
import { ModelSeriesRoutes } from "./modelseries/ModelSeriesRoutes"
import { OrderTypeRoutes } from "./ordertypes/OrderTypeRoutes"
import { FC } from "react"
import { Route, Routes } from "react-router-dom"

export const ModelRoutes: FC = () => {
    return (
        <Routes>
            <Route path={"model-series/*"} element={<ModelSeriesRoutes />} />
            <Route path={"model-generations/*"} element={<ModelGenerationRoutes />} />
            <Route path={"derivative-categories/*"} element={<DerivativeCategoryRoutes />} />
            <Route path={"derivatives/*"} element={<DerivativeRoutes />} />
            <Route path={"order-types/*"} element={<OrderTypeRoutes />} />
        </Routes>
    )
}
