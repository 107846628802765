/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    InteriorCustomizationAttributesValueDTOColor,
    InteriorCustomizationAttributesValueDTOColorFromJSON,
    InteriorCustomizationAttributesValueDTOColorFromJSONTyped,
    InteriorCustomizationAttributesValueDTOColorToJSON,
    SelectDTOInteriorMaterial,
    SelectDTOInteriorMaterialFromJSON,
    SelectDTOInteriorMaterialFromJSONTyped,
    SelectDTOInteriorMaterialToJSON,
} from './';

/**
 * 
 * @export
 * @interface InteriorCustomizationAttributesValueDTO
 */
export interface InteriorCustomizationAttributesValueDTO {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof InteriorCustomizationAttributesValueDTO
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof InteriorCustomizationAttributesValueDTO
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {InteriorCustomizationAttributesValueDTOColor}
     * @memberof InteriorCustomizationAttributesValueDTO
     */
    color: InteriorCustomizationAttributesValueDTOColor;
    /**
     * 
     * @type {SelectDTOInteriorMaterial}
     * @memberof InteriorCustomizationAttributesValueDTO
     */
    material: SelectDTOInteriorMaterial;
}

export function InteriorCustomizationAttributesValueDTOFromJSON(json: any): InteriorCustomizationAttributesValueDTO {
    return InteriorCustomizationAttributesValueDTOFromJSONTyped(json, false);
}

export function InteriorCustomizationAttributesValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorCustomizationAttributesValueDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'color': InteriorCustomizationAttributesValueDTOColorFromJSON(json['color']),
        'material': SelectDTOInteriorMaterialFromJSON(json['material']),
    };
}

export function InteriorCustomizationAttributesValueDTOToJSON(value?: InteriorCustomizationAttributesValueDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
        'description': BaseNameDTOToJSON(value.description),
        'color': InteriorCustomizationAttributesValueDTOColorToJSON(value.color),
        'material': SelectDTOInteriorMaterialToJSON(value.material),
    };
}


