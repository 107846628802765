/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    CreateOptionResponseDTO,
    CreateOptionResponseDTOFromJSON,
    CreateOptionResponseDTOToJSON,
    RoofColorCreateRequestDTO,
    RoofColorCreateRequestDTOFromJSON,
    RoofColorCreateRequestDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface CreateRoofColorRequest {
    roofColorCreateRequestDTO: RoofColorCreateRequestDTO;
}

/**
 * 
 */
export class RoofColorCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new RoofColor
     */
    async createRoofColorRaw(requestParameters: CreateRoofColorRequest): Promise<runtime.ApiResponse<CreateOptionResponseDTO>> {
        if (requestParameters.roofColorCreateRequestDTO === null || requestParameters.roofColorCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('roofColorCreateRequestDTO','Required parameter requestParameters.roofColorCreateRequestDTO was null or undefined when calling createRoofColor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/roof-color/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoofColorCreateRequestDTOToJSON(requestParameters.roofColorCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOptionResponseDTOFromJSON(jsonValue));
    }

    /**
     * Create a new RoofColor
     */
    async createRoofColor(requestParameters: CreateRoofColorRequest): Promise<CreateOptionResponseDTO> {
        const response = await this.createRoofColorRaw(requestParameters);
        return await response.value();
    }

}
