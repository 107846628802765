/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VideoUpdateDTO
 */
export interface VideoUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof VideoUpdateDTO
     */
    vimeoId?: string;
    /**
     * 
     * @type {string}
     * @memberof VideoUpdateDTO
     */
    hash?: string;
}

export function VideoUpdateDTOFromJSON(json: any): VideoUpdateDTO {
    return VideoUpdateDTOFromJSONTyped(json, false);
}

export function VideoUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VideoUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vimeoId': !exists(json, 'vimeoId') ? undefined : json['vimeoId'],
        'hash': !exists(json, 'hash') ? undefined : json['hash'],
    };
}

export function VideoUpdateDTOToJSON(value?: VideoUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vimeoId': value.vimeoId,
        'hash': value.hash,
    };
}


