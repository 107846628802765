/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    InteriorColorTileDTO,
    InteriorColorTileDTOFromJSON,
    InteriorColorTileDTOFromJSONTyped,
    InteriorColorTileDTOToJSON,
    LinkedInteriorCustomizationDTOCustomizationValueColorTag,
    LinkedInteriorCustomizationDTOCustomizationValueColorTagFromJSON,
    LinkedInteriorCustomizationDTOCustomizationValueColorTagFromJSONTyped,
    LinkedInteriorCustomizationDTOCustomizationValueColorTagToJSON,
} from './';

/**
 * 
 * @export
 * @interface LinkedInteriorCustomizationDTOCustomizationValue
 */
export interface LinkedInteriorCustomizationDTOCustomizationValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof LinkedInteriorCustomizationDTOCustomizationValue
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof LinkedInteriorCustomizationDTOCustomizationValue
     */
    materialName?: string;
    /**
     * 
     * @type {InteriorColorTileDTO}
     * @memberof LinkedInteriorCustomizationDTOCustomizationValue
     */
    color: InteriorColorTileDTO;
    /**
     * 
     * @type {LinkedInteriorCustomizationDTOCustomizationValueColorTag}
     * @memberof LinkedInteriorCustomizationDTOCustomizationValue
     */
    colorTag?: LinkedInteriorCustomizationDTOCustomizationValueColorTag;
}

export function LinkedInteriorCustomizationDTOCustomizationValueFromJSON(json: any): LinkedInteriorCustomizationDTOCustomizationValue {
    return LinkedInteriorCustomizationDTOCustomizationValueFromJSONTyped(json, false);
}

export function LinkedInteriorCustomizationDTOCustomizationValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedInteriorCustomizationDTOCustomizationValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'materialName': !exists(json, 'materialName') ? undefined : json['materialName'],
        'color': InteriorColorTileDTOFromJSON(json['color']),
        'colorTag': !exists(json, 'colorTag') ? undefined : LinkedInteriorCustomizationDTOCustomizationValueColorTagFromJSON(json['colorTag']),
    };
}

export function LinkedInteriorCustomizationDTOCustomizationValueToJSON(value?: LinkedInteriorCustomizationDTOCustomizationValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
        'materialName': value.materialName,
        'color': InteriorColorTileDTOToJSON(value.color),
        'colorTag': LinkedInteriorCustomizationDTOCustomizationValueColorTagToJSON(value.colorTag),
    };
}


