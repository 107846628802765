import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { ExteriorColorTagCreateRequestDTO } from "../../../../generated/pdsapi"
import { exteriorColorTagApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { ExteriorColorTagCreateAttributesCard } from "./attributes/ExteriorColorTagCreateAttributesCard"

export const ExteriorColorTagProductCreateContainerConfig: ProductCreateContainerConfig<ExteriorColorTagCreateRequestDTO, unknown> = {
    headline: "Create new Exterior Color Tag",

    create: (item) =>
        exteriorColorTagApi.create.create({
            exteriorColorTagCreateRequestDTO: item,
        }),
    getCreateOptions: async () => ({}),

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <ExteriorColorTagCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const ExteriorColorTagCreateContainer = getProductCreateContainer(ExteriorColorTagProductCreateContainerConfig)
