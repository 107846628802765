import { DefaultCardViewContentWrapper } from "../../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { CreateOptionResponseDTO, EquipmentCreateOptionsDTO, EquipmentCreateRequestDTO } from "../../../../../generated/pdsapi"
import { equipmentApi } from "../../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { OrderTypeEquipmentCreateOptionCard } from "./option/OrderTypeEquipmentCreateOptionCard"

export const OrderTypeEquipmentProductCreateContainerConfig: ProductCreateContainerConfig<
    EquipmentCreateRequestDTO,
    EquipmentCreateOptionsDTO,
    CreateOptionResponseDTO
> = {
    headline: "Create new Equipment",

    create: (item) =>
        equipmentApi.create.create({
            equipmentCreateRequestDTO: item,
        }),
    getCreateOptions: equipmentApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <OrderTypeEquipmentCreateOptionCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const OrderTypeEquipmentCreateContainer = getProductCreateContainer(OrderTypeEquipmentProductCreateContainerConfig)
