import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toGroupedOption } from "../../../../../components/formelements/groupedselecttextformelement/GroupedSelectOption"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { OrderTypeFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { OrderTypeFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const OrderTypeListFilter: FunctionComponent<TableFilterProps<OrderTypeFilter, OrderTypeFilterOptionsDTO>> = ({ filter, setFilter, filterOptions }) => {
    const modelSeriesKeysFilter = (
        <MultiSelect
            label={"Model Series"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelSeries.map(toOption)}
            value={filter.modelSeriesKeys ?? []}
            onChange={(modelSeriesKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelSeriesKeys: modelSeriesKeys.length === 0 ? undefined : modelSeriesKeys,
                    modelGenerationKeys: undefined,
                    derivativeKeys: undefined,
                }))
            }
        />
    )

    const relevantModelGenerations = filter.modelSeriesKeys
        ? filterOptions?.modelSeriesRelatedModelGenerations.filter((modelSeriesGroup) => filter.modelSeriesKeys!.includes(modelSeriesGroup.relatedKey))
        : filterOptions?.modelSeriesRelatedModelGenerations
    const modelGenerationKeysFilter = (
        <MultiSelect
            label={"Model Generations"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={relevantModelGenerations?.map(toGroupedOption)}
            value={filter.modelGenerationKeys ?? []}
            onChange={(modelGenerationKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelGenerationKeys: modelGenerationKeys.length === 0 ? undefined : modelGenerationKeys,
                }))
            }
        />
    )

    const relevantDerivatives = filter.modelSeriesKeys
        ? filterOptions?.modelSeriesRelatedDerivatives.filter((modelSeriesGroup) => filter.modelSeriesKeys!.includes(modelSeriesGroup.relatedKey))
        : filterOptions?.modelSeriesRelatedDerivatives
    const derivativeKeysFilter = (
        <MultiSelect
            label={"Derivatives"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={relevantDerivatives?.map(toGroupedOption)}
            value={filter.derivativeKeys ?? []}
            onChange={(derivativeKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    derivativeKeys: derivativeKeys.length === 0 ? undefined : derivativeKeys,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>
                {modelSeriesKeysFilter}
                {modelGenerationKeysFilter}
                {derivativeKeysFilter}
            </ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
