import { orderTypeRoutes } from "../../../../common/routes"
import { OrderTypeOptionRoutes } from "../OrderTypeOptionRoutes"
import { OrderTypeExteriorColorCreateContainer } from "./create/OrderTypeExteriorColorCreateContainer"
import { ExteriorColorDetailsContainer } from "./details/ExteriorColorDetailsContainer"
import { FC } from "react"
import { useParams } from "react-router-dom"

export const OrderTypeExteriorColorsRoutes: FC = () => {
    const { orderTypeKey } = useParams()

    return OrderTypeOptionRoutes({
        Create: OrderTypeExteriorColorCreateContainer,
        Details: ExteriorColorDetailsContainer,
        optionListCrumb: {
            name: "ExteriorColor",
            path: orderTypeRoutes.details(
                orderTypeKey,
                new URLSearchParams({
                    selectedTab: "2",
                    "tab2.exteriorcolors.accordion.isExpanded": "true",
                })
            ),
        },
    })
}
