/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PageDTORoofColorTagItemDTO,
    PageDTORoofColorTagItemDTOFromJSON,
    PageDTORoofColorTagItemDTOToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    SortFieldCombinedEnum,
    SortFieldCombinedEnumFromJSON,
    SortFieldCombinedEnumToJSON,
} from '../models';

export interface GetRoofColorTagsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: SortFieldCombinedEnum;
    sortDirection: SortDirection;
    search?: string;
    active?: boolean;
}

/**
 * 
 */
export class RoofColorTagListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get page of RoofColorTags
     */
    async getRoofColorTagsPageRaw(requestParameters: GetRoofColorTagsPageRequest): Promise<runtime.ApiResponse<PageDTORoofColorTagItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getRoofColorTagsPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getRoofColorTagsPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getRoofColorTagsPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getRoofColorTagsPage.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getRoofColorTagsPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-tags/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTORoofColorTagItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of RoofColorTags
     */
    async getRoofColorTagsPage(requestParameters: GetRoofColorTagsPageRequest): Promise<PageDTORoofColorTagItemDTO> {
        const response = await this.getRoofColorTagsPageRaw(requestParameters);
        return await response.value();
    }

}
