/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderTypeRoofColorItemDTO,
    OrderTypeRoofColorItemDTOFromJSON,
    OrderTypeRoofColorItemDTOFromJSONTyped,
    OrderTypeRoofColorItemDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageDTOOrderTypeRoofColorItemDTO
 */
export interface PageDTOOrderTypeRoofColorItemDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOOrderTypeRoofColorItemDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<OrderTypeRoofColorItemDTO>}
     * @memberof PageDTOOrderTypeRoofColorItemDTO
     */
    content: Array<OrderTypeRoofColorItemDTO>;
}

export function PageDTOOrderTypeRoofColorItemDTOFromJSON(json: any): PageDTOOrderTypeRoofColorItemDTO {
    return PageDTOOrderTypeRoofColorItemDTOFromJSONTyped(json, false);
}

export function PageDTOOrderTypeRoofColorItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOOrderTypeRoofColorItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(OrderTypeRoofColorItemDTOFromJSON)),
    };
}

export function PageDTOOrderTypeRoofColorItemDTOToJSON(value?: PageDTOOrderTypeRoofColorItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItemCount': value.totalItemCount,
        'content': ((value.content as Array<any>).map(OrderTypeRoofColorItemDTOToJSON)),
    };
}


