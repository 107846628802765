import { EquipmentCatalogueRoutes } from "./catalogue/EquipmentCatalogueRoutes"
import { EquipmentCustomizationRoutes } from "./customizations/EquipmentCustomizationRoutes"
import { EquipmentTagRoutes } from "./tags/EquipmentTagRoutes"
import { FC } from "react"
import { Route, Routes } from "react-router-dom"

export const EquipmentRoutes: FC = () => (
    <Routes>
        <Route path={"customizations/*"} element={<EquipmentCustomizationRoutes />} />
        <Route path={"tags/*"} element={<EquipmentTagRoutes />} />
        <Route path={"catalogue/*"} element={<EquipmentCatalogueRoutes />} />
    </Routes>
)
