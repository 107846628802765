import { customExteriorColorApi } from "../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLocalizationCard } from "../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { CustomExteriorColorDetailsAttributesCardContent } from "./data/attributes/CustomExteriorColorDetailsAttributesCardContent"
import { CustomExteriorColorDetailsVehicleImagesCard } from "./data/vehicleimages/CustomExteriorColorDetailsVehicleImagesCard"

export const CustomExteriorColorProductDetailsContainerConfig: ProductDetailsContainerConfig = {
    getBaseInformation: customExteriorColorApi.details.getBaseInformation,
    deleteByKey: customExteriorColorApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={customExteriorColorApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            customExteriorColorApi.details.updateAttributes({
                                key,
                                customExteriorColorAttributesUpdateDTO: update,
                            })
                        }
                        Content={CustomExteriorColorDetailsAttributesCardContent}
                    />
                    <CustomExteriorColorDetailsVehicleImagesCard itemKey={props.itemKey.key} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={customExteriorColorApi.details.getNameLocalizations}
                        updateData={(update) =>
                            customExteriorColorApi.details.updateName({
                                key: update.key,
                                requestBody: update.update,
                            })
                        }
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

export const CustomExteriorColorDetailsContainer = getProductDetailsContainer(CustomExteriorColorProductDetailsContainerConfig)
