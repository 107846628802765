import { equipmentCustomizationRoutes, equipmentRoutes } from "../../../../../common/routes"
import { NameItem } from "../../../../../components/formelements/derivednameformelement/DerivedNameFormElement"
import { LinkedCustomizationCard } from "../../../../../components/linkedcustomizationcard/LinkedCustomizationCard"
import { LocalizationsItemDTO, OptionLocalizationItemDTO } from "../../../../../generated/pdsapi"
import { equipmentApi } from "../../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDerivedLocalizationCard } from "../../../../../viewtemplates/details/cards/derivedlocalizationcard/DetailsDerivedLocalizationCard"
import { ProductDetailsTab } from "../../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { EquipmentLinkedCustomizationCardContent } from "./data/linkedcustomization/EquipmentLinkedCustomizationCardContent"
import { EquipmentOptionCardContent } from "./data/option/EquipmentOptionCardContent"
import { OptionsInFamilyCard } from "./data/optionsinfamilycard/OptionsInFamilyCard"
import { EquipmentSimilarCustomizationsCard } from "./data/similarcustomizations/EquipmentSimilarCustomizationsCard"
import { MarketplaceSpecificsCardContent } from "./marketplacespecifics/marketplacespecificscard/content/MarketplaceSpecificsCardContent"

const nameItem = (label: string | undefined, item: LocalizationsItemDTO): NameItem => ({
    label,
    isDerived: item.raw === undefined,
    value: item.withFallback,
})

export const EquipmentProductDetailsContainerConfig: ProductDetailsContainerConfig<{
    orderTypeKey: string
    optionCode: string
}> = {
    getBaseInformation: equipmentApi.details.getBaseInformation,

    deleteByKey: equipmentApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Option"}
                        getData={equipmentApi.details.getOption}
                        updateData={(data) =>
                            equipmentApi.details.updateOption({
                                orderTypeKey: data.orderTypeKey,
                                optionCode: data.optionCode,
                                equipmentOptionUpdateDTO: data.update,
                            })
                        }
                        Content={EquipmentOptionCardContent}
                    />
                    <LinkedCustomizationCard
                        {...props}
                        getLinkedCustomization={equipmentApi.details.getLinkedCustomization}
                        CustomizationContent={EquipmentLinkedCustomizationCardContent}
                        getCreateCustomizationRoute={equipmentCustomizationRoutes.create}
                    />
                    <EquipmentSimilarCustomizationsCard {...props} />
                    <OptionsInFamilyCard {...props} getData={equipmentApi.details.getOptionsInFamily} getRoute={equipmentRoutes.details} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsDerivedLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={equipmentApi.details.getName}
                        updateData={({ orderTypeKey, optionCode, update }) =>
                            equipmentApi.details.updateName({ orderTypeKey, optionCode, requestBody: update })
                        }
                        getViewProps={(data, languageTag) => {
                            const name: OptionLocalizationItemDTO = data[languageTag]
                            return {
                                primaryName: nameItem(undefined, name.merged),
                                secondaryNames: [nameItem("Option", name.option), nameItem("Customization", name.customization ?? {})],
                            }
                        }}
                        getEditProps={(data, languageTag) => ({
                            rawName: data[languageTag].option.raw,
                        })}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsDerivedLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={equipmentApi.details.getDescription}
                        updateData={({ orderTypeKey, optionCode, update }) =>
                            equipmentApi.details.updateDescription({ orderTypeKey, optionCode, requestBody: update })
                        }
                        getViewProps={(data, languageTag) => {
                            const description: OptionLocalizationItemDTO = data[languageTag]
                            return {
                                primaryName: nameItem(undefined, description.merged),
                                secondaryNames: [nameItem("Option", description.option), nameItem("Customization", description.customization ?? {})],
                            }
                        }}
                        getEditProps={(data, languageTag) => ({
                            rawName: data[languageTag].option.raw,
                        })}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Market Specifics",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        isDataEditable={false}
                        urlPathParamsPrefix={props.urlPathParamsPrefix}
                        itemKey={props.itemKey}
                        reloadDataIndicator={props.reloadDataIndicator}
                        triggerReload={props.triggerReload}
                        label={"Market Specifics"}
                        getData={equipmentApi.details.getMarketplaceSpecifics}
                        Content={MarketplaceSpecificsCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

export const EquipmentDetailsContainer = getProductDetailsContainer(EquipmentProductDetailsContainerConfig)
