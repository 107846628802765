/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorTileDTO,
    ColorTileDTOFromJSON,
    ColorTileDTOFromJSONTyped,
    ColorTileDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomExteriorColorItemDTO
 */
export interface CustomExteriorColorItemDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorItemDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorItemDTO
     */
    hexCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorItemDTO
     */
    name: string;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof CustomExteriorColorItemDTO
     */
    exteriorColorTag?: ColorTileDTO;
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorItemDTO
     */
    exteriorColorTypeName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomExteriorColorItemDTO
     */
    knownNames: Array<string>;
}

export function CustomExteriorColorItemDTOFromJSON(json: any): CustomExteriorColorItemDTO {
    return CustomExteriorColorItemDTOFromJSONTyped(json, false);
}

export function CustomExteriorColorItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomExteriorColorItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'hexCode': !exists(json, 'hexCode') ? undefined : json['hexCode'],
        'name': json['name'],
        'exteriorColorTag': !exists(json, 'exteriorColorTag') ? undefined : ColorTileDTOFromJSON(json['exteriorColorTag']),
        'exteriorColorTypeName': !exists(json, 'exteriorColorTypeName') ? undefined : json['exteriorColorTypeName'],
        'knownNames': json['knownNames'],
    };
}

export function CustomExteriorColorItemDTOToJSON(value?: CustomExteriorColorItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'hexCode': value.hexCode,
        'name': value.name,
        'exteriorColorTag': ColorTileDTOToJSON(value.exteriorColorTag),
        'exteriorColorTypeName': value.exteriorColorTypeName,
        'knownNames': value.knownNames,
    };
}


