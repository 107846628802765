/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderTypePressEmbargoesDTOPressEmbargoes
 */
export interface OrderTypePressEmbargoesDTOPressEmbargoes {
    /**
     * 
     * @type {Date}
     * @memberof OrderTypePressEmbargoesDTOPressEmbargoes
     */
    synced?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderTypePressEmbargoesDTOPressEmbargoes
     */
    manual?: Date;
}

export function OrderTypePressEmbargoesDTOPressEmbargoesFromJSON(json: any): OrderTypePressEmbargoesDTOPressEmbargoes {
    return OrderTypePressEmbargoesDTOPressEmbargoesFromJSONTyped(json, false);
}

export function OrderTypePressEmbargoesDTOPressEmbargoesFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypePressEmbargoesDTOPressEmbargoes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'synced': !exists(json, 'synced') ? undefined : (new Date(json['synced'])),
        'manual': !exists(json, 'manual') ? undefined : (new Date(json['manual'])),
    };
}

export function OrderTypePressEmbargoesDTOPressEmbargoesToJSON(value?: OrderTypePressEmbargoesDTOPressEmbargoes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'synced': value.synced === undefined ? undefined : (value.synced.toISOString()),
        'manual': value.manual === undefined ? undefined : (value.manual.toISOString()),
    };
}


