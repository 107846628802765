import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { RoofColorCustomizationCreateContainer } from "./create/RoofColorCustomizationCreateContainer"
import { RoofColorCustomizationDetailsContainer } from "./details/RoofColorCustomizationDetailsContainer"
import { RoofColorCustomizationListContainer } from "./list/RoofColorCustomizationListContainer"
import { FC } from "react"

export const RoofColorCustomizationRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Roof Color Customizations",
        List: RoofColorCustomizationListContainer,
        Details: RoofColorCustomizationDetailsContainer,
        Create: RoofColorCustomizationCreateContainer,
    })
