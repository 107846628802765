import { browserLanguage } from "../common/global"
import { orderTypeApi } from "../pdsapi"
import { useEffect, useState } from "react"

export const useOrderTypeName = (orderTypeKey: string): string => {
    const [orderTypeName, setOrderTypeName] = useState<string>(orderTypeKey)

    useEffect(() => {
        const fetchOrderTypeName = async () => {
            const baseInformation = await orderTypeApi.details.getBaseInformation({
                key: orderTypeKey,
                languageTag: browserLanguage,
            })
            setOrderTypeName(baseInformation.headline)
        }

        fetchOrderTypeName()
    }, [orderTypeKey])

    return orderTypeName
}
