/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntRange,
    IntRangeFromJSON,
    IntRangeFromJSONTyped,
    IntRangeToJSON,
    RoofColorCustomizationItemDTOValue,
    RoofColorCustomizationItemDTOValueFromJSON,
    RoofColorCustomizationItemDTOValueFromJSONTyped,
    RoofColorCustomizationItemDTOValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueDTORoofColorCustomizationItemDTOValue
 */
export interface OptionCustomizationConditionalValueDTORoofColorCustomizationItemDTOValue {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueDTORoofColorCustomizationItemDTOValue
     */
    key: string;
    /**
     * 
     * @type {IntRange}
     * @memberof OptionCustomizationConditionalValueDTORoofColorCustomizationItemDTOValue
     */
    modelYearRange?: IntRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationConditionalValueDTORoofColorCustomizationItemDTOValue
     */
    derivativeKeys?: Array<string>;
    /**
     * 
     * @type {RoofColorCustomizationItemDTOValue}
     * @memberof OptionCustomizationConditionalValueDTORoofColorCustomizationItemDTOValue
     */
    value: RoofColorCustomizationItemDTOValue;
}

export function OptionCustomizationConditionalValueDTORoofColorCustomizationItemDTOValueFromJSON(json: any): OptionCustomizationConditionalValueDTORoofColorCustomizationItemDTOValue {
    return OptionCustomizationConditionalValueDTORoofColorCustomizationItemDTOValueFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTORoofColorCustomizationItemDTOValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueDTORoofColorCustomizationItemDTOValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': !exists(json, 'modelYearRange') ? undefined : IntRangeFromJSON(json['modelYearRange']),
        'derivativeKeys': !exists(json, 'derivativeKeys') ? undefined : json['derivativeKeys'],
        'value': RoofColorCustomizationItemDTOValueFromJSON(json['value']),
    };
}

export function OptionCustomizationConditionalValueDTORoofColorCustomizationItemDTOValueToJSON(value?: OptionCustomizationConditionalValueDTORoofColorCustomizationItemDTOValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'modelYearRange': IntRangeToJSON(value.modelYearRange),
        'derivativeKeys': value.derivativeKeys,
        'value': RoofColorCustomizationItemDTOValueToJSON(value.value),
    };
}


