import { NamedExteriorColorTile } from "../namedexteriorcolortile/NamedExteriorColorTile"
import { FunctionComponent } from "react"

export interface NamedColorTileProps {
    label: string
    hexCode: string
}

export const NamedColorTile: FunctionComponent<NamedColorTileProps> = (props) => (
    <NamedExteriorColorTile label={props.label} hexCode={props.hexCode} glossy={false} />
)
