/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    CustomizationLinksDTO,
    CustomizationLinksDTOFromJSON,
    CustomizationLinksDTOToJSON,
    OptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO,
    OptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTOFromJSON,
    OptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTOToJSON,
    OptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO,
    OptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTOFromJSON,
    OptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTOToJSON,
    OptionCustomizationLocalizationsDTO,
    OptionCustomizationLocalizationsDTOFromJSON,
    OptionCustomizationLocalizationsDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    VehicleStateSearchResult,
    VehicleStateSearchResultFromJSON,
    VehicleStateSearchResultToJSON,
} from '../models';

export interface DeleteInteriorCustomizationRequest {
    key: string;
}

export interface GetAttributesOfInteriorCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfInteriorCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetLinksOfInteriorCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfDescriptionOfInteriorCustomizationRequest {
    key: string;
}

export interface GetLocalizationsOfNameOfInteriorCustomizationRequest {
    key: string;
}

export interface GetVehicleImagesOfInteriorCustomizationRequest {
    key: string;
    limit?: number;
}

export interface UpdateAttributesOfInteriorCustomizationRequest {
    key: string;
    optionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO: OptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO;
}

export interface UpdateDescriptionOfDefaultValueOfInteriorCustomizationRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomizationRequest {
    key: string;
    conditionalValueKey: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfDefaultValueOfInteriorCustomizationRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomizationRequest {
    key: string;
    conditionalValueKey: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class InteriorCustomizationDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a InteriorCustomization by a given key
     */
    async deleteInteriorCustomizationRaw(requestParameters: DeleteInteriorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteInteriorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/interior-customizations/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a InteriorCustomization by a given key
     */
    async deleteInteriorCustomization(requestParameters: DeleteInteriorCustomizationRequest): Promise<void> {
        await this.deleteInteriorCustomizationRaw(requestParameters);
    }

    /**
     * Get attributes of InteriorCustomization with given key
     */
    async getAttributesOfInteriorCustomizationRaw(requestParameters: GetAttributesOfInteriorCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfInteriorCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfInteriorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/interior-customizations/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of InteriorCustomization with given key
     */
    async getAttributesOfInteriorCustomization(requestParameters: GetAttributesOfInteriorCustomizationRequest): Promise<OptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO> {
        const response = await this.getAttributesOfInteriorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of InteriorCustomization with given key
     */
    async getBaseInformationOfInteriorCustomizationRaw(requestParameters: GetBaseInformationOfInteriorCustomizationRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfInteriorCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfInteriorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/interior-customizations/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of InteriorCustomization with given key
     */
    async getBaseInformationOfInteriorCustomization(requestParameters: GetBaseInformationOfInteriorCustomizationRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfInteriorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get links of InteriorCustomization with given key
     */
    async getLinksOfInteriorCustomizationRaw(requestParameters: GetLinksOfInteriorCustomizationRequest): Promise<runtime.ApiResponse<CustomizationLinksDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLinksOfInteriorCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinksOfInteriorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/interior-customizations/details/{key}/links`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomizationLinksDTOFromJSON(jsonValue));
    }

    /**
     * Get links of InteriorCustomization with given key
     */
    async getLinksOfInteriorCustomization(requestParameters: GetLinksOfInteriorCustomizationRequest): Promise<CustomizationLinksDTO> {
        const response = await this.getLinksOfInteriorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfDescription of InteriorCustomization with given key
     */
    async getLocalizationsOfDescriptionOfInteriorCustomizationRaw(requestParameters: GetLocalizationsOfDescriptionOfInteriorCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationLocalizationsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfDescriptionOfInteriorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/interior-customizations/details/{key}/description/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationLocalizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfDescription of InteriorCustomization with given key
     */
    async getLocalizationsOfDescriptionOfInteriorCustomization(requestParameters: GetLocalizationsOfDescriptionOfInteriorCustomizationRequest): Promise<OptionCustomizationLocalizationsDTO> {
        const response = await this.getLocalizationsOfDescriptionOfInteriorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of InteriorCustomization with given key
     */
    async getLocalizationsOfNameOfInteriorCustomizationRaw(requestParameters: GetLocalizationsOfNameOfInteriorCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationLocalizationsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfInteriorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/interior-customizations/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationLocalizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfName of InteriorCustomization with given key
     */
    async getLocalizationsOfNameOfInteriorCustomization(requestParameters: GetLocalizationsOfNameOfInteriorCustomizationRequest): Promise<OptionCustomizationLocalizationsDTO> {
        const response = await this.getLocalizationsOfNameOfInteriorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get vehicleImages of InteriorCustomization with given key
     */
    async getVehicleImagesOfInteriorCustomizationRaw(requestParameters: GetVehicleImagesOfInteriorCustomizationRequest): Promise<runtime.ApiResponse<Array<VehicleStateSearchResult>>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getVehicleImagesOfInteriorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/interior-customizations/details/{key}/vehicle-images`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateSearchResultFromJSON));
    }

    /**
     * Get vehicleImages of InteriorCustomization with given key
     */
    async getVehicleImagesOfInteriorCustomization(requestParameters: GetVehicleImagesOfInteriorCustomizationRequest): Promise<Array<VehicleStateSearchResult>> {
        const response = await this.getVehicleImagesOfInteriorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing InteriorCustomization
     */
    async updateAttributesOfInteriorCustomizationRaw(requestParameters: UpdateAttributesOfInteriorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfInteriorCustomization.');
        }

        if (requestParameters.optionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO === null || requestParameters.optionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO === undefined) {
            throw new runtime.RequiredError('optionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO','Required parameter requestParameters.optionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO was null or undefined when calling updateAttributesOfInteriorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/interior-customizations/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTOToJSON(requestParameters.optionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing InteriorCustomization
     */
    async updateAttributesOfInteriorCustomization(requestParameters: UpdateAttributesOfInteriorCustomizationRequest): Promise<void> {
        await this.updateAttributesOfInteriorCustomizationRaw(requestParameters);
    }

    /**
     * Updates descriptionOfDefaultValue of an existing InteriorCustomization
     */
    async updateDescriptionOfDefaultValueOfInteriorCustomizationRaw(requestParameters: UpdateDescriptionOfDefaultValueOfInteriorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateDescriptionOfDefaultValueOfInteriorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateDescriptionOfDefaultValueOfInteriorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/interior-customizations/details/{key}/default-value/description`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates descriptionOfDefaultValue of an existing InteriorCustomization
     */
    async updateDescriptionOfDefaultValueOfInteriorCustomization(requestParameters: UpdateDescriptionOfDefaultValueOfInteriorCustomizationRequest): Promise<void> {
        await this.updateDescriptionOfDefaultValueOfInteriorCustomizationRaw(requestParameters);
    }

    /**
     * Updates descriptionOfValueOfConditionalValuesByConditionalValueKey of an existing InteriorCustomization
     */
    async updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomizationRaw(requestParameters: UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/interior-customizations/details/{key}/conditional-values/{conditionalValueKey}/value/description`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates descriptionOfValueOfConditionalValuesByConditionalValueKey of an existing InteriorCustomization
     */
    async updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomization(requestParameters: UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomizationRequest): Promise<void> {
        await this.updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomizationRaw(requestParameters);
    }

    /**
     * Updates nameOfDefaultValue of an existing InteriorCustomization
     */
    async updateNameOfDefaultValueOfInteriorCustomizationRaw(requestParameters: UpdateNameOfDefaultValueOfInteriorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfDefaultValueOfInteriorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfDefaultValueOfInteriorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/interior-customizations/details/{key}/default-value/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfDefaultValue of an existing InteriorCustomization
     */
    async updateNameOfDefaultValueOfInteriorCustomization(requestParameters: UpdateNameOfDefaultValueOfInteriorCustomizationRequest): Promise<void> {
        await this.updateNameOfDefaultValueOfInteriorCustomizationRaw(requestParameters);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing InteriorCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomizationRaw(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/interior-customizations/details/{key}/conditional-values/{conditionalValueKey}/value/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing InteriorCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomization(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomizationRequest): Promise<void> {
        await this.updateNameOfValueOfConditionalValuesByConditionalValueKeyOfInteriorCustomizationRaw(requestParameters);
    }

}
