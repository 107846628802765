import { getEnumKeyByEnumValue } from "../../../../../common/global"
import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ActiveFilter } from "../../../../../components/filteredpagedtable/filter/active/ActiveFilter"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { EquipmentTagCategory, EquipmentTagFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { EquipmentTagFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const EquipmentTagListFilter: FunctionComponent<TableFilterProps<EquipmentTagFilter, EquipmentTagFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const categoryFilter = (
        <MultiSelect
            label={"Category"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.categories?.map(toOption)}
            value={filter.categories ?? []}
            onChange={(categories) =>
                setFilter((filter) => ({
                    ...filter,
                    categories:
                        categories.length === 0
                            ? undefined
                            : categories.map((categoryString) => EquipmentTagCategory[getEnumKeyByEnumValue(EquipmentTagCategory, categoryString)!]),
                }))
            }
        />
    )

    const activeFilter = (
        <ActiveFilter
            active={filter.active}
            onChange={(active) =>
                setFilter((filter) => ({
                    ...filter,
                    active,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Category"}>{categoryFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Status"}>{activeFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
