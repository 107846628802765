import { orderTypeRoutes } from "../../../../common/routes"
import { OrderTypeOptionRoutes } from "../OrderTypeOptionRoutes"
import { PaintToSampleExteriorColorDetailsContainer } from "./details/PaintToSampleExteriorColorDetailsContainer"
import { FC } from "react"
import { useParams } from "react-router-dom"
import { OrderTypePaintToSampleExteriorColorCreateContainer } from "./create/OrderTypePaintToSampleExteriorColorCreateContainer"

export const OrderTypePaintToSampleExteriorColorsRoutes: FC = () => {
    const { orderTypeKey } = useParams()

    return OrderTypeOptionRoutes({
        Create: OrderTypePaintToSampleExteriorColorCreateContainer,
        Details: PaintToSampleExteriorColorDetailsContainer,
        optionListCrumb: {
            name: "PaintToSampleExteriorColor",
            path: orderTypeRoutes.details(
                orderTypeKey,
                new URLSearchParams({
                    selectedTab: "2",
                    "tab2.painttosampleexteriorcolors.accordion.isExpanded": "true",
                })
            ),
        },
    })
}
