/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderTypeManualPressEmbargoesUpdateDTO
 */
export interface OrderTypeManualPressEmbargoesUpdateDTO {
    /**
     * 
     * @type {Date}
     * @memberof OrderTypeManualPressEmbargoesUpdateDTO
     */
    global?: Date;
    /**
     * 
     * @type {{ [key: string]: Date; }}
     * @memberof OrderTypeManualPressEmbargoesUpdateDTO
     */
    marketplaceSpecifics: { [key: string]: Date; };
}

export function OrderTypeManualPressEmbargoesUpdateDTOFromJSON(json: any): OrderTypeManualPressEmbargoesUpdateDTO {
    return OrderTypeManualPressEmbargoesUpdateDTOFromJSONTyped(json, false);
}

export function OrderTypeManualPressEmbargoesUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeManualPressEmbargoesUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'global': !exists(json, 'global') ? undefined : (new Date(json['global'])),
        'marketplaceSpecifics': json['marketplaceSpecifics'],
    };
}

export function OrderTypeManualPressEmbargoesUpdateDTOToJSON(value?: OrderTypeManualPressEmbargoesUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'global': value.global === undefined ? undefined : (value.global.toISOString()),
        'marketplaceSpecifics': value.marketplaceSpecifics,
    };
}


