/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VideoUpdateDTO,
    VideoUpdateDTOFromJSON,
    VideoUpdateDTOFromJSONTyped,
    VideoUpdateDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomizationVideoUpdateDTO
 */
export interface CustomizationVideoUpdateDTO {
    /**
     * 
     * @type {{ [key: string]: VideoUpdateDTO; }}
     * @memberof CustomizationVideoUpdateDTO
     */
    video: { [key: string]: VideoUpdateDTO; };
}

export function CustomizationVideoUpdateDTOFromJSON(json: any): CustomizationVideoUpdateDTO {
    return CustomizationVideoUpdateDTOFromJSONTyped(json, false);
}

export function CustomizationVideoUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomizationVideoUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'video': (mapValues(json['video'], VideoUpdateDTOFromJSON)),
    };
}

export function CustomizationVideoUpdateDTOToJSON(value?: CustomizationVideoUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'video': (mapValues(value.video, VideoUpdateDTOToJSON)),
    };
}


