/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InteriorCustomizationItemDTOValueColorTag,
    InteriorCustomizationItemDTOValueColorTagFromJSON,
    InteriorCustomizationItemDTOValueColorTagFromJSONTyped,
    InteriorCustomizationItemDTOValueColorTagToJSON,
} from './';

/**
 * 
 * @export
 * @interface InteriorCustomizationItemDTOValueColor
 */
export interface InteriorCustomizationItemDTOValueColor {
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationItemDTOValueColor
     */
    seatColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationItemDTOValueColor
     */
    seatStitchingColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationItemDTOValueColor
     */
    cockpitColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationItemDTOValueColor
     */
    cockpitStitchingColorHexCode: string;
    /**
     * 
     * @type {InteriorCustomizationItemDTOValueColorTag}
     * @memberof InteriorCustomizationItemDTOValueColor
     */
    tag?: InteriorCustomizationItemDTOValueColorTag;
}

export function InteriorCustomizationItemDTOValueColorFromJSON(json: any): InteriorCustomizationItemDTOValueColor {
    return InteriorCustomizationItemDTOValueColorFromJSONTyped(json, false);
}

export function InteriorCustomizationItemDTOValueColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorCustomizationItemDTOValueColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seatColorHexCode': json['seatColorHexCode'],
        'seatStitchingColorHexCode': json['seatStitchingColorHexCode'],
        'cockpitColorHexCode': json['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': json['cockpitStitchingColorHexCode'],
        'tag': !exists(json, 'tag') ? undefined : InteriorCustomizationItemDTOValueColorTagFromJSON(json['tag']),
    };
}

export function InteriorCustomizationItemDTOValueColorToJSON(value?: InteriorCustomizationItemDTOValueColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seatColorHexCode': value.seatColorHexCode,
        'seatStitchingColorHexCode': value.seatStitchingColorHexCode,
        'cockpitColorHexCode': value.cockpitColorHexCode,
        'cockpitStitchingColorHexCode': value.cockpitStitchingColorHexCode,
        'tag': InteriorCustomizationItemDTOValueColorTagToJSON(value.tag),
    };
}


