/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    LinkedPaintToSampleExteriorColorCustomizationDTO,
    LinkedPaintToSampleExteriorColorCustomizationDTOFromJSON,
    LinkedPaintToSampleExteriorColorCustomizationDTOToJSON,
    PageDTOPaintToSampleExteriorColorOptionDTO,
    PageDTOPaintToSampleExteriorColorOptionDTOFromJSON,
    PageDTOPaintToSampleExteriorColorOptionDTOToJSON,
    PaintToSampleExteriorColorOptionDTO,
    PaintToSampleExteriorColorOptionDTOFromJSON,
    PaintToSampleExteriorColorOptionDTOToJSON,
    PaintToSampleExteriorColorOptionUpdateDTO,
    PaintToSampleExteriorColorOptionUpdateDTOFromJSON,
    PaintToSampleExteriorColorOptionUpdateDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface GetLinkedCustomizationOfPaintToSampleExteriorColorRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

export interface GetOptionOfPaintToSampleExteriorColorRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

export interface GetOptionsOfPaintToSampleExteriorColorRequest {
    orderTypeKey: string;
    languageTag: string;
}

export interface UpdateOptionOfPaintToSampleExteriorColorRequest {
    orderTypeKey: string;
    optionCode: string;
    paintToSampleExteriorColorOptionUpdateDTO: PaintToSampleExteriorColorOptionUpdateDTO;
}

/**
 * 
 */
export class OrderTypePaintToSampleExteriorColorDataDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get linkedCustomization of PaintToSampleExteriorColor with given key
     */
    async getLinkedCustomizationOfPaintToSampleExteriorColorRaw(requestParameters: GetLinkedCustomizationOfPaintToSampleExteriorColorRequest): Promise<runtime.ApiResponse<LinkedPaintToSampleExteriorColorCustomizationDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getLinkedCustomizationOfPaintToSampleExteriorColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getLinkedCustomizationOfPaintToSampleExteriorColor.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinkedCustomizationOfPaintToSampleExteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/paint-to-sample-exterior-color/data/linked-customization`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkedPaintToSampleExteriorColorCustomizationDTOFromJSON(jsonValue));
    }

    /**
     * Get linkedCustomization of PaintToSampleExteriorColor with given key
     */
    async getLinkedCustomizationOfPaintToSampleExteriorColor(requestParameters: GetLinkedCustomizationOfPaintToSampleExteriorColorRequest): Promise<LinkedPaintToSampleExteriorColorCustomizationDTO> {
        const response = await this.getLinkedCustomizationOfPaintToSampleExteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get option of PaintToSampleExteriorColor with given key
     */
    async getOptionOfPaintToSampleExteriorColorRaw(requestParameters: GetOptionOfPaintToSampleExteriorColorRequest): Promise<runtime.ApiResponse<PaintToSampleExteriorColorOptionDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getOptionOfPaintToSampleExteriorColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getOptionOfPaintToSampleExteriorColor.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOptionOfPaintToSampleExteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/paint-to-sample-exterior-color/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaintToSampleExteriorColorOptionDTOFromJSON(jsonValue));
    }

    /**
     * Get option of PaintToSampleExteriorColor with given key
     */
    async getOptionOfPaintToSampleExteriorColor(requestParameters: GetOptionOfPaintToSampleExteriorColorRequest): Promise<PaintToSampleExteriorColorOptionDTO> {
        const response = await this.getOptionOfPaintToSampleExteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get options of PaintToSampleExteriorColor with given key
     */
    async getOptionsOfPaintToSampleExteriorColorRaw(requestParameters: GetOptionsOfPaintToSampleExteriorColorRequest): Promise<runtime.ApiResponse<PageDTOPaintToSampleExteriorColorOptionDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getOptionsOfPaintToSampleExteriorColor.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOptionsOfPaintToSampleExteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/paint-to-sample-exterior-color/data/options`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOPaintToSampleExteriorColorOptionDTOFromJSON(jsonValue));
    }

    /**
     * Get options of PaintToSampleExteriorColor with given key
     */
    async getOptionsOfPaintToSampleExteriorColor(requestParameters: GetOptionsOfPaintToSampleExteriorColorRequest): Promise<PageDTOPaintToSampleExteriorColorOptionDTO> {
        const response = await this.getOptionsOfPaintToSampleExteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates option of an existing PaintToSampleExteriorColor
     */
    async updateOptionOfPaintToSampleExteriorColorRaw(requestParameters: UpdateOptionOfPaintToSampleExteriorColorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling updateOptionOfPaintToSampleExteriorColor.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling updateOptionOfPaintToSampleExteriorColor.');
        }

        if (requestParameters.paintToSampleExteriorColorOptionUpdateDTO === null || requestParameters.paintToSampleExteriorColorOptionUpdateDTO === undefined) {
            throw new runtime.RequiredError('paintToSampleExteriorColorOptionUpdateDTO','Required parameter requestParameters.paintToSampleExteriorColorOptionUpdateDTO was null or undefined when calling updateOptionOfPaintToSampleExteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/paint-to-sample-exterior-color/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PaintToSampleExteriorColorOptionUpdateDTOToJSON(requestParameters.paintToSampleExteriorColorOptionUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates option of an existing PaintToSampleExteriorColor
     */
    async updateOptionOfPaintToSampleExteriorColor(requestParameters: UpdateOptionOfPaintToSampleExteriorColorRequest): Promise<void> {
        await this.updateOptionOfPaintToSampleExteriorColorRaw(requestParameters);
    }

}
