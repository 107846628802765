/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoofColorCustomizationItemDTOValueTag
 */
export interface RoofColorCustomizationItemDTOValueTag {
    /**
     * 
     * @type {string}
     * @memberof RoofColorCustomizationItemDTOValueTag
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoofColorCustomizationItemDTOValueTag
     */
    hexCode: string;
}

export function RoofColorCustomizationItemDTOValueTagFromJSON(json: any): RoofColorCustomizationItemDTOValueTag {
    return RoofColorCustomizationItemDTOValueTagFromJSONTyped(json, false);
}

export function RoofColorCustomizationItemDTOValueTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoofColorCustomizationItemDTOValueTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'hexCode': json['hexCode'],
    };
}

export function RoofColorCustomizationItemDTOValueTagToJSON(value?: RoofColorCustomizationItemDTOValueTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'hexCode': value.hexCode,
    };
}


