import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { NamedColorTile } from "../../../../../components/tiles/namedcolortile/NamedColorTile"
import { NamedInteriorColorTile } from "../../../../../components/tiles/namedinteriorcolortile/NamedInteriorColorTile"
import { CustomInteriorColorItemDTO } from "../../../../../generated/pdsapi"

type CustomInteriorColorColumnBuilder = ColumnBuilder<CustomInteriorColorItemDTO, unknown>

export const CustomInteriorColorListColumnsConfig: TableColumnsConfig<CustomInteriorColorItemDTO, unknown> = () => {
    const codeColumn: CustomInteriorColorColumnBuilder = {
        width: 60,
        headerCellProps: {
            content: "Code",
        },
        buildCellContent: (customInteriorColor) => customInteriorColor.key,
    }
    const nameColumn: CustomInteriorColorColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (customInteriorColor) => {
            const name = customInteriorColor.name

            return customInteriorColor.hexCode ? <NamedInteriorColorTile seatColor={customInteriorColor.hexCode} label={name} /> : name
        },
    }
    const knownNamesColumn: CustomInteriorColorColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Known names",
        },
        buildCellContent: (customInteriorColor) => customInteriorColor.knownNames.join(", ") || "-",
    }
    const interiorColorTagsColumn: CustomInteriorColorColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Interior color tag",
        },
        buildCellContent: (customInteriorColor) => {
            return customInteriorColor.interiorColorTag ? <NamedColorTile {...customInteriorColor.interiorColorTag} /> : "-"
        },
    }

    return [codeColumn, nameColumn, knownNamesColumn, interiorColorTagsColumn]
}
