import { SelectOption } from "@finder/ui-kit/build/types/components/forms/multiSelect/MultiSelect"
import { PSelectWrapper } from "@porsche-design-system/components-react"
import { ChangeEvent, FC } from "react"

export type SelectorGroup = {
    name: string
    options: SelectOption[]
}

export interface GroupedTextSelectorProps {
    label?: string
    groups: SelectorGroup[]
    value?: string
    onChange: (value: string) => void
    readOnly?: boolean
    errorMessage?: string
}

export const GroupedTextSelector: FC<GroupedTextSelectorProps> = ({ groups, label, onChange, value, readOnly, errorMessage }) => (
    <PSelectWrapper label={label} hideLabel={label === undefined} state={errorMessage ? "error" : "none"} message={errorMessage}>
        <select disabled={readOnly} value={value} onChange={(changeEvent: ChangeEvent<HTMLSelectElement>) => onChange(changeEvent.target.value)}>
            {groups.map((group) => (
                <optgroup key={group.name} label={group.name}>
                    {group.options.map((item) => (
                        <option key={item.value} value={item.value}>
                            {item.label}
                        </option>
                    ))}
                </optgroup>
            ))}
        </select>
    </PSelectWrapper>
)
