import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { NamedColorTile } from "../../../../../components/tiles/namedcolortile/NamedColorTile"
import { NamedExteriorColorTile } from "../../../../../components/tiles/namedexteriorcolortile/NamedExteriorColorTile"
import { CustomExteriorColorItemDTO } from "../../../../../generated/pdsapi"

type CustomExteriorColorColumnBuilder = ColumnBuilder<CustomExteriorColorItemDTO, unknown>

export const CustomExteriorColorListColumnsConfig: TableColumnsConfig<CustomExteriorColorItemDTO, unknown> = () => {
    const codeColumn: CustomExteriorColorColumnBuilder = {
        width: 60,
        headerCellProps: {
            content: "Code",
        },
        buildCellContent: (customExteriorColor) => customExteriorColor.key,
    }
    const nameColumn: CustomExteriorColorColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (customExteriorColor) => {
            const name = customExteriorColor.name

            return customExteriorColor.hexCode ? <NamedExteriorColorTile hexCode={customExteriorColor.hexCode} label={name} /> : name
        },
    }
    const knownNamesColumn: CustomExteriorColorColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Known names",
        },
        buildCellContent: (customExteriorColor) => customExteriorColor.knownNames.join(", ") || "-",
    }
    const exteriorColorTagsColumn: CustomExteriorColorColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Exterior color tag",
        },
        buildCellContent: (customExteriorColor) => {
            return customExteriorColor.exteriorColorTag ? <NamedColorTile {...customExteriorColor.exteriorColorTag} /> : "-"
        },
    }

    const exteriorColorTypeColumn: CustomExteriorColorColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Exterior color type",
        },
        buildCellContent: (customExteriorColor) => customExteriorColor.exteriorColorTypeName || "-",
    }

    return [codeColumn, nameColumn, knownNamesColumn, exteriorColorTagsColumn, exteriorColorTypeColumn]
}
