import { TableFilterProps } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toOption } from "../../../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ExteriorColorType, OrderTypeExteriorColorFilterOptionsDTO } from "../../../../../../../generated/pdsapi"
import { OrderTypeExteriorColorsFilter } from "../../../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const OrderTypeExteriorColorsCardFilter: FunctionComponent<TableFilterProps<OrderTypeExteriorColorsFilter, OrderTypeExteriorColorFilterOptionsDTO>> = (
    props
) => {
    const colorTypeFilter = (
        <MultiSelect
            label={"Color Types"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={props.filterOptions?.colorTypes.map(toOption)}
            value={props.filter.colorTypes ?? []}
            onChange={(colorTypes) => {
                props.setFilter((filter) => ({
                    ...filter,
                    colorTypes: colorTypes.length === 0 ? undefined : (colorTypes as ExteriorColorType[]),
                }))
            }}
        />
    )

    const tagFilter = (
        <MultiSelect
            label={"Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={props.filterOptions?.tags.map(toOption)}
            value={props.filter.tagKeys ?? []}
            onChange={(tagKeys) =>
                props.setFilter((filter) => ({
                    ...filter,
                    tagKeys: tagKeys.length === 0 ? undefined : tagKeys,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            {colorTypeFilter}
            {tagFilter}
        </ColumnGroupsWrapper>
    )
}
