import { SpacedItems } from "../spaceditems/SpacedItems"
import { ViewContentWrapper } from "../viewcontentwrapper/ViewContentWrapper"
import { color, spacing } from "@porsche-design-system/utilities"
import { Children, FC, ReactNode } from "react"
import styled from "styled-components"

interface DefaultCardViewContentWrapperProps {
    children: ReactNode
}

export const DefaultCardViewContentWrapper: FC<DefaultCardViewContentWrapperProps> = (props) => (
    <ViewContentWrapper>
        <SpacedItems lineSpacing={defaultSpacing} direction={"column"}>
            {Children.toArray(props.children)
                .filter((child) => !!child)
                .map((child, index) => (
                    <StyledCard key={`card_${index}`}>{child}</StyledCard>
                ))}
        </SpacedItems>
    </ViewContentWrapper>
)

const defaultSpacing = spacing[32]

export const StyledCard = styled.div`
    background-color: ${color.background.default};
    padding: ${spacing[32]};
`
