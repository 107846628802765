import { derivativeCategoryApi } from "../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLocalizationCard } from "../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { DerivativeCategoryDetailsAttributesCardContent } from "./data/attributes/DerivativeCategoryDetailsAttributesCardContent"
import { DerivativeCategoryLinksCard } from "./data/links/DerivativeCategoryLinksCard"

export const DerivativeCategoryProductDetailsContainerConfig: ProductDetailsContainerConfig = {
    getBaseInformation: derivativeCategoryApi.details.getBaseInformation,
    deleteByKey: derivativeCategoryApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={derivativeCategoryApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            derivativeCategoryApi.details.updateAttributes({
                                key,
                                derivativeCategoryAttributesUpdateDTO: update,
                            })
                        }
                        Content={DerivativeCategoryDetailsAttributesCardContent}
                    />
                    <DerivativeCategoryLinksCard {...props} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={derivativeCategoryApi.details.getLocalizations}
                        updateData={(update) =>
                            derivativeCategoryApi.details.updateLocalizations({
                                key: update.key,
                                requestBody: update.update,
                            })
                        }
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

export const DerivativeCategoryDetailsContainer = getProductDetailsContainer(DerivativeCategoryProductDetailsContainerConfig)
