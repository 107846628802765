import { InteriorCatalogueRoutes } from "./catalogue/InteriorCatalogueRoutes"
import { InteriorColorTagRoutes } from "./colortags/InteriorColorTagRoutes"
import { CustomInteriorColorRoutes } from "./custominteriorcolors/CustomInteriorColorRoutes"
import { InteriorCustomizationRoutes } from "./customizations/InteriorCustomizationRoutes"
import { FC } from "react"
import { Route, Routes } from "react-router-dom"

export const InteriorRoutes: FC = () => (
    <Routes>
        <Route path={"customizations/*"} element={<InteriorCustomizationRoutes />} />
        <Route path={"color-tags/*"} element={<InteriorColorTagRoutes />} />
        <Route path={"catalogue/*"} element={<InteriorCatalogueRoutes />} />
        <Route path={"custom-interior-colors/*"} element={<CustomInteriorColorRoutes />} />
    </Routes>
)
