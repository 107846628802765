import { equipmentRoutes } from "../../../../common/routes"
import { equipmentCustomizationApi } from "../../../../pdsapi"
import { getCustomizationDetailsContainer } from "../../../../viewtemplates/details/CustomizationDetailsContainer"
import { ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLinkedOptionsCard } from "../../../../viewtemplates/details/cards/linkedoptionscard/DetailsLinkedOptionsCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { DetailsOptionCustomizationLocalizationTab } from "../../../../viewtemplates/details/tabs/optioncustomizationlocalizationtab/DetailsOptionCustomizationLocalizationTab"
import { EquipmentCustomizationDetailsAttributesCardContent } from "./data/attributes/EquipmentCustomizationDetailsAttributesCardContent"
import { CustomizationVideosTab } from "../../../../viewtemplates/details/tabs/optionscustomizationvideotab/CustomizationVideosTab"

export const EquipmentCustomizationProductDetailsContainerConfig: ProductDetailsContainerConfig = {
    getBaseInformation: equipmentCustomizationApi.details.getBaseInformation,
    deleteByKey: equipmentCustomizationApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={equipmentCustomizationApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            equipmentCustomizationApi.details.updateAttributes({
                                key,
                                optionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTO: update,
                            })
                        }
                        Content={EquipmentCustomizationDetailsAttributesCardContent}
                    />
                    <DetailsLinkedOptionsCard {...props} getData={equipmentCustomizationApi.details.getLinks} getRoute={equipmentRoutes.details} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={equipmentCustomizationApi.details.getName}
                    updateDefaultValue={equipmentCustomizationApi.details.updateDefaultName}
                    updateConditionalValue={equipmentCustomizationApi.details.updateConditionalValueName}
                />
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={equipmentCustomizationApi.details.getDescription}
                    updateDefaultValue={equipmentCustomizationApi.details.updateDefaultDescription}
                    updateConditionalValue={equipmentCustomizationApi.details.updateConditionalValueDescription}
                />
            ),
        },
        {
            label: "Videos",
            Content: (props) => (
                <CustomizationVideosTab
                    {...props}
                    getData={equipmentCustomizationApi.details.getVideos}
                    updateDefaultValue={equipmentCustomizationApi.details.updateDefaultVideo}
                    updateConditionalValue={equipmentCustomizationApi.details.updateConditionalValueVideo}
                />
            ),
        },
    ],
}

export const EquipmentCustomizationDetailsContainer = getCustomizationDetailsContainer(EquipmentCustomizationProductDetailsContainerConfig)
