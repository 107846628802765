import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { EquipmentTagCreateContainer } from "./create/EquipmentTagCreateContainer"
import { EquipmentTagDetailsContainer } from "./details/EquipmentTagDetailsContainer"
import { EquipmentTagListContainer } from "./list/EquipmentTagListContainer"
import { EquipmentTagSortContainer } from "./sort/EquipmentTagSortContainer"
import { FC } from "react"

export const EquipmentTagRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Equipment Tags",
        List: EquipmentTagListContainer,
        Details: EquipmentTagDetailsContainer,
        Create: EquipmentTagCreateContainer,
        Sort: EquipmentTagSortContainer,
    })
