import { propertiesOf } from "../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../components/formelements/EditFormElementWrapper"
import { getBoolFormElement } from "../../../../../components/formelements/boolformelement/BoolFormElement"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTextFormElement } from "../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { EquipmentTagCreateOptionsDTO, EquipmentTagCreateRequestDTO } from "../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import { FC } from "react"
import { useLocation } from "react-router-dom"

export const EquipmentTagCreateAttributesCard: FC<ProductCreateContentProps<EquipmentTagCreateRequestDTO, EquipmentTagCreateOptionsDTO>> = ({
    formDisplayData,
}) => {
    const properties = propertiesOf<EquipmentTagCreateRequestDTO>()
    const searchParams = new URLSearchParams(useLocation().search)

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Code"}
                    formDisplayData={formDisplayData}
                    field={properties.key}
                    FormElement={getTextFormElement<EquipmentTagCreateOptionsDTO, EquipmentTagCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: searchParams.get("key") ?? "",
                    })}
                />

                <EditFormElementWrapper
                    label={"Category"}
                    formDisplayData={formDisplayData}
                    field={properties.category}
                    FormElement={getSelectTextFormElement<EquipmentTagCreateOptionsDTO, EquipmentTagCreateRequestDTO>()}
                    getEditProps={(data) => ({
                        value: searchParams.get("category") ?? "",
                        options: data.categories.map(toOption),
                    })}
                />
            </RowGroupWrapper>

            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                })}
            />

            <RowGroupWrapper label={"Status"}>
                <EditFormElementWrapper
                    label={"Active"}
                    formDisplayData={formDisplayData}
                    field={properties.active}
                    FormElement={getBoolFormElement<EquipmentTagCreateOptionsDTO, EquipmentTagCreateRequestDTO>()}
                    getEditProps={() => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: true,
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
