import { ExteriorColorCatalogueRoutes } from "./catalogue/ExteriorColorCatalogueRoutes"
import { CustomExteriorColorRoutes } from "./customexteriorcolors/CustomExteriorColorRoutes"
import { ExteriorColorCustomizationRoutes } from "./customizations/ExteriorColorCustomizationRoutes"
import { ExteriorColorTagRoutes } from "./tags/ExteriorColorTagRoutes"
import { FC } from "react"
import { Route, Routes } from "react-router-dom"

export const ExteriorColorRoutes: FC = () => (
    <Routes>
        <Route path={"customizations/*"} element={<ExteriorColorCustomizationRoutes />} />
        <Route path={"tags/*"} element={<ExteriorColorTagRoutes />} />
        <Route path={"catalogue/*"} element={<ExteriorColorCatalogueRoutes />} />
        <Route path={"custom-exterior-colors/*"} element={<CustomExteriorColorRoutes />} />
    </Routes>
)
