/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LinkedInteriorCustomizationDTOCustomization,
    LinkedInteriorCustomizationDTOCustomizationFromJSON,
    LinkedInteriorCustomizationDTOCustomizationFromJSONTyped,
    LinkedInteriorCustomizationDTOCustomizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface LinkedInteriorCustomizationDTO
 */
export interface LinkedInteriorCustomizationDTO {
    /**
     * 
     * @type {LinkedInteriorCustomizationDTOCustomization}
     * @memberof LinkedInteriorCustomizationDTO
     */
    customization?: LinkedInteriorCustomizationDTOCustomization;
    /**
     * 
     * @type {string}
     * @memberof LinkedInteriorCustomizationDTO
     */
    modelGenerationKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LinkedInteriorCustomizationDTO
     */
    hasSimilarCustomizations: boolean;
}

export function LinkedInteriorCustomizationDTOFromJSON(json: any): LinkedInteriorCustomizationDTO {
    return LinkedInteriorCustomizationDTOFromJSONTyped(json, false);
}

export function LinkedInteriorCustomizationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedInteriorCustomizationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customization': !exists(json, 'customization') ? undefined : LinkedInteriorCustomizationDTOCustomizationFromJSON(json['customization']),
        'modelGenerationKey': !exists(json, 'modelGenerationKey') ? undefined : json['modelGenerationKey'],
        'hasSimilarCustomizations': json['hasSimilarCustomizations'],
    };
}

export function LinkedInteriorCustomizationDTOToJSON(value?: LinkedInteriorCustomizationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customization': LinkedInteriorCustomizationDTOCustomizationToJSON(value.customization),
        'modelGenerationKey': value.modelGenerationKey,
        'hasSimilarCustomizations': value.hasSimilarCustomizations,
    };
}


