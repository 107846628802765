import { exteriorColorCustomizationRoutes } from "../../../common/routes"
import { IncompleteCustomizationDTO } from "../../../generated/pdsapi"
import { dashboardApi } from "../../../pdsapi"
import { DashboardPagedTableProps } from "../dashboardpagedtable/DashboardPagedTable"
import { incompleteCustomizationTableColumns } from "./parts/IncompleteCustomizationTableColumns"

export const IncompleteExteriorColorCustomizationsTableConfig: DashboardPagedTableProps<IncompleteCustomizationDTO> = {
    titlePrefix: "Incomplete",
    itemsName: "Exterior Color Customizations",
    getRoute: (incompleteCustomization) => exteriorColorCustomizationRoutes.details(incompleteCustomization.key),
    urlParamsPrefix: "exterior-color-customizations.incomplete",
    columns: incompleteCustomizationTableColumns,
    fetchPage: dashboardApi.getIncompleteExteriorCustomizations,
}
