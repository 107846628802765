import { SortableListItem } from "./SortableList"
import { PIcon, PText } from "@porsche-design-system/components-react"
import { color, spacing } from "@porsche-design-system/utilities"
import { SortableElement } from "react-sortable-hoc"
import styled from "styled-components"

export interface SortableListCellProps {
    item: SortableListItem
}

export const SortableListCell = SortableElement<SortableListCellProps>((props: SortableListCellProps) => {
    return (
        <SortableListElementWrapper>
            <SortableListIconElementSpacing>
                <PIcon name={"menu-lines"} />
            </SortableListIconElementSpacing>
            <PText>{props.item.label}</PText>
        </SortableListElementWrapper>
    )
})

const SortableListElementWrapper = styled.li`
    display: flex;
    align-items: center;
    background: ${color.background.default};
    padding-top: ${spacing[16]};
    padding-bottom: ${spacing[16]};
    cursor: move;

    &.dragging-helper-class {
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
        border: 1px solid ${color.neutralContrast.high};
    }

    border-bottom: 1px solid ${color.neutralContrast.low};

    &:last-child {
        border-bottom: none;
    }
`

const SortableListIconElementSpacing = styled.div`
    margin-left: ${spacing[16]};
    margin-right: ${spacing[16]};
`
