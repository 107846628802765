/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VideoDTO,
    VideoDTOFromJSON,
    VideoDTOFromJSONTyped,
    VideoDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface VideoLocalizationsItemDTO
 */
export interface VideoLocalizationsItemDTO {
    /**
     * 
     * @type {VideoDTO}
     * @memberof VideoLocalizationsItemDTO
     */
    raw?: VideoDTO;
    /**
     * 
     * @type {VideoDTO}
     * @memberof VideoLocalizationsItemDTO
     */
    withFallback?: VideoDTO;
}

export function VideoLocalizationsItemDTOFromJSON(json: any): VideoLocalizationsItemDTO {
    return VideoLocalizationsItemDTOFromJSONTyped(json, false);
}

export function VideoLocalizationsItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VideoLocalizationsItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'raw': !exists(json, 'raw') ? undefined : VideoDTOFromJSON(json['raw']),
        'withFallback': !exists(json, 'withFallback') ? undefined : VideoDTOFromJSON(json['withFallback']),
    };
}

export function VideoLocalizationsItemDTOToJSON(value?: VideoLocalizationsItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'raw': VideoDTOToJSON(value.raw),
        'withFallback': VideoDTOToJSON(value.withFallback),
    };
}


