/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LinkedEquipmentCustomizationDTOCustomization,
    LinkedEquipmentCustomizationDTOCustomizationFromJSON,
    LinkedEquipmentCustomizationDTOCustomizationFromJSONTyped,
    LinkedEquipmentCustomizationDTOCustomizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface LinkedEquipmentCustomizationDTO
 */
export interface LinkedEquipmentCustomizationDTO {
    /**
     * 
     * @type {LinkedEquipmentCustomizationDTOCustomization}
     * @memberof LinkedEquipmentCustomizationDTO
     */
    customization?: LinkedEquipmentCustomizationDTOCustomization;
    /**
     * 
     * @type {string}
     * @memberof LinkedEquipmentCustomizationDTO
     */
    modelGenerationKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LinkedEquipmentCustomizationDTO
     */
    hasSimilarCustomizations: boolean;
}

export function LinkedEquipmentCustomizationDTOFromJSON(json: any): LinkedEquipmentCustomizationDTO {
    return LinkedEquipmentCustomizationDTOFromJSONTyped(json, false);
}

export function LinkedEquipmentCustomizationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedEquipmentCustomizationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customization': !exists(json, 'customization') ? undefined : LinkedEquipmentCustomizationDTOCustomizationFromJSON(json['customization']),
        'modelGenerationKey': !exists(json, 'modelGenerationKey') ? undefined : json['modelGenerationKey'],
        'hasSimilarCustomizations': json['hasSimilarCustomizations'],
    };
}

export function LinkedEquipmentCustomizationDTOToJSON(value?: LinkedEquipmentCustomizationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customization': LinkedEquipmentCustomizationDTOCustomizationToJSON(value.customization),
        'modelGenerationKey': value.modelGenerationKey,
        'hasSimilarCustomizations': value.hasSimilarCustomizations,
    };
}


