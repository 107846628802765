/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EquipmentCustomizationAttributesValueUpdateDTO,
    EquipmentCustomizationAttributesValueUpdateDTOFromJSON,
    EquipmentCustomizationAttributesValueUpdateDTOFromJSONTyped,
    EquipmentCustomizationAttributesValueUpdateDTOToJSON,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueUpdateDTO,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueUpdateDTOFromJSON,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueUpdateDTOFromJSONTyped,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueUpdateDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTO
 */
export interface OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTO
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTO
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {EquipmentCustomizationAttributesValueUpdateDTO}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTO
     */
    defaultValue: EquipmentCustomizationAttributesValueUpdateDTO;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueUpdateDTO>}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueUpdateDTO>;
}

export function OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTOFromJSON(json: any): OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTO {
    return OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTOFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'isDefaultValueActive': json['isDefaultValueActive'],
        'defaultValue': EquipmentCustomizationAttributesValueUpdateDTOFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueUpdateDTOFromJSON)),
    };
}

export function OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTOToJSON(value?: OptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerationKeys': value.modelGenerationKeys,
        'isDefaultValueActive': value.isDefaultValueActive,
        'defaultValue': EquipmentCustomizationAttributesValueUpdateDTOToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueUpdateDTOToJSON)),
    };
}


