/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorTileDTO,
    ColorTileDTOFromJSON,
    ColorTileDTOFromJSONTyped,
    ColorTileDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExteriorColorItemDTO
 */
export interface ExteriorColorItemDTO {
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorItemDTO
     */
    orderTypeKey: string;
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorItemDTO
     */
    imageKey?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExteriorColorItemDTO
     */
    orderTypeCodes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ExteriorColorItemDTO
     */
    modelYear: number;
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorItemDTO
     */
    optionCode: string;
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorItemDTO
     */
    hexCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorItemDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorItemDTO
     */
    colorTypeName?: string;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof ExteriorColorItemDTO
     */
    colorTag?: ColorTileDTO;
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorItemDTO
     */
    modelGenerationName?: string;
}

export function ExteriorColorItemDTOFromJSON(json: any): ExteriorColorItemDTO {
    return ExteriorColorItemDTOFromJSONTyped(json, false);
}

export function ExteriorColorItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExteriorColorItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderTypeKey': json['orderTypeKey'],
        'imageKey': !exists(json, 'imageKey') ? undefined : json['imageKey'],
        'orderTypeCodes': json['orderTypeCodes'],
        'modelYear': json['modelYear'],
        'optionCode': json['optionCode'],
        'hexCode': !exists(json, 'hexCode') ? undefined : json['hexCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'colorTypeName': !exists(json, 'colorTypeName') ? undefined : json['colorTypeName'],
        'colorTag': !exists(json, 'colorTag') ? undefined : ColorTileDTOFromJSON(json['colorTag']),
        'modelGenerationName': !exists(json, 'modelGenerationName') ? undefined : json['modelGenerationName'],
    };
}

export function ExteriorColorItemDTOToJSON(value?: ExteriorColorItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderTypeKey': value.orderTypeKey,
        'imageKey': value.imageKey,
        'orderTypeCodes': value.orderTypeCodes,
        'modelYear': value.modelYear,
        'optionCode': value.optionCode,
        'hexCode': value.hexCode,
        'name': value.name,
        'colorTypeName': value.colorTypeName,
        'colorTag': ColorTileDTOToJSON(value.colorTag),
        'modelGenerationName': value.modelGenerationName,
    };
}


