import { PSelectWrapper } from "@porsche-design-system/components-react"
import { ChangeEvent, FC } from "react"

export interface Bool2SelectorProps {
    label?: string
    value: boolean
    onChange: (newValue: boolean) => void
    trueMessage: string
    falseMessage: string
    readOnly?: boolean
}

export const Bool2Selector: FC<Bool2SelectorProps> = (props) => (
    <PSelectWrapper label={props.label} hideLabel={props.label === undefined}>
        <select
            value={props.value.toString()}
            disabled={props.readOnly}
            onChange={(changeEvent: ChangeEvent<HTMLSelectElement>) => {
                let value: boolean
                switch (changeEvent.target.value) {
                    case "true":
                        value = true
                        break
                    default:
                        value = false
                }

                props.onChange(value)
            }}
        >
            <option value={"true"}>{props.trueMessage}</option>
            <option value={"false"}>{props.falseMessage}</option>
        </select>
    </PSelectWrapper>
)
