/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOVehicleEquipmentCategory,
    OptionDTOVehicleEquipmentCategoryFromJSON,
    OptionDTOVehicleEquipmentCategoryFromJSONTyped,
    OptionDTOVehicleEquipmentCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionalSelectDTOVehicleEquipmentCategory
 */
export interface OptionalSelectDTOVehicleEquipmentCategory {
    /**
     * 
     * @type {OptionDTOVehicleEquipmentCategory}
     * @memberof OptionalSelectDTOVehicleEquipmentCategory
     */
    value?: OptionDTOVehicleEquipmentCategory;
    /**
     * 
     * @type {Array<OptionDTOVehicleEquipmentCategory>}
     * @memberof OptionalSelectDTOVehicleEquipmentCategory
     */
    options: Array<OptionDTOVehicleEquipmentCategory>;
}

export function OptionalSelectDTOVehicleEquipmentCategoryFromJSON(json: any): OptionalSelectDTOVehicleEquipmentCategory {
    return OptionalSelectDTOVehicleEquipmentCategoryFromJSONTyped(json, false);
}

export function OptionalSelectDTOVehicleEquipmentCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalSelectDTOVehicleEquipmentCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : OptionDTOVehicleEquipmentCategoryFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(OptionDTOVehicleEquipmentCategoryFromJSON)),
    };
}

export function OptionalSelectDTOVehicleEquipmentCategoryToJSON(value?: OptionalSelectDTOVehicleEquipmentCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': OptionDTOVehicleEquipmentCategoryToJSON(value.value),
        'options': ((value.options as Array<any>).map(OptionDTOVehicleEquipmentCategoryToJSON)),
    };
}


