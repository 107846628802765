import { ViewState } from "../../common/ViewState"
import { hasMessage } from "../../common/global"
import { ColumnBuilder, Table } from "../layouts/table/Table"
import { PagingWrapper } from "../pagingwrapper/PagingWrapper"
import { Dispatch, PropsWithChildren, SetStateAction, useEffect, useState } from "react"

export type Page<T> = {
    totalItemCount: number
    content: T[]
}

export type PagingFindParams = {
    offset: number
    size: number
}

export interface PagedTableProps<ITEM, FIND_PARAMS, SORTING_TYPE> {
    itemsName: string
    urlParamsPrefix: string
    columns: ColumnBuilder<ITEM, SORTING_TYPE>[]
    fetchPage: (findParams: FIND_PARAMS) => Promise<Page<ITEM>>
    onRowClick?: (item: ITEM, index: number) => void
    findParams: FIND_PARAMS
    setPagingFindParams: Dispatch<SetStateAction<PagingFindParams>>
}

export const PagedTable = <ITEM, FIND_PARAMS extends PagingFindParams, SORTING_TYPE = undefined>(
    props: PropsWithChildren<PagedTableProps<ITEM, FIND_PARAMS, SORTING_TYPE>>
) => {
    const { itemsName, columns, fetchPage, onRowClick, findParams, setPagingFindParams } = props

    const [viewState, setViewState] = useState<ViewState>(ViewState.LOADING)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [page, setPage] = useState<Page<ITEM>>({ totalItemCount: 0, content: [] })

    useEffect(() => {
        const fetchItems = async () => {
            setViewState(ViewState.LOADING)
            try {
                const pagedItems = await fetchPage(findParams)
                setPage(pagedItems)
                setViewState(ViewState.CONTENT)
            } catch (e) {
                if (hasMessage(e)) setErrorMessage(e.message)
                setViewState(ViewState.ERROR)
            }
        }

        fetchItems()
    }, [fetchPage, findParams])

    return (
        <PagingWrapper
            itemsName={itemsName}
            totalItemsCount={page.totalItemCount}
            currentItemsCount={page.content.length}
            activePage={findParams.offset / findParams.size + 1}
            itemsPerPage={findParams.size}
            onActivePageChange={(activePage) =>
                setPagingFindParams((findParams) => ({
                    ...findParams,
                    offset: findParams.size * (activePage - 1),
                }))
            }
            onItemsPerPageChange={(itemsPerPage: number) =>
                setPagingFindParams({
                    offset: 0,
                    size: itemsPerPage,
                })
            }
        >
            <Table columns={columns} onRowClick={onRowClick} items={page.content} viewState={viewState} errorMessage={errorMessage} />
        </PagingWrapper>
    )
}
