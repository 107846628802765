import { MarketplaceDTO, UserAuthority } from "../generated/pdsapi"
import { LanguageGroup } from "./getLanguageGroups"
import { createContext, useContext } from "react"

export interface PdsBootContextProps {
    hasAuthority: (userAuthority: UserAuthority) => boolean
    supportedLanguageGroups: LanguageGroup[]
    supportedMarketplaces: MarketplaceDTO[]
}

export const PdsBootContext = createContext<PdsBootContextProps>({
    hasAuthority: () => false,
    supportedLanguageGroups: [],
    supportedMarketplaces: [],
})

export const usePdsBootContext = () => {
    const context = useContext(PdsBootContext)
    if (!context) {
        throw new Error("usePdsBootContext requires a <PdsBootContext.Provider> to be in the component hierarchy.")
    }

    return context
}
