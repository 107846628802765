import styled from "styled-components"

export interface CenteredProps {
    height?: string
}

export const Centered = styled.div<CenteredProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${(props) => (props.height ? `height: ${props.height};` : "")}
`
