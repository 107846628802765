/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaintToSampleExteriorColorColor,
    PaintToSampleExteriorColorColorFromJSON,
    PaintToSampleExteriorColorColorFromJSONTyped,
    PaintToSampleExteriorColorColorToJSON,
    PaintToSampleExteriorColorOptionDTOBaseLocalization,
    PaintToSampleExteriorColorOptionDTOBaseLocalizationFromJSON,
    PaintToSampleExteriorColorOptionDTOBaseLocalizationFromJSONTyped,
    PaintToSampleExteriorColorOptionDTOBaseLocalizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaintToSampleExteriorColorOptionDTO
 */
export interface PaintToSampleExteriorColorOptionDTO {
    /**
     * 
     * @type {string}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    optionCode: string;
    /**
     * 
     * @type {string}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    optionType?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    imageKeys: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    sortIndex: number;
    /**
     * 
     * @type {PaintToSampleExteriorColorOptionDTOBaseLocalization}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    name: PaintToSampleExteriorColorOptionDTOBaseLocalization;
    /**
     * 
     * @type {PaintToSampleExteriorColorOptionDTOBaseLocalization}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    description: PaintToSampleExteriorColorOptionDTOBaseLocalization;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    zOrderOptionCodes?: Array<string>;
    /**
     * 
     * @type {Array<PaintToSampleExteriorColorColor>}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    availableColors?: Array<PaintToSampleExteriorColorColor>;
    /**
     * 
     * @type {string}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    colorOfChoiceOptionCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    colorOfChoicePlusOptionCode?: string;
}

export function PaintToSampleExteriorColorOptionDTOFromJSON(json: any): PaintToSampleExteriorColorOptionDTO {
    return PaintToSampleExteriorColorOptionDTOFromJSONTyped(json, false);
}

export function PaintToSampleExteriorColorOptionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaintToSampleExteriorColorOptionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'optionType': !exists(json, 'optionType') ? undefined : json['optionType'],
        'imageKeys': json['imageKeys'],
        'sortIndex': json['sortIndex'],
        'name': PaintToSampleExteriorColorOptionDTOBaseLocalizationFromJSON(json['name']),
        'description': PaintToSampleExteriorColorOptionDTOBaseLocalizationFromJSON(json['description']),
        'zOrderOptionCodes': !exists(json, 'zOrderOptionCodes') ? undefined : json['zOrderOptionCodes'],
        'availableColors': !exists(json, 'availableColors') ? undefined : ((json['availableColors'] as Array<any>).map(PaintToSampleExteriorColorColorFromJSON)),
        'colorOfChoiceOptionCode': !exists(json, 'colorOfChoiceOptionCode') ? undefined : json['colorOfChoiceOptionCode'],
        'colorOfChoicePlusOptionCode': !exists(json, 'colorOfChoicePlusOptionCode') ? undefined : json['colorOfChoicePlusOptionCode'],
    };
}

export function PaintToSampleExteriorColorOptionDTOToJSON(value?: PaintToSampleExteriorColorOptionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'optionType': value.optionType,
        'imageKeys': value.imageKeys,
        'sortIndex': value.sortIndex,
        'name': PaintToSampleExteriorColorOptionDTOBaseLocalizationToJSON(value.name),
        'description': PaintToSampleExteriorColorOptionDTOBaseLocalizationToJSON(value.description),
        'zOrderOptionCodes': value.zOrderOptionCodes,
        'availableColors': value.availableColors === undefined ? undefined : ((value.availableColors as Array<any>).map(PaintToSampleExteriorColorColorToJSON)),
        'colorOfChoiceOptionCode': value.colorOfChoiceOptionCode,
        'colorOfChoicePlusOptionCode': value.colorOfChoicePlusOptionCode,
    };
}


