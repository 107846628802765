import { DefaultFormElementLoading, FormElement } from "../FormElementWrapper"
import { GroupedSelectTextFormElementEdit, GroupedSelectTextFormElementEditProps } from "../groupedselecttextformelement/GroupedSelectTextFormElement"
import { TagFormElementView, TagFormElementViewProps } from "../tagformelement/TagFormElement"
import { FieldValues } from "react-hook-form"

export const getGroupedTagFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string,
    TagFormElementViewProps,
    GroupedSelectTextFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: TagFormElementView,
    Edit: GroupedSelectTextFormElementEdit,
})
