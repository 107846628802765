/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TechnicalDataAttributeDataValueObject,
    TechnicalDataAttributeDataValueObjectFromJSON,
    TechnicalDataAttributeDataValueObjectFromJSONTyped,
    TechnicalDataAttributeDataValueObjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue
 */
export interface MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue {
    /**
     * 
     * @type {TechnicalDataAttributeDataValueObject}
     * @memberof MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue
     */
    globalValue?: TechnicalDataAttributeDataValueObject;
    /**
     * 
     * @type {TechnicalDataAttributeDataValueObject}
     * @memberof MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue
     */
    specificValue: TechnicalDataAttributeDataValueObject;
}

export function MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValueFromJSON(json: any): MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue {
    return MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValueFromJSONTyped(json, false);
}

export function MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'globalValue': !exists(json, 'globalValue') ? undefined : TechnicalDataAttributeDataValueObjectFromJSON(json['globalValue']),
        'specificValue': TechnicalDataAttributeDataValueObjectFromJSON(json['specificValue']),
    };
}

export function MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValueToJSON(value?: MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'globalValue': TechnicalDataAttributeDataValueObjectToJSON(value.globalValue),
        'specificValue': TechnicalDataAttributeDataValueObjectToJSON(value.specificValue),
    };
}


