import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { ExteriorColorTagCreateContainer } from "./create/ExteriorColorTagCreateContainer"
import { ExteriorColorTagDetailsContainer } from "./details/ExteriorColorTagDetailsContainer"
import { ExteriorColorTagListContainer } from "./list/ExteriorColorTagListContainer"
import { ExteriorColorTagSortContainer } from "./sort/ExteriorColorTagSortContainer"
import { FC } from "react"

export const ExteriorColorTagRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Exterior Color Tags",
        List: ExteriorColorTagListContainer,
        Details: ExteriorColorTagDetailsContainer,
        Create: ExteriorColorTagCreateContainer,
        Sort: ExteriorColorTagSortContainer,
    })
