import { Box } from "../layouts/box/Box"
import { PButtonPure, PFlex } from "@porsche-design-system/components-react"
import { color, spacing } from "@porsche-design-system/utilities"
import { FC, ReactNode } from "react"
import styled from "styled-components"

export interface TagProps {
    onClick?: () => void
    onRemove?: () => void
    children: ReactNode
}

export const Tag: FC<TagProps> = ({ onClick, onRemove, children }) => (
    <PFlex>
        <StyledPFlex alignItems={"center"}>
            <PButtonPure
                icon={"none"}
                size={"x-small"}
                onClick={
                    onClick
                        ? (e: { stopPropagation: () => void }) => {
                              e.stopPropagation()
                              onClick()
                          }
                        : undefined
                }
            >
                {children}
            </PButtonPure>
            {onRemove && <Box width={spacing[4]} />}
            {onRemove && (
                <PButtonPure
                    icon={"close"}
                    type={"reset"}
                    onClick={(event) => {
                        event.stopPropagation()
                        onRemove()
                    }}
                    size={"x-small"}
                />
            )}
        </StyledPFlex>
    </PFlex>
)

const StyledPFlex = styled(PFlex)`
    padding-left: ${spacing[8]};
    padding-right: ${spacing[8]};
    padding-top: ${spacing[4]};
    padding-bottom: ${spacing[4]};
    background-color: ${color.background.surface};
`
