/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOBodyType,
    OptionDTOBodyTypeFromJSON,
    OptionDTOBodyTypeFromJSONTyped,
    OptionDTOBodyTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface SelectDTOBodyType
 */
export interface SelectDTOBodyType {
    /**
     * 
     * @type {OptionDTOBodyType}
     * @memberof SelectDTOBodyType
     */
    value: OptionDTOBodyType;
    /**
     * 
     * @type {Array<OptionDTOBodyType>}
     * @memberof SelectDTOBodyType
     */
    options: Array<OptionDTOBodyType>;
}

export function SelectDTOBodyTypeFromJSON(json: any): SelectDTOBodyType {
    return SelectDTOBodyTypeFromJSONTyped(json, false);
}

export function SelectDTOBodyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectDTOBodyType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': OptionDTOBodyTypeFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(OptionDTOBodyTypeFromJSON)),
    };
}

export function SelectDTOBodyTypeToJSON(value?: SelectDTOBodyType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': OptionDTOBodyTypeToJSON(value.value),
        'options': ((value.options as Array<any>).map(OptionDTOBodyTypeToJSON)),
    };
}


