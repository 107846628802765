/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    SortIndexUpdateRequestDTO,
    SortIndexUpdateRequestDTOFromJSON,
    SortIndexUpdateRequestDTOToJSON,
    SortableListItemDTO,
    SortableListItemDTOFromJSON,
    SortableListItemDTOToJSON,
} from '../models';

export interface GetRoofColorTagSortableItemsRequest {
    languageTag: string;
}

export interface UpdateRoofColorTagSortIndexRequest {
    sortIndexUpdateRequestDTO: SortIndexUpdateRequestDTO;
}

/**
 * 
 */
export class RoofColorTagSortWebControllerApi extends runtime.BaseAPI {

    /**
     * Get sortable items of RoofColorTags
     */
    async getRoofColorTagSortableItemsRaw(requestParameters: GetRoofColorTagSortableItemsRequest): Promise<runtime.ApiResponse<Array<SortableListItemDTO>>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getRoofColorTagSortableItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-tags/sort/items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SortableListItemDTOFromJSON));
    }

    /**
     * Get sortable items of RoofColorTags
     */
    async getRoofColorTagSortableItems(requestParameters: GetRoofColorTagSortableItemsRequest): Promise<Array<SortableListItemDTO>> {
        const response = await this.getRoofColorTagSortableItemsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the sortIndex of multiple RoofColorTags
     */
    async updateRoofColorTagSortIndexRaw(requestParameters: UpdateRoofColorTagSortIndexRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sortIndexUpdateRequestDTO === null || requestParameters.sortIndexUpdateRequestDTO === undefined) {
            throw new runtime.RequiredError('sortIndexUpdateRequestDTO','Required parameter requestParameters.sortIndexUpdateRequestDTO was null or undefined when calling updateRoofColorTagSortIndex.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/roof-color-tags/sort/_update-sortindices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SortIndexUpdateRequestDTOToJSON(requestParameters.sortIndexUpdateRequestDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the sortIndex of multiple RoofColorTags
     */
    async updateRoofColorTagSortIndex(requestParameters: UpdateRoofColorTagSortIndexRequest): Promise<void> {
        await this.updateRoofColorTagSortIndexRaw(requestParameters);
    }

}
