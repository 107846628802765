import { Sorting } from "../../common/Sorting"
import { showErrorToast } from "../../common/errorToastHelper"
import { browserLanguage } from "../../common/global"
import {
    getObjectURLParamConverter,
    numberConverterGroup,
    stringConverterGroup,
    URLParamConverter,
    useURLSearchParamState,
} from "../../common/useURLSearchParamState"
import { SpacedItems } from "../layouts/spaceditems/SpacedItems"
import { ColumnBuilder } from "../layouts/table/Table"
import { Page, PagedTable, PagingFindParams } from "../pagedtable/PagedTable"
import { FilterFindParams, FilterWrapper } from "./filterwrapper/FilterWrapper"
import { useToast } from "@finder/ui-kit"
import { PDivider } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

export type FilteredPagedTableFindParams<FILTER, SORTING_TYPE> = FILTER & FilterFindParams & PagingFindParams & Sorting<SORTING_TYPE>

export interface TableFilterProps<FILTER, FILTER_OPTIONS> {
    filter: FILTER
    setFilter: Dispatch<SetStateAction<FILTER>>
    filterOptions?: FILTER_OPTIONS
}

export interface TableFilterTagsConfigProps<FILTER, FILTER_OPTIONS> {
    filter: FILTER
    setFilter: Dispatch<SetStateAction<FILTER>>
    filterOptions?: FILTER_OPTIONS
}

export type TableFilterTagsConfig<FILTER, FILTER_OPTIONS> = (props: TableFilterTagsConfigProps<FILTER, FILTER_OPTIONS>) => JSX.Element[]

export type TableColumnsConfig<ITEM, SORTING_TYPE> = (props: {
    sorting: Sorting<SORTING_TYPE>
    setSorting: (sorting: Sorting<SORTING_TYPE>) => void
}) => ColumnBuilder<ITEM, SORTING_TYPE>[] | Promise<ColumnBuilder<ITEM, SORTING_TYPE>[]>

export interface FilteredPagedTableProps<ITEM, FILTER, FILTER_OPTIONS, SORTING_TYPE> {
    itemsName: string
    urlParamsPrefix: string
    columnsConfig: TableColumnsConfig<ITEM, SORTING_TYPE>
    buildDetailsPath: (item: ITEM) => string

    fetchPage: (findParams: FilteredPagedTableFindParams<FILTER, SORTING_TYPE>) => Promise<Page<ITEM>>
    fetchFilterOptions: (props: { languageTag: string }) => Promise<FILTER_OPTIONS>

    defaultFilter: FILTER
    filterUrlParamsConverter: URLParamConverter<FILTER>
    defaultSorting: Sorting<SORTING_TYPE>
    sortingUrlParamsConverter: URLParamConverter<Sorting<SORTING_TYPE>>
    defaultShowsFilter: boolean
    filterFlexWidth?: "one-third" | "one-quarter"
    FilterComponent: FunctionComponent<TableFilterProps<FILTER, FILTER_OPTIONS>>
    filterTagsConfig: TableFilterTagsConfig<FILTER, FILTER_OPTIONS>
}

export const FilteredPagedTable = <ITEM, FILTER, FILTER_OPTIONS, SORTING_TYPE>(props: FilteredPagedTableProps<ITEM, FILTER, FILTER_OPTIONS, SORTING_TYPE>) => {
    const {
        itemsName,
        urlParamsPrefix,
        columnsConfig,
        buildDetailsPath,

        fetchPage,
        fetchFilterOptions,

        defaultFilter,
        filterUrlParamsConverter,
        defaultSorting,
        sortingUrlParamsConverter,
        defaultShowsFilter,
        filterFlexWidth,
        FilterComponent,
        filterTagsConfig,
    } = props

    const navigate = useNavigate()
    const toastRef = useRef(useToast())

    const [baseFilter, setBaseFilter] = useURLSearchParamState<FilterFindParams>(
        urlParamsPrefix + ".filter",
        { languageTag: browserLanguage },
        getObjectURLParamConverter<FilterFindParams>({
            languageTag: stringConverterGroup.required,
            search: stringConverterGroup.optional,
        })
    )
    const [filter, setFilter] = useURLSearchParamState<FILTER>(urlParamsPrefix + ".filter", defaultFilter, filterUrlParamsConverter)
    const [sorting, setSorting] = useURLSearchParamState<Sorting<SORTING_TYPE>>(urlParamsPrefix + ".sorting", defaultSorting, sortingUrlParamsConverter)
    const [page, setPage] = useURLSearchParamState<PagingFindParams>(
        urlParamsPrefix + ".page",
        { offset: 0, size: 10 },
        getObjectURLParamConverter({
            offset: numberConverterGroup.required,
            size: numberConverterGroup.required,
        })
    )
    const [filterOptions, setFilterOptions] = useState<FILTER_OPTIONS | undefined>(undefined)
    const [columns, setColumns] = useState<ColumnBuilder<ITEM, SORTING_TYPE>[]>([])

    useEffect(() => {
        setPage((page) => ({
            offset: 0,
            size: page.size,
        }))
    }, [filter, baseFilter, setPage])

    useEffect(() => {
        const loadFilterOptions = async () => {
            try {
                const newFilterOptions = await fetchFilterOptions({ languageTag: baseFilter.languageTag })
                setFilterOptions(newFilterOptions)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        loadFilterOptions()
    }, [fetchFilterOptions, baseFilter.languageTag, toastRef])

    useEffect(() => {
        const recalculateColumns = async () => {
            const newColumns = await columnsConfig({
                sorting,
                setSorting,
            })
            setColumns(newColumns)
        }
        // noinspection JSIgnoredPromiseFromCall
        recalculateColumns()
    }, [columnsConfig, sorting, setSorting])

    const onRowClick = useCallback((item: ITEM) => navigate(buildDetailsPath(item)), [navigate, buildDetailsPath])
    const findParams = useMemo(
        () => ({
            ...baseFilter,
            ...filter,
            ...sorting,
            ...page,
        }),
        [baseFilter, filter, page, sorting]
    )

    const filterTags = useMemo(
        () =>
            filterTagsConfig({
                filter,
                setFilter,
                filterOptions,
            }),
        [filterTagsConfig, filter, filterOptions, setFilter]
    )

    return (
        <>
            {
                <SpacedItems direction={"column"} lineSpacing={spacing[32]}>
                    <FilterWrapper
                        urlParamsPrefix={urlParamsPrefix}
                        filter={baseFilter}
                        setFilter={setBaseFilter}
                        defaultShowsFilter={defaultShowsFilter}
                        filterTags={filterTags}
                        flexWidth={filterFlexWidth}
                    >
                        <FilterComponent filter={filter} setFilter={setFilter} filterOptions={filterOptions} />
                    </FilterWrapper>

                    <PDivider />

                    <PagedTable
                        itemsName={itemsName}
                        urlParamsPrefix={`${urlParamsPrefix}.table`}
                        fetchPage={fetchPage}
                        findParams={findParams}
                        setPagingFindParams={setPage}
                        columns={columns}
                        onRowClick={onRowClick}
                    />
                </SpacedItems>
            }
        </>
    )
}
