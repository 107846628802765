/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    CustomInteriorColorAttributesDTO,
    CustomInteriorColorAttributesDTOFromJSON,
    CustomInteriorColorAttributesDTOToJSON,
    CustomInteriorColorAttributesUpdateDTO,
    CustomInteriorColorAttributesUpdateDTOFromJSON,
    CustomInteriorColorAttributesUpdateDTOToJSON,
    LocalizationsItemDTO,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    VehicleStateSearchResult,
    VehicleStateSearchResultFromJSON,
    VehicleStateSearchResultToJSON,
} from '../models';

export interface DeleteCustomInteriorColorRequest {
    key: string;
}

export interface FindCustomInteriorColorVehicleImagesRequest {
    key: string;
    limit?: number;
}

export interface GetAttributesOfCustomInteriorColorRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfCustomInteriorColorRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfCustomInteriorColorRequest {
    key: string;
}

export interface UpdateAttributesOfCustomInteriorColorRequest {
    key: string;
    customInteriorColorAttributesUpdateDTO: CustomInteriorColorAttributesUpdateDTO;
}

export interface UpdateNameOfCustomInteriorColorRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class CustomInteriorColorDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a CustomInteriorColor by a given key
     */
    async deleteCustomInteriorColorRaw(requestParameters: DeleteCustomInteriorColorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteCustomInteriorColor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a CustomInteriorColor by a given key
     */
    async deleteCustomInteriorColor(requestParameters: DeleteCustomInteriorColorRequest): Promise<void> {
        await this.deleteCustomInteriorColorRaw(requestParameters);
    }

    /**
     * Find vehicle images that have this CustomInteriorColor
     */
    async findCustomInteriorColorVehicleImagesRaw(requestParameters: FindCustomInteriorColorVehicleImagesRequest): Promise<runtime.ApiResponse<Array<VehicleStateSearchResult>>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling findCustomInteriorColorVehicleImages.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}/vehicle-images`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateSearchResultFromJSON));
    }

    /**
     * Find vehicle images that have this CustomInteriorColor
     */
    async findCustomInteriorColorVehicleImages(requestParameters: FindCustomInteriorColorVehicleImagesRequest): Promise<Array<VehicleStateSearchResult>> {
        const response = await this.findCustomInteriorColorVehicleImagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get attributes of CustomInteriorColor with given key
     */
    async getAttributesOfCustomInteriorColorRaw(requestParameters: GetAttributesOfCustomInteriorColorRequest): Promise<runtime.ApiResponse<CustomInteriorColorAttributesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfCustomInteriorColor.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfCustomInteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomInteriorColorAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of CustomInteriorColor with given key
     */
    async getAttributesOfCustomInteriorColor(requestParameters: GetAttributesOfCustomInteriorColorRequest): Promise<CustomInteriorColorAttributesDTO> {
        const response = await this.getAttributesOfCustomInteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of CustomInteriorColor with given key
     */
    async getBaseInformationOfCustomInteriorColorRaw(requestParameters: GetBaseInformationOfCustomInteriorColorRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfCustomInteriorColor.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfCustomInteriorColor.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of CustomInteriorColor with given key
     */
    async getBaseInformationOfCustomInteriorColor(requestParameters: GetBaseInformationOfCustomInteriorColorRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfCustomInteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of CustomInteriorColor with given key
     */
    async getLocalizationsOfNameOfCustomInteriorColorRaw(requestParameters: GetLocalizationsOfNameOfCustomInteriorColorRequest): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfCustomInteriorColor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of CustomInteriorColor with given key
     */
    async getLocalizationsOfNameOfCustomInteriorColor(requestParameters: GetLocalizationsOfNameOfCustomInteriorColorRequest): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfCustomInteriorColorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing CustomInteriorColor
     */
    async updateAttributesOfCustomInteriorColorRaw(requestParameters: UpdateAttributesOfCustomInteriorColorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfCustomInteriorColor.');
        }

        if (requestParameters.customInteriorColorAttributesUpdateDTO === null || requestParameters.customInteriorColorAttributesUpdateDTO === undefined) {
            throw new runtime.RequiredError('customInteriorColorAttributesUpdateDTO','Required parameter requestParameters.customInteriorColorAttributesUpdateDTO was null or undefined when calling updateAttributesOfCustomInteriorColor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomInteriorColorAttributesUpdateDTOToJSON(requestParameters.customInteriorColorAttributesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing CustomInteriorColor
     */
    async updateAttributesOfCustomInteriorColor(requestParameters: UpdateAttributesOfCustomInteriorColorRequest): Promise<void> {
        await this.updateAttributesOfCustomInteriorColorRaw(requestParameters);
    }

    /**
     * Updates name of an existing CustomInteriorColor
     */
    async updateNameOfCustomInteriorColorRaw(requestParameters: UpdateNameOfCustomInteriorColorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfCustomInteriorColor.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfCustomInteriorColor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing CustomInteriorColor
     */
    async updateNameOfCustomInteriorColor(requestParameters: UpdateNameOfCustomInteriorColorRequest): Promise<void> {
        await this.updateNameOfCustomInteriorColorRaw(requestParameters);
    }

}
