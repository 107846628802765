/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalizationsItemDTO,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOFromJSONTyped,
    LocalizationsItemDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeNameItemDTO
 */
export interface OrderTypeNameItemDTO {
    /**
     * 
     * @type {LocalizationsItemDTO}
     * @memberof OrderTypeNameItemDTO
     */
    merged: LocalizationsItemDTO;
    /**
     * 
     * @type {LocalizationsItemDTO}
     * @memberof OrderTypeNameItemDTO
     */
    manual: LocalizationsItemDTO;
    /**
     * 
     * @type {LocalizationsItemDTO}
     * @memberof OrderTypeNameItemDTO
     */
    synced: LocalizationsItemDTO;
}

export function OrderTypeNameItemDTOFromJSON(json: any): OrderTypeNameItemDTO {
    return OrderTypeNameItemDTOFromJSONTyped(json, false);
}

export function OrderTypeNameItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeNameItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'merged': LocalizationsItemDTOFromJSON(json['merged']),
        'manual': LocalizationsItemDTOFromJSON(json['manual']),
        'synced': LocalizationsItemDTOFromJSON(json['synced']),
    };
}

export function OrderTypeNameItemDTOToJSON(value?: OrderTypeNameItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'merged': LocalizationsItemDTOToJSON(value.merged),
        'manual': LocalizationsItemDTOToJSON(value.manual),
        'synced': LocalizationsItemDTOToJSON(value.synced),
    };
}


