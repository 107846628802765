import { IntRange } from "../generated/pdsapi"

export const conditionalValueLabelOf = (conditionalValue: { modelYearRange?: IntRange; derivativeKeys?: string[] }) =>
    [
        conditionalValue.modelYearRange
            ? conditionalValue.modelYearRange.start !== conditionalValue.modelYearRange.end
                ? `${conditionalValue.modelYearRange.start}-${conditionalValue.modelYearRange.end}`
                : conditionalValue.modelYearRange.start
            : undefined,
        conditionalValue.derivativeKeys?.length ? conditionalValue.derivativeKeys.join(", ") : undefined,
    ]
        .filter((text) => text)
        .join(" and ")
