/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    CreateResponseDTO,
    CreateResponseDTOFromJSON,
    CreateResponseDTOToJSON,
    DerivativeCreateOptionsDTO,
    DerivativeCreateOptionsDTOFromJSON,
    DerivativeCreateOptionsDTOToJSON,
    DerivativeCreateRequestDTO,
    DerivativeCreateRequestDTOFromJSON,
    DerivativeCreateRequestDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface CreateDerivativeRequest {
    derivativeCreateRequestDTO: DerivativeCreateRequestDTO;
}

export interface GetDerivativeCreateOptionsRequest {
    languageTag: string;
}

/**
 * 
 */
export class DerivativeCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new Derivative
     */
    async createDerivativeRaw(requestParameters: CreateDerivativeRequest): Promise<runtime.ApiResponse<CreateResponseDTO>> {
        if (requestParameters.derivativeCreateRequestDTO === null || requestParameters.derivativeCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('derivativeCreateRequestDTO','Required parameter requestParameters.derivativeCreateRequestDTO was null or undefined when calling createDerivative.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivatives/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DerivativeCreateRequestDTOToJSON(requestParameters.derivativeCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResponseDTOFromJSON(jsonValue));
    }

    /**
     * Create a new Derivative
     */
    async createDerivative(requestParameters: CreateDerivativeRequest): Promise<CreateResponseDTO> {
        const response = await this.createDerivativeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get select options to create Derivatives
     */
    async getDerivativeCreateOptionsRaw(requestParameters: GetDerivativeCreateOptionsRequest): Promise<runtime.ApiResponse<DerivativeCreateOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getDerivativeCreateOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DerivativeCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create Derivatives
     */
    async getDerivativeCreateOptions(requestParameters: GetDerivativeCreateOptionsRequest): Promise<DerivativeCreateOptionsDTO> {
        const response = await this.getDerivativeCreateOptionsRaw(requestParameters);
        return await response.value();
    }

}
