import { color, spacing } from "@porsche-design-system/utilities"
import { FC, ReactNode } from "react"
import styled, { css } from "styled-components"

export interface TableCellProps {
    width?: number
    grow?: number
    onClick?: () => void
    children: ReactNode
}

export const TableCell: FC<TableCellProps> = ({ width, grow, onClick, children }) => {
    return (
        <StyledTableCell width={width} grow={grow} onClick={onClick}>
            {children}
        </StyledTableCell>
    )
}

const StyledTableCell = styled.div<TableCellProps>`
    display: flex;
    align-items: center;
    padding: ${spacing[8]};
    word-wrap: break-word;

    ${({ width, grow }) => css`
        flex: ${grow ?? 0} ${grow ?? 0} ${width ? `${width}px` : ""};
    `}

    ${({ onClick }) =>
        onClick &&
        css`
            :hover {
                cursor: pointer;
                color: ${color.brand};
            }
        `}
`
