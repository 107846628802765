import { propertiesOf } from "../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../components/formelements/EditFormElementWrapper"
import { getColorPickerFormElement } from "../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { getMultiTextFormElement } from "../../../../../components/formelements/multitextformelement/MultiTextFormElement"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTextFormElement } from "../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { CustomInteriorColorCreateOptionsDTO, CustomInteriorColorCreateRequestDTO } from "../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import { FC } from "react"
import { useLocation } from "react-router-dom"

export const CustomInteriorColorCreateAttributesCard: FC<
    ProductCreateContentProps<CustomInteriorColorCreateRequestDTO, CustomInteriorColorCreateOptionsDTO>
> = ({ formDisplayData }) => {
    const properties = propertiesOf<CustomInteriorColorCreateRequestDTO>()
    const searchParams = new URLSearchParams(useLocation().search)

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Code"}
                    formDisplayData={formDisplayData}
                    field={properties.key}
                    FormElement={getTextFormElement<unknown, CustomInteriorColorCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: searchParams.get("key") ?? "",
                    })}
                />
                <EditFormElementWrapper
                    label={"Known Names"}
                    formDisplayData={formDisplayData}
                    field={properties.knownNames}
                    FormElement={getMultiTextFormElement<unknown, CustomInteriorColorCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: [],
                    })}
                />
            </RowGroupWrapper>

            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                })}
            />

            <RowGroupWrapper label={"Color"}>
                <EditFormElementWrapper
                    label={"Hex Code"}
                    formDisplayData={formDisplayData}
                    field={properties.hexCode}
                    FormElement={getColorPickerFormElement<unknown, CustomInteriorColorCreateRequestDTO>()}
                    getEditProps={() => ({
                        hexValue: "#000000",
                    })}
                />
                <EditFormElementWrapper
                    label={"Interior color tag"}
                    formDisplayData={formDisplayData}
                    field={properties.interiorColorTagKey}
                    FormElement={getSelectTextFormElement<unknown, CustomInteriorColorCreateRequestDTO>()}
                    getEditProps={(options) => ({
                        options: [{ value: "", label: "-" }, ...options.interiorColorTags.map(toOption)],
                        value: "",
                        optional: true,
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
