import { SortableListCell } from "./SortableListCell"
import { color } from "@porsche-design-system/utilities"
import { FC } from "react"
import { arrayMove, SortableContainer, SortEnd } from "react-sortable-hoc"
import styled from "styled-components"

export interface SortableListItem {
    // identifier of item
    key: string
    // displayed name
    label: string
}

interface SortableListProps {
    items: SortableListItem[]
    onChange: (value: SortableListItem[]) => void
}

export const SortableList: FC<SortableListProps> = (props) => {
    const handleSortEnd = (sort: SortEnd) => {
        const update = arrayMove(props.items, sort.oldIndex, sort.newIndex)
        props.onChange(update)
    }

    // third party component throws warnings in react strict mode https://github.com/clauderic/react-sortable-hoc/issues/677
    const SortableContainerFC = SortableContainer(() => (
        // div is needed as there must be an element between SortableContainer and SortableItem
        <div>
            {props.items.map((item, index) => (
                <SortableListCell index={index} key={item.label} item={item} />
            ))}
        </div>
    ))

    return (
        <SortableListWrapper>
            <SortableContainerFC axis={"y"} helperClass={"dragging-helper-class"} onSortEnd={handleSortEnd} />
        </SortableListWrapper>
    )
}

const SortableListWrapper = styled.ul`
    background: ${color.background.surface};
    padding: 0;
    margin: 0;
    border: 1px solid ${color.neutralContrast.low};
`
