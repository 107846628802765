/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    InteriorMaterial,
    InteriorMaterialFromJSON,
    InteriorMaterialFromJSONTyped,
    InteriorMaterialToJSON,
} from './';

/**
 * 
 * @export
 * @interface InteriorCustomizationCreateRequestDTO
 */
export interface InteriorCustomizationCreateRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationCreateRequestDTO
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InteriorCustomizationCreateRequestDTO
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof InteriorCustomizationCreateRequestDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof InteriorCustomizationCreateRequestDTO
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof InteriorCustomizationCreateRequestDTO
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationCreateRequestDTO
     */
    seatColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationCreateRequestDTO
     */
    seatStitchingColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationCreateRequestDTO
     */
    cockpitColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationCreateRequestDTO
     */
    cockpitStitchingColorHexCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof InteriorCustomizationCreateRequestDTO
     */
    isCustomColor: boolean;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationCreateRequestDTO
     */
    interiorColorTagKey: string;
    /**
     * 
     * @type {InteriorMaterial}
     * @memberof InteriorCustomizationCreateRequestDTO
     */
    material: InteriorMaterial;
}

export function InteriorCustomizationCreateRequestDTOFromJSON(json: any): InteriorCustomizationCreateRequestDTO {
    return InteriorCustomizationCreateRequestDTOFromJSONTyped(json, false);
}

export function InteriorCustomizationCreateRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorCustomizationCreateRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'isDefaultValueActive': json['isDefaultValueActive'],
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'seatColorHexCode': json['seatColorHexCode'],
        'seatStitchingColorHexCode': json['seatStitchingColorHexCode'],
        'cockpitColorHexCode': json['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': json['cockpitStitchingColorHexCode'],
        'isCustomColor': json['isCustomColor'],
        'interiorColorTagKey': json['interiorColorTagKey'],
        'material': InteriorMaterialFromJSON(json['material']),
    };
}

export function InteriorCustomizationCreateRequestDTOToJSON(value?: InteriorCustomizationCreateRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerationKeys': value.modelGenerationKeys,
        'isDefaultValueActive': value.isDefaultValueActive,
        'name': BaseNameDTOToJSON(value.name),
        'description': BaseNameDTOToJSON(value.description),
        'seatColorHexCode': value.seatColorHexCode,
        'seatStitchingColorHexCode': value.seatStitchingColorHexCode,
        'cockpitColorHexCode': value.cockpitColorHexCode,
        'cockpitStitchingColorHexCode': value.cockpitStitchingColorHexCode,
        'isCustomColor': value.isCustomColor,
        'interiorColorTagKey': value.interiorColorTagKey,
        'material': InteriorMaterialToJSON(value.material),
    };
}


