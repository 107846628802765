/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipment
 */
export interface EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipment {
    /**
     * 
     * @type {Date}
     * @memberof EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipment
     */
    from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipment
     */
    to?: Date;
}

export function EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentFromJSON(json: any): EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipment {
    return EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentFromJSONTyped(json, false);
}

export function EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
    };
}

export function EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentToJSON(value?: EquipmentMarketplaceSpecificsIndexDTOValuesIsStandardEquipment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from === undefined ? undefined : (value.from.toISOString().substr(0,10)),
        'to': value.to === undefined ? undefined : (value.to.toISOString().substr(0,10)),
    };
}


