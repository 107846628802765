import { orderTypeRoutes } from "../../../../common/routes"
import { OrderTypeOptionRoutes } from "../OrderTypeOptionRoutes"
import { OrderTypeRoofColorCreateContainer } from "./create/OrderTypeRoofColorCreateContainer"
import { RoofColorDetailsContainer } from "./details/RoofColorDetailsContainer"
import { FC } from "react"
import { useParams } from "react-router-dom"

export const OrderTypeRoofColorsRoutes: FC = () => {
    const { orderTypeKey } = useParams()

    return OrderTypeOptionRoutes({
        Create: OrderTypeRoofColorCreateContainer,
        Details: RoofColorDetailsContainer,
        optionListCrumb: {
            name: "RoofColor",
            path: orderTypeRoutes.details(
                orderTypeKey,
                new URLSearchParams({
                    selectedTab: "2",
                    "tab2.roofcolors.accordion.isExpanded": "true",
                })
            ),
        },
    })
}
