import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { ModelGenerationCreateContainer } from "./create/ModelGenerationCreateContainer"
import { ModelGenerationDetailsContainer } from "./details/ModelGenerationDetailsContainer"
import { ModelGenerationListContainer } from "./list/ModelGenerationListContainer"
import { ModelGenerationSortContainer } from "./sort/ModelGenerationSortContainer"
import { FC } from "react"

export const ModelGenerationRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Model Generations",
        List: ModelGenerationListContainer,
        Details: ModelGenerationDetailsContainer,
        Create: ModelGenerationCreateContainer,
        Sort: ModelGenerationSortContainer,
    })
