/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExteriorColorCustomizationItemDTOValueTag
 */
export interface ExteriorColorCustomizationItemDTOValueTag {
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorCustomizationItemDTOValueTag
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorCustomizationItemDTOValueTag
     */
    hexCode: string;
}

export function ExteriorColorCustomizationItemDTOValueTagFromJSON(json: any): ExteriorColorCustomizationItemDTOValueTag {
    return ExteriorColorCustomizationItemDTOValueTagFromJSONTyped(json, false);
}

export function ExteriorColorCustomizationItemDTOValueTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExteriorColorCustomizationItemDTOValueTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'hexCode': json['hexCode'],
    };
}

export function ExteriorColorCustomizationItemDTOValueTagToJSON(value?: ExteriorColorCustomizationItemDTOValueTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'hexCode': value.hexCode,
    };
}


