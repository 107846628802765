import { ModelSeriesLinksDTO } from "../../../../../../generated/pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard, ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { ModelSeriesLinksCardContent } from "./content/ModelSeriesLinksCardContent"
import { FC, useCallback } from "react"

type Key = { key: string }

interface ModelSeriesLinksCardProps {
    getData: (props: Key & { languageTag: string }) => Promise<ModelSeriesLinksDTO>
    getModelGenerationRoute: (key: string) => string
    getDerivativeCategoryRoute: (key: string) => string
}

export const ModelSeriesLinksCard: FC<ProductDetailsTabConfigContentProps<Key> & ModelSeriesLinksCardProps> = (props) => {
    const Content: FC<ProductDetailsCardContentProps<ModelSeriesLinksDTO, Record<string, never>>> = useCallback(
        (contentProps) => (
            <ModelSeriesLinksCardContent
                {...contentProps}
                getModelGenerationRoute={props.getModelGenerationRoute}
                getDerivativeCategoryRoute={props.getDerivativeCategoryRoute}
            />
        ),
        [props.getModelGenerationRoute, props.getDerivativeCategoryRoute]
    )

    return <ProductDetailsCard<ModelSeriesLinksDTO, Record<string, never>, Key> {...props} label={"Links"} Content={Content} />
}
