/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EngineLayoutCombinedEnum {
    FRONT = 'FRONT',
    MID = 'MID',
    REAR = 'REAR'
}

export function EngineLayoutCombinedEnumFromJSON(json: any): EngineLayoutCombinedEnum {
    return EngineLayoutCombinedEnumFromJSONTyped(json, false);
}

export function EngineLayoutCombinedEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineLayoutCombinedEnum {
    return json as EngineLayoutCombinedEnum;
}

export function EngineLayoutCombinedEnumToJSON(value?: EngineLayoutCombinedEnum | null): any {
    return value as any;
}

