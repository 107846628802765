/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncompleteCustomizationDTO,
    IncompleteCustomizationDTOFromJSON,
    IncompleteCustomizationDTOFromJSONTyped,
    IncompleteCustomizationDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageDTOIncompleteCustomizationDTO
 */
export interface PageDTOIncompleteCustomizationDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOIncompleteCustomizationDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<IncompleteCustomizationDTO>}
     * @memberof PageDTOIncompleteCustomizationDTO
     */
    content: Array<IncompleteCustomizationDTO>;
}

export function PageDTOIncompleteCustomizationDTOFromJSON(json: any): PageDTOIncompleteCustomizationDTO {
    return PageDTOIncompleteCustomizationDTOFromJSONTyped(json, false);
}

export function PageDTOIncompleteCustomizationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOIncompleteCustomizationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(IncompleteCustomizationDTOFromJSON)),
    };
}

export function PageDTOIncompleteCustomizationDTOToJSON(value?: PageDTOIncompleteCustomizationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItemCount': value.totalItemCount,
        'content': ((value.content as Array<any>).map(IncompleteCustomizationDTOToJSON)),
    };
}


