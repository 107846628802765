/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorTileDTO,
    ColorTileDTOFromJSON,
    ColorTileDTOFromJSONTyped,
    ColorTileDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomInteriorColorItemDTO
 */
export interface CustomInteriorColorItemDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomInteriorColorItemDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof CustomInteriorColorItemDTO
     */
    hexCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomInteriorColorItemDTO
     */
    name: string;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof CustomInteriorColorItemDTO
     */
    interiorColorTag?: ColorTileDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInteriorColorItemDTO
     */
    knownNames: Array<string>;
}

export function CustomInteriorColorItemDTOFromJSON(json: any): CustomInteriorColorItemDTO {
    return CustomInteriorColorItemDTOFromJSONTyped(json, false);
}

export function CustomInteriorColorItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomInteriorColorItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'hexCode': !exists(json, 'hexCode') ? undefined : json['hexCode'],
        'name': json['name'],
        'interiorColorTag': !exists(json, 'interiorColorTag') ? undefined : ColorTileDTOFromJSON(json['interiorColorTag']),
        'knownNames': json['knownNames'],
    };
}

export function CustomInteriorColorItemDTOToJSON(value?: CustomInteriorColorItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'hexCode': value.hexCode,
        'name': value.name,
        'interiorColorTag': ColorTileDTOToJSON(value.interiorColorTag),
        'knownNames': value.knownNames,
    };
}


