import { Breadcrumb, Crumb } from "../../breadcrumb/Breadcrumb"
import { Box } from "../../layouts/box/Box"
import { HeadlineWithActions } from "../headlinewithactions/HeadlineWithActions"
import { PContentWrapper } from "@porsche-design-system/components-react"
import { color, spacing } from "@porsche-design-system/utilities"
import { FC } from "react"
import styled from "styled-components"

export interface ViewHeadlineProps {
    headline: string
    parentCrumbs?: Crumb[]
    actions?: JSX.Element[]
}

export const ViewHeadline: FC<ViewHeadlineProps> = (props) => (
    <BackgroundColor>
        <PContentWrapper>
            <Box height={spacing[32]} />
            <Breadcrumb name={props.headline} parentCrumbs={props.parentCrumbs} />
            <HeadlinePadding>
                <HeadlineWithActions variant={"headline-3"} label={props.headline} rightActions={props.actions} />
            </HeadlinePadding>
        </PContentWrapper>
    </BackgroundColor>
)

const BackgroundColor = styled.div`
    background-color: ${color.background.default};
`
const HeadlinePadding = styled.div`
    padding-top: ${spacing[32]};
    padding-bottom: ${spacing[32]};
`
