import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { RoofColorTagCreateRequestDTO } from "../../../../generated/pdsapi"
import { roofColorTagApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { RoofColorTagCreateAttributesCard } from "./attributes/RoofColorTagCreateAttributesCard"

export const RoofColorTagProductCreateContainerConfig: ProductCreateContainerConfig<RoofColorTagCreateRequestDTO, unknown> = {
    headline: "Create new Roof Color Tag",

    create: (item) =>
        roofColorTagApi.create.create({
            roofColorTagCreateRequestDTO: item,
        }),
    getCreateOptions: async () => ({}),

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <RoofColorTagCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const RoofColorTagCreateContainer = getProductCreateContainer(RoofColorTagProductCreateContainerConfig)
