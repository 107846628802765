import { usePdsBootContext } from "../../../common/PdsBootContext"
import { browserLanguage } from "../../../common/global"
import { GroupedTextSelector } from "../groupedtextselector/GroupedTextSelector"
import { PButtonPure, PFlex, PFlexItem } from "@porsche-design-system/components-react"
import styled from "styled-components"

interface GroupedLanguageSelectorProps {
    value: string
    onChange: (languageTag: string) => void
}

export const GroupedLanguageTagSelector = ({ value, onChange }: GroupedLanguageSelectorProps) => {
    const { supportedLanguageGroups } = usePdsBootContext()

    return (
        <PFlex alignItems={"center"} key={"language-select"}>
            <PFlexItem width={"full"}>
                <GroupedTextSelector
                    value={value}
                    onChange={onChange}
                    groups={supportedLanguageGroups.map((languageGroup) => ({
                        name: languageGroup.label,
                        options: languageGroup.languages.map(({ label, languageTag }) => ({
                            value: languageTag,
                            label: label,
                        })),
                    }))}
                />
            </PFlexItem>
            <MoveLeft>
                <PButtonPure icon={"reset"} onClick={() => onChange(browserLanguage)} />
            </MoveLeft>
        </PFlex>
    )
}

const MoveLeft = styled.div`
    width: 0;
    transform: translate(-70px, 0px);
`
