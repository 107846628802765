/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation
 */
export interface OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation
     */
    equipmentCodes: Array<string>;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation
     */
    values: { [key: string]: any; };
}

export function OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationFromJSON(json: any): OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation {
    return OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationFromJSONTyped(json, false);
}

export function OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equipmentCodes': json['equipmentCodes'],
        'values': json['values'],
    };
}

export function OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationToJSON(value?: OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equipmentCodes': value.equipmentCodes,
        'values': value.values,
    };
}


