/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    ExteriorColorGroup,
    ExteriorColorGroupFromJSON,
    ExteriorColorGroupFromJSONTyped,
    ExteriorColorGroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExteriorColorOptionUpdateDTO
 */
export interface ExteriorColorOptionUpdateDTO {
    /**
     * 
     * @type {number}
     * @memberof ExteriorColorOptionUpdateDTO
     */
    sortIndex: number;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof ExteriorColorOptionUpdateDTO
     */
    optionName: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof ExteriorColorOptionUpdateDTO
     */
    optionDescription: BaseNameDTO;
    /**
     * 
     * @type {ExteriorColorGroup}
     * @memberof ExteriorColorOptionUpdateDTO
     */
    exteriorColorGroup?: ExteriorColorGroup;
}

export function ExteriorColorOptionUpdateDTOFromJSON(json: any): ExteriorColorOptionUpdateDTO {
    return ExteriorColorOptionUpdateDTOFromJSONTyped(json, false);
}

export function ExteriorColorOptionUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExteriorColorOptionUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sortIndex': json['sortIndex'],
        'optionName': BaseNameDTOFromJSON(json['optionName']),
        'optionDescription': BaseNameDTOFromJSON(json['optionDescription']),
        'exteriorColorGroup': !exists(json, 'exteriorColorGroup') ? undefined : ExteriorColorGroupFromJSON(json['exteriorColorGroup']),
    };
}

export function ExteriorColorOptionUpdateDTOToJSON(value?: ExteriorColorOptionUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sortIndex': value.sortIndex,
        'optionName': BaseNameDTOToJSON(value.optionName),
        'optionDescription': BaseNameDTOToJSON(value.optionDescription),
        'exteriorColorGroup': ExteriorColorGroupToJSON(value.exteriorColorGroup),
    };
}


