/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    SortCategoryDTO,
    SortCategoryDTOFromJSON,
    SortCategoryDTOToJSON,
    SortIndexUpdateRequestDTO,
    SortIndexUpdateRequestDTOFromJSON,
    SortIndexUpdateRequestDTOToJSON,
} from '../models';

export interface GetDerivativeSortableItemsRequest {
    languageTag: string;
}

export interface UpdateDerivativeSortIndexRequest {
    sortIndexUpdateRequestDTO: SortIndexUpdateRequestDTO;
}

/**
 * 
 */
export class DerivativeSortWebControllerApi extends runtime.BaseAPI {

    /**
     * Get sortable items of Derivatives
     */
    async getDerivativeSortableItemsRaw(requestParameters: GetDerivativeSortableItemsRequest): Promise<runtime.ApiResponse<Array<SortCategoryDTO>>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getDerivativeSortableItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/sort/items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SortCategoryDTOFromJSON));
    }

    /**
     * Get sortable items of Derivatives
     */
    async getDerivativeSortableItems(requestParameters: GetDerivativeSortableItemsRequest): Promise<Array<SortCategoryDTO>> {
        const response = await this.getDerivativeSortableItemsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the sortIndex of multiple Derivatives
     */
    async updateDerivativeSortIndexRaw(requestParameters: UpdateDerivativeSortIndexRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sortIndexUpdateRequestDTO === null || requestParameters.sortIndexUpdateRequestDTO === undefined) {
            throw new runtime.RequiredError('sortIndexUpdateRequestDTO','Required parameter requestParameters.sortIndexUpdateRequestDTO was null or undefined when calling updateDerivativeSortIndex.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivatives/sort/_update-sortindices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SortIndexUpdateRequestDTOToJSON(requestParameters.sortIndexUpdateRequestDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the sortIndex of multiple Derivatives
     */
    async updateDerivativeSortIndex(requestParameters: UpdateDerivativeSortIndexRequest): Promise<void> {
        await this.updateDerivativeSortIndexRaw(requestParameters);
    }

}
