/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineLayoutCombinedEnum,
    EngineLayoutCombinedEnumFromJSON,
    EngineLayoutCombinedEnumFromJSONTyped,
    EngineLayoutCombinedEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionDTOEngineLayout
 */
export interface OptionDTOEngineLayout {
    /**
     * 
     * @type {EngineLayoutCombinedEnum}
     * @memberof OptionDTOEngineLayout
     */
    key: EngineLayoutCombinedEnum;
    /**
     * 
     * @type {string}
     * @memberof OptionDTOEngineLayout
     */
    label: string;
}

export function OptionDTOEngineLayoutFromJSON(json: any): OptionDTOEngineLayout {
    return OptionDTOEngineLayoutFromJSONTyped(json, false);
}

export function OptionDTOEngineLayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionDTOEngineLayout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': EngineLayoutCombinedEnumFromJSON(json['key']),
        'label': json['label'],
    };
}

export function OptionDTOEngineLayoutToJSON(value?: OptionDTOEngineLayout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': EngineLayoutCombinedEnumToJSON(value.key),
        'label': value.label,
    };
}


