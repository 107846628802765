import { Tag } from "../../tag/Tag"
import { DefaultFormElementLoading, FormElement, ViewLabeledContentWrapper, ViewProps } from "../FormElementWrapper"
import { SelectTextFormElementEdit, SelectTextFormElementEditProps } from "../selecttextformelement/SelectTextFormElement"
import { FieldValues } from "react-hook-form"
import { useNavigate } from "react-router-dom"

export const getTagFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string,
    TagFormElementViewProps,
    SelectTextFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: TagFormElementView,
    Edit: SelectTextFormElementEdit,
})

export interface TagFormElementViewProps {
    tag: { label: string; route: string } | undefined
}

export const TagFormElementView = ({ label, tag }: ViewProps<TagFormElementViewProps>) => {
    const navigate = useNavigate()

    return <ViewLabeledContentWrapper label={label}>{tag ? <Tag onClick={() => navigate(tag.route)}>{tag.label}</Tag> : "-"}</ViewLabeledContentWrapper>
}
