import { SkeletonText } from "../../skeleton/SkeletonText"
import { EditLabeledContentWrapper, EditProps, FormElement, LoadingProps, ViewLabeledContentWrapper, ViewProps } from "../FormElementWrapper"
import { PText, PTextFieldWrapper, PTextProps } from "@porsche-design-system/components-react"
import { FieldValues } from "react-hook-form"

export const getDerivedNameFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string,
    DerivedNameFormElementViewProps,
    DerivedNameFormElementEditProps
> => ({
    Loading: DerivedNameFormElementLoading,
    View: DerivedNameFormElementView,
    Edit: DerivedNameFormElementEdit,
})

export const DerivedNameFormElementLoading = ({ label }: LoadingProps) => (
    <ViewLabeledContentWrapper label={label}>
        <SkeletonText />
        <SkeletonText />
        <SkeletonText />
    </ViewLabeledContentWrapper>
)

export type NameItem = {
    label?: string
    value?: string
    isDerived: boolean
}

export interface DerivedNameFormElementViewProps {
    primaryName: NameItem
    secondaryNames: NameItem[]
}

export const DerivedNameFormElementView = ({ label, primaryName, secondaryNames }: ViewProps<DerivedNameFormElementViewProps>) => {
    const getTextValue = (name: NameItem, textColor: PTextProps["color"]) => (
        <PText color={textColor}>
            {name.label ? `${name.label}: ` : ""}
            {name.value ?? "-"}
            {name.isDerived && name.value ? " *" : ""}
        </PText>
    )

    return (
        <ViewLabeledContentWrapper label={label}>
            {getTextValue(primaryName, primaryName.isDerived ? "neutral-contrast-low" : "neutral-contrast-high")}
            {secondaryNames.map((name) => getTextValue(name, "neutral-contrast-low"))}
        </ViewLabeledContentWrapper>
    )
}

export interface DerivedNameFormElementEditProps {
    rawName: string | undefined
}

export const DerivedNameFormElementEdit = <DATA, UPDATE extends FieldValues>({
    label,
    rawName,
    field,
    register,
}: EditProps<DATA, UPDATE, string, DerivedNameFormElementEditProps>) => (
    <EditLabeledContentWrapper label={label}>
        <PTextFieldWrapper>
            <input {...register(field._path)} type={"text"} defaultValue={rawName} />
        </PTextFieldWrapper>
    </EditLabeledContentWrapper>
)
