/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntRange,
    IntRangeFromJSON,
    IntRangeFromJSONTyped,
    IntRangeToJSON,
    InteriorCustomizationItemDTOValue,
    InteriorCustomizationItemDTOValueFromJSON,
    InteriorCustomizationItemDTOValueFromJSONTyped,
    InteriorCustomizationItemDTOValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValue
 */
export interface OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValue {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValue
     */
    key: string;
    /**
     * 
     * @type {IntRange}
     * @memberof OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValue
     */
    modelYearRange?: IntRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValue
     */
    derivativeKeys?: Array<string>;
    /**
     * 
     * @type {InteriorCustomizationItemDTOValue}
     * @memberof OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValue
     */
    value: InteriorCustomizationItemDTOValue;
}

export function OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValueFromJSON(json: any): OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValue {
    return OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValueFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': !exists(json, 'modelYearRange') ? undefined : IntRangeFromJSON(json['modelYearRange']),
        'derivativeKeys': !exists(json, 'derivativeKeys') ? undefined : json['derivativeKeys'],
        'value': InteriorCustomizationItemDTOValueFromJSON(json['value']),
    };
}

export function OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValueToJSON(value?: OptionCustomizationConditionalValueDTOInteriorCustomizationItemDTOValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'modelYearRange': IntRangeToJSON(value.modelYearRange),
        'derivativeKeys': value.derivativeKeys,
        'value': InteriorCustomizationItemDTOValueToJSON(value.value),
    };
}


