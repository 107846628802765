import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { ModelGenerationCreateOptionsDTO, ModelGenerationCreateRequestDTO } from "../../../../generated/pdsapi"
import { modelGenerationApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { ModelGenerationCreateAttributesCard } from "./attributes/ModelGenerationCreateAttributesCard"

export const ModelGenerationProductCreateContainerConfig: ProductCreateContainerConfig<ModelGenerationCreateRequestDTO, ModelGenerationCreateOptionsDTO> = {
    headline: "Create new Model Generation",

    create: (item) =>
        modelGenerationApi.create.create({
            modelGenerationCreateRequestDTO: item,
        }),
    getCreateOptions: modelGenerationApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <ModelGenerationCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const ModelGenerationCreateContainer = getProductCreateContainer(ModelGenerationProductCreateContainerConfig)
