import { RequestContext } from "../generated/pdsapi"
import { apiConfiguration } from "../pdsapi/boundApi"
import { getCurrentEnvironment } from "./environment"
import { getCognitoConfig } from "./getCognitoConfig"
import { useCognito } from "@finder/cognito-react"
import { FC, ReactNode } from "react"

export interface CognitoRedirectProps {
    children: ReactNode
}

export const CognitoRedirect: FC<CognitoRedirectProps> = ({ children }) => {
    const environment = getCurrentEnvironment()

    return environment.name === "LOCAL" ? <>{children}</> : <EnvCognitoRedirect environment={environment.name}>{children}</EnvCognitoRedirect>
}

const EnvCognitoRedirect: FC<CognitoRedirectProps & { environment: "DEV" | "PROD" }> = ({ children, environment }) => {
    const { getAuthorizationHeader } = useCognito(getCognitoConfig(environment))

    apiConfiguration.middleware.push({
        pre: async (context: RequestContext) => {
            context.init.headers = { ...context.init.headers, ...(await getAuthorizationHeader()) }
            return {
                url: context.url,
                init: context.init,
            }
        },
    })

    return <>{children}</>
}
