/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    ExteriorColorTagAttributesDTO,
    ExteriorColorTagAttributesDTOFromJSON,
    ExteriorColorTagAttributesDTOToJSON,
    ExteriorColorTagAttributesUpdateDTO,
    ExteriorColorTagAttributesUpdateDTOFromJSON,
    ExteriorColorTagAttributesUpdateDTOToJSON,
    LocalizationsItemDTO,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface DeleteExteriorColorTagRequest {
    key: string;
}

export interface GetAttributesOfExteriorColorTagRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfExteriorColorTagRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfExteriorColorTagRequest {
    key: string;
}

export interface UpdateAttributesOfExteriorColorTagRequest {
    key: string;
    exteriorColorTagAttributesUpdateDTO: ExteriorColorTagAttributesUpdateDTO;
}

export interface UpdateNameOfExteriorColorTagRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class ExteriorColorTagDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a ExteriorColorTag by a given key
     */
    async deleteExteriorColorTagRaw(requestParameters: DeleteExteriorColorTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteExteriorColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color-tags/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a ExteriorColorTag by a given key
     */
    async deleteExteriorColorTag(requestParameters: DeleteExteriorColorTagRequest): Promise<void> {
        await this.deleteExteriorColorTagRaw(requestParameters);
    }

    /**
     * Get attributes of ExteriorColorTag with given key
     */
    async getAttributesOfExteriorColorTagRaw(requestParameters: GetAttributesOfExteriorColorTagRequest): Promise<runtime.ApiResponse<ExteriorColorTagAttributesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfExteriorColorTag.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfExteriorColorTag.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color-tags/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExteriorColorTagAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of ExteriorColorTag with given key
     */
    async getAttributesOfExteriorColorTag(requestParameters: GetAttributesOfExteriorColorTagRequest): Promise<ExteriorColorTagAttributesDTO> {
        const response = await this.getAttributesOfExteriorColorTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of ExteriorColorTag with given key
     */
    async getBaseInformationOfExteriorColorTagRaw(requestParameters: GetBaseInformationOfExteriorColorTagRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfExteriorColorTag.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfExteriorColorTag.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color-tags/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of ExteriorColorTag with given key
     */
    async getBaseInformationOfExteriorColorTag(requestParameters: GetBaseInformationOfExteriorColorTagRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfExteriorColorTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of ExteriorColorTag with given key
     */
    async getLocalizationsOfNameOfExteriorColorTagRaw(requestParameters: GetLocalizationsOfNameOfExteriorColorTagRequest): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfExteriorColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color-tags/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of ExteriorColorTag with given key
     */
    async getLocalizationsOfNameOfExteriorColorTag(requestParameters: GetLocalizationsOfNameOfExteriorColorTagRequest): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfExteriorColorTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing ExteriorColorTag
     */
    async updateAttributesOfExteriorColorTagRaw(requestParameters: UpdateAttributesOfExteriorColorTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfExteriorColorTag.');
        }

        if (requestParameters.exteriorColorTagAttributesUpdateDTO === null || requestParameters.exteriorColorTagAttributesUpdateDTO === undefined) {
            throw new runtime.RequiredError('exteriorColorTagAttributesUpdateDTO','Required parameter requestParameters.exteriorColorTagAttributesUpdateDTO was null or undefined when calling updateAttributesOfExteriorColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/exterior-color-tags/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExteriorColorTagAttributesUpdateDTOToJSON(requestParameters.exteriorColorTagAttributesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing ExteriorColorTag
     */
    async updateAttributesOfExteriorColorTag(requestParameters: UpdateAttributesOfExteriorColorTagRequest): Promise<void> {
        await this.updateAttributesOfExteriorColorTagRaw(requestParameters);
    }

    /**
     * Updates name of an existing ExteriorColorTag
     */
    async updateNameOfExteriorColorTagRaw(requestParameters: UpdateNameOfExteriorColorTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfExteriorColorTag.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfExteriorColorTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/exterior-color-tags/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing ExteriorColorTag
     */
    async updateNameOfExteriorColorTag(requestParameters: UpdateNameOfExteriorColorTagRequest): Promise<void> {
        await this.updateNameOfExteriorColorTagRaw(requestParameters);
    }

}
