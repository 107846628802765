import { PContentWrapper } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC, ReactNode } from "react"
import styled from "styled-components"

interface ViewContentWrapperProps {
    children: ReactNode
}

export const ViewContentWrapper: FC<ViewContentWrapperProps> = (props) => (
    <ContentArea>
        <PContentWrapper>{props.children}</PContentWrapper>
    </ContentArea>
)

const ContentArea = styled.div`
    padding-top: ${spacing[32]};
    padding-bottom: ${spacing[32]};
`
