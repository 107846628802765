/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    EquipmentOptionDTO,
    EquipmentOptionDTOFromJSON,
    EquipmentOptionDTOToJSON,
    EquipmentOptionUpdateDTO,
    EquipmentOptionUpdateDTOFromJSON,
    EquipmentOptionUpdateDTOToJSON,
    LinkedEquipmentCustomizationDTO,
    LinkedEquipmentCustomizationDTOFromJSON,
    LinkedEquipmentCustomizationDTOToJSON,
    OptionsInFamilyDTO,
    OptionsInFamilyDTOFromJSON,
    OptionsInFamilyDTOToJSON,
    SimilarEquipmentCustomizationsDTO,
    SimilarEquipmentCustomizationsDTOFromJSON,
    SimilarEquipmentCustomizationsDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface GetLinkedCustomizationOfEquipmentRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

export interface GetOptionOfEquipmentRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

export interface GetOptionsInFamilyOfEquipmentRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

export interface GetSimilarCustomizationsOfEquipmentRequest {
    orderTypeKey: string;
    optionCode: string;
    languageTag: string;
}

export interface UpdateOptionOfEquipmentRequest {
    orderTypeKey: string;
    optionCode: string;
    equipmentOptionUpdateDTO: EquipmentOptionUpdateDTO;
}

/**
 * 
 */
export class OrderTypeEquipmentDataDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get linkedCustomization of Equipment with given key
     */
    async getLinkedCustomizationOfEquipmentRaw(requestParameters: GetLinkedCustomizationOfEquipmentRequest): Promise<runtime.ApiResponse<LinkedEquipmentCustomizationDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getLinkedCustomizationOfEquipment.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getLinkedCustomizationOfEquipment.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinkedCustomizationOfEquipment.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/equipment/data/linked-customization`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkedEquipmentCustomizationDTOFromJSON(jsonValue));
    }

    /**
     * Get linkedCustomization of Equipment with given key
     */
    async getLinkedCustomizationOfEquipment(requestParameters: GetLinkedCustomizationOfEquipmentRequest): Promise<LinkedEquipmentCustomizationDTO> {
        const response = await this.getLinkedCustomizationOfEquipmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get option of Equipment with given key
     */
    async getOptionOfEquipmentRaw(requestParameters: GetOptionOfEquipmentRequest): Promise<runtime.ApiResponse<EquipmentOptionDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getOptionOfEquipment.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getOptionOfEquipment.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOptionOfEquipment.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/equipment/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentOptionDTOFromJSON(jsonValue));
    }

    /**
     * Get option of Equipment with given key
     */
    async getOptionOfEquipment(requestParameters: GetOptionOfEquipmentRequest): Promise<EquipmentOptionDTO> {
        const response = await this.getOptionOfEquipmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get optionsInFamily of Equipment with given key
     */
    async getOptionsInFamilyOfEquipmentRaw(requestParameters: GetOptionsInFamilyOfEquipmentRequest): Promise<runtime.ApiResponse<OptionsInFamilyDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getOptionsInFamilyOfEquipment.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getOptionsInFamilyOfEquipment.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOptionsInFamilyOfEquipment.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/equipment/data/options-in-family`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionsInFamilyDTOFromJSON(jsonValue));
    }

    /**
     * Get optionsInFamily of Equipment with given key
     */
    async getOptionsInFamilyOfEquipment(requestParameters: GetOptionsInFamilyOfEquipmentRequest): Promise<OptionsInFamilyDTO> {
        const response = await this.getOptionsInFamilyOfEquipmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get similarCustomizations of Equipment with given key
     */
    async getSimilarCustomizationsOfEquipmentRaw(requestParameters: GetSimilarCustomizationsOfEquipmentRequest): Promise<runtime.ApiResponse<SimilarEquipmentCustomizationsDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getSimilarCustomizationsOfEquipment.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getSimilarCustomizationsOfEquipment.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getSimilarCustomizationsOfEquipment.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/equipment/data/similar-customizations`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SimilarEquipmentCustomizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get similarCustomizations of Equipment with given key
     */
    async getSimilarCustomizationsOfEquipment(requestParameters: GetSimilarCustomizationsOfEquipmentRequest): Promise<SimilarEquipmentCustomizationsDTO> {
        const response = await this.getSimilarCustomizationsOfEquipmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates option of an existing Equipment
     */
    async updateOptionOfEquipmentRaw(requestParameters: UpdateOptionOfEquipmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling updateOptionOfEquipment.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling updateOptionOfEquipment.');
        }

        if (requestParameters.equipmentOptionUpdateDTO === null || requestParameters.equipmentOptionUpdateDTO === undefined) {
            throw new runtime.RequiredError('equipmentOptionUpdateDTO','Required parameter requestParameters.equipmentOptionUpdateDTO was null or undefined when calling updateOptionOfEquipment.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/equipment/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EquipmentOptionUpdateDTOToJSON(requestParameters.equipmentOptionUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates option of an existing Equipment
     */
    async updateOptionOfEquipment(requestParameters: UpdateOptionOfEquipmentRequest): Promise<void> {
        await this.updateOptionOfEquipmentRaw(requestParameters);
    }

}
